import React, { createContext, useState, useContext } from 'react';

const MessagesContext = createContext();

export default function MessagesProvider({ children }){

   const [ need, setNeed ] = useState('');
   const [ notNeed, setNotNeed ] = useState('');

   return (
      <div>
         <MessagesContext.Provider
               value={ { need, setNeed, notNeed, setNotNeed } }
         >
               { children }
         </MessagesContext.Provider> 
      </div>
   );
}

export function useMessages() {
   const context = useContext(MessagesContext);

   if(!context) throw new Error("Error");

   const { need, setNeed, notNeed, setNotNeed } = context;

   return {  need, setNeed, notNeed, setNotNeed };
}
