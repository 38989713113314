import React from 'react';
import { Container } from './styles';
import BottomRsColorLine from '../../assets/images/BottomRsColorLine.svg';
import pulsusLogo from '../../assets/images/pulsusLogo.png';

const Footer = () => (
  <Container>
    <div>
      <p>
        Projeto desenvolvido para apoiar a{''}
        <span className="bold">
          {' '}
          Defesa Civil do Estado do Rio Grande do Sul{' '}
        </span>
        no mapeamento unificado de abrigados devido às enchentes.
      </p>
    </div>

    <div>
      <p>Esta iniciativa é uma colaboração entre entidades e voluntários.</p>
    </div>
    <div>
      <p>
        Para mais informações, sugestões ou dúvidas, entre em contato conosco
        através de <b>sosrs@pulsus.mobi</b>
      </p>
    </div>

    <div>
      <p>APOIO:</p>
      <img src={pulsusLogo} alt="" />
    </div>

    <img src={BottomRsColorLine} alt="" />
  </Container>
);

export default Footer;
