import styled from "styled-components";
import Collapse from 'react-bootstrap/Collapse';
import ListGroup from 'react-bootstrap/ListGroup';

export const Container = styled.div`
  position: absolute;
  background-color: transparent;
  padding: 10px 15px;
  height: 44px;
  width: 100%;
  bottom: -49px;

  button.menu {

    @keyframes buttonHoverIn {
      0% {
        transform: translateY(0px);  
      }
      100% {
        transform: translateY(-2px);  
      }
    }

    @keyframes buttonHoverOut {
      0% {
        transform: translateY(-2px);  
      }
      100% {
        transform: translateY(0px);  
      }
    }

    background: transparent;
    border: none;
    border-radius: 50%;

    & {
      animation: buttonHoverOut 0.15s ease-in-out;
      transform: translateY(0px);
    }

    &:hover {
      animation: buttonHoverIn 0.15s ease-in-out;
      transform: translateY(-2px);  
    }
  }
`;

export const MenuItems = styled(Collapse)`
  position: relative;
  margin-top: 10px;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
`;

export const List = styled(ListGroup)`
  max-width: 350px;
  button {
    font-size: 14px;
    
  }
  
  p.subMenu {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .subItem {
    text-indent: 20px;
  }
`;
