import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyle from './styles/globals';

import Routes from './routes';

import Header from './components/Header';
import Footer from './components/Footer';

import MessagesProvider from './context/Messages';


import { Content } from './styles';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <GlobalStyle />
    <Header /> 
      <MessagesProvider>
        <Content>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Content>
      </MessagesProvider>
    <Footer />
  </>
);


