import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route'

import ListPeople from '../pages/ListPeople';
import Cadastro from '../pages/Register';
import Login from '../pages/Login';
// import Links from '../pages/Links';
import Shelters from '../pages/Shelters';
import Message from '../pages/Shelters/Message';
import Home from '../pages/Home';
import PetsRegister from '../pages/PetsRegister'
import ListPets from '../pages/ListPets';

const App = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/desabrigados/cadastro" component={Cadastro} />
    <Route exact isPrivate path="/desabrigados/lista" component={ListPeople} />
    <Route exact path="/abrigos/lista" component={Shelters} />
    <Route path="/abrigos/:id" component={Message} />
    <Route exact path="/rotas" component={() => window.location.href = "https://crbm.app.br/gestao-de-rotas/index.php?class=RotaCardList"} />

    <Route exact path="/8fd69f82000e9b299b9c61469cb0b61cf9e072bb" component={PetsRegister} />
    <Route exact path="/animais-resgatados/lista" component={ListPets} />

    <Route exact path="/canoas/8fd69f82000e9b299b9c61469cb0b61cf9e072bb" component={PetsRegister} />
    <Route exact path="/canoas/animais-resgatados/lista" component={ListPets} />

    <Route exact path="/" component={Home} />
  </Switch>
);

export default App;