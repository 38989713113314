import React, { useEffect, useState } from 'react';
import { ContainerStyled, Title } from './styles';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Service } from '../../../services';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { useParams, useHistory } from "react-router-dom";
import { useMessages } from '../../../context/Messages';

const Message = () => {
    const [ messageNecessity, setMessageNecessity ] = useState(''); 
    const [ messageNoNecessity, setMessageNoNecessity ] = useState(''); 
    const params = useParams();
    const history  = useHistory();
    const [isLoading, setIsloading] = useState(false);
    const [statusRequest, setStatusRequest] = useState('');

    const { need, notNeed } = useMessages();



    useEffect(() => {
        setMessageNecessity(need && need.props.children);
        setMessageNoNecessity(notNeed && notNeed.props.children);
    }, [need, notNeed])

    const handleSubmit = () =>{
        setIsloading(true);

        const dataNeed = {
            message: messageNecessity,
            type_message: 1,
            location_id: params.id
        }

        const dataNotNeed = {
            message: messageNoNecessity,
            type_message: 2,
            location_id: params.id
        }

            Promise.all([
                new Service().createShelterMessage(dataNotNeed),
                new Service().createShelterMessage(dataNeed)
            ]).then(() => {
                setTimeout(() => {
                    setStatusRequest('success');
                    setIsloading(false);
                }, 1000)

                setTimeout(() => {
                    history.push('/abrigos');
                }, 3000)

            }).catch(() => {
                setStatusRequest('danger');
                setIsloading(false);
                setTimeout(() => {
                    history.push('/abrigos');
                }, 2500)
            })
    }


    return (
        <>
            <ContainerStyled>
                <Form>
                    <Title> Envie as mensagens para o abrigo </Title>

                    <Form.Group className="formGroup" controlId="mandatoryData">
                        <Form.Label>Itens necessários</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={4} 
                            placeholder="Adicione aqui os itens e suprimentos que são necessários"
                            onChange={e => setMessageNecessity(e.target.value)}
                            value={messageNecessity && messageNecessity}
                            style={{marginBottom: '14px'}}
                            />
                        <Form.Label>Itens não necessários</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={4} 
                            placeholder="Adicione aqui os itens e suprimentos que já não são mais necessários"
                            onChange={e => setMessageNoNecessity(e.target.value)}
                            value={messageNoNecessity && messageNoNecessity}
                        />
                    </Form.Group>

                    <Form.Group className="formGroup" controlId="mandatoryData">
                        <Button 
                            variant="success" 
                            className='mt-3' 
                            size='lg col-12' 
                            type="button" 
                            onClick={handleSubmit}  
                        >
                            {
                                isLoading &&  (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className='mr-10'
                                    />
                                )
                            }
                            <span className='pl-10'>
                                Enviar Mensagem
                            </span>
                        </Button>
                        { statusRequest === 'success' && (
                            <Alert  variant={statusRequest} className='mt-3'>
                                { statusRequest === 'success' ? 'Mensagem enviada com sucesso!' : 'Erro ao enviar mensagem'}
                            </Alert>
                        )}
                    </Form.Group>
                </Form>
            </ContainerStyled>
        </>
    )
} 

export default Message;