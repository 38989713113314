import React from 'react';
import {
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import { HelperAuthentication } from '../helper-authentication';

const Route = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {

  const token = HelperAuthentication.getCookieByKey("access_token");
  const isValid = token ? Math.floor(new Date().getTime() / 1000) < HelperAuthentication.decodeJwt(token).exp : false;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {

        if (isPrivate && isValid) {
          return <Component />
        }

        if (!isPrivate) {
          return <Component />
        }

        if (isPrivate && !isValid) {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }
      }}
    />
  );
};

export default Route;