import styled from 'styled-components';

export const Buttons = styled.div`
  button {
  }
`;

export const Container = styled.div`
  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    margin-top: 10px;
    margin-bottom: 90px;
    background: #f1f1f1;
    border-color: #858585;
    color: #858585;
  }

  .btn-trash {
    border-color: none !important;
  }
`;

export const TakePictureButton = styled.button`
  position: absolute;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  margin-right: calc(50% - 25px);
  margin-bottom: 7px;
  background-color: #f0f2f5;
  box-shadow: inset 0 0 0 1px #000;
  border: 2px solid #f0f2f5;
`;

export const SwitchCamera = styled.button`
  position: absolute;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  margin-right: 7px;
  margin-bottom: 7px;
  background-color: #198754;
`;

export const Video = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 0 0;
  position: relative;
`;

export const ButtonInformation = styled.p`
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  color: #adb5bd;
  margin-top: 4px;
  b {
    font-weight: 600;
  }
`;
