import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  background: #2e2e2e;
  color: #858585;
  text-align: center;
  padding: 40px 20px 0px 20px;
  gap: 20px;
  margin-bottom: 0;
  margin-top: 50px;

  div {
    align-items: center;
    line-height: 24px;
    .bold {
      font-weight: 700;
    }
  }

  h1 {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
  }

  img {
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;
