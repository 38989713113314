import { Button } from 'react-bootstrap';
import { ModalContainer, Header, Body, Label, StyledModal, Text, Title } from './style';
import { Person } from '../../assets/icons/Person';

const PersonModal = ({
  personData,
  show = false,
  setShow,
  cleanPersonData,
  openActionModal,
}) => {
  const handleClose = () => {
    setShow(false);
    cleanPersonData();
  };

  return (
    <StyledModal show={show} onHide={handleClose} dialogClassName="modal-right">
      <ModalContainer>
        <Header>
          <div className='imageBox'>
            <img src={personData?.photo_link} alt="" />
          </div>
          <Button
            className="actionButton"
            onClick={() => openActionModal(personData.id)}
          >
            <Person />
            <p>Marcar saída</p>
          </Button>
        </Header>
        <Body>
          <section>
            <Title className="name-person">
              {personData?.full_name || '-'}
            </Title>
            <div>
              <Label>CPF:</Label>
              <Text>
                {personData?.cpf || '-'}
              </Text>
            </div>
            <div>
              <Label>Data de nascimento:</Label>
              <Text>
                {personData?.date_of_birth || '-'}
              </Text>
            </div>
            <div>
              <Label>Idade:</Label>
              <Text>
                {personData?.age || '-'}
              </Text>
            </div>
          </section>
          <section>
            <Title>Acolhimento</Title>
            <div>
              <Label>Lugar onde a pessoa foi acolhida:</Label>
              <Text>
                {personData?.location?.name_city || '-'}
              </Text>
            </div>
          </section>
          <section>
            <Title>Informações complementares</Title>
            <div>
              <Label>Cidade de origem:</Label>
              <Text>
                {personData?.city || '-'}
              </Text>
            </div>
            <div>
              <Label>Endereço da cidade de origem:</Label>
              <Text>
                {personData?.address || '-'}
              </Text>
            </div>
            <div>
              <Label>Bairro:</Label>
              <Text>
                {personData?.neighborhood || '-'}
              </Text>
            </div>
            <div>
              <Label>CEP: </Label>
              <Text>
                {personData?.zip_code || '-'}
              </Text>
            </div>
          </section>
        </Body>
      </ModalContainer>
    </StyledModal>
  );
};

export default PersonModal;
