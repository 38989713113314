export const importantURLs = {
  serviceInformations: { 
    title: "Centro de informações",
    description: "Consulte informações e serviços do governo.",
    url :"https://sosenchentes.rs.gov.br/inicial",
  },
  telecommunicationsInfo: {
    title: "Situação das redes de telefonia móvel",
    description: "Consulte a situação das redes de telefonia móvel em Santa Catarina e no Rio Grande do Sul.",
    url :"https://informacoes.anatel.gov.br/paineis/utilidade-publica/recuperacao-das-redes",
  },
  formCompanyLossesInfo: {
    title: "Formulário de perdas para empresas atingidas",
    description: "Consulte informações sobre formulário de perdas para empresas atingidas pelas inundações.",
    url :"https://www.estado.rs.gov.br/estado-libera-formulario-de-perdas-para-empresas-atingidas-pelas-inundacoes",
  }
};