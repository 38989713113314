import styled from 'styled-components';

export const Container = styled.header`
  position: relative;
  height: 250px;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 53.635px;

  .flag {
    z-index: 10000;
    width: 100%;
  }

  .line {
    z-index: 999;
    position: fixed;
    width: 100%;
    min-width: 100%;
    height: fit-content;
    position: absolute;
  }

  .banner {
    object-fit: cover;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .canoarBanner {
    object-fit: cover;
    object-position: 50% 60%;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .help {
    width: 107.27px;
    min-width: 107.27px;
    height: 107.27px;
    min-height: 107.27px;
    top: calc(0px - 53.635px);
    position: relative;
    z-index: 999;
  }

  .canoasLogo {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #343a40;
    width: 200px;
    min-width: 107.27px;
    height: 107.27px;
    top: calc(0px - 53.635px);
    border-radius: 10px;
    position: relative;
    
    img {
      width: 100%;
      min-width: 107.27px;
      height: auto;
      min-height: 107.27px;
    }
  }
`;
