import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Title, Container } from './styles';
import { Service } from '../../services';
import { Redirect } from "react-router-dom";

const Login = () => {
    const service = new Service();

    const [username, setUsername]   = useState("");
    const [password, setPassword]   = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect]   = useState(false);

    const handleLogin = () => {
        setIsLoading(true);
        setIsInvalid(false);

        if (username && password) {
            service.getLogin(username, password).then((data) => {
                setRedirect(true);
                if (data?.token) {
                    document.cookie = `access_token=${data.token}`;
                }
            }).catch(() => {
                setIsLoading(false);
                setIsInvalid(true);
            })
        } else {
            setIsLoading(false);
            setIsInvalid(true);
        }
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return redirect ? <Redirect to="/pessoas/lista" /> : (
        <Container>
            <Form className="login-form" >
                <Title>
                    Login
                </Title>
                <Form.Control isInvalid={isInvalid} onChange={handleUsernameChange} value={username} className="mt-3 mb-3" type="text"     placeholder="Usuário"/>
                <Form.Control isInvalid={isInvalid} onChange={handlePasswordChange} value={password} className="mb-3"      type="password" placeholder="Senha"/>
                <Button disabled={isLoading} onClick={handleLogin} variant="success"  className="submitButton">
                    Entrar
                </Button>
            </Form>
        </Container>
    )
};

export default Login;