export const getBrazilianCities = () => {
    return [
    {"id":1, "id_estado":22, "cidade":"Alta Floresta D'Oeste", "codigo":1100015},
    {"id":2, "id_estado":22, "cidade":"Ariquemes", "codigo":1100023},
    {"id":3, "id_estado":22, "cidade":"Cabixi", "codigo":1100031},
    {"id":4, "id_estado":22, "cidade":"Cacoal", "codigo":1100049},
    {"id":5, "id_estado":22, "cidade":"Cerejeiras", "codigo":1100056},
    {"id":6, "id_estado":22, "cidade":"Colorado do Oeste", "codigo":1100064},
    {"id":7, "id_estado":22, "cidade":"Corumbiara", "codigo":1100072},
    {"id":8, "id_estado":22, "cidade":"Costa Marques", "codigo":1100080},
    {"id":9, "id_estado":22, "cidade":"Espig\u00e3o D'Oeste", "codigo":1100098},
    {"id":10, "id_estado":22, "cidade":"Guajar\u00e1-Mirim", "codigo":1100106},
    {"id":11, "id_estado":22, "cidade":"Jaru", "codigo":1100114},
    {"id":12, "id_estado":22, "cidade":"Ji-Paran\u00e1", "codigo":1100122},
    {"id":13, "id_estado":22, "cidade":"Machadinho D'Oeste", "codigo":1100130},
    {"id":14, "id_estado":22, "cidade":"Nova Brasil\u00e2ndia D'Oeste", "codigo":1100148},
    {"id":15, "id_estado":22, "cidade":"Ouro Preto do Oeste", "codigo":1100155},
    {"id":16, "id_estado":22, "cidade":"Pimenta Bueno", "codigo":1100189},
    {"id":17, "id_estado":22, "cidade":"Porto Velho", "codigo":1100205},
    {"id":18, "id_estado":22, "cidade":"Presidente M\u00e9dici", "codigo":1100254},
    {"id":19, "id_estado":22, "cidade":"Rio Crespo", "codigo":1100262},
    {"id":20, "id_estado":22, "cidade":"Rolim de Moura", "codigo":1100288},
    {"id":21, "id_estado":22, "cidade":"Santa Luzia D'Oeste", "codigo":1100296},
    {"id":22, "id_estado":22, "cidade":"Vilhena", "codigo":1100304},
    {"id":23, "id_estado":22, "cidade":"S\u00e3o Miguel do Guapor\u00e9", "codigo":1100320},
    {"id":24, "id_estado":22, "cidade":"Nova Mamor\u00e9", "codigo":1100338},
    {"id":25, "id_estado":22, "cidade":"Alvorada D'Oeste", "codigo":1100346},
    {"id":26, "id_estado":22, "cidade":"Alto Alegre dos Parecis", "codigo":1100379},
    {"id":27, "id_estado":22, "cidade":"Alto Para\u00edso", "codigo":1100403},
    {"id":28, "id_estado":22, "cidade":"Buritis", "codigo":1100452},
    {"id":29, "id_estado":22, "cidade":"Novo Horizonte do Oeste", "codigo":1100502},
    {"id":30, "id_estado":22, "cidade":"Cacaul\u00e2ndia", "codigo":1100601},
    {"id":31, "id_estado":22, "cidade":"Campo Novo de Rond\u00f4nia", "codigo":1100700},
    {"id":32, "id_estado":22, "cidade":"Candeias do Jamari", "codigo":1100809},
    {"id":33, "id_estado":22, "cidade":"Castanheiras", "codigo":1100908},
    {"id":34, "id_estado":22, "cidade":"Chupinguaia", "codigo":1100924},
    {"id":35, "id_estado":22, "cidade":"Cujubim", "codigo":1100940},
    {"id":36, "id_estado":22, "cidade":"Governador Jorge Teixeira", "codigo":1101005},
    {"id":37, "id_estado":22, "cidade":"Itapu\u00e3 do Oeste", "codigo":1101104},
    {"id":38, "id_estado":22, "cidade":"Ministro Andreazza", "codigo":1101203},
    {"id":39, "id_estado":22, "cidade":"Mirante da Serra", "codigo":1101302},
    {"id":40, "id_estado":22, "cidade":"Monte Negro", "codigo":1101401},
    {"id":41, "id_estado":22, "cidade":"Nova Uni\u00e3o", "codigo":1101435},
    {"id":42, "id_estado":22, "cidade":"Parecis", "codigo":1101450},
    {"id":43, "id_estado":22, "cidade":"Pimenteiras do Oeste", "codigo":1101468},
    {"id":44, "id_estado":22, "cidade":"Primavera de Rond\u00f4nia", "codigo":1101476},
    {"id":45, "id_estado":22, "cidade":"S\u00e3o Felipe D'Oeste", "codigo":1101484},
    {"id":46, "id_estado":22, "cidade":"S\u00e3o Francisco do Guapor\u00e9", "codigo":1101492},
    {"id":47, "id_estado":22, "cidade":"Seringueiras", "codigo":1101500},
    {"id":48, "id_estado":22, "cidade":"Teixeir\u00f3polis", "codigo":1101559},
    {"id":49, "id_estado":22, "cidade":"Theobroma", "codigo":1101609},
    {"id":50, "id_estado":22, "cidade":"Urup\u00e1", "codigo":1101708},
    {"id":51, "id_estado":22, "cidade":"Vale do Anari", "codigo":1101757},
    {"id":52, "id_estado":22, "cidade":"Vale do Para\u00edso", "codigo":1101807},
    {"id":53, "id_estado":1, "cidade":"Acrel\u00e2ndia", "codigo":1200013},
    {"id":54, "id_estado":1, "cidade":"Assis Brasil", "codigo":1200054},
    {"id":55, "id_estado":1, "cidade":"Brasil\u00e9ia", "codigo":1200104},
    {"id":56, "id_estado":1, "cidade":"Bujari", "codigo":1200138},
    {"id":57, "id_estado":1, "cidade":"Capixaba", "codigo":1200179},
    {"id":58, "id_estado":1, "cidade":"Cruzeiro do Sul", "codigo":1200203},
    {"id":59, "id_estado":1, "cidade":"Epitaciol\u00e2ndia", "codigo":1200252},
    {"id":60, "id_estado":1, "cidade":"Feij\u00f3", "codigo":1200302},
    {"id":61, "id_estado":1, "cidade":"Jord\u00e3o", "codigo":1200328},
    {"id":62, "id_estado":1, "cidade":"M\u00e2ncio Lima", "codigo":1200336},
    {"id":63, "id_estado":1, "cidade":"Manoel Urbano", "codigo":1200344},
    {"id":64, "id_estado":1, "cidade":"Marechal Thaumaturgo", "codigo":1200351},
    {"id":65, "id_estado":1, "cidade":"Pl\u00e1cido de Castro", "codigo":1200385},
    {"id":66, "id_estado":1, "cidade":"Porto Walter", "codigo":1200393},
    {"id":67, "id_estado":1, "cidade":"Rio Branco", "codigo":1200401},
    {"id":68, "id_estado":1, "cidade":"Rodrigues Alves", "codigo":1200427},
    {"id":69, "id_estado":1, "cidade":"Santa Rosa do Purus", "codigo":1200435},
    {"id":70, "id_estado":1, "cidade":"Senador Guiomard", "codigo":1200450},
    {"id":71, "id_estado":1, "cidade":"Sena Madureira", "codigo":1200500},
    {"id":72, "id_estado":1, "cidade":"Tarauac\u00e1", "codigo":1200609},
    {"id":73, "id_estado":1, "cidade":"Xapuri", "codigo":1200708},
    {"id":74, "id_estado":1, "cidade":"Porto Acre", "codigo":1200807},
    {"id":75, "id_estado":4, "cidade":"Alvar\u00e3es", "codigo":1300029},
    {"id":76, "id_estado":4, "cidade":"Amatur\u00e1", "codigo":1300060},
    {"id":77, "id_estado":4, "cidade":"Anam\u00e3", "codigo":1300086},
    {"id":78, "id_estado":4, "cidade":"Anori", "codigo":1300102},
    {"id":79, "id_estado":4, "cidade":"Apu\u00ed", "codigo":1300144},
    {"id":80, "id_estado":4, "cidade":"Atalaia do Norte", "codigo":1300201},
    {"id":81, "id_estado":4, "cidade":"Autazes", "codigo":1300300},
    {"id":82, "id_estado":4, "cidade":"Barcelos", "codigo":1300409},
    {"id":83, "id_estado":4, "cidade":"Barreirinha", "codigo":1300508},
    {"id":84, "id_estado":4, "cidade":"Benjamin Constant", "codigo":1300607},
    {"id":85, "id_estado":4, "cidade":"Beruri", "codigo":1300631},
    {"id":86, "id_estado":4, "cidade":"Boa Vista do Ramos", "codigo":1300680},
    {"id":87, "id_estado":4, "cidade":"Boca do Acre", "codigo":1300706},
    {"id":88, "id_estado":4, "cidade":"Borba", "codigo":1300805},
    {"id":89, "id_estado":4, "cidade":"Caapiranga", "codigo":1300839},
    {"id":90, "id_estado":4, "cidade":"Canutama", "codigo":1300904},
    {"id":91, "id_estado":4, "cidade":"Carauari", "codigo":1301001},
    {"id":92, "id_estado":4, "cidade":"Careiro", "codigo":1301100},
    {"id":93, "id_estado":4, "cidade":"Careiro da V\u00e1rzea", "codigo":1301159},
    {"id":94, "id_estado":4, "cidade":"Coari", "codigo":1301209},
    {"id":95, "id_estado":4, "cidade":"Codaj\u00e1s", "codigo":1301308},
    {"id":96, "id_estado":4, "cidade":"Eirunep\u00e9", "codigo":1301407},
    {"id":97, "id_estado":4, "cidade":"Envira", "codigo":1301506},
    {"id":98, "id_estado":4, "cidade":"Fonte Boa", "codigo":1301605},
    {"id":99, "id_estado":4, "cidade":"Guajar\u00e1", "codigo":1301654},
    {"id":100, "id_estado":4, "cidade":"Humait\u00e1", "codigo":1301704},
    {"id":101, "id_estado":4, "cidade":"Ipixuna", "codigo":1301803},
    {"id":102, "id_estado":4, "cidade":"Iranduba", "codigo":1301852},
    {"id":103, "id_estado":4, "cidade":"Itacoatiara", "codigo":1301902},
    {"id":104, "id_estado":4, "cidade":"Itamarati", "codigo":1301951},
    {"id":105, "id_estado":4, "cidade":"Itapiranga", "codigo":1302009},
    {"id":106, "id_estado":4, "cidade":"Japur\u00e1", "codigo":1302108},
    {"id":107, "id_estado":4, "cidade":"Juru\u00e1", "codigo":1302207},
    {"id":108, "id_estado":4, "cidade":"Juta\u00ed", "codigo":1302306},
    {"id":109, "id_estado":4, "cidade":"L\u00e1brea", "codigo":1302405},
    {"id":110, "id_estado":4, "cidade":"Manacapuru", "codigo":1302504},
    {"id":111, "id_estado":4, "cidade":"Manaquiri", "codigo":1302553},
    {"id":112, "id_estado":4, "cidade":"Manaus", "codigo":1302603},
    {"id":113, "id_estado":4, "cidade":"Manicor\u00e9", "codigo":1302702},
    {"id":114, "id_estado":4, "cidade":"Mara\u00e3", "codigo":1302801},
    {"id":115, "id_estado":4, "cidade":"Mau\u00e9s", "codigo":1302900},
    {"id":116, "id_estado":4, "cidade":"Nhamund\u00e1", "codigo":1303007},
    {"id":117, "id_estado":4, "cidade":"Nova Olinda do Norte", "codigo":1303106},
    {"id":118, "id_estado":4, "cidade":"Novo Air\u00e3o", "codigo":1303205},
    {"id":119, "id_estado":4, "cidade":"Novo Aripuan\u00e3", "codigo":1303304},
    {"id":120, "id_estado":4, "cidade":"Parintins", "codigo":1303403},
    {"id":121, "id_estado":4, "cidade":"Pauini", "codigo":1303502},
    {"id":122, "id_estado":4, "cidade":"Presidente Figueiredo", "codigo":1303536},
    {"id":123, "id_estado":4, "cidade":"Rio Preto da Eva", "codigo":1303569},
    {"id":124, "id_estado":4, "cidade":"Santa Isabel do Rio Negro", "codigo":1303601},
    {"id":125, "id_estado":4, "cidade":"Santo Ant\u00f4nio do I\u00e7\u00e1", "codigo":1303700},
    {"id":126, "id_estado":4, "cidade":"S\u00e3o Gabriel da Cachoeira", "codigo":1303809},
    {"id":127, "id_estado":4, "cidade":"S\u00e3o Paulo de Oliven\u00e7a", "codigo":1303908},
    {"id":128, "id_estado":4, "cidade":"S\u00e3o Sebasti\u00e3o do Uatum\u00e3", "codigo":1303957},
    {"id":129, "id_estado":4, "cidade":"Silves", "codigo":1304005},
    {"id":130, "id_estado":4, "cidade":"Tabatinga", "codigo":1304062},
    {"id":131, "id_estado":4, "cidade":"Tapau\u00e1", "codigo":1304104},
    {"id":132, "id_estado":4, "cidade":"Tef\u00e9", "codigo":1304203},
    {"id":133, "id_estado":4, "cidade":"Tonantins", "codigo":1304237},
    {"id":134, "id_estado":4, "cidade":"Uarini", "codigo":1304260},
    {"id":135, "id_estado":4, "cidade":"Urucar\u00e1", "codigo":1304302},
    {"id":136, "id_estado":4, "cidade":"Urucurituba", "codigo":1304401},
    {"id":137, "id_estado":23, "cidade":"Amajari", "codigo":1400027},
    {"id":138, "id_estado":23, "cidade":"Alto Alegre", "codigo":1400050},
    {"id":139, "id_estado":23, "cidade":"Boa Vista", "codigo":1400100},
    {"id":140, "id_estado":23, "cidade":"Bonfim", "codigo":1400159},
    {"id":141, "id_estado":23, "cidade":"Cant\u00e1", "codigo":1400175},
    {"id":142, "id_estado":23, "cidade":"Caracara\u00ed", "codigo":1400209},
    {"id":143, "id_estado":23, "cidade":"Caroebe", "codigo":1400233},
    {"id":144, "id_estado":23, "cidade":"Iracema", "codigo":1400282},
    {"id":145, "id_estado":23, "cidade":"Mucaja\u00ed", "codigo":1400308},
    {"id":146, "id_estado":23, "cidade":"Normandia", "codigo":1400407},
    {"id":147, "id_estado":23, "cidade":"Pacaraima", "codigo":1400456},
    {"id":148, "id_estado":23, "cidade":"Rorain\u00f3polis", "codigo":1400472},
    {"id":149, "id_estado":23, "cidade":"S\u00e3o Jo\u00e3o da Baliza", "codigo":1400506},
    {"id":150, "id_estado":23, "cidade":"S\u00e3o Luiz", "codigo":1400605},
    {"id":151, "id_estado":23, "cidade":"Uiramut\u00e3", "codigo":1400704},
    {"id":152, "id_estado":15, "cidade":"Abaetetuba", "codigo":1500107},
    {"id":153, "id_estado":15, "cidade":"Abel Figueiredo", "codigo":1500131},
    {"id":154, "id_estado":15, "cidade":"Acar\u00e1", "codigo":1500206},
    {"id":155, "id_estado":15, "cidade":"Afu\u00e1", "codigo":1500305},
    {"id":156, "id_estado":15, "cidade":"\u00c1gua Azul do Norte", "codigo":1500347},
    {"id":157, "id_estado":15, "cidade":"Alenquer", "codigo":1500404},
    {"id":158, "id_estado":15, "cidade":"Almeirim", "codigo":1500503},
    {"id":159, "id_estado":15, "cidade":"Altamira", "codigo":1500602},
    {"id":160, "id_estado":15, "cidade":"Anaj\u00e1s", "codigo":1500701},
    {"id":161, "id_estado":15, "cidade":"Ananindeua", "codigo":1500800},
    {"id":162, "id_estado":15, "cidade":"Anapu", "codigo":1500859},
    {"id":163, "id_estado":15, "cidade":"Augusto Corr\u00eaa", "codigo":1500909},
    {"id":164, "id_estado":15, "cidade":"Aurora do Par\u00e1", "codigo":1500958},
    {"id":165, "id_estado":15, "cidade":"Aveiro", "codigo":1501006},
    {"id":166, "id_estado":15, "cidade":"Bagre", "codigo":1501105},
    {"id":167, "id_estado":15, "cidade":"Bai\u00e3o", "codigo":1501204},
    {"id":168, "id_estado":15, "cidade":"Bannach", "codigo":1501253},
    {"id":169, "id_estado":15, "cidade":"Barcarena", "codigo":1501303},
    {"id":170, "id_estado":15, "cidade":"Bel\u00e9m", "codigo":1501402},
    {"id":171, "id_estado":15, "cidade":"Belterra", "codigo":1501451},
    {"id":172, "id_estado":15, "cidade":"Benevides", "codigo":1501501},
    {"id":173, "id_estado":15, "cidade":"Bom Jesus do Tocantins", "codigo":1501576},
    {"id":174, "id_estado":15, "cidade":"Bonito", "codigo":1501600},
    {"id":175, "id_estado":15, "cidade":"Bragan\u00e7a", "codigo":1501709},
    {"id":176, "id_estado":15, "cidade":"Brasil Novo", "codigo":1501725},
    {"id":177, "id_estado":15, "cidade":"Brejo Grande do Araguaia", "codigo":1501758},
    {"id":178, "id_estado":15, "cidade":"Breu Branco", "codigo":1501782},
    {"id":179, "id_estado":15, "cidade":"Breves", "codigo":1501808},
    {"id":180, "id_estado":15, "cidade":"Bujaru", "codigo":1501907},
    {"id":181, "id_estado":15, "cidade":"Cachoeira do Piri\u00e1", "codigo":1501956},
    {"id":182, "id_estado":15, "cidade":"Cachoeira do Arari", "codigo":1502004},
    {"id":183, "id_estado":15, "cidade":"Camet\u00e1", "codigo":1502103},
    {"id":184, "id_estado":15, "cidade":"Cana\u00e3 dos Caraj\u00e1s", "codigo":1502152},
    {"id":185, "id_estado":15, "cidade":"Capanema", "codigo":1502202},
    {"id":186, "id_estado":15, "cidade":"Capit\u00e3o Po\u00e7o", "codigo":1502301},
    {"id":187, "id_estado":15, "cidade":"Castanhal", "codigo":1502400},
    {"id":188, "id_estado":15, "cidade":"Chaves", "codigo":1502509},
    {"id":189, "id_estado":15, "cidade":"Colares", "codigo":1502608},
    {"id":190, "id_estado":15, "cidade":"Concei\u00e7\u00e3o do Araguaia", "codigo":1502707},
    {"id":191, "id_estado":15, "cidade":"Conc\u00f3rdia do Par\u00e1", "codigo":1502756},
    {"id":192, "id_estado":15, "cidade":"Cumaru do Norte", "codigo":1502764},
    {"id":193, "id_estado":15, "cidade":"Curion\u00f3polis", "codigo":1502772},
    {"id":194, "id_estado":15, "cidade":"Curralinho", "codigo":1502806},
    {"id":195, "id_estado":15, "cidade":"Curu\u00e1", "codigo":1502855},
    {"id":196, "id_estado":15, "cidade":"Curu\u00e7\u00e1", "codigo":1502905},
    {"id":197, "id_estado":15, "cidade":"Dom Eliseu", "codigo":1502939},
    {"id":198, "id_estado":15, "cidade":"Eldorado dos Caraj\u00e1s", "codigo":1502954},
    {"id":199, "id_estado":15, "cidade":"Faro", "codigo":1503002},
    {"id":200, "id_estado":15, "cidade":"Floresta do Araguaia", "codigo":1503044},
    {"id":201, "id_estado":15, "cidade":"Garraf\u00e3o do Norte", "codigo":1503077},
    {"id":202, "id_estado":15, "cidade":"Goian\u00e9sia do Par\u00e1", "codigo":1503093},
    {"id":203, "id_estado":15, "cidade":"Gurup\u00e1", "codigo":1503101},
    {"id":204, "id_estado":15, "cidade":"Igarap\u00e9-A\u00e7u", "codigo":1503200},
    {"id":205, "id_estado":15, "cidade":"Igarap\u00e9-Miri", "codigo":1503309},
    {"id":206, "id_estado":15, "cidade":"Inhangapi", "codigo":1503408},
    {"id":207, "id_estado":15, "cidade":"Ipixuna do Par\u00e1", "codigo":1503457},
    {"id":208, "id_estado":15, "cidade":"Irituia", "codigo":1503507},
    {"id":209, "id_estado":15, "cidade":"Itaituba", "codigo":1503606},
    {"id":210, "id_estado":15, "cidade":"Itupiranga", "codigo":1503705},
    {"id":211, "id_estado":15, "cidade":"Jacareacanga", "codigo":1503754},
    {"id":212, "id_estado":15, "cidade":"Jacund\u00e1", "codigo":1503804},
    {"id":213, "id_estado":15, "cidade":"Juruti", "codigo":1503903},
    {"id":214, "id_estado":15, "cidade":"Limoeiro do Ajuru", "codigo":1504000},
    {"id":215, "id_estado":15, "cidade":"M\u00e3e do Rio", "codigo":1504059},
    {"id":216, "id_estado":15, "cidade":"Magalh\u00e3es Barata", "codigo":1504109},
    {"id":217, "id_estado":15, "cidade":"Marab\u00e1", "codigo":1504208},
    {"id":218, "id_estado":15, "cidade":"Maracan\u00e3", "codigo":1504307},
    {"id":219, "id_estado":15, "cidade":"Marapanim", "codigo":1504406},
    {"id":220, "id_estado":15, "cidade":"Marituba", "codigo":1504422},
    {"id":221, "id_estado":15, "cidade":"Medicil\u00e2ndia", "codigo":1504455},
    {"id":222, "id_estado":15, "cidade":"Melga\u00e7o", "codigo":1504505},
    {"id":223, "id_estado":15, "cidade":"Mocajuba", "codigo":1504604},
    {"id":224, "id_estado":15, "cidade":"Moju", "codigo":1504703},
    {"id":225, "id_estado":15, "cidade":"Moju\u00ed dos Campos", "codigo":1504752},
    {"id":226, "id_estado":15, "cidade":"Monte Alegre", "codigo":1504802},
    {"id":227, "id_estado":15, "cidade":"Muan\u00e1", "codigo":1504901},
    {"id":228, "id_estado":15, "cidade":"Nova Esperan\u00e7a do Piri\u00e1", "codigo":1504950},
    {"id":229, "id_estado":15, "cidade":"Nova Ipixuna", "codigo":1504976},
    {"id":230, "id_estado":15, "cidade":"Nova Timboteua", "codigo":1505007},
    {"id":231, "id_estado":15, "cidade":"Novo Progresso", "codigo":1505031},
    {"id":232, "id_estado":15, "cidade":"Novo Repartimento", "codigo":1505064},
    {"id":233, "id_estado":15, "cidade":"\u00d3bidos", "codigo":1505106},
    {"id":234, "id_estado":15, "cidade":"Oeiras do Par\u00e1", "codigo":1505205},
    {"id":235, "id_estado":15, "cidade":"Oriximin\u00e1", "codigo":1505304},
    {"id":236, "id_estado":15, "cidade":"Our\u00e9m", "codigo":1505403},
    {"id":237, "id_estado":15, "cidade":"Ouril\u00e2ndia do Norte", "codigo":1505437},
    {"id":238, "id_estado":15, "cidade":"Pacaj\u00e1", "codigo":1505486},
    {"id":239, "id_estado":15, "cidade":"Palestina do Par\u00e1", "codigo":1505494},
    {"id":240, "id_estado":15, "cidade":"Paragominas", "codigo":1505502},
    {"id":241, "id_estado":15, "cidade":"Parauapebas", "codigo":1505536},
    {"id":242, "id_estado":15, "cidade":"Pau D'Arco", "codigo":1505551},
    {"id":243, "id_estado":15, "cidade":"Peixe-Boi", "codigo":1505601},
    {"id":244, "id_estado":15, "cidade":"Pi\u00e7arra", "codigo":1505635},
    {"id":245, "id_estado":15, "cidade":"Placas", "codigo":1505650},
    {"id":246, "id_estado":15, "cidade":"Ponta de Pedras", "codigo":1505700},
    {"id":247, "id_estado":15, "cidade":"Portel", "codigo":1505809},
    {"id":248, "id_estado":15, "cidade":"Porto de Moz", "codigo":1505908},
    {"id":249, "id_estado":15, "cidade":"Prainha", "codigo":1506005},
    {"id":250, "id_estado":15, "cidade":"Primavera", "codigo":1506104},
    {"id":251, "id_estado":15, "cidade":"Quatipuru", "codigo":1506112},
    {"id":252, "id_estado":15, "cidade":"Reden\u00e7\u00e3o", "codigo":1506138},
    {"id":253, "id_estado":15, "cidade":"Rio Maria", "codigo":1506161},
    {"id":254, "id_estado":15, "cidade":"Rondon do Par\u00e1", "codigo":1506187},
    {"id":255, "id_estado":15, "cidade":"Rur\u00f3polis", "codigo":1506195},
    {"id":256, "id_estado":15, "cidade":"Salin\u00f3polis", "codigo":1506203},
    {"id":257, "id_estado":15, "cidade":"Salvaterra", "codigo":1506302},
    {"id":258, "id_estado":15, "cidade":"Santa B\u00e1rbara do Par\u00e1", "codigo":1506351},
    {"id":259, "id_estado":15, "cidade":"Santa Cruz do Arari", "codigo":1506401},
    {"id":260, "id_estado":15, "cidade":"Santa Isabel do Par\u00e1", "codigo":1506500},
    {"id":261, "id_estado":15, "cidade":"Santa Luzia do Par\u00e1", "codigo":1506559},
    {"id":262, "id_estado":15, "cidade":"Santa Maria das Barreiras", "codigo":1506583},
    {"id":263, "id_estado":15, "cidade":"Santa Maria do Par\u00e1", "codigo":1506609},
    {"id":264, "id_estado":15, "cidade":"Santana do Araguaia", "codigo":1506708},
    {"id":265, "id_estado":15, "cidade":"Santar\u00e9m", "codigo":1506807},
    {"id":266, "id_estado":15, "cidade":"Santar\u00e9m Novo", "codigo":1506906},
    {"id":267, "id_estado":15, "cidade":"Santo Ant\u00f4nio do Tau\u00e1", "codigo":1507003},
    {"id":268, "id_estado":15, "cidade":"S\u00e3o Caetano de Odivelas", "codigo":1507102},
    {"id":269, "id_estado":15, "cidade":"S\u00e3o Domingos do Araguaia", "codigo":1507151},
    {"id":270, "id_estado":15, "cidade":"S\u00e3o Domingos do Capim", "codigo":1507201},
    {"id":271, "id_estado":15, "cidade":"S\u00e3o F\u00e9lix do Xingu", "codigo":1507300},
    {"id":272, "id_estado":15, "cidade":"S\u00e3o Francisco do Par\u00e1", "codigo":1507409},
    {"id":273, "id_estado":15, "cidade":"S\u00e3o Geraldo do Araguaia", "codigo":1507458},
    {"id":274, "id_estado":15, "cidade":"S\u00e3o Jo\u00e3o da Ponta", "codigo":1507466},
    {"id":275, "id_estado":15, "cidade":"S\u00e3o Jo\u00e3o de Pirabas", "codigo":1507474},
    {"id":276, "id_estado":15, "cidade":"S\u00e3o Jo\u00e3o do Araguaia", "codigo":1507508},
    {"id":277, "id_estado":15, "cidade":"S\u00e3o Miguel do Guam\u00e1", "codigo":1507607},
    {"id":278, "id_estado":15, "cidade":"S\u00e3o Sebasti\u00e3o da Boa Vista", "codigo":1507706},
    {"id":279, "id_estado":15, "cidade":"Sapucaia", "codigo":1507755},
    {"id":280, "id_estado":15, "cidade":"Senador Jos\u00e9 Porf\u00edrio", "codigo":1507805},
    {"id":281, "id_estado":15, "cidade":"Soure", "codigo":1507904},
    {"id":282, "id_estado":15, "cidade":"Tail\u00e2ndia", "codigo":1507953},
    {"id":283, "id_estado":15, "cidade":"Terra Alta", "codigo":1507961},
    {"id":284, "id_estado":15, "cidade":"Terra Santa", "codigo":1507979},
    {"id":285, "id_estado":15, "cidade":"Tom\u00e9-A\u00e7u", "codigo":1508001},
    {"id":286, "id_estado":15, "cidade":"Tracuateua", "codigo":1508035},
    {"id":287, "id_estado":15, "cidade":"Trair\u00e3o", "codigo":1508050},
    {"id":288, "id_estado":15, "cidade":"Tucum\u00e3", "codigo":1508084},
    {"id":289, "id_estado":15, "cidade":"Tucuru\u00ed", "codigo":1508100},
    {"id":290, "id_estado":15, "cidade":"Ulian\u00f3polis", "codigo":1508126},
    {"id":291, "id_estado":15, "cidade":"Uruar\u00e1", "codigo":1508159},
    {"id":292, "id_estado":15, "cidade":"Vigia", "codigo":1508209},
    {"id":293, "id_estado":15, "cidade":"Viseu", "codigo":1508308},
    {"id":294, "id_estado":15, "cidade":"Vit\u00f3ria do Xingu", "codigo":1508357},
    {"id":295, "id_estado":15, "cidade":"Xinguara", "codigo":1508407},
    {"id":296, "id_estado":3, "cidade":"Serra do Navio", "codigo":1600055},
    {"id":297, "id_estado":3, "cidade":"Amap\u00e1", "codigo":1600105},
    {"id":298, "id_estado":3, "cidade":"Pedra Branca do Amapari", "codigo":1600154},
    {"id":299, "id_estado":3, "cidade":"Cal\u00e7oene", "codigo":1600204},
    {"id":300, "id_estado":3, "cidade":"Cutias", "codigo":1600212},
    {"id":301, "id_estado":3, "cidade":"Ferreira Gomes", "codigo":1600238},
    {"id":302, "id_estado":3, "cidade":"Itaubal", "codigo":1600253},
    {"id":303, "id_estado":3, "cidade":"Laranjal do Jari", "codigo":1600279},
    {"id":304, "id_estado":3, "cidade":"Macap\u00e1", "codigo":1600303},
    {"id":305, "id_estado":3, "cidade":"Mazag\u00e3o", "codigo":1600402},
    {"id":306, "id_estado":3, "cidade":"Oiapoque", "codigo":1600501},
    {"id":307, "id_estado":3, "cidade":"Porto Grande", "codigo":1600535},
    {"id":308, "id_estado":3, "cidade":"Pracu\u00faba", "codigo":1600550},
    {"id":309, "id_estado":3, "cidade":"Santana", "codigo":1600600},
    {"id":310, "id_estado":3, "cidade":"Tartarugalzinho", "codigo":1600709},
    {"id":311, "id_estado":3, "cidade":"Vit\u00f3ria do Jari", "codigo":1600808},
    {"id":312, "id_estado":27, "cidade":"Abreul\u00e2ndia", "codigo":1700251},
    {"id":313, "id_estado":27, "cidade":"Aguiarn\u00f3polis", "codigo":1700301},
    {"id":314, "id_estado":27, "cidade":"Alian\u00e7a do Tocantins", "codigo":1700350},
    {"id":315, "id_estado":27, "cidade":"Almas", "codigo":1700400},
    {"id":316, "id_estado":27, "cidade":"Alvorada", "codigo":1700707},
    {"id":317, "id_estado":27, "cidade":"Anan\u00e1s", "codigo":1701002},
    {"id":318, "id_estado":27, "cidade":"Angico", "codigo":1701051},
    {"id":319, "id_estado":27, "cidade":"Aparecida do Rio Negro", "codigo":1701101},
    {"id":320, "id_estado":27, "cidade":"Aragominas", "codigo":1701309},
    {"id":321, "id_estado":27, "cidade":"Araguacema", "codigo":1701903},
    {"id":322, "id_estado":27, "cidade":"Aragua\u00e7u", "codigo":1702000},
    {"id":323, "id_estado":27, "cidade":"Aragua\u00edna", "codigo":1702109},
    {"id":324, "id_estado":27, "cidade":"Araguan\u00e3", "codigo":1702158},
    {"id":325, "id_estado":27, "cidade":"Araguatins", "codigo":1702208},
    {"id":326, "id_estado":27, "cidade":"Arapoema", "codigo":1702307},
    {"id":327, "id_estado":27, "cidade":"Arraias", "codigo":1702406},
    {"id":328, "id_estado":27, "cidade":"Augustin\u00f3polis", "codigo":1702554},
    {"id":329, "id_estado":27, "cidade":"Aurora do Tocantins", "codigo":1702703},
    {"id":330, "id_estado":27, "cidade":"Axix\u00e1 do Tocantins", "codigo":1702901},
    {"id":331, "id_estado":27, "cidade":"Baba\u00e7ul\u00e2ndia", "codigo":1703008},
    {"id":332, "id_estado":27, "cidade":"Bandeirantes do Tocantins", "codigo":1703057},
    {"id":333, "id_estado":27, "cidade":"Barra do Ouro", "codigo":1703073},
    {"id":334, "id_estado":27, "cidade":"Barrol\u00e2ndia", "codigo":1703107},
    {"id":335, "id_estado":27, "cidade":"Bernardo Say\u00e3o", "codigo":1703206},
    {"id":336, "id_estado":27, "cidade":"Bom Jesus do Tocantins", "codigo":1703305},
    {"id":337, "id_estado":27, "cidade":"Brasil\u00e2ndia do Tocantins", "codigo":1703602},
    {"id":338, "id_estado":27, "cidade":"Brejinho de Nazar\u00e9", "codigo":1703701},
    {"id":339, "id_estado":27, "cidade":"Buriti do Tocantins", "codigo":1703800},
    {"id":340, "id_estado":27, "cidade":"Cachoeirinha", "codigo":1703826},
    {"id":341, "id_estado":27, "cidade":"Campos Lindos", "codigo":1703842},
    {"id":342, "id_estado":27, "cidade":"Cariri do Tocantins", "codigo":1703867},
    {"id":343, "id_estado":27, "cidade":"Carmol\u00e2ndia", "codigo":1703883},
    {"id":344, "id_estado":27, "cidade":"Carrasco Bonito", "codigo":1703891},
    {"id":345, "id_estado":27, "cidade":"Caseara", "codigo":1703909},
    {"id":346, "id_estado":27, "cidade":"Centen\u00e1rio", "codigo":1704105},
    {"id":347, "id_estado":27, "cidade":"Chapada de Areia", "codigo":1704600},
    {"id":348, "id_estado":27, "cidade":"Chapada da Natividade", "codigo":1705102},
    {"id":349, "id_estado":27, "cidade":"Colinas do Tocantins", "codigo":1705508},
    {"id":350, "id_estado":27, "cidade":"Combinado", "codigo":1705557},
    {"id":351, "id_estado":27, "cidade":"Concei\u00e7\u00e3o do Tocantins", "codigo":1705607},
    {"id":352, "id_estado":27, "cidade":"Couto Magalh\u00e3es", "codigo":1706001},
    {"id":353, "id_estado":27, "cidade":"Cristal\u00e2ndia", "codigo":1706100},
    {"id":354, "id_estado":27, "cidade":"Crix\u00e1s do Tocantins", "codigo":1706258},
    {"id":355, "id_estado":27, "cidade":"Darcin\u00f3polis", "codigo":1706506},
    {"id":356, "id_estado":27, "cidade":"Dian\u00f3polis", "codigo":1707009},
    {"id":357, "id_estado":27, "cidade":"Divin\u00f3polis do Tocantins", "codigo":1707108},
    {"id":358, "id_estado":27, "cidade":"Dois Irm\u00e3os do Tocantins", "codigo":1707207},
    {"id":359, "id_estado":27, "cidade":"Duer\u00e9", "codigo":1707306},
    {"id":360, "id_estado":27, "cidade":"Esperantina", "codigo":1707405},
    {"id":361, "id_estado":27, "cidade":"F\u00e1tima", "codigo":1707553},
    {"id":362, "id_estado":27, "cidade":"Figueir\u00f3polis", "codigo":1707652},
    {"id":363, "id_estado":27, "cidade":"Filad\u00e9lfia", "codigo":1707702},
    {"id":364, "id_estado":27, "cidade":"Formoso do Araguaia", "codigo":1708205},
    {"id":365, "id_estado":27, "cidade":"Fortaleza do Taboc\u00e3o", "codigo":1708254},
    {"id":366, "id_estado":27, "cidade":"Goianorte", "codigo":1708304},
    {"id":367, "id_estado":27, "cidade":"Goiatins", "codigo":1709005},
    {"id":368, "id_estado":27, "cidade":"Guara\u00ed", "codigo":1709302},
    {"id":369, "id_estado":27, "cidade":"Gurupi", "codigo":1709500},
    {"id":370, "id_estado":27, "cidade":"Ipueiras", "codigo":1709807},
    {"id":371, "id_estado":27, "cidade":"Itacaj\u00e1", "codigo":1710508},
    {"id":372, "id_estado":27, "cidade":"Itaguatins", "codigo":1710706},
    {"id":373, "id_estado":27, "cidade":"Itapiratins", "codigo":1710904},
    {"id":374, "id_estado":27, "cidade":"Itapor\u00e3 do Tocantins", "codigo":1711100},
    {"id":375, "id_estado":27, "cidade":"Ja\u00fa do Tocantins", "codigo":1711506},
    {"id":376, "id_estado":27, "cidade":"Juarina", "codigo":1711803},
    {"id":377, "id_estado":27, "cidade":"Lagoa da Confus\u00e3o", "codigo":1711902},
    {"id":378, "id_estado":27, "cidade":"Lagoa do Tocantins", "codigo":1711951},
    {"id":379, "id_estado":27, "cidade":"Lajeado", "codigo":1712009},
    {"id":380, "id_estado":27, "cidade":"Lavandeira", "codigo":1712157},
    {"id":381, "id_estado":27, "cidade":"Lizarda", "codigo":1712405},
    {"id":382, "id_estado":27, "cidade":"Luzin\u00f3polis", "codigo":1712454},
    {"id":383, "id_estado":27, "cidade":"Marian\u00f3polis do Tocantins", "codigo":1712504},
    {"id":384, "id_estado":27, "cidade":"Mateiros", "codigo":1712702},
    {"id":385, "id_estado":27, "cidade":"Mauril\u00e2ndia do Tocantins", "codigo":1712801},
    {"id":386, "id_estado":27, "cidade":"Miracema do Tocantins", "codigo":1713205},
    {"id":387, "id_estado":27, "cidade":"Miranorte", "codigo":1713304},
    {"id":388, "id_estado":27, "cidade":"Monte do Carmo", "codigo":1713601},
    {"id":389, "id_estado":27, "cidade":"Monte Santo do Tocantins", "codigo":1713700},
    {"id":390, "id_estado":27, "cidade":"Palmeiras do Tocantins", "codigo":1713809},
    {"id":391, "id_estado":27, "cidade":"Muricil\u00e2ndia", "codigo":1713957},
    {"id":392, "id_estado":27, "cidade":"Natividade", "codigo":1714203},
    {"id":393, "id_estado":27, "cidade":"Nazar\u00e9", "codigo":1714302},
    {"id":394, "id_estado":27, "cidade":"Nova Olinda", "codigo":1714880},
    {"id":395, "id_estado":27, "cidade":"Nova Rosal\u00e2ndia", "codigo":1715002},
    {"id":396, "id_estado":27, "cidade":"Novo Acordo", "codigo":1715101},
    {"id":397, "id_estado":27, "cidade":"Novo Alegre", "codigo":1715150},
    {"id":398, "id_estado":27, "cidade":"Novo Jardim", "codigo":1715259},
    {"id":399, "id_estado":27, "cidade":"Oliveira de F\u00e1tima", "codigo":1715507},
    {"id":400, "id_estado":27, "cidade":"Palmeirante", "codigo":1715705},
    {"id":401, "id_estado":27, "cidade":"Palmeir\u00f3polis", "codigo":1715754},
    {"id":402, "id_estado":27, "cidade":"Para\u00edso do Tocantins", "codigo":1716109},
    {"id":403, "id_estado":27, "cidade":"Paran\u00e3", "codigo":1716208},
    {"id":404, "id_estado":27, "cidade":"Pau D'Arco", "codigo":1716307},
    {"id":405, "id_estado":27, "cidade":"Pedro Afonso", "codigo":1716505},
    {"id":406, "id_estado":27, "cidade":"Peixe", "codigo":1716604},
    {"id":407, "id_estado":27, "cidade":"Pequizeiro", "codigo":1716653},
    {"id":408, "id_estado":27, "cidade":"Colm\u00e9ia", "codigo":1716703},
    {"id":409, "id_estado":27, "cidade":"Pindorama do Tocantins", "codigo":1717008},
    {"id":410, "id_estado":27, "cidade":"Piraqu\u00ea", "codigo":1717206},
    {"id":411, "id_estado":27, "cidade":"Pium", "codigo":1717503},
    {"id":412, "id_estado":27, "cidade":"Ponte Alta do Bom Jesus", "codigo":1717800},
    {"id":413, "id_estado":27, "cidade":"Ponte Alta do Tocantins", "codigo":1717909},
    {"id":414, "id_estado":27, "cidade":"Porto Alegre do Tocantins", "codigo":1718006},
    {"id":415, "id_estado":27, "cidade":"Porto Nacional", "codigo":1718204},
    {"id":416, "id_estado":27, "cidade":"Praia Norte", "codigo":1718303},
    {"id":417, "id_estado":27, "cidade":"Presidente Kennedy", "codigo":1718402},
    {"id":418, "id_estado":27, "cidade":"Pugmil", "codigo":1718451},
    {"id":419, "id_estado":27, "cidade":"Recursol\u00e2ndia", "codigo":1718501},
    {"id":420, "id_estado":27, "cidade":"Riachinho", "codigo":1718550},
    {"id":421, "id_estado":27, "cidade":"Rio da Concei\u00e7\u00e3o", "codigo":1718659},
    {"id":422, "id_estado":27, "cidade":"Rio dos Bois", "codigo":1718709},
    {"id":423, "id_estado":27, "cidade":"Rio Sono", "codigo":1718758},
    {"id":424, "id_estado":27, "cidade":"Sampaio", "codigo":1718808},
    {"id":425, "id_estado":27, "cidade":"Sandol\u00e2ndia", "codigo":1718840},
    {"id":426, "id_estado":27, "cidade":"Santa F\u00e9 do Araguaia", "codigo":1718865},
    {"id":427, "id_estado":27, "cidade":"Santa Maria do Tocantins", "codigo":1718881},
    {"id":428, "id_estado":27, "cidade":"Santa Rita do Tocantins", "codigo":1718899},
    {"id":429, "id_estado":27, "cidade":"Santa Rosa do Tocantins", "codigo":1718907},
    {"id":430, "id_estado":27, "cidade":"Santa Tereza do Tocantins", "codigo":1719004},
    {"id":431, "id_estado":27, "cidade":"Santa Terezinha do Tocantins", "codigo":1720002},
    {"id":432, "id_estado":27, "cidade":"S\u00e3o Bento do Tocantins", "codigo":1720101},
    {"id":433, "id_estado":27, "cidade":"S\u00e3o F\u00e9lix do Tocantins", "codigo":1720150},
    {"id":434, "id_estado":27, "cidade":"S\u00e3o Miguel do Tocantins", "codigo":1720200},
    {"id":435, "id_estado":27, "cidade":"S\u00e3o Salvador do Tocantins", "codigo":1720259},
    {"id":436, "id_estado":27, "cidade":"S\u00e3o Sebasti\u00e3o do Tocantins", "codigo":1720309},
    {"id":437, "id_estado":27, "cidade":"S\u00e3o Val\u00e9rio", "codigo":1720499},
    {"id":438, "id_estado":27, "cidade":"Silvan\u00f3polis", "codigo":1720655},
    {"id":439, "id_estado":27, "cidade":"S\u00edtio Novo do Tocantins", "codigo":1720804},
    {"id":440, "id_estado":27, "cidade":"Sucupira", "codigo":1720853},
    {"id":441, "id_estado":27, "cidade":"Taguatinga", "codigo":1720903},
    {"id":442, "id_estado":27, "cidade":"Taipas do Tocantins", "codigo":1720937},
    {"id":443, "id_estado":27, "cidade":"Talism\u00e3", "codigo":1720978},
    {"id":444, "id_estado":27, "cidade":"Palmas", "codigo":1721000},
    {"id":445, "id_estado":27, "cidade":"Tocant\u00ednia", "codigo":1721109},
    {"id":446, "id_estado":27, "cidade":"Tocantin\u00f3polis", "codigo":1721208},
    {"id":447, "id_estado":27, "cidade":"Tupirama", "codigo":1721257},
    {"id":448, "id_estado":27, "cidade":"Tupiratins", "codigo":1721307},
    {"id":449, "id_estado":27, "cidade":"Wanderl\u00e2ndia", "codigo":1722081},
    {"id":450, "id_estado":27, "cidade":"Xambio\u00e1", "codigo":1722107},
    {"id":451, "id_estado":10, "cidade":"A\u00e7ail\u00e2ndia", "codigo":2100055},
    {"id":452, "id_estado":10, "cidade":"Afonso Cunha", "codigo":2100105},
    {"id":453, "id_estado":10, "cidade":"\u00c1gua Doce do Maranh\u00e3o", "codigo":2100154},
    {"id":454, "id_estado":10, "cidade":"Alc\u00e2ntara", "codigo":2100204},
    {"id":455, "id_estado":10, "cidade":"Aldeias Altas", "codigo":2100303},
    {"id":456, "id_estado":10, "cidade":"Altamira do Maranh\u00e3o", "codigo":2100402},
    {"id":457, "id_estado":10, "cidade":"Alto Alegre do Maranh\u00e3o", "codigo":2100436},
    {"id":458, "id_estado":10, "cidade":"Alto Alegre do Pindar\u00e9", "codigo":2100477},
    {"id":459, "id_estado":10, "cidade":"Alto Parna\u00edba", "codigo":2100501},
    {"id":460, "id_estado":10, "cidade":"Amap\u00e1 do Maranh\u00e3o", "codigo":2100550},
    {"id":461, "id_estado":10, "cidade":"Amarante do Maranh\u00e3o", "codigo":2100600},
    {"id":462, "id_estado":10, "cidade":"Anajatuba", "codigo":2100709},
    {"id":463, "id_estado":10, "cidade":"Anapurus", "codigo":2100808},
    {"id":464, "id_estado":10, "cidade":"Apicum-A\u00e7u", "codigo":2100832},
    {"id":465, "id_estado":10, "cidade":"Araguan\u00e3", "codigo":2100873},
    {"id":466, "id_estado":10, "cidade":"Araioses", "codigo":2100907},
    {"id":467, "id_estado":10, "cidade":"Arame", "codigo":2100956},
    {"id":468, "id_estado":10, "cidade":"Arari", "codigo":2101004},
    {"id":469, "id_estado":10, "cidade":"Axix\u00e1", "codigo":2101103},
    {"id":470, "id_estado":10, "cidade":"Bacabal", "codigo":2101202},
    {"id":471, "id_estado":10, "cidade":"Bacabeira", "codigo":2101251},
    {"id":472, "id_estado":10, "cidade":"Bacuri", "codigo":2101301},
    {"id":473, "id_estado":10, "cidade":"Bacurituba", "codigo":2101350},
    {"id":474, "id_estado":10, "cidade":"Balsas", "codigo":2101400},
    {"id":475, "id_estado":10, "cidade":"Bar\u00e3o de Graja\u00fa", "codigo":2101509},
    {"id":476, "id_estado":10, "cidade":"Barra do Corda", "codigo":2101608},
    {"id":477, "id_estado":10, "cidade":"Barreirinhas", "codigo":2101707},
    {"id":478, "id_estado":10, "cidade":"Bel\u00e1gua", "codigo":2101731},
    {"id":479, "id_estado":10, "cidade":"Bela Vista do Maranh\u00e3o", "codigo":2101772},
    {"id":480, "id_estado":10, "cidade":"Benedito Leite", "codigo":2101806},
    {"id":481, "id_estado":10, "cidade":"Bequim\u00e3o", "codigo":2101905},
    {"id":482, "id_estado":10, "cidade":"Bernardo do Mearim", "codigo":2101939},
    {"id":483, "id_estado":10, "cidade":"Boa Vista do Gurupi", "codigo":2101970},
    {"id":484, "id_estado":10, "cidade":"Bom Jardim", "codigo":2102002},
    {"id":485, "id_estado":10, "cidade":"Bom Jesus das Selvas", "codigo":2102036},
    {"id":486, "id_estado":10, "cidade":"Bom Lugar", "codigo":2102077},
    {"id":487, "id_estado":10, "cidade":"Brejo", "codigo":2102101},
    {"id":488, "id_estado":10, "cidade":"Brejo de Areia", "codigo":2102150},
    {"id":489, "id_estado":10, "cidade":"Buriti", "codigo":2102200},
    {"id":490, "id_estado":10, "cidade":"Buriti Bravo", "codigo":2102309},
    {"id":491, "id_estado":10, "cidade":"Buriticupu", "codigo":2102325},
    {"id":492, "id_estado":10, "cidade":"Buritirana", "codigo":2102358},
    {"id":493, "id_estado":10, "cidade":"Cachoeira Grande", "codigo":2102374},
    {"id":494, "id_estado":10, "cidade":"Cajapi\u00f3", "codigo":2102408},
    {"id":495, "id_estado":10, "cidade":"Cajari", "codigo":2102507},
    {"id":496, "id_estado":10, "cidade":"Campestre do Maranh\u00e3o", "codigo":2102556},
    {"id":497, "id_estado":10, "cidade":"C\u00e2ndido Mendes", "codigo":2102606},
    {"id":498, "id_estado":10, "cidade":"Cantanhede", "codigo":2102705},
    {"id":499, "id_estado":10, "cidade":"Capinzal do Norte", "codigo":2102754},
    {"id":500, "id_estado":10, "cidade":"Carolina", "codigo":2102804},
    {"id":501, "id_estado":10, "cidade":"Carutapera", "codigo":2102903},
    {"id":502, "id_estado":10, "cidade":"Caxias", "codigo":2103000},
    {"id":503, "id_estado":10, "cidade":"Cedral", "codigo":2103109},
    {"id":504, "id_estado":10, "cidade":"Central do Maranh\u00e3o", "codigo":2103125},
    {"id":505, "id_estado":10, "cidade":"Centro do Guilherme", "codigo":2103158},
    {"id":506, "id_estado":10, "cidade":"Centro Novo do Maranh\u00e3o", "codigo":2103174},
    {"id":507, "id_estado":10, "cidade":"Chapadinha", "codigo":2103208},
    {"id":508, "id_estado":10, "cidade":"Cidel\u00e2ndia", "codigo":2103257},
    {"id":509, "id_estado":10, "cidade":"Cod\u00f3", "codigo":2103307},
    {"id":510, "id_estado":10, "cidade":"Coelho Neto", "codigo":2103406},
    {"id":511, "id_estado":10, "cidade":"Colinas", "codigo":2103505},
    {"id":512, "id_estado":10, "cidade":"Concei\u00e7\u00e3o do Lago-A\u00e7u", "codigo":2103554},
    {"id":513, "id_estado":10, "cidade":"Coroat\u00e1", "codigo":2103604},
    {"id":514, "id_estado":10, "cidade":"Cururupu", "codigo":2103703},
    {"id":515, "id_estado":10, "cidade":"Davin\u00f3polis", "codigo":2103752},
    {"id":516, "id_estado":10, "cidade":"Dom Pedro", "codigo":2103802},
    {"id":517, "id_estado":10, "cidade":"Duque Bacelar", "codigo":2103901},
    {"id":518, "id_estado":10, "cidade":"Esperantin\u00f3polis", "codigo":2104008},
    {"id":519, "id_estado":10, "cidade":"Estreito", "codigo":2104057},
    {"id":520, "id_estado":10, "cidade":"Feira Nova do Maranh\u00e3o", "codigo":2104073},
    {"id":521, "id_estado":10, "cidade":"Fernando Falc\u00e3o", "codigo":2104081},
    {"id":522, "id_estado":10, "cidade":"Formosa da Serra Negra", "codigo":2104099},
    {"id":523, "id_estado":10, "cidade":"Fortaleza dos Nogueiras", "codigo":2104107},
    {"id":524, "id_estado":10, "cidade":"Fortuna", "codigo":2104206},
    {"id":525, "id_estado":10, "cidade":"Godofredo Viana", "codigo":2104305},
    {"id":526, "id_estado":10, "cidade":"Gon\u00e7alves Dias", "codigo":2104404},
    {"id":527, "id_estado":10, "cidade":"Governador Archer", "codigo":2104503},
    {"id":528, "id_estado":10, "cidade":"Governador Edison Lob\u00e3o", "codigo":2104552},
    {"id":529, "id_estado":10, "cidade":"Governador Eug\u00eanio Barros", "codigo":2104602},
    {"id":530, "id_estado":10, "cidade":"Governador Luiz Rocha", "codigo":2104628},
    {"id":531, "id_estado":10, "cidade":"Governador Newton Bello", "codigo":2104651},
    {"id":532, "id_estado":10, "cidade":"Governador Nunes Freire", "codigo":2104677},
    {"id":533, "id_estado":10, "cidade":"Gra\u00e7a Aranha", "codigo":2104701},
    {"id":534, "id_estado":10, "cidade":"Graja\u00fa", "codigo":2104800},
    {"id":535, "id_estado":10, "cidade":"Guimar\u00e3es", "codigo":2104909},
    {"id":536, "id_estado":10, "cidade":"Humberto de Campos", "codigo":2105005},
    {"id":537, "id_estado":10, "cidade":"Icatu", "codigo":2105104},
    {"id":538, "id_estado":10, "cidade":"Igarap\u00e9 do Meio", "codigo":2105153},
    {"id":539, "id_estado":10, "cidade":"Igarap\u00e9 Grande", "codigo":2105203},
    {"id":540, "id_estado":10, "cidade":"Imperatriz", "codigo":2105302},
    {"id":541, "id_estado":10, "cidade":"Itaipava do Graja\u00fa", "codigo":2105351},
    {"id":542, "id_estado":10, "cidade":"Itapecuru Mirim", "codigo":2105401},
    {"id":543, "id_estado":10, "cidade":"Itinga do Maranh\u00e3o", "codigo":2105427},
    {"id":544, "id_estado":10, "cidade":"Jatob\u00e1", "codigo":2105450},
    {"id":545, "id_estado":10, "cidade":"Jenipapo dos Vieiras", "codigo":2105476},
    {"id":546, "id_estado":10, "cidade":"Jo\u00e3o Lisboa", "codigo":2105500},
    {"id":547, "id_estado":10, "cidade":"Josel\u00e2ndia", "codigo":2105609},
    {"id":548, "id_estado":10, "cidade":"Junco do Maranh\u00e3o", "codigo":2105658},
    {"id":549, "id_estado":10, "cidade":"Lago da Pedra", "codigo":2105708},
    {"id":550, "id_estado":10, "cidade":"Lago do Junco", "codigo":2105807},
    {"id":551, "id_estado":10, "cidade":"Lago Verde", "codigo":2105906},
    {"id":552, "id_estado":10, "cidade":"Lagoa do Mato", "codigo":2105922},
    {"id":553, "id_estado":10, "cidade":"Lago dos Rodrigues", "codigo":2105948},
    {"id":554, "id_estado":10, "cidade":"Lagoa Grande do Maranh\u00e3o", "codigo":2105963},
    {"id":555, "id_estado":10, "cidade":"Lajeado Novo", "codigo":2105989},
    {"id":556, "id_estado":10, "cidade":"Lima Campos", "codigo":2106003},
    {"id":557, "id_estado":10, "cidade":"Loreto", "codigo":2106102},
    {"id":558, "id_estado":10, "cidade":"Lu\u00eds Domingues", "codigo":2106201},
    {"id":559, "id_estado":10, "cidade":"Magalh\u00e3es de Almeida", "codigo":2106300},
    {"id":560, "id_estado":10, "cidade":"Maraca\u00e7um\u00e9", "codigo":2106326},
    {"id":561, "id_estado":10, "cidade":"Maraj\u00e1 do Sena", "codigo":2106359},
    {"id":562, "id_estado":10, "cidade":"Maranh\u00e3ozinho", "codigo":2106375},
    {"id":563, "id_estado":10, "cidade":"Mata Roma", "codigo":2106409},
    {"id":564, "id_estado":10, "cidade":"Matinha", "codigo":2106508},
    {"id":565, "id_estado":10, "cidade":"Mat\u00f5es", "codigo":2106607},
    {"id":566, "id_estado":10, "cidade":"Mat\u00f5es do Norte", "codigo":2106631},
    {"id":567, "id_estado":10, "cidade":"Milagres do Maranh\u00e3o", "codigo":2106672},
    {"id":568, "id_estado":10, "cidade":"Mirador", "codigo":2106706},
    {"id":569, "id_estado":10, "cidade":"Miranda do Norte", "codigo":2106755},
    {"id":570, "id_estado":10, "cidade":"Mirinzal", "codigo":2106805},
    {"id":571, "id_estado":10, "cidade":"Mon\u00e7\u00e3o", "codigo":2106904},
    {"id":572, "id_estado":10, "cidade":"Montes Altos", "codigo":2107001},
    {"id":573, "id_estado":10, "cidade":"Morros", "codigo":2107100},
    {"id":574, "id_estado":10, "cidade":"Nina Rodrigues", "codigo":2107209},
    {"id":575, "id_estado":10, "cidade":"Nova Colinas", "codigo":2107258},
    {"id":576, "id_estado":10, "cidade":"Nova Iorque", "codigo":2107308},
    {"id":577, "id_estado":10, "cidade":"Nova Olinda do Maranh\u00e3o", "codigo":2107357},
    {"id":578, "id_estado":10, "cidade":"Olho D'\u00c1gua das Cunh\u00e3s", "codigo":2107407},
    {"id":579, "id_estado":10, "cidade":"Olinda Nova do Maranh\u00e3o", "codigo":2107456},
    {"id":580, "id_estado":10, "cidade":"Pa\u00e7o do Lumiar", "codigo":2107506},
    {"id":581, "id_estado":10, "cidade":"Palmeir\u00e2ndia", "codigo":2107605},
    {"id":582, "id_estado":10, "cidade":"Paraibano", "codigo":2107704},
    {"id":583, "id_estado":10, "cidade":"Parnarama", "codigo":2107803},
    {"id":584, "id_estado":10, "cidade":"Passagem Franca", "codigo":2107902},
    {"id":585, "id_estado":10, "cidade":"Pastos Bons", "codigo":2108009},
    {"id":586, "id_estado":10, "cidade":"Paulino Neves", "codigo":2108058},
    {"id":587, "id_estado":10, "cidade":"Paulo Ramos", "codigo":2108108},
    {"id":588, "id_estado":10, "cidade":"Pedreiras", "codigo":2108207},
    {"id":589, "id_estado":10, "cidade":"Pedro do Ros\u00e1rio", "codigo":2108256},
    {"id":590, "id_estado":10, "cidade":"Penalva", "codigo":2108306},
    {"id":591, "id_estado":10, "cidade":"Peri Mirim", "codigo":2108405},
    {"id":592, "id_estado":10, "cidade":"Peritor\u00f3", "codigo":2108454},
    {"id":593, "id_estado":10, "cidade":"Pindar\u00e9-Mirim", "codigo":2108504},
    {"id":594, "id_estado":10, "cidade":"Pinheiro", "codigo":2108603},
    {"id":595, "id_estado":10, "cidade":"Pio XII", "codigo":2108702},
    {"id":596, "id_estado":10, "cidade":"Pirapemas", "codigo":2108801},
    {"id":597, "id_estado":10, "cidade":"Po\u00e7\u00e3o de Pedras", "codigo":2108900},
    {"id":598, "id_estado":10, "cidade":"Porto Franco", "codigo":2109007},
    {"id":599, "id_estado":10, "cidade":"Porto Rico do Maranh\u00e3o", "codigo":2109056},
    {"id":600, "id_estado":10, "cidade":"Presidente Dutra", "codigo":2109106},
    {"id":601, "id_estado":10, "cidade":"Presidente Juscelino", "codigo":2109205},
    {"id":602, "id_estado":10, "cidade":"Presidente M\u00e9dici", "codigo":2109239},
    {"id":603, "id_estado":10, "cidade":"Presidente Sarney", "codigo":2109270},
    {"id":604, "id_estado":10, "cidade":"Presidente Vargas", "codigo":2109304},
    {"id":605, "id_estado":10, "cidade":"Primeira Cruz", "codigo":2109403},
    {"id":606, "id_estado":10, "cidade":"Raposa", "codigo":2109452},
    {"id":607, "id_estado":10, "cidade":"Riach\u00e3o", "codigo":2109502},
    {"id":608, "id_estado":10, "cidade":"Ribamar Fiquene", "codigo":2109551},
    {"id":609, "id_estado":10, "cidade":"Ros\u00e1rio", "codigo":2109601},
    {"id":610, "id_estado":10, "cidade":"Samba\u00edba", "codigo":2109700},
    {"id":611, "id_estado":10, "cidade":"Santa Filomena do Maranh\u00e3o", "codigo":2109759},
    {"id":612, "id_estado":10, "cidade":"Santa Helena", "codigo":2109809},
    {"id":613, "id_estado":10, "cidade":"Santa In\u00eas", "codigo":2109908},
    {"id":614, "id_estado":10, "cidade":"Santa Luzia", "codigo":2110005},
    {"id":615, "id_estado":10, "cidade":"Santa Luzia do Paru\u00e1", "codigo":2110039},
    {"id":616, "id_estado":10, "cidade":"Santa Quit\u00e9ria do Maranh\u00e3o", "codigo":2110104},
    {"id":617, "id_estado":10, "cidade":"Santa Rita", "codigo":2110203},
    {"id":618, "id_estado":10, "cidade":"Santana do Maranh\u00e3o", "codigo":2110237},
    {"id":619, "id_estado":10, "cidade":"Santo Amaro do Maranh\u00e3o", "codigo":2110278},
    {"id":620, "id_estado":10, "cidade":"Santo Ant\u00f4nio dos Lopes", "codigo":2110302},
    {"id":621, "id_estado":10, "cidade":"S\u00e3o Benedito do Rio Preto", "codigo":2110401},
    {"id":622, "id_estado":10, "cidade":"S\u00e3o Bento", "codigo":2110500},
    {"id":623, "id_estado":10, "cidade":"S\u00e3o Bernardo", "codigo":2110609},
    {"id":624, "id_estado":10, "cidade":"S\u00e3o Domingos do Azeit\u00e3o", "codigo":2110658},
    {"id":625, "id_estado":10, "cidade":"S\u00e3o Domingos do Maranh\u00e3o", "codigo":2110708},
    {"id":626, "id_estado":10, "cidade":"S\u00e3o F\u00e9lix de Balsas", "codigo":2110807},
    {"id":627, "id_estado":10, "cidade":"S\u00e3o Francisco do Brej\u00e3o", "codigo":2110856},
    {"id":628, "id_estado":10, "cidade":"S\u00e3o Francisco do Maranh\u00e3o", "codigo":2110906},
    {"id":629, "id_estado":10, "cidade":"S\u00e3o Jo\u00e3o Batista", "codigo":2111003},
    {"id":630, "id_estado":10, "cidade":"S\u00e3o Jo\u00e3o do Car\u00fa", "codigo":2111029},
    {"id":631, "id_estado":10, "cidade":"S\u00e3o Jo\u00e3o do Para\u00edso", "codigo":2111052},
    {"id":632, "id_estado":10, "cidade":"S\u00e3o Jo\u00e3o do Soter", "codigo":2111078},
    {"id":633, "id_estado":10, "cidade":"S\u00e3o Jo\u00e3o dos Patos", "codigo":2111102},
    {"id":634, "id_estado":10, "cidade":"S\u00e3o Jos\u00e9 de Ribamar", "codigo":2111201},
    {"id":635, "id_estado":10, "cidade":"S\u00e3o Jos\u00e9 dos Bas\u00edlios", "codigo":2111250},
    {"id":636, "id_estado":10, "cidade":"S\u00e3o Lu\u00eds", "codigo":2111300},
    {"id":637, "id_estado":10, "cidade":"S\u00e3o Lu\u00eds Gonzaga do Maranh\u00e3o", "codigo":2111409},
    {"id":638, "id_estado":10, "cidade":"S\u00e3o Mateus do Maranh\u00e3o", "codigo":2111508},
    {"id":639, "id_estado":10, "cidade":"S\u00e3o Pedro da \u00c1gua Branca", "codigo":2111532},
    {"id":640, "id_estado":10, "cidade":"S\u00e3o Pedro dos Crentes", "codigo":2111573},
    {"id":641, "id_estado":10, "cidade":"S\u00e3o Raimundo das Mangabeiras", "codigo":2111607},
    {"id":642, "id_estado":10, "cidade":"S\u00e3o Raimundo do Doca Bezerra", "codigo":2111631},
    {"id":643, "id_estado":10, "cidade":"S\u00e3o Roberto", "codigo":2111672},
    {"id":644, "id_estado":10, "cidade":"S\u00e3o Vicente Ferrer", "codigo":2111706},
    {"id":645, "id_estado":10, "cidade":"Satubinha", "codigo":2111722},
    {"id":646, "id_estado":10, "cidade":"Senador Alexandre Costa", "codigo":2111748},
    {"id":647, "id_estado":10, "cidade":"Senador La Rocque", "codigo":2111763},
    {"id":648, "id_estado":10, "cidade":"Serrano do Maranh\u00e3o", "codigo":2111789},
    {"id":649, "id_estado":10, "cidade":"S\u00edtio Novo", "codigo":2111805},
    {"id":650, "id_estado":10, "cidade":"Sucupira do Norte", "codigo":2111904},
    {"id":651, "id_estado":10, "cidade":"Sucupira do Riach\u00e3o", "codigo":2111953},
    {"id":652, "id_estado":10, "cidade":"Tasso Fragoso", "codigo":2112001},
    {"id":653, "id_estado":10, "cidade":"Timbiras", "codigo":2112100},
    {"id":654, "id_estado":10, "cidade":"Timon", "codigo":2112209},
    {"id":655, "id_estado":10, "cidade":"Trizidela do Vale", "codigo":2112233},
    {"id":656, "id_estado":10, "cidade":"Tufil\u00e2ndia", "codigo":2112274},
    {"id":657, "id_estado":10, "cidade":"Tuntum", "codigo":2112308},
    {"id":658, "id_estado":10, "cidade":"Turia\u00e7u", "codigo":2112407},
    {"id":659, "id_estado":10, "cidade":"Turil\u00e2ndia", "codigo":2112456},
    {"id":660, "id_estado":10, "cidade":"Tut\u00f3ia", "codigo":2112506},
    {"id":661, "id_estado":10, "cidade":"Urbano Santos", "codigo":2112605},
    {"id":662, "id_estado":10, "cidade":"Vargem Grande", "codigo":2112704},
    {"id":663, "id_estado":10, "cidade":"Viana", "codigo":2112803},
    {"id":664, "id_estado":10, "cidade":"Vila Nova dos Mart\u00edrios", "codigo":2112852},
    {"id":665, "id_estado":10, "cidade":"Vit\u00f3ria do Mearim", "codigo":2112902},
    {"id":666, "id_estado":10, "cidade":"Vitorino Freire", "codigo":2113009},
    {"id":667, "id_estado":10, "cidade":"Z\u00e9 Doca", "codigo":2114007},
    {"id":668, "id_estado":18, "cidade":"Acau\u00e3", "codigo":2200053},
    {"id":669, "id_estado":18, "cidade":"Agricol\u00e2ndia", "codigo":2200103},
    {"id":670, "id_estado":18, "cidade":"\u00c1gua Branca", "codigo":2200202},
    {"id":671, "id_estado":18, "cidade":"Alagoinha do Piau\u00ed", "codigo":2200251},
    {"id":672, "id_estado":18, "cidade":"Alegrete do Piau\u00ed", "codigo":2200277},
    {"id":673, "id_estado":18, "cidade":"Alto Long\u00e1", "codigo":2200301},
    {"id":674, "id_estado":18, "cidade":"Altos", "codigo":2200400},
    {"id":675, "id_estado":18, "cidade":"Alvorada do Gurgu\u00e9ia", "codigo":2200459},
    {"id":676, "id_estado":18, "cidade":"Amarante", "codigo":2200509},
    {"id":677, "id_estado":18, "cidade":"Angical do Piau\u00ed", "codigo":2200608},
    {"id":678, "id_estado":18, "cidade":"An\u00edsio de Abreu", "codigo":2200707},
    {"id":679, "id_estado":18, "cidade":"Ant\u00f4nio Almeida", "codigo":2200806},
    {"id":680, "id_estado":18, "cidade":"Aroazes", "codigo":2200905},
    {"id":681, "id_estado":18, "cidade":"Aroeiras do Itaim", "codigo":2200954},
    {"id":682, "id_estado":18, "cidade":"Arraial", "codigo":2201002},
    {"id":683, "id_estado":18, "cidade":"Assun\u00e7\u00e3o do Piau\u00ed", "codigo":2201051},
    {"id":684, "id_estado":18, "cidade":"Avelino Lopes", "codigo":2201101},
    {"id":685, "id_estado":18, "cidade":"Baixa Grande do Ribeiro", "codigo":2201150},
    {"id":686, "id_estado":18, "cidade":"Barra D'Alc\u00e2ntara", "codigo":2201176},
    {"id":687, "id_estado":18, "cidade":"Barras", "codigo":2201200},
    {"id":688, "id_estado":18, "cidade":"Barreiras do Piau\u00ed", "codigo":2201309},
    {"id":689, "id_estado":18, "cidade":"Barro Duro", "codigo":2201408},
    {"id":690, "id_estado":18, "cidade":"Batalha", "codigo":2201507},
    {"id":691, "id_estado":18, "cidade":"Bela Vista do Piau\u00ed", "codigo":2201556},
    {"id":692, "id_estado":18, "cidade":"Bel\u00e9m do Piau\u00ed", "codigo":2201572},
    {"id":693, "id_estado":18, "cidade":"Beneditinos", "codigo":2201606},
    {"id":694, "id_estado":18, "cidade":"Bertol\u00ednia", "codigo":2201705},
    {"id":695, "id_estado":18, "cidade":"Bet\u00e2nia do Piau\u00ed", "codigo":2201739},
    {"id":696, "id_estado":18, "cidade":"Boa Hora", "codigo":2201770},
    {"id":697, "id_estado":18, "cidade":"Bocaina", "codigo":2201804},
    {"id":698, "id_estado":18, "cidade":"Bom Jesus", "codigo":2201903},
    {"id":699, "id_estado":18, "cidade":"Bom Princ\u00edpio do Piau\u00ed", "codigo":2201919},
    {"id":700, "id_estado":18, "cidade":"Bonfim do Piau\u00ed", "codigo":2201929},
    {"id":701, "id_estado":18, "cidade":"Boqueir\u00e3o do Piau\u00ed", "codigo":2201945},
    {"id":702, "id_estado":18, "cidade":"Brasileira", "codigo":2201960},
    {"id":703, "id_estado":18, "cidade":"Brejo do Piau\u00ed", "codigo":2201988},
    {"id":704, "id_estado":18, "cidade":"Buriti dos Lopes", "codigo":2202000},
    {"id":705, "id_estado":18, "cidade":"Buriti dos Montes", "codigo":2202026},
    {"id":706, "id_estado":18, "cidade":"Cabeceiras do Piau\u00ed", "codigo":2202059},
    {"id":707, "id_estado":18, "cidade":"Cajazeiras do Piau\u00ed", "codigo":2202075},
    {"id":708, "id_estado":18, "cidade":"Cajueiro da Praia", "codigo":2202083},
    {"id":709, "id_estado":18, "cidade":"Caldeir\u00e3o Grande do Piau\u00ed", "codigo":2202091},
    {"id":710, "id_estado":18, "cidade":"Campinas do Piau\u00ed", "codigo":2202109},
    {"id":711, "id_estado":18, "cidade":"Campo Alegre do Fidalgo", "codigo":2202117},
    {"id":712, "id_estado":18, "cidade":"Campo Grande do Piau\u00ed", "codigo":2202133},
    {"id":713, "id_estado":18, "cidade":"Campo Largo do Piau\u00ed", "codigo":2202174},
    {"id":714, "id_estado":18, "cidade":"Campo Maior", "codigo":2202208},
    {"id":715, "id_estado":18, "cidade":"Canavieira", "codigo":2202251},
    {"id":716, "id_estado":18, "cidade":"Canto do Buriti", "codigo":2202307},
    {"id":717, "id_estado":18, "cidade":"Capit\u00e3o de Campos", "codigo":2202406},
    {"id":718, "id_estado":18, "cidade":"Capit\u00e3o Gerv\u00e1sio Oliveira", "codigo":2202455},
    {"id":719, "id_estado":18, "cidade":"Caracol", "codigo":2202505},
    {"id":720, "id_estado":18, "cidade":"Cara\u00fabas do Piau\u00ed", "codigo":2202539},
    {"id":721, "id_estado":18, "cidade":"Caridade do Piau\u00ed", "codigo":2202554},
    {"id":722, "id_estado":18, "cidade":"Castelo do Piau\u00ed", "codigo":2202604},
    {"id":723, "id_estado":18, "cidade":"Caxing\u00f3", "codigo":2202653},
    {"id":724, "id_estado":18, "cidade":"Cocal", "codigo":2202703},
    {"id":725, "id_estado":18, "cidade":"Cocal de Telha", "codigo":2202711},
    {"id":726, "id_estado":18, "cidade":"Cocal dos Alves", "codigo":2202729},
    {"id":727, "id_estado":18, "cidade":"Coivaras", "codigo":2202737},
    {"id":728, "id_estado":18, "cidade":"Col\u00f4nia do Gurgu\u00e9ia", "codigo":2202752},
    {"id":729, "id_estado":18, "cidade":"Col\u00f4nia do Piau\u00ed", "codigo":2202778},
    {"id":730, "id_estado":18, "cidade":"Concei\u00e7\u00e3o do Canind\u00e9", "codigo":2202802},
    {"id":731, "id_estado":18, "cidade":"Coronel Jos\u00e9 Dias", "codigo":2202851},
    {"id":732, "id_estado":18, "cidade":"Corrente", "codigo":2202901},
    {"id":733, "id_estado":18, "cidade":"Cristal\u00e2ndia do Piau\u00ed", "codigo":2203008},
    {"id":734, "id_estado":18, "cidade":"Cristino Castro", "codigo":2203107},
    {"id":735, "id_estado":18, "cidade":"Curimat\u00e1", "codigo":2203206},
    {"id":736, "id_estado":18, "cidade":"Currais", "codigo":2203230},
    {"id":737, "id_estado":18, "cidade":"Curralinhos", "codigo":2203255},
    {"id":738, "id_estado":18, "cidade":"Curral Novo do Piau\u00ed", "codigo":2203271},
    {"id":739, "id_estado":18, "cidade":"Demerval Lob\u00e3o", "codigo":2203305},
    {"id":740, "id_estado":18, "cidade":"Dirceu Arcoverde", "codigo":2203354},
    {"id":741, "id_estado":18, "cidade":"Dom Expedito Lopes", "codigo":2203404},
    {"id":742, "id_estado":18, "cidade":"Domingos Mour\u00e3o", "codigo":2203420},
    {"id":743, "id_estado":18, "cidade":"Dom Inoc\u00eancio", "codigo":2203453},
    {"id":744, "id_estado":18, "cidade":"Elesb\u00e3o Veloso", "codigo":2203503},
    {"id":745, "id_estado":18, "cidade":"Eliseu Martins", "codigo":2203602},
    {"id":746, "id_estado":18, "cidade":"Esperantina", "codigo":2203701},
    {"id":747, "id_estado":18, "cidade":"Fartura do Piau\u00ed", "codigo":2203750},
    {"id":748, "id_estado":18, "cidade":"Flores do Piau\u00ed", "codigo":2203800},
    {"id":749, "id_estado":18, "cidade":"Floresta do Piau\u00ed", "codigo":2203859},
    {"id":750, "id_estado":18, "cidade":"Floriano", "codigo":2203909},
    {"id":751, "id_estado":18, "cidade":"Francin\u00f3polis", "codigo":2204006},
    {"id":752, "id_estado":18, "cidade":"Francisco Ayres", "codigo":2204105},
    {"id":753, "id_estado":18, "cidade":"Francisco Macedo", "codigo":2204154},
    {"id":754, "id_estado":18, "cidade":"Francisco Santos", "codigo":2204204},
    {"id":755, "id_estado":18, "cidade":"Fronteiras", "codigo":2204303},
    {"id":756, "id_estado":18, "cidade":"Geminiano", "codigo":2204352},
    {"id":757, "id_estado":18, "cidade":"Gilbu\u00e9s", "codigo":2204402},
    {"id":758, "id_estado":18, "cidade":"Guadalupe", "codigo":2204501},
    {"id":759, "id_estado":18, "cidade":"Guaribas", "codigo":2204550},
    {"id":760, "id_estado":18, "cidade":"Hugo Napole\u00e3o", "codigo":2204600},
    {"id":761, "id_estado":18, "cidade":"Ilha Grande", "codigo":2204659},
    {"id":762, "id_estado":18, "cidade":"Inhuma", "codigo":2204709},
    {"id":763, "id_estado":18, "cidade":"Ipiranga do Piau\u00ed", "codigo":2204808},
    {"id":764, "id_estado":18, "cidade":"Isa\u00edas Coelho", "codigo":2204907},
    {"id":765, "id_estado":18, "cidade":"Itain\u00f3polis", "codigo":2205003},
    {"id":766, "id_estado":18, "cidade":"Itaueira", "codigo":2205102},
    {"id":767, "id_estado":18, "cidade":"Jacobina do Piau\u00ed", "codigo":2205151},
    {"id":768, "id_estado":18, "cidade":"Jaic\u00f3s", "codigo":2205201},
    {"id":769, "id_estado":18, "cidade":"Jardim do Mulato", "codigo":2205250},
    {"id":770, "id_estado":18, "cidade":"Jatob\u00e1 do Piau\u00ed", "codigo":2205276},
    {"id":771, "id_estado":18, "cidade":"Jerumenha", "codigo":2205300},
    {"id":772, "id_estado":18, "cidade":"Jo\u00e3o Costa", "codigo":2205359},
    {"id":773, "id_estado":18, "cidade":"Joaquim Pires", "codigo":2205409},
    {"id":774, "id_estado":18, "cidade":"Joca Marques", "codigo":2205458},
    {"id":775, "id_estado":18, "cidade":"Jos\u00e9 de Freitas", "codigo":2205508},
    {"id":776, "id_estado":18, "cidade":"Juazeiro do Piau\u00ed", "codigo":2205516},
    {"id":777, "id_estado":18, "cidade":"J\u00falio Borges", "codigo":2205524},
    {"id":778, "id_estado":18, "cidade":"Jurema", "codigo":2205532},
    {"id":779, "id_estado":18, "cidade":"Lagoinha do Piau\u00ed", "codigo":2205540},
    {"id":780, "id_estado":18, "cidade":"Lagoa Alegre", "codigo":2205557},
    {"id":781, "id_estado":18, "cidade":"Lagoa do Barro do Piau\u00ed", "codigo":2205565},
    {"id":782, "id_estado":18, "cidade":"Lagoa de S\u00e3o Francisco", "codigo":2205573},
    {"id":783, "id_estado":18, "cidade":"Lagoa do Piau\u00ed", "codigo":2205581},
    {"id":784, "id_estado":18, "cidade":"Lagoa do S\u00edtio", "codigo":2205599},
    {"id":785, "id_estado":18, "cidade":"Landri Sales", "codigo":2205607},
    {"id":786, "id_estado":18, "cidade":"Lu\u00eds Correia", "codigo":2205706},
    {"id":787, "id_estado":18, "cidade":"Luzil\u00e2ndia", "codigo":2205805},
    {"id":788, "id_estado":18, "cidade":"Madeiro", "codigo":2205854},
    {"id":789, "id_estado":18, "cidade":"Manoel Em\u00eddio", "codigo":2205904},
    {"id":790, "id_estado":18, "cidade":"Marcol\u00e2ndia", "codigo":2205953},
    {"id":791, "id_estado":18, "cidade":"Marcos Parente", "codigo":2206001},
    {"id":792, "id_estado":18, "cidade":"Massap\u00ea do Piau\u00ed", "codigo":2206050},
    {"id":793, "id_estado":18, "cidade":"Matias Ol\u00edmpio", "codigo":2206100},
    {"id":794, "id_estado":18, "cidade":"Miguel Alves", "codigo":2206209},
    {"id":795, "id_estado":18, "cidade":"Miguel Le\u00e3o", "codigo":2206308},
    {"id":796, "id_estado":18, "cidade":"Milton Brand\u00e3o", "codigo":2206357},
    {"id":797, "id_estado":18, "cidade":"Monsenhor Gil", "codigo":2206407},
    {"id":798, "id_estado":18, "cidade":"Monsenhor Hip\u00f3lito", "codigo":2206506},
    {"id":799, "id_estado":18, "cidade":"Monte Alegre do Piau\u00ed", "codigo":2206605},
    {"id":800, "id_estado":18, "cidade":"Morro Cabe\u00e7a no Tempo", "codigo":2206654},
    {"id":801, "id_estado":18, "cidade":"Morro do Chap\u00e9u do Piau\u00ed", "codigo":2206670},
    {"id":802, "id_estado":18, "cidade":"Murici dos Portelas", "codigo":2206696},
    {"id":803, "id_estado":18, "cidade":"Nazar\u00e9 do Piau\u00ed", "codigo":2206704},
    {"id":804, "id_estado":18, "cidade":"Naz\u00e1ria", "codigo":2206720},
    {"id":805, "id_estado":18, "cidade":"Nossa Senhora de Nazar\u00e9", "codigo":2206753},
    {"id":806, "id_estado":18, "cidade":"Nossa Senhora dos Rem\u00e9dios", "codigo":2206803},
    {"id":807, "id_estado":18, "cidade":"Novo Oriente do Piau\u00ed", "codigo":2206902},
    {"id":808, "id_estado":18, "cidade":"Novo Santo Ant\u00f4nio", "codigo":2206951},
    {"id":809, "id_estado":18, "cidade":"Oeiras", "codigo":2207009},
    {"id":810, "id_estado":18, "cidade":"Olho D'\u00c1gua do Piau\u00ed", "codigo":2207108},
    {"id":811, "id_estado":18, "cidade":"Padre Marcos", "codigo":2207207},
    {"id":812, "id_estado":18, "cidade":"Paes Landim", "codigo":2207306},
    {"id":813, "id_estado":18, "cidade":"Paje\u00fa do Piau\u00ed", "codigo":2207355},
    {"id":814, "id_estado":18, "cidade":"Palmeira do Piau\u00ed", "codigo":2207405},
    {"id":815, "id_estado":18, "cidade":"Palmeirais", "codigo":2207504},
    {"id":816, "id_estado":18, "cidade":"Paquet\u00e1", "codigo":2207553},
    {"id":817, "id_estado":18, "cidade":"Parnagu\u00e1", "codigo":2207603},
    {"id":818, "id_estado":18, "cidade":"Parna\u00edba", "codigo":2207702},
    {"id":819, "id_estado":18, "cidade":"Passagem Franca do Piau\u00ed", "codigo":2207751},
    {"id":820, "id_estado":18, "cidade":"Patos do Piau\u00ed", "codigo":2207777},
    {"id":821, "id_estado":18, "cidade":"Pau D'Arco do Piau\u00ed", "codigo":2207793},
    {"id":822, "id_estado":18, "cidade":"Paulistana", "codigo":2207801},
    {"id":823, "id_estado":18, "cidade":"Pavussu", "codigo":2207850},
    {"id":824, "id_estado":18, "cidade":"Pedro II", "codigo":2207900},
    {"id":825, "id_estado":18, "cidade":"Pedro Laurentino", "codigo":2207934},
    {"id":826, "id_estado":18, "cidade":"Nova Santa Rita", "codigo":2207959},
    {"id":827, "id_estado":18, "cidade":"Picos", "codigo":2208007},
    {"id":828, "id_estado":18, "cidade":"Pimenteiras", "codigo":2208106},
    {"id":829, "id_estado":18, "cidade":"Pio IX", "codigo":2208205},
    {"id":830, "id_estado":18, "cidade":"Piracuruca", "codigo":2208304},
    {"id":831, "id_estado":18, "cidade":"Piripiri", "codigo":2208403},
    {"id":832, "id_estado":18, "cidade":"Porto", "codigo":2208502},
    {"id":833, "id_estado":18, "cidade":"Porto Alegre do Piau\u00ed", "codigo":2208551},
    {"id":834, "id_estado":18, "cidade":"Prata do Piau\u00ed", "codigo":2208601},
    {"id":835, "id_estado":18, "cidade":"Queimada Nova", "codigo":2208650},
    {"id":836, "id_estado":18, "cidade":"Reden\u00e7\u00e3o do Gurgu\u00e9ia", "codigo":2208700},
    {"id":837, "id_estado":18, "cidade":"Regenera\u00e7\u00e3o", "codigo":2208809},
    {"id":838, "id_estado":18, "cidade":"Riacho Frio", "codigo":2208858},
    {"id":839, "id_estado":18, "cidade":"Ribeira do Piau\u00ed", "codigo":2208874},
    {"id":840, "id_estado":18, "cidade":"Ribeiro Gon\u00e7alves", "codigo":2208908},
    {"id":841, "id_estado":18, "cidade":"Rio Grande do Piau\u00ed", "codigo":2209005},
    {"id":842, "id_estado":18, "cidade":"Santa Cruz do Piau\u00ed", "codigo":2209104},
    {"id":843, "id_estado":18, "cidade":"Santa Cruz dos Milagres", "codigo":2209153},
    {"id":844, "id_estado":18, "cidade":"Santa Filomena", "codigo":2209203},
    {"id":845, "id_estado":18, "cidade":"Santa Luz", "codigo":2209302},
    {"id":846, "id_estado":18, "cidade":"Santana do Piau\u00ed", "codigo":2209351},
    {"id":847, "id_estado":18, "cidade":"Santa Rosa do Piau\u00ed", "codigo":2209377},
    {"id":848, "id_estado":18, "cidade":"Santo Ant\u00f4nio de Lisboa", "codigo":2209401},
    {"id":849, "id_estado":18, "cidade":"Santo Ant\u00f4nio dos Milagres", "codigo":2209450},
    {"id":850, "id_estado":18, "cidade":"Santo In\u00e1cio do Piau\u00ed", "codigo":2209500},
    {"id":851, "id_estado":18, "cidade":"S\u00e3o Braz do Piau\u00ed", "codigo":2209559},
    {"id":852, "id_estado":18, "cidade":"S\u00e3o F\u00e9lix do Piau\u00ed", "codigo":2209609},
    {"id":853, "id_estado":18, "cidade":"S\u00e3o Francisco de Assis do Piau\u00ed", "codigo":2209658},
    {"id":854, "id_estado":18, "cidade":"S\u00e3o Francisco do Piau\u00ed", "codigo":2209708},
    {"id":855, "id_estado":18, "cidade":"S\u00e3o Gon\u00e7alo do Gurgu\u00e9ia", "codigo":2209757},
    {"id":856, "id_estado":18, "cidade":"S\u00e3o Gon\u00e7alo do Piau\u00ed", "codigo":2209807},
    {"id":857, "id_estado":18, "cidade":"S\u00e3o Jo\u00e3o da Canabrava", "codigo":2209856},
    {"id":858, "id_estado":18, "cidade":"S\u00e3o Jo\u00e3o da Fronteira", "codigo":2209872},
    {"id":859, "id_estado":18, "cidade":"S\u00e3o Jo\u00e3o da Serra", "codigo":2209906},
    {"id":860, "id_estado":18, "cidade":"S\u00e3o Jo\u00e3o da Varjota", "codigo":2209955},
    {"id":861, "id_estado":18, "cidade":"S\u00e3o Jo\u00e3o do Arraial", "codigo":2209971},
    {"id":862, "id_estado":18, "cidade":"S\u00e3o Jo\u00e3o do Piau\u00ed", "codigo":2210003},
    {"id":863, "id_estado":18, "cidade":"S\u00e3o Jos\u00e9 do Divino", "codigo":2210052},
    {"id":864, "id_estado":18, "cidade":"S\u00e3o Jos\u00e9 do Peixe", "codigo":2210102},
    {"id":865, "id_estado":18, "cidade":"S\u00e3o Jos\u00e9 do Piau\u00ed", "codigo":2210201},
    {"id":866, "id_estado":18, "cidade":"S\u00e3o Juli\u00e3o", "codigo":2210300},
    {"id":867, "id_estado":18, "cidade":"S\u00e3o Louren\u00e7o do Piau\u00ed", "codigo":2210359},
    {"id":868, "id_estado":18, "cidade":"S\u00e3o Luis do Piau\u00ed", "codigo":2210375},
    {"id":869, "id_estado":18, "cidade":"S\u00e3o Miguel da Baixa Grande", "codigo":2210383},
    {"id":870, "id_estado":18, "cidade":"S\u00e3o Miguel do Fidalgo", "codigo":2210391},
    {"id":871, "id_estado":18, "cidade":"S\u00e3o Miguel do Tapuio", "codigo":2210409},
    {"id":872, "id_estado":18, "cidade":"S\u00e3o Pedro do Piau\u00ed", "codigo":2210508},
    {"id":873, "id_estado":18, "cidade":"S\u00e3o Raimundo Nonato", "codigo":2210607},
    {"id":874, "id_estado":18, "cidade":"Sebasti\u00e3o Barros", "codigo":2210623},
    {"id":875, "id_estado":18, "cidade":"Sebasti\u00e3o Leal", "codigo":2210631},
    {"id":876, "id_estado":18, "cidade":"Sigefredo Pacheco", "codigo":2210656},
    {"id":877, "id_estado":18, "cidade":"Sim\u00f5es", "codigo":2210706},
    {"id":878, "id_estado":18, "cidade":"Simpl\u00edcio Mendes", "codigo":2210805},
    {"id":879, "id_estado":18, "cidade":"Socorro do Piau\u00ed", "codigo":2210904},
    {"id":880, "id_estado":18, "cidade":"Sussuapara", "codigo":2210938},
    {"id":881, "id_estado":18, "cidade":"Tamboril do Piau\u00ed", "codigo":2210953},
    {"id":882, "id_estado":18, "cidade":"Tanque do Piau\u00ed", "codigo":2210979},
    {"id":883, "id_estado":18, "cidade":"Teresina", "codigo":2211001},
    {"id":884, "id_estado":18, "cidade":"Uni\u00e3o", "codigo":2211100},
    {"id":885, "id_estado":18, "cidade":"Uru\u00e7u\u00ed", "codigo":2211209},
    {"id":886, "id_estado":18, "cidade":"Valen\u00e7a do Piau\u00ed", "codigo":2211308},
    {"id":887, "id_estado":18, "cidade":"V\u00e1rzea Branca", "codigo":2211357},
    {"id":888, "id_estado":18, "cidade":"V\u00e1rzea Grande", "codigo":2211407},
    {"id":889, "id_estado":18, "cidade":"Vera Mendes", "codigo":2211506},
    {"id":890, "id_estado":18, "cidade":"Vila Nova do Piau\u00ed", "codigo":2211605},
    {"id":891, "id_estado":18, "cidade":"Wall Ferraz", "codigo":2211704},
    {"id":892, "id_estado":6, "cidade":"Abaiara", "codigo":2300101},
    {"id":893, "id_estado":6, "cidade":"Acarape", "codigo":2300150},
    {"id":894, "id_estado":6, "cidade":"Acara\u00fa", "codigo":2300200},
    {"id":895, "id_estado":6, "cidade":"Acopiara", "codigo":2300309},
    {"id":896, "id_estado":6, "cidade":"Aiuaba", "codigo":2300408},
    {"id":897, "id_estado":6, "cidade":"Alc\u00e2ntaras", "codigo":2300507},
    {"id":898, "id_estado":6, "cidade":"Altaneira", "codigo":2300606},
    {"id":899, "id_estado":6, "cidade":"Alto Santo", "codigo":2300705},
    {"id":900, "id_estado":6, "cidade":"Amontada", "codigo":2300754},
    {"id":901, "id_estado":6, "cidade":"Antonina do Norte", "codigo":2300804},
    {"id":902, "id_estado":6, "cidade":"Apuiar\u00e9s", "codigo":2300903},
    {"id":903, "id_estado":6, "cidade":"Aquiraz", "codigo":2301000},
    {"id":904, "id_estado":6, "cidade":"Aracati", "codigo":2301109},
    {"id":905, "id_estado":6, "cidade":"Aracoiaba", "codigo":2301208},
    {"id":906, "id_estado":6, "cidade":"Ararend\u00e1", "codigo":2301257},
    {"id":907, "id_estado":6, "cidade":"Araripe", "codigo":2301307},
    {"id":908, "id_estado":6, "cidade":"Aratuba", "codigo":2301406},
    {"id":909, "id_estado":6, "cidade":"Arneiroz", "codigo":2301505},
    {"id":910, "id_estado":6, "cidade":"Assar\u00e9", "codigo":2301604},
    {"id":911, "id_estado":6, "cidade":"Aurora", "codigo":2301703},
    {"id":912, "id_estado":6, "cidade":"Baixio", "codigo":2301802},
    {"id":913, "id_estado":6, "cidade":"Banabui\u00fa", "codigo":2301851},
    {"id":914, "id_estado":6, "cidade":"Barbalha", "codigo":2301901},
    {"id":915, "id_estado":6, "cidade":"Barreira", "codigo":2301950},
    {"id":916, "id_estado":6, "cidade":"Barro", "codigo":2302008},
    {"id":917, "id_estado":6, "cidade":"Barroquinha", "codigo":2302057},
    {"id":918, "id_estado":6, "cidade":"Baturit\u00e9", "codigo":2302107},
    {"id":919, "id_estado":6, "cidade":"Beberibe", "codigo":2302206},
    {"id":920, "id_estado":6, "cidade":"Bela Cruz", "codigo":2302305},
    {"id":921, "id_estado":6, "cidade":"Boa Viagem", "codigo":2302404},
    {"id":922, "id_estado":6, "cidade":"Brejo Santo", "codigo":2302503},
    {"id":923, "id_estado":6, "cidade":"Camocim", "codigo":2302602},
    {"id":924, "id_estado":6, "cidade":"Campos Sales", "codigo":2302701},
    {"id":925, "id_estado":6, "cidade":"Canind\u00e9", "codigo":2302800},
    {"id":926, "id_estado":6, "cidade":"Capistrano", "codigo":2302909},
    {"id":927, "id_estado":6, "cidade":"Caridade", "codigo":2303006},
    {"id":928, "id_estado":6, "cidade":"Carir\u00e9", "codigo":2303105},
    {"id":929, "id_estado":6, "cidade":"Cariria\u00e7u", "codigo":2303204},
    {"id":930, "id_estado":6, "cidade":"Cari\u00fas", "codigo":2303303},
    {"id":931, "id_estado":6, "cidade":"Carnaubal", "codigo":2303402},
    {"id":932, "id_estado":6, "cidade":"Cascavel", "codigo":2303501},
    {"id":933, "id_estado":6, "cidade":"Catarina", "codigo":2303600},
    {"id":934, "id_estado":6, "cidade":"Catunda", "codigo":2303659},
    {"id":935, "id_estado":6, "cidade":"Caucaia", "codigo":2303709},
    {"id":936, "id_estado":6, "cidade":"Cedro", "codigo":2303808},
    {"id":937, "id_estado":6, "cidade":"Chaval", "codigo":2303907},
    {"id":938, "id_estado":6, "cidade":"Chor\u00f3", "codigo":2303931},
    {"id":939, "id_estado":6, "cidade":"Chorozinho", "codigo":2303956},
    {"id":940, "id_estado":6, "cidade":"Corea\u00fa", "codigo":2304004},
    {"id":941, "id_estado":6, "cidade":"Crate\u00fas", "codigo":2304103},
    {"id":942, "id_estado":6, "cidade":"Crato", "codigo":2304202},
    {"id":943, "id_estado":6, "cidade":"Croat\u00e1", "codigo":2304236},
    {"id":944, "id_estado":6, "cidade":"Cruz", "codigo":2304251},
    {"id":945, "id_estado":6, "cidade":"Deputado Irapuan Pinheiro", "codigo":2304269},
    {"id":946, "id_estado":6, "cidade":"Erer\u00ea", "codigo":2304277},
    {"id":947, "id_estado":6, "cidade":"Eus\u00e9bio", "codigo":2304285},
    {"id":948, "id_estado":6, "cidade":"Farias Brito", "codigo":2304301},
    {"id":949, "id_estado":6, "cidade":"Forquilha", "codigo":2304350},
    {"id":950, "id_estado":6, "cidade":"Fortaleza", "codigo":2304400},
    {"id":951, "id_estado":6, "cidade":"Fortim", "codigo":2304459},
    {"id":952, "id_estado":6, "cidade":"Frecheirinha", "codigo":2304509},
    {"id":953, "id_estado":6, "cidade":"General Sampaio", "codigo":2304608},
    {"id":954, "id_estado":6, "cidade":"Gra\u00e7a", "codigo":2304657},
    {"id":955, "id_estado":6, "cidade":"Granja", "codigo":2304707},
    {"id":956, "id_estado":6, "cidade":"Granjeiro", "codigo":2304806},
    {"id":957, "id_estado":6, "cidade":"Groa\u00edras", "codigo":2304905},
    {"id":958, "id_estado":6, "cidade":"Guai\u00faba", "codigo":2304954},
    {"id":959, "id_estado":6, "cidade":"Guaraciaba do Norte", "codigo":2305001},
    {"id":960, "id_estado":6, "cidade":"Guaramiranga", "codigo":2305100},
    {"id":961, "id_estado":6, "cidade":"Hidrol\u00e2ndia", "codigo":2305209},
    {"id":962, "id_estado":6, "cidade":"Horizonte", "codigo":2305233},
    {"id":963, "id_estado":6, "cidade":"Ibaretama", "codigo":2305266},
    {"id":964, "id_estado":6, "cidade":"Ibiapina", "codigo":2305308},
    {"id":965, "id_estado":6, "cidade":"Ibicuitinga", "codigo":2305332},
    {"id":966, "id_estado":6, "cidade":"Icapu\u00ed", "codigo":2305357},
    {"id":967, "id_estado":6, "cidade":"Ic\u00f3", "codigo":2305407},
    {"id":968, "id_estado":6, "cidade":"Iguatu", "codigo":2305506},
    {"id":969, "id_estado":6, "cidade":"Independ\u00eancia", "codigo":2305605},
    {"id":970, "id_estado":6, "cidade":"Ipaporanga", "codigo":2305654},
    {"id":971, "id_estado":6, "cidade":"Ipaumirim", "codigo":2305704},
    {"id":972, "id_estado":6, "cidade":"Ipu", "codigo":2305803},
    {"id":973, "id_estado":6, "cidade":"Ipueiras", "codigo":2305902},
    {"id":974, "id_estado":6, "cidade":"Iracema", "codigo":2306009},
    {"id":975, "id_estado":6, "cidade":"Irau\u00e7uba", "codigo":2306108},
    {"id":976, "id_estado":6, "cidade":"Itai\u00e7aba", "codigo":2306207},
    {"id":977, "id_estado":6, "cidade":"Itaitinga", "codigo":2306256},
    {"id":978, "id_estado":6, "cidade":"Itapag\u00e9", "codigo":2306306},
    {"id":979, "id_estado":6, "cidade":"Itapipoca", "codigo":2306405},
    {"id":980, "id_estado":6, "cidade":"Itapi\u00fana", "codigo":2306504},
    {"id":981, "id_estado":6, "cidade":"Itarema", "codigo":2306553},
    {"id":982, "id_estado":6, "cidade":"Itatira", "codigo":2306603},
    {"id":983, "id_estado":6, "cidade":"Jaguaretama", "codigo":2306702},
    {"id":984, "id_estado":6, "cidade":"Jaguaribara", "codigo":2306801},
    {"id":985, "id_estado":6, "cidade":"Jaguaribe", "codigo":2306900},
    {"id":986, "id_estado":6, "cidade":"Jaguaruana", "codigo":2307007},
    {"id":987, "id_estado":6, "cidade":"Jardim", "codigo":2307106},
    {"id":988, "id_estado":6, "cidade":"Jati", "codigo":2307205},
    {"id":989, "id_estado":6, "cidade":"Jijoca de Jericoacoara", "codigo":2307254},
    {"id":990, "id_estado":6, "cidade":"Juazeiro do Norte", "codigo":2307304},
    {"id":991, "id_estado":6, "cidade":"Juc\u00e1s", "codigo":2307403},
    {"id":992, "id_estado":6, "cidade":"Lavras da Mangabeira", "codigo":2307502},
    {"id":993, "id_estado":6, "cidade":"Limoeiro do Norte", "codigo":2307601},
    {"id":994, "id_estado":6, "cidade":"Madalena", "codigo":2307635},
    {"id":995, "id_estado":6, "cidade":"Maracana\u00fa", "codigo":2307650},
    {"id":996, "id_estado":6, "cidade":"Maranguape", "codigo":2307700},
    {"id":997, "id_estado":6, "cidade":"Marco", "codigo":2307809},
    {"id":998, "id_estado":6, "cidade":"Martin\u00f3pole", "codigo":2307908},
    {"id":999, "id_estado":6, "cidade":"Massap\u00ea", "codigo":2308005},
    {"id":1000, "id_estado":6, "cidade":"Mauriti", "codigo":2308104},
    {"id":1001, "id_estado":6, "cidade":"Meruoca", "codigo":2308203},
    {"id":1002, "id_estado":6, "cidade":"Milagres", "codigo":2308302},
    {"id":1003, "id_estado":6, "cidade":"Milh\u00e3", "codigo":2308351},
    {"id":1004, "id_estado":6, "cidade":"Mira\u00edma", "codigo":2308377},
    {"id":1005, "id_estado":6, "cidade":"Miss\u00e3o Velha", "codigo":2308401},
    {"id":1006, "id_estado":6, "cidade":"Momba\u00e7a", "codigo":2308500},
    {"id":1007, "id_estado":6, "cidade":"Monsenhor Tabosa", "codigo":2308609},
    {"id":1008, "id_estado":6, "cidade":"Morada Nova", "codigo":2308708},
    {"id":1009, "id_estado":6, "cidade":"Mora\u00fajo", "codigo":2308807},
    {"id":1010, "id_estado":6, "cidade":"Morrinhos", "codigo":2308906},
    {"id":1011, "id_estado":6, "cidade":"Mucambo", "codigo":2309003},
    {"id":1012, "id_estado":6, "cidade":"Mulungu", "codigo":2309102},
    {"id":1013, "id_estado":6, "cidade":"Nova Olinda", "codigo":2309201},
    {"id":1014, "id_estado":6, "cidade":"Nova Russas", "codigo":2309300},
    {"id":1015, "id_estado":6, "cidade":"Novo Oriente", "codigo":2309409},
    {"id":1016, "id_estado":6, "cidade":"Ocara", "codigo":2309458},
    {"id":1017, "id_estado":6, "cidade":"Or\u00f3s", "codigo":2309508},
    {"id":1018, "id_estado":6, "cidade":"Pacajus", "codigo":2309607},
    {"id":1019, "id_estado":6, "cidade":"Pacatuba", "codigo":2309706},
    {"id":1020, "id_estado":6, "cidade":"Pacoti", "codigo":2309805},
    {"id":1021, "id_estado":6, "cidade":"Pacuj\u00e1", "codigo":2309904},
    {"id":1022, "id_estado":6, "cidade":"Palhano", "codigo":2310001},
    {"id":1023, "id_estado":6, "cidade":"Palm\u00e1cia", "codigo":2310100},
    {"id":1024, "id_estado":6, "cidade":"Paracuru", "codigo":2310209},
    {"id":1025, "id_estado":6, "cidade":"Paraipaba", "codigo":2310258},
    {"id":1026, "id_estado":6, "cidade":"Parambu", "codigo":2310308},
    {"id":1027, "id_estado":6, "cidade":"Paramoti", "codigo":2310407},
    {"id":1028, "id_estado":6, "cidade":"Pedra Branca", "codigo":2310506},
    {"id":1029, "id_estado":6, "cidade":"Penaforte", "codigo":2310605},
    {"id":1030, "id_estado":6, "cidade":"Pentecoste", "codigo":2310704},
    {"id":1031, "id_estado":6, "cidade":"Pereiro", "codigo":2310803},
    {"id":1032, "id_estado":6, "cidade":"Pindoretama", "codigo":2310852},
    {"id":1033, "id_estado":6, "cidade":"Piquet Carneiro", "codigo":2310902},
    {"id":1034, "id_estado":6, "cidade":"Pires Ferreira", "codigo":2310951},
    {"id":1035, "id_estado":6, "cidade":"Poranga", "codigo":2311009},
    {"id":1036, "id_estado":6, "cidade":"Porteiras", "codigo":2311108},
    {"id":1037, "id_estado":6, "cidade":"Potengi", "codigo":2311207},
    {"id":1038, "id_estado":6, "cidade":"Potiretama", "codigo":2311231},
    {"id":1039, "id_estado":6, "cidade":"Quiterian\u00f3polis", "codigo":2311264},
    {"id":1040, "id_estado":6, "cidade":"Quixad\u00e1", "codigo":2311306},
    {"id":1041, "id_estado":6, "cidade":"Quixel\u00f4", "codigo":2311355},
    {"id":1042, "id_estado":6, "cidade":"Quixeramobim", "codigo":2311405},
    {"id":1043, "id_estado":6, "cidade":"Quixer\u00e9", "codigo":2311504},
    {"id":1044, "id_estado":6, "cidade":"Reden\u00e7\u00e3o", "codigo":2311603},
    {"id":1045, "id_estado":6, "cidade":"Reriutaba", "codigo":2311702},
    {"id":1046, "id_estado":6, "cidade":"Russas", "codigo":2311801},
    {"id":1047, "id_estado":6, "cidade":"Saboeiro", "codigo":2311900},
    {"id":1048, "id_estado":6, "cidade":"Salitre", "codigo":2311959},
    {"id":1049, "id_estado":6, "cidade":"Santana do Acara\u00fa", "codigo":2312007},
    {"id":1050, "id_estado":6, "cidade":"Santana do Cariri", "codigo":2312106},
    {"id":1051, "id_estado":6, "cidade":"Santa Quit\u00e9ria", "codigo":2312205},
    {"id":1052, "id_estado":6, "cidade":"S\u00e3o Benedito", "codigo":2312304},
    {"id":1053, "id_estado":6, "cidade":"S\u00e3o Gon\u00e7alo do Amarante", "codigo":2312403},
    {"id":1054, "id_estado":6, "cidade":"S\u00e3o Jo\u00e3o do Jaguaribe", "codigo":2312502},
    {"id":1055, "id_estado":6, "cidade":"S\u00e3o Lu\u00eds do Curu", "codigo":2312601},
    {"id":1056, "id_estado":6, "cidade":"Senador Pompeu", "codigo":2312700},
    {"id":1057, "id_estado":6, "cidade":"Senador S\u00e1", "codigo":2312809},
    {"id":1058, "id_estado":6, "cidade":"Sobral", "codigo":2312908},
    {"id":1059, "id_estado":6, "cidade":"Solon\u00f3pole", "codigo":2313005},
    {"id":1060, "id_estado":6, "cidade":"Tabuleiro do Norte", "codigo":2313104},
    {"id":1061, "id_estado":6, "cidade":"Tamboril", "codigo":2313203},
    {"id":1062, "id_estado":6, "cidade":"Tarrafas", "codigo":2313252},
    {"id":1063, "id_estado":6, "cidade":"Tau\u00e1", "codigo":2313302},
    {"id":1064, "id_estado":6, "cidade":"Teju\u00e7uoca", "codigo":2313351},
    {"id":1065, "id_estado":6, "cidade":"Tiangu\u00e1", "codigo":2313401},
    {"id":1066, "id_estado":6, "cidade":"Trairi", "codigo":2313500},
    {"id":1067, "id_estado":6, "cidade":"Tururu", "codigo":2313559},
    {"id":1068, "id_estado":6, "cidade":"Ubajara", "codigo":2313609},
    {"id":1069, "id_estado":6, "cidade":"Umari", "codigo":2313708},
    {"id":1070, "id_estado":6, "cidade":"Umirim", "codigo":2313757},
    {"id":1071, "id_estado":6, "cidade":"Uruburetama", "codigo":2313807},
    {"id":1072, "id_estado":6, "cidade":"Uruoca", "codigo":2313906},
    {"id":1073, "id_estado":6, "cidade":"Varjota", "codigo":2313955},
    {"id":1074, "id_estado":6, "cidade":"V\u00e1rzea Alegre", "codigo":2314003},
    {"id":1075, "id_estado":6, "cidade":"Vi\u00e7osa do Cear\u00e1", "codigo":2314102},
    {"id":1076, "id_estado":20, "cidade":"Acari", "codigo":2400109},
    {"id":1077, "id_estado":20, "cidade":"A\u00e7u", "codigo":2400208},
    {"id":1078, "id_estado":20, "cidade":"Afonso Bezerra", "codigo":2400307},
    {"id":1079, "id_estado":20, "cidade":"\u00c1gua Nova", "codigo":2400406},
    {"id":1080, "id_estado":20, "cidade":"Alexandria", "codigo":2400505},
    {"id":1081, "id_estado":20, "cidade":"Almino Afonso", "codigo":2400604},
    {"id":1082, "id_estado":20, "cidade":"Alto do Rodrigues", "codigo":2400703},
    {"id":1083, "id_estado":20, "cidade":"Angicos", "codigo":2400802},
    {"id":1084, "id_estado":20, "cidade":"Ant\u00f4nio Martins", "codigo":2400901},
    {"id":1085, "id_estado":20, "cidade":"Apodi", "codigo":2401008},
    {"id":1086, "id_estado":20, "cidade":"Areia Branca", "codigo":2401107},
    {"id":1087, "id_estado":20, "cidade":"Ar\u00eas", "codigo":2401206},
    {"id":1088, "id_estado":20, "cidade":"Augusto Severo", "codigo":2401305},
    {"id":1089, "id_estado":20, "cidade":"Ba\u00eda Formosa", "codigo":2401404},
    {"id":1090, "id_estado":20, "cidade":"Bara\u00fana", "codigo":2401453},
    {"id":1091, "id_estado":20, "cidade":"Barcelona", "codigo":2401503},
    {"id":1092, "id_estado":20, "cidade":"Bento Fernandes", "codigo":2401602},
    {"id":1093, "id_estado":20, "cidade":"Bod\u00f3", "codigo":2401651},
    {"id":1094, "id_estado":20, "cidade":"Bom Jesus", "codigo":2401701},
    {"id":1095, "id_estado":20, "cidade":"Brejinho", "codigo":2401800},
    {"id":1096, "id_estado":20, "cidade":"Cai\u00e7ara do Norte", "codigo":2401859},
    {"id":1097, "id_estado":20, "cidade":"Cai\u00e7ara do Rio do Vento", "codigo":2401909},
    {"id":1098, "id_estado":20, "cidade":"Caic\u00f3", "codigo":2402006},
    {"id":1099, "id_estado":20, "cidade":"Campo Redondo", "codigo":2402105},
    {"id":1100, "id_estado":20, "cidade":"Canguaretama", "codigo":2402204},
    {"id":1101, "id_estado":20, "cidade":"Cara\u00fabas", "codigo":2402303},
    {"id":1102, "id_estado":20, "cidade":"Carna\u00faba dos Dantas", "codigo":2402402},
    {"id":1103, "id_estado":20, "cidade":"Carnaubais", "codigo":2402501},
    {"id":1104, "id_estado":20, "cidade":"Cear\u00e1-Mirim", "codigo":2402600},
    {"id":1105, "id_estado":20, "cidade":"Cerro Cor\u00e1", "codigo":2402709},
    {"id":1106, "id_estado":20, "cidade":"Coronel Ezequiel", "codigo":2402808},
    {"id":1107, "id_estado":20, "cidade":"Coronel Jo\u00e3o Pessoa", "codigo":2402907},
    {"id":1108, "id_estado":20, "cidade":"Cruzeta", "codigo":2403004},
    {"id":1109, "id_estado":20, "cidade":"Currais Novos", "codigo":2403103},
    {"id":1110, "id_estado":20, "cidade":"Doutor Severiano", "codigo":2403202},
    {"id":1111, "id_estado":20, "cidade":"Parnamirim", "codigo":2403251},
    {"id":1112, "id_estado":20, "cidade":"Encanto", "codigo":2403301},
    {"id":1113, "id_estado":20, "cidade":"Equador", "codigo":2403400},
    {"id":1114, "id_estado":20, "cidade":"Esp\u00edrito Santo", "codigo":2403509},
    {"id":1115, "id_estado":20, "cidade":"Extremoz", "codigo":2403608},
    {"id":1116, "id_estado":20, "cidade":"Felipe Guerra", "codigo":2403707},
    {"id":1117, "id_estado":20, "cidade":"Fernando Pedroza", "codigo":2403756},
    {"id":1118, "id_estado":20, "cidade":"Flor\u00e2nia", "codigo":2403806},
    {"id":1119, "id_estado":20, "cidade":"Francisco Dantas", "codigo":2403905},
    {"id":1120, "id_estado":20, "cidade":"Frutuoso Gomes", "codigo":2404002},
    {"id":1121, "id_estado":20, "cidade":"Galinhos", "codigo":2404101},
    {"id":1122, "id_estado":20, "cidade":"Goianinha", "codigo":2404200},
    {"id":1123, "id_estado":20, "cidade":"Governador Dix-Sept Rosado", "codigo":2404309},
    {"id":1124, "id_estado":20, "cidade":"Grossos", "codigo":2404408},
    {"id":1125, "id_estado":20, "cidade":"Guamar\u00e9", "codigo":2404507},
    {"id":1126, "id_estado":20, "cidade":"Ielmo Marinho", "codigo":2404606},
    {"id":1127, "id_estado":20, "cidade":"Ipangua\u00e7u", "codigo":2404705},
    {"id":1128, "id_estado":20, "cidade":"Ipueira", "codigo":2404804},
    {"id":1129, "id_estado":20, "cidade":"Itaj\u00e1", "codigo":2404853},
    {"id":1130, "id_estado":20, "cidade":"Ita\u00fa", "codigo":2404903},
    {"id":1131, "id_estado":20, "cidade":"Ja\u00e7an\u00e3", "codigo":2405009},
    {"id":1132, "id_estado":20, "cidade":"Janda\u00edra", "codigo":2405108},
    {"id":1133, "id_estado":20, "cidade":"Jandu\u00eds", "codigo":2405207},
    {"id":1134, "id_estado":20, "cidade":"Janu\u00e1rio Cicco", "codigo":2405306},
    {"id":1135, "id_estado":20, "cidade":"Japi", "codigo":2405405},
    {"id":1136, "id_estado":20, "cidade":"Jardim de Angicos", "codigo":2405504},
    {"id":1137, "id_estado":20, "cidade":"Jardim de Piranhas", "codigo":2405603},
    {"id":1138, "id_estado":20, "cidade":"Jardim do Serid\u00f3", "codigo":2405702},
    {"id":1139, "id_estado":20, "cidade":"Jo\u00e3o C\u00e2mara", "codigo":2405801},
    {"id":1140, "id_estado":20, "cidade":"Jo\u00e3o Dias", "codigo":2405900},
    {"id":1141, "id_estado":20, "cidade":"Jos\u00e9 da Penha", "codigo":2406007},
    {"id":1142, "id_estado":20, "cidade":"Jucurutu", "codigo":2406106},
    {"id":1143, "id_estado":20, "cidade":"Jundi\u00e1", "codigo":2406155},
    {"id":1144, "id_estado":20, "cidade":"Lagoa D'Anta", "codigo":2406205},
    {"id":1145, "id_estado":20, "cidade":"Lagoa de Pedras", "codigo":2406304},
    {"id":1146, "id_estado":20, "cidade":"Lagoa de Velhos", "codigo":2406403},
    {"id":1147, "id_estado":20, "cidade":"Lagoa Nova", "codigo":2406502},
    {"id":1148, "id_estado":20, "cidade":"Lagoa Salgada", "codigo":2406601},
    {"id":1149, "id_estado":20, "cidade":"Lajes", "codigo":2406700},
    {"id":1150, "id_estado":20, "cidade":"Lajes Pintadas", "codigo":2406809},
    {"id":1151, "id_estado":20, "cidade":"Lucr\u00e9cia", "codigo":2406908},
    {"id":1152, "id_estado":20, "cidade":"Lu\u00eds Gomes", "codigo":2407005},
    {"id":1153, "id_estado":20, "cidade":"Maca\u00edba", "codigo":2407104},
    {"id":1154, "id_estado":20, "cidade":"Macau", "codigo":2407203},
    {"id":1155, "id_estado":20, "cidade":"Major Sales", "codigo":2407252},
    {"id":1156, "id_estado":20, "cidade":"Marcelino Vieira", "codigo":2407302},
    {"id":1157, "id_estado":20, "cidade":"Martins", "codigo":2407401},
    {"id":1158, "id_estado":20, "cidade":"Maxaranguape", "codigo":2407500},
    {"id":1159, "id_estado":20, "cidade":"Messias Targino", "codigo":2407609},
    {"id":1160, "id_estado":20, "cidade":"Montanhas", "codigo":2407708},
    {"id":1161, "id_estado":20, "cidade":"Monte Alegre", "codigo":2407807},
    {"id":1162, "id_estado":20, "cidade":"Monte das Gameleiras", "codigo":2407906},
    {"id":1163, "id_estado":20, "cidade":"Mossor\u00f3", "codigo":2408003},
    {"id":1164, "id_estado":20, "cidade":"Natal", "codigo":2408102},
    {"id":1165, "id_estado":20, "cidade":"N\u00edsia Floresta", "codigo":2408201},
    {"id":1166, "id_estado":20, "cidade":"Nova Cruz", "codigo":2408300},
    {"id":1167, "id_estado":20, "cidade":"Olho-D'\u00c1gua do Borges", "codigo":2408409},
    {"id":1168, "id_estado":20, "cidade":"Ouro Branco", "codigo":2408508},
    {"id":1169, "id_estado":20, "cidade":"Paran\u00e1", "codigo":2408607},
    {"id":1170, "id_estado":20, "cidade":"Para\u00fa", "codigo":2408706},
    {"id":1171, "id_estado":20, "cidade":"Parazinho", "codigo":2408805},
    {"id":1172, "id_estado":20, "cidade":"Parelhas", "codigo":2408904},
    {"id":1173, "id_estado":20, "cidade":"Rio do Fogo", "codigo":2408953},
    {"id":1174, "id_estado":20, "cidade":"Passa e Fica", "codigo":2409100},
    {"id":1175, "id_estado":20, "cidade":"Passagem", "codigo":2409209},
    {"id":1176, "id_estado":20, "cidade":"Patu", "codigo":2409308},
    {"id":1177, "id_estado":20, "cidade":"Santa Maria", "codigo":2409332},
    {"id":1178, "id_estado":20, "cidade":"Pau dos Ferros", "codigo":2409407},
    {"id":1179, "id_estado":20, "cidade":"Pedra Grande", "codigo":2409506},
    {"id":1180, "id_estado":20, "cidade":"Pedra Preta", "codigo":2409605},
    {"id":1181, "id_estado":20, "cidade":"Pedro Avelino", "codigo":2409704},
    {"id":1182, "id_estado":20, "cidade":"Pedro Velho", "codigo":2409803},
    {"id":1183, "id_estado":20, "cidade":"Pend\u00eancias", "codigo":2409902},
    {"id":1184, "id_estado":20, "cidade":"Pil\u00f5es", "codigo":2410009},
    {"id":1185, "id_estado":20, "cidade":"Po\u00e7o Branco", "codigo":2410108},
    {"id":1186, "id_estado":20, "cidade":"Portalegre", "codigo":2410207},
    {"id":1187, "id_estado":20, "cidade":"Porto do Mangue", "codigo":2410256},
    {"id":1188, "id_estado":20, "cidade":"Presidente Juscelino", "codigo":2410306},
    {"id":1189, "id_estado":20, "cidade":"Pureza", "codigo":2410405},
    {"id":1190, "id_estado":20, "cidade":"Rafael Fernandes", "codigo":2410504},
    {"id":1191, "id_estado":20, "cidade":"Rafael Godeiro", "codigo":2410603},
    {"id":1192, "id_estado":20, "cidade":"Riacho da Cruz", "codigo":2410702},
    {"id":1193, "id_estado":20, "cidade":"Riacho de Santana", "codigo":2410801},
    {"id":1194, "id_estado":20, "cidade":"Riachuelo", "codigo":2410900},
    {"id":1195, "id_estado":20, "cidade":"Rodolfo Fernandes", "codigo":2411007},
    {"id":1196, "id_estado":20, "cidade":"Tibau", "codigo":2411056},
    {"id":1197, "id_estado":20, "cidade":"Ruy Barbosa", "codigo":2411106},
    {"id":1198, "id_estado":20, "cidade":"Santa Cruz", "codigo":2411205},
    {"id":1199, "id_estado":20, "cidade":"Santana do Matos", "codigo":2411403},
    {"id":1200, "id_estado":20, "cidade":"Santana do Serid\u00f3", "codigo":2411429},
    {"id":1201, "id_estado":20, "cidade":"Santo Ant\u00f4nio", "codigo":2411502},
    {"id":1202, "id_estado":20, "cidade":"S\u00e3o Bento do Norte", "codigo":2411601},
    {"id":1203, "id_estado":20, "cidade":"S\u00e3o Bento do Trair\u00ed", "codigo":2411700},
    {"id":1204, "id_estado":20, "cidade":"S\u00e3o Fernando", "codigo":2411809},
    {"id":1205, "id_estado":20, "cidade":"S\u00e3o Francisco do Oeste", "codigo":2411908},
    {"id":1206, "id_estado":20, "cidade":"S\u00e3o Gon\u00e7alo do Amarante", "codigo":2412005},
    {"id":1207, "id_estado":20, "cidade":"S\u00e3o Jo\u00e3o do Sabugi", "codigo":2412104},
    {"id":1208, "id_estado":20, "cidade":"S\u00e3o Jos\u00e9 de Mipibu", "codigo":2412203},
    {"id":1209, "id_estado":20, "cidade":"S\u00e3o Jos\u00e9 do Campestre", "codigo":2412302},
    {"id":1210, "id_estado":20, "cidade":"S\u00e3o Jos\u00e9 do Serid\u00f3", "codigo":2412401},
    {"id":1211, "id_estado":20, "cidade":"S\u00e3o Miguel", "codigo":2412500},
    {"id":1212, "id_estado":20, "cidade":"S\u00e3o Miguel do Gostoso", "codigo":2412559},
    {"id":1213, "id_estado":20, "cidade":"S\u00e3o Paulo do Potengi", "codigo":2412609},
    {"id":1214, "id_estado":20, "cidade":"S\u00e3o Pedro", "codigo":2412708},
    {"id":1215, "id_estado":20, "cidade":"S\u00e3o Rafael", "codigo":2412807},
    {"id":1216, "id_estado":20, "cidade":"S\u00e3o Tom\u00e9", "codigo":2412906},
    {"id":1217, "id_estado":20, "cidade":"S\u00e3o Vicente", "codigo":2413003},
    {"id":1218, "id_estado":20, "cidade":"Senador El\u00f3i de Souza", "codigo":2413102},
    {"id":1219, "id_estado":20, "cidade":"Senador Georgino Avelino", "codigo":2413201},
    {"id":1220, "id_estado":20, "cidade":"Serra de S\u00e3o Bento", "codigo":2413300},
    {"id":1221, "id_estado":20, "cidade":"Serra do Mel", "codigo":2413359},
    {"id":1222, "id_estado":20, "cidade":"Serra Negra do Norte", "codigo":2413409},
    {"id":1223, "id_estado":20, "cidade":"Serrinha", "codigo":2413508},
    {"id":1224, "id_estado":20, "cidade":"Serrinha dos Pintos", "codigo":2413557},
    {"id":1225, "id_estado":20, "cidade":"Severiano Melo", "codigo":2413607},
    {"id":1226, "id_estado":20, "cidade":"S\u00edtio Novo", "codigo":2413706},
    {"id":1227, "id_estado":20, "cidade":"Taboleiro Grande", "codigo":2413805},
    {"id":1228, "id_estado":20, "cidade":"Taipu", "codigo":2413904},
    {"id":1229, "id_estado":20, "cidade":"Tangar\u00e1", "codigo":2414001},
    {"id":1230, "id_estado":20, "cidade":"Tenente Ananias", "codigo":2414100},
    {"id":1231, "id_estado":20, "cidade":"Tenente Laurentino Cruz", "codigo":2414159},
    {"id":1232, "id_estado":20, "cidade":"Tibau do Sul", "codigo":2414209},
    {"id":1233, "id_estado":20, "cidade":"Timba\u00faba dos Batistas", "codigo":2414308},
    {"id":1234, "id_estado":20, "cidade":"Touros", "codigo":2414407},
    {"id":1235, "id_estado":20, "cidade":"Triunfo Potiguar", "codigo":2414456},
    {"id":1236, "id_estado":20, "cidade":"Umarizal", "codigo":2414506},
    {"id":1237, "id_estado":20, "cidade":"Upanema", "codigo":2414605},
    {"id":1238, "id_estado":20, "cidade":"V\u00e1rzea", "codigo":2414704},
    {"id":1239, "id_estado":20, "cidade":"Venha-Ver", "codigo":2414753},
    {"id":1240, "id_estado":20, "cidade":"Vera Cruz", "codigo":2414803},
    {"id":1241, "id_estado":20, "cidade":"Vi\u00e7osa", "codigo":2414902},
    {"id":1242, "id_estado":20, "cidade":"Vila Flor", "codigo":2415008},
    {"id":1243, "id_estado":15, "cidade":"\u00c1gua Branca", "codigo":2500106},
    {"id":1244, "id_estado":15, "cidade":"Aguiar", "codigo":2500205},
    {"id":1245, "id_estado":15, "cidade":"Alagoa Grande", "codigo":2500304},
    {"id":1246, "id_estado":15, "cidade":"Alagoa Nova", "codigo":2500403},
    {"id":1247, "id_estado":15, "cidade":"Alagoinha", "codigo":2500502},
    {"id":1248, "id_estado":15, "cidade":"Alcantil", "codigo":2500536},
    {"id":1249, "id_estado":15, "cidade":"Algod\u00e3o de Janda\u00edra", "codigo":2500577},
    {"id":1250, "id_estado":15, "cidade":"Alhandra", "codigo":2500601},
    {"id":1251, "id_estado":15, "cidade":"S\u00e3o Jo\u00e3o do Rio do Peixe", "codigo":2500700},
    {"id":1252, "id_estado":15, "cidade":"Amparo", "codigo":2500734},
    {"id":1253, "id_estado":15, "cidade":"Aparecida", "codigo":2500775},
    {"id":1254, "id_estado":15, "cidade":"Ara\u00e7agi", "codigo":2500809},
    {"id":1255, "id_estado":15, "cidade":"Arara", "codigo":2500908},
    {"id":1256, "id_estado":15, "cidade":"Araruna", "codigo":2501005},
    {"id":1257, "id_estado":15, "cidade":"Areia", "codigo":2501104},
    {"id":1258, "id_estado":15, "cidade":"Areia de Bara\u00fanas", "codigo":2501153},
    {"id":1259, "id_estado":15, "cidade":"Areial", "codigo":2501203},
    {"id":1260, "id_estado":15, "cidade":"Aroeiras", "codigo":2501302},
    {"id":1261, "id_estado":15, "cidade":"Assun\u00e7\u00e3o", "codigo":2501351},
    {"id":1262, "id_estado":15, "cidade":"Ba\u00eda da Trai\u00e7\u00e3o", "codigo":2501401},
    {"id":1263, "id_estado":15, "cidade":"Bananeiras", "codigo":2501500},
    {"id":1264, "id_estado":15, "cidade":"Bara\u00fana", "codigo":2501534},
    {"id":1265, "id_estado":15, "cidade":"Barra de Santana", "codigo":2501575},
    {"id":1266, "id_estado":15, "cidade":"Barra de Santa Rosa", "codigo":2501609},
    {"id":1267, "id_estado":15, "cidade":"Barra de S\u00e3o Miguel", "codigo":2501708},
    {"id":1268, "id_estado":15, "cidade":"Bayeux", "codigo":2501807},
    {"id":1269, "id_estado":15, "cidade":"Bel\u00e9m", "codigo":2501906},
    {"id":1270, "id_estado":15, "cidade":"Bel\u00e9m do Brejo do Cruz", "codigo":2502003},
    {"id":1271, "id_estado":15, "cidade":"Bernardino Batista", "codigo":2502052},
    {"id":1272, "id_estado":15, "cidade":"Boa Ventura", "codigo":2502102},
    {"id":1273, "id_estado":15, "cidade":"Boa Vista", "codigo":2502151},
    {"id":1274, "id_estado":15, "cidade":"Bom Jesus", "codigo":2502201},
    {"id":1275, "id_estado":15, "cidade":"Bom Sucesso", "codigo":2502300},
    {"id":1276, "id_estado":15, "cidade":"Bonito de Santa F\u00e9", "codigo":2502409},
    {"id":1277, "id_estado":15, "cidade":"Boqueir\u00e3o", "codigo":2502508},
    {"id":1278, "id_estado":15, "cidade":"Igaracy", "codigo":2502607},
    {"id":1279, "id_estado":15, "cidade":"Borborema", "codigo":2502706},
    {"id":1280, "id_estado":15, "cidade":"Brejo do Cruz", "codigo":2502805},
    {"id":1281, "id_estado":15, "cidade":"Brejo dos Santos", "codigo":2502904},
    {"id":1282, "id_estado":15, "cidade":"Caapor\u00e3", "codigo":2503001},
    {"id":1283, "id_estado":15, "cidade":"Cabaceiras", "codigo":2503100},
    {"id":1284, "id_estado":15, "cidade":"Cabedelo", "codigo":2503209},
    {"id":1285, "id_estado":15, "cidade":"Cachoeira dos \u00cdndios", "codigo":2503308},
    {"id":1286, "id_estado":15, "cidade":"Cacimba de Areia", "codigo":2503407},
    {"id":1287, "id_estado":15, "cidade":"Cacimba de Dentro", "codigo":2503506},
    {"id":1288, "id_estado":15, "cidade":"Cacimbas", "codigo":2503555},
    {"id":1289, "id_estado":15, "cidade":"Cai\u00e7ara", "codigo":2503605},
    {"id":1290, "id_estado":15, "cidade":"Cajazeiras", "codigo":2503704},
    {"id":1291, "id_estado":15, "cidade":"Cajazeirinhas", "codigo":2503753},
    {"id":1292, "id_estado":15, "cidade":"Caldas Brand\u00e3o", "codigo":2503803},
    {"id":1293, "id_estado":15, "cidade":"Camala\u00fa", "codigo":2503902},
    {"id":1294, "id_estado":15, "cidade":"Campina Grande", "codigo":2504009},
    {"id":1295, "id_estado":15, "cidade":"Capim", "codigo":2504033},
    {"id":1296, "id_estado":15, "cidade":"Cara\u00fabas", "codigo":2504074},
    {"id":1297, "id_estado":15, "cidade":"Carrapateira", "codigo":2504108},
    {"id":1298, "id_estado":15, "cidade":"Casserengue", "codigo":2504157},
    {"id":1299, "id_estado":15, "cidade":"Catingueira", "codigo":2504207},
    {"id":1300, "id_estado":15, "cidade":"Catol\u00e9 do Rocha", "codigo":2504306},
    {"id":1301, "id_estado":15, "cidade":"Caturit\u00e9", "codigo":2504355},
    {"id":1302, "id_estado":15, "cidade":"Concei\u00e7\u00e3o", "codigo":2504405},
    {"id":1303, "id_estado":15, "cidade":"Condado", "codigo":2504504},
    {"id":1304, "id_estado":15, "cidade":"Conde", "codigo":2504603},
    {"id":1305, "id_estado":15, "cidade":"Congo", "codigo":2504702},
    {"id":1306, "id_estado":15, "cidade":"Coremas", "codigo":2504801},
    {"id":1307, "id_estado":15, "cidade":"Coxixola", "codigo":2504850},
    {"id":1308, "id_estado":15, "cidade":"Cruz do Esp\u00edrito Santo", "codigo":2504900},
    {"id":1309, "id_estado":15, "cidade":"Cubati", "codigo":2505006},
    {"id":1310, "id_estado":15, "cidade":"Cuit\u00e9", "codigo":2505105},
    {"id":1311, "id_estado":15, "cidade":"Cuitegi", "codigo":2505204},
    {"id":1312, "id_estado":15, "cidade":"Cuit\u00e9 de Mamanguape", "codigo":2505238},
    {"id":1313, "id_estado":15, "cidade":"Curral de Cima", "codigo":2505279},
    {"id":1314, "id_estado":15, "cidade":"Curral Velho", "codigo":2505303},
    {"id":1315, "id_estado":15, "cidade":"Dami\u00e3o", "codigo":2505352},
    {"id":1316, "id_estado":15, "cidade":"Desterro", "codigo":2505402},
    {"id":1317, "id_estado":15, "cidade":"Vista Serrana", "codigo":2505501},
    {"id":1318, "id_estado":15, "cidade":"Diamante", "codigo":2505600},
    {"id":1319, "id_estado":15, "cidade":"Dona In\u00eas", "codigo":2505709},
    {"id":1320, "id_estado":15, "cidade":"Duas Estradas", "codigo":2505808},
    {"id":1321, "id_estado":15, "cidade":"Emas", "codigo":2505907},
    {"id":1322, "id_estado":15, "cidade":"Esperan\u00e7a", "codigo":2506004},
    {"id":1323, "id_estado":15, "cidade":"Fagundes", "codigo":2506103},
    {"id":1324, "id_estado":15, "cidade":"Frei Martinho", "codigo":2506202},
    {"id":1325, "id_estado":15, "cidade":"Gado Bravo", "codigo":2506251},
    {"id":1326, "id_estado":15, "cidade":"Guarabira", "codigo":2506301},
    {"id":1327, "id_estado":15, "cidade":"Gurinh\u00e9m", "codigo":2506400},
    {"id":1328, "id_estado":15, "cidade":"Gurj\u00e3o", "codigo":2506509},
    {"id":1329, "id_estado":15, "cidade":"Ibiara", "codigo":2506608},
    {"id":1330, "id_estado":15, "cidade":"Imaculada", "codigo":2506707},
    {"id":1331, "id_estado":15, "cidade":"Ing\u00e1", "codigo":2506806},
    {"id":1332, "id_estado":15, "cidade":"Itabaiana", "codigo":2506905},
    {"id":1333, "id_estado":15, "cidade":"Itaporanga", "codigo":2507002},
    {"id":1334, "id_estado":15, "cidade":"Itapororoca", "codigo":2507101},
    {"id":1335, "id_estado":15, "cidade":"Itatuba", "codigo":2507200},
    {"id":1336, "id_estado":15, "cidade":"Jacara\u00fa", "codigo":2507309},
    {"id":1337, "id_estado":15, "cidade":"Jeric\u00f3", "codigo":2507408},
    {"id":1338, "id_estado":15, "cidade":"Jo\u00e3o Pessoa", "codigo":2507507},
    {"id":1339, "id_estado":15, "cidade":"Juarez T\u00e1vora", "codigo":2507606},
    {"id":1340, "id_estado":15, "cidade":"Juazeirinho", "codigo":2507705},
    {"id":1341, "id_estado":15, "cidade":"Junco do Serid\u00f3", "codigo":2507804},
    {"id":1342, "id_estado":15, "cidade":"Juripiranga", "codigo":2507903},
    {"id":1343, "id_estado":15, "cidade":"Juru", "codigo":2508000},
    {"id":1344, "id_estado":15, "cidade":"Lagoa", "codigo":2508109},
    {"id":1345, "id_estado":15, "cidade":"Lagoa de Dentro", "codigo":2508208},
    {"id":1346, "id_estado":15, "cidade":"Lagoa Seca", "codigo":2508307},
    {"id":1347, "id_estado":15, "cidade":"Lastro", "codigo":2508406},
    {"id":1348, "id_estado":15, "cidade":"Livramento", "codigo":2508505},
    {"id":1349, "id_estado":15, "cidade":"Logradouro", "codigo":2508554},
    {"id":1350, "id_estado":15, "cidade":"Lucena", "codigo":2508604},
    {"id":1351, "id_estado":15, "cidade":"M\u00e3e D'\u00c1gua", "codigo":2508703},
    {"id":1352, "id_estado":15, "cidade":"Malta", "codigo":2508802},
    {"id":1353, "id_estado":15, "cidade":"Mamanguape", "codigo":2508901},
    {"id":1354, "id_estado":15, "cidade":"Mana\u00edra", "codigo":2509008},
    {"id":1355, "id_estado":15, "cidade":"Marca\u00e7\u00e3o", "codigo":2509057},
    {"id":1356, "id_estado":15, "cidade":"Mari", "codigo":2509107},
    {"id":1357, "id_estado":15, "cidade":"Mariz\u00f3polis", "codigo":2509156},
    {"id":1358, "id_estado":15, "cidade":"Massaranduba", "codigo":2509206},
    {"id":1359, "id_estado":15, "cidade":"Mataraca", "codigo":2509305},
    {"id":1360, "id_estado":15, "cidade":"Matinhas", "codigo":2509339},
    {"id":1361, "id_estado":15, "cidade":"Mato Grosso", "codigo":2509370},
    {"id":1362, "id_estado":15, "cidade":"Matur\u00e9ia", "codigo":2509396},
    {"id":1363, "id_estado":15, "cidade":"Mogeiro", "codigo":2509404},
    {"id":1364, "id_estado":15, "cidade":"Montadas", "codigo":2509503},
    {"id":1365, "id_estado":15, "cidade":"Monte Horebe", "codigo":2509602},
    {"id":1366, "id_estado":15, "cidade":"Monteiro", "codigo":2509701},
    {"id":1367, "id_estado":15, "cidade":"Mulungu", "codigo":2509800},
    {"id":1368, "id_estado":15, "cidade":"Natuba", "codigo":2509909},
    {"id":1369, "id_estado":15, "cidade":"Nazarezinho", "codigo":2510006},
    {"id":1370, "id_estado":15, "cidade":"Nova Floresta", "codigo":2510105},
    {"id":1371, "id_estado":15, "cidade":"Nova Olinda", "codigo":2510204},
    {"id":1372, "id_estado":15, "cidade":"Nova Palmeira", "codigo":2510303},
    {"id":1373, "id_estado":15, "cidade":"Olho D'\u00c1gua", "codigo":2510402},
    {"id":1374, "id_estado":15, "cidade":"Olivedos", "codigo":2510501},
    {"id":1375, "id_estado":15, "cidade":"Ouro Velho", "codigo":2510600},
    {"id":1376, "id_estado":15, "cidade":"Parari", "codigo":2510659},
    {"id":1377, "id_estado":15, "cidade":"Passagem", "codigo":2510709},
    {"id":1378, "id_estado":15, "cidade":"Patos", "codigo":2510808},
    {"id":1379, "id_estado":15, "cidade":"Paulista", "codigo":2510907},
    {"id":1380, "id_estado":15, "cidade":"Pedra Branca", "codigo":2511004},
    {"id":1381, "id_estado":15, "cidade":"Pedra Lavrada", "codigo":2511103},
    {"id":1382, "id_estado":15, "cidade":"Pedras de Fogo", "codigo":2511202},
    {"id":1383, "id_estado":15, "cidade":"Pianc\u00f3", "codigo":2511301},
    {"id":1384, "id_estado":15, "cidade":"Picu\u00ed", "codigo":2511400},
    {"id":1385, "id_estado":15, "cidade":"Pilar", "codigo":2511509},
    {"id":1386, "id_estado":15, "cidade":"Pil\u00f5es", "codigo":2511608},
    {"id":1387, "id_estado":15, "cidade":"Pil\u00f5ezinhos", "codigo":2511707},
    {"id":1388, "id_estado":15, "cidade":"Pirpirituba", "codigo":2511806},
    {"id":1389, "id_estado":15, "cidade":"Pitimbu", "codigo":2511905},
    {"id":1390, "id_estado":15, "cidade":"Pocinhos", "codigo":2512002},
    {"id":1391, "id_estado":15, "cidade":"Po\u00e7o Dantas", "codigo":2512036},
    {"id":1392, "id_estado":15, "cidade":"Po\u00e7o de Jos\u00e9 de Moura", "codigo":2512077},
    {"id":1393, "id_estado":15, "cidade":"Pombal", "codigo":2512101},
    {"id":1394, "id_estado":15, "cidade":"Prata", "codigo":2512200},
    {"id":1395, "id_estado":15, "cidade":"Princesa Isabel", "codigo":2512309},
    {"id":1396, "id_estado":15, "cidade":"Puxinan\u00e3", "codigo":2512408},
    {"id":1397, "id_estado":15, "cidade":"Queimadas", "codigo":2512507},
    {"id":1398, "id_estado":15, "cidade":"Quixab\u00e1", "codigo":2512606},
    {"id":1399, "id_estado":15, "cidade":"Rem\u00edgio", "codigo":2512705},
    {"id":1400, "id_estado":15, "cidade":"Pedro R\u00e9gis", "codigo":2512721},
    {"id":1401, "id_estado":15, "cidade":"Riach\u00e3o", "codigo":2512747},
    {"id":1402, "id_estado":15, "cidade":"Riach\u00e3o do Bacamarte", "codigo":2512754},
    {"id":1403, "id_estado":15, "cidade":"Riach\u00e3o do Po\u00e7o", "codigo":2512762},
    {"id":1404, "id_estado":15, "cidade":"Riacho de Santo Ant\u00f4nio", "codigo":2512788},
    {"id":1405, "id_estado":15, "cidade":"Riacho dos Cavalos", "codigo":2512804},
    {"id":1406, "id_estado":15, "cidade":"Rio Tinto", "codigo":2512903},
    {"id":1407, "id_estado":15, "cidade":"Salgadinho", "codigo":2513000},
    {"id":1408, "id_estado":15, "cidade":"Salgado de S\u00e3o F\u00e9lix", "codigo":2513109},
    {"id":1409, "id_estado":15, "cidade":"Santa Cec\u00edlia", "codigo":2513158},
    {"id":1410, "id_estado":15, "cidade":"Santa Cruz", "codigo":2513208},
    {"id":1411, "id_estado":15, "cidade":"Santa Helena", "codigo":2513307},
    {"id":1412, "id_estado":15, "cidade":"Santa In\u00eas", "codigo":2513356},
    {"id":1413, "id_estado":15, "cidade":"Santa Luzia", "codigo":2513406},
    {"id":1414, "id_estado":15, "cidade":"Santana de Mangueira", "codigo":2513505},
    {"id":1415, "id_estado":15, "cidade":"Santana dos Garrotes", "codigo":2513604},
    {"id":1416, "id_estado":15, "cidade":"Joca Claudino", "codigo":2513653},
    {"id":1417, "id_estado":15, "cidade":"Santa Rita", "codigo":2513703},
    {"id":1418, "id_estado":15, "cidade":"Santa Teresinha", "codigo":2513802},
    {"id":1419, "id_estado":15, "cidade":"Santo Andr\u00e9", "codigo":2513851},
    {"id":1420, "id_estado":15, "cidade":"S\u00e3o Bento", "codigo":2513901},
    {"id":1421, "id_estado":15, "cidade":"S\u00e3o Bentinho", "codigo":2513927},
    {"id":1422, "id_estado":15, "cidade":"S\u00e3o Domingos do Cariri", "codigo":2513943},
    {"id":1423, "id_estado":15, "cidade":"S\u00e3o Domingos", "codigo":2513968},
    {"id":1424, "id_estado":15, "cidade":"S\u00e3o Francisco", "codigo":2513984},
    {"id":1425, "id_estado":15, "cidade":"S\u00e3o Jo\u00e3o do Cariri", "codigo":2514008},
    {"id":1426, "id_estado":15, "cidade":"S\u00e3o Jo\u00e3o do Tigre", "codigo":2514107},
    {"id":1427, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 da Lagoa Tapada", "codigo":2514206},
    {"id":1428, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 de Caiana", "codigo":2514305},
    {"id":1429, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 de Espinharas", "codigo":2514404},
    {"id":1430, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 dos Ramos", "codigo":2514453},
    {"id":1431, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 de Piranhas", "codigo":2514503},
    {"id":1432, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 de Princesa", "codigo":2514552},
    {"id":1433, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 do Bonfim", "codigo":2514602},
    {"id":1434, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 do Brejo do Cruz", "codigo":2514651},
    {"id":1435, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 do Sabugi", "codigo":2514701},
    {"id":1436, "id_estado":15, "cidade":"S\u00e3o Jos\u00e9 dos Cordeiros", "codigo":2514800},
    {"id":1437, "id_estado":15, "cidade":"S\u00e3o Mamede", "codigo":2514909},
    {"id":1438, "id_estado":15, "cidade":"S\u00e3o Miguel de Taipu", "codigo":2515005},
    {"id":1439, "id_estado":15, "cidade":"S\u00e3o Sebasti\u00e3o de Lagoa de Ro\u00e7a", "codigo":2515104},
    {"id":1440, "id_estado":15, "cidade":"S\u00e3o Sebasti\u00e3o do Umbuzeiro", "codigo":2515203},
    {"id":1441, "id_estado":15, "cidade":"Sap\u00e9", "codigo":2515302},
    {"id":1442, "id_estado":15, "cidade":"S\u00e3o Vicente do Serid\u00f3", "codigo":2515401},
    {"id":1443, "id_estado":15, "cidade":"Serra Branca", "codigo":2515500},
    {"id":1444, "id_estado":15, "cidade":"Serra da Raiz", "codigo":2515609},
    {"id":1445, "id_estado":15, "cidade":"Serra Grande", "codigo":2515708},
    {"id":1446, "id_estado":15, "cidade":"Serra Redonda", "codigo":2515807},
    {"id":1447, "id_estado":15, "cidade":"Serraria", "codigo":2515906},
    {"id":1448, "id_estado":15, "cidade":"Sert\u00e3ozinho", "codigo":2515930},
    {"id":1449, "id_estado":15, "cidade":"Sobrado", "codigo":2515971},
    {"id":1450, "id_estado":15, "cidade":"Sol\u00e2nea", "codigo":2516003},
    {"id":1451, "id_estado":15, "cidade":"Soledade", "codigo":2516102},
    {"id":1452, "id_estado":15, "cidade":"Soss\u00eago", "codigo":2516151},
    {"id":1453, "id_estado":15, "cidade":"Sousa", "codigo":2516201},
    {"id":1454, "id_estado":15, "cidade":"Sum\u00e9", "codigo":2516300},
    {"id":1455, "id_estado":15, "cidade":"Tacima", "codigo":2516409},
    {"id":1456, "id_estado":15, "cidade":"Tapero\u00e1", "codigo":2516508},
    {"id":1457, "id_estado":15, "cidade":"Tavares", "codigo":2516607},
    {"id":1458, "id_estado":15, "cidade":"Teixeira", "codigo":2516706},
    {"id":1459, "id_estado":15, "cidade":"Ten\u00f3rio", "codigo":2516755},
    {"id":1460, "id_estado":15, "cidade":"Triunfo", "codigo":2516805},
    {"id":1461, "id_estado":15, "cidade":"Uira\u00fana", "codigo":2516904},
    {"id":1462, "id_estado":15, "cidade":"Umbuzeiro", "codigo":2517001},
    {"id":1463, "id_estado":15, "cidade":"V\u00e1rzea", "codigo":2517100},
    {"id":1464, "id_estado":15, "cidade":"Vieir\u00f3polis", "codigo":2517209},
    {"id":1465, "id_estado":15, "cidade":"Zabel\u00ea", "codigo":2517407},
    {"id":1466, "id_estado":17, "cidade":"Abreu e Lima", "codigo":2600054},
    {"id":1467, "id_estado":17, "cidade":"Afogados da Ingazeira", "codigo":2600104},
    {"id":1468, "id_estado":17, "cidade":"Afr\u00e2nio", "codigo":2600203},
    {"id":1469, "id_estado":17, "cidade":"Agrestina", "codigo":2600302},
    {"id":1470, "id_estado":17, "cidade":"\u00c1gua Preta", "codigo":2600401},
    {"id":1471, "id_estado":17, "cidade":"\u00c1guas Belas", "codigo":2600500},
    {"id":1472, "id_estado":17, "cidade":"Alagoinha", "codigo":2600609},
    {"id":1473, "id_estado":17, "cidade":"Alian\u00e7a", "codigo":2600708},
    {"id":1474, "id_estado":17, "cidade":"Altinho", "codigo":2600807},
    {"id":1475, "id_estado":17, "cidade":"Amaraji", "codigo":2600906},
    {"id":1476, "id_estado":17, "cidade":"Angelim", "codigo":2601003},
    {"id":1477, "id_estado":17, "cidade":"Ara\u00e7oiaba", "codigo":2601052},
    {"id":1478, "id_estado":17, "cidade":"Araripina", "codigo":2601102},
    {"id":1479, "id_estado":17, "cidade":"Arcoverde", "codigo":2601201},
    {"id":1480, "id_estado":17, "cidade":"Barra de Guabiraba", "codigo":2601300},
    {"id":1481, "id_estado":17, "cidade":"Barreiros", "codigo":2601409},
    {"id":1482, "id_estado":17, "cidade":"Bel\u00e9m de Maria", "codigo":2601508},
    {"id":1483, "id_estado":17, "cidade":"Bel\u00e9m do S\u00e3o Francisco", "codigo":2601607},
    {"id":1484, "id_estado":17, "cidade":"Belo Jardim", "codigo":2601706},
    {"id":1485, "id_estado":17, "cidade":"Bet\u00e2nia", "codigo":2601805},
    {"id":1486, "id_estado":17, "cidade":"Bezerros", "codigo":2601904},
    {"id":1487, "id_estado":17, "cidade":"Bodoc\u00f3", "codigo":2602001},
    {"id":1488, "id_estado":17, "cidade":"Bom Conselho", "codigo":2602100},
    {"id":1489, "id_estado":17, "cidade":"Bom Jardim", "codigo":2602209},
    {"id":1490, "id_estado":17, "cidade":"Bonito", "codigo":2602308},
    {"id":1491, "id_estado":17, "cidade":"Brej\u00e3o", "codigo":2602407},
    {"id":1492, "id_estado":17, "cidade":"Brejinho", "codigo":2602506},
    {"id":1493, "id_estado":17, "cidade":"Brejo da Madre de Deus", "codigo":2602605},
    {"id":1494, "id_estado":17, "cidade":"Buenos Aires", "codigo":2602704},
    {"id":1495, "id_estado":17, "cidade":"Bu\u00edque", "codigo":2602803},
    {"id":1496, "id_estado":17, "cidade":"Cabo de Santo Agostinho", "codigo":2602902},
    {"id":1497, "id_estado":17, "cidade":"Cabrob\u00f3", "codigo":2603009},
    {"id":1498, "id_estado":17, "cidade":"Cachoeirinha", "codigo":2603108},
    {"id":1499, "id_estado":17, "cidade":"Caet\u00e9s", "codigo":2603207},
    {"id":1500, "id_estado":17, "cidade":"Cal\u00e7ado", "codigo":2603306},
    {"id":1501, "id_estado":17, "cidade":"Calumbi", "codigo":2603405},
    {"id":1502, "id_estado":17, "cidade":"Camaragibe", "codigo":2603454},
    {"id":1503, "id_estado":17, "cidade":"Camocim de S\u00e3o F\u00e9lix", "codigo":2603504},
    {"id":1504, "id_estado":17, "cidade":"Camutanga", "codigo":2603603},
    {"id":1505, "id_estado":17, "cidade":"Canhotinho", "codigo":2603702},
    {"id":1506, "id_estado":17, "cidade":"Capoeiras", "codigo":2603801},
    {"id":1507, "id_estado":17, "cidade":"Carna\u00edba", "codigo":2603900},
    {"id":1508, "id_estado":17, "cidade":"Carnaubeira da Penha", "codigo":2603926},
    {"id":1509, "id_estado":17, "cidade":"Carpina", "codigo":2604007},
    {"id":1510, "id_estado":17, "cidade":"Caruaru", "codigo":2604106},
    {"id":1511, "id_estado":17, "cidade":"Casinhas", "codigo":2604155},
    {"id":1512, "id_estado":17, "cidade":"Catende", "codigo":2604205},
    {"id":1513, "id_estado":17, "cidade":"Cedro", "codigo":2604304},
    {"id":1514, "id_estado":17, "cidade":"Ch\u00e3 de Alegria", "codigo":2604403},
    {"id":1515, "id_estado":17, "cidade":"Ch\u00e3 Grande", "codigo":2604502},
    {"id":1516, "id_estado":17, "cidade":"Condado", "codigo":2604601},
    {"id":1517, "id_estado":17, "cidade":"Correntes", "codigo":2604700},
    {"id":1518, "id_estado":17, "cidade":"Cort\u00eas", "codigo":2604809},
    {"id":1519, "id_estado":17, "cidade":"Cumaru", "codigo":2604908},
    {"id":1520, "id_estado":17, "cidade":"Cupira", "codigo":2605004},
    {"id":1521, "id_estado":17, "cidade":"Cust\u00f3dia", "codigo":2605103},
    {"id":1522, "id_estado":17, "cidade":"Dormentes", "codigo":2605152},
    {"id":1523, "id_estado":17, "cidade":"Escada", "codigo":2605202},
    {"id":1524, "id_estado":17, "cidade":"Exu", "codigo":2605301},
    {"id":1525, "id_estado":17, "cidade":"Feira Nova", "codigo":2605400},
    {"id":1526, "id_estado":17, "cidade":"Fernando de Noronha", "codigo":2605459},
    {"id":1527, "id_estado":17, "cidade":"Ferreiros", "codigo":2605509},
    {"id":1528, "id_estado":17, "cidade":"Flores", "codigo":2605608},
    {"id":1529, "id_estado":17, "cidade":"Floresta", "codigo":2605707},
    {"id":1530, "id_estado":17, "cidade":"Frei Miguelinho", "codigo":2605806},
    {"id":1531, "id_estado":17, "cidade":"Gameleira", "codigo":2605905},
    {"id":1532, "id_estado":17, "cidade":"Garanhuns", "codigo":2606002},
    {"id":1533, "id_estado":17, "cidade":"Gl\u00f3ria do Goit\u00e1", "codigo":2606101},
    {"id":1534, "id_estado":17, "cidade":"Goiana", "codigo":2606200},
    {"id":1535, "id_estado":17, "cidade":"Granito", "codigo":2606309},
    {"id":1536, "id_estado":17, "cidade":"Gravat\u00e1", "codigo":2606408},
    {"id":1537, "id_estado":17, "cidade":"Iati", "codigo":2606507},
    {"id":1538, "id_estado":17, "cidade":"Ibimirim", "codigo":2606606},
    {"id":1539, "id_estado":17, "cidade":"Ibirajuba", "codigo":2606705},
    {"id":1540, "id_estado":17, "cidade":"Igarassu", "codigo":2606804},
    {"id":1541, "id_estado":17, "cidade":"Iguaraci", "codigo":2606903},
    {"id":1542, "id_estado":17, "cidade":"Inaj\u00e1", "codigo":2607000},
    {"id":1543, "id_estado":17, "cidade":"Ingazeira", "codigo":2607109},
    {"id":1544, "id_estado":17, "cidade":"Ipojuca", "codigo":2607208},
    {"id":1545, "id_estado":17, "cidade":"Ipubi", "codigo":2607307},
    {"id":1546, "id_estado":17, "cidade":"Itacuruba", "codigo":2607406},
    {"id":1547, "id_estado":17, "cidade":"Ita\u00edba", "codigo":2607505},
    {"id":1548, "id_estado":17, "cidade":"Ilha de Itamarac\u00e1", "codigo":2607604},
    {"id":1549, "id_estado":17, "cidade":"Itamb\u00e9", "codigo":2607653},
    {"id":1550, "id_estado":17, "cidade":"Itapetim", "codigo":2607703},
    {"id":1551, "id_estado":17, "cidade":"Itapissuma", "codigo":2607752},
    {"id":1552, "id_estado":17, "cidade":"Itaquitinga", "codigo":2607802},
    {"id":1553, "id_estado":17, "cidade":"Jaboat\u00e3o dos Guararapes", "codigo":2607901},
    {"id":1554, "id_estado":17, "cidade":"Jaqueira", "codigo":2607950},
    {"id":1555, "id_estado":17, "cidade":"Jata\u00faba", "codigo":2608008},
    {"id":1556, "id_estado":17, "cidade":"Jatob\u00e1", "codigo":2608057},
    {"id":1557, "id_estado":17, "cidade":"Jo\u00e3o Alfredo", "codigo":2608107},
    {"id":1558, "id_estado":17, "cidade":"Joaquim Nabuco", "codigo":2608206},
    {"id":1559, "id_estado":17, "cidade":"Jucati", "codigo":2608255},
    {"id":1560, "id_estado":17, "cidade":"Jupi", "codigo":2608305},
    {"id":1561, "id_estado":17, "cidade":"Jurema", "codigo":2608404},
    {"id":1562, "id_estado":17, "cidade":"Lagoa do Carro", "codigo":2608453},
    {"id":1563, "id_estado":17, "cidade":"Lagoa de Itaenga", "codigo":2608503},
    {"id":1564, "id_estado":17, "cidade":"Lagoa do Ouro", "codigo":2608602},
    {"id":1565, "id_estado":17, "cidade":"Lagoa dos Gatos", "codigo":2608701},
    {"id":1566, "id_estado":17, "cidade":"Lagoa Grande", "codigo":2608750},
    {"id":1567, "id_estado":17, "cidade":"Lajedo", "codigo":2608800},
    {"id":1568, "id_estado":17, "cidade":"Limoeiro", "codigo":2608909},
    {"id":1569, "id_estado":17, "cidade":"Macaparana", "codigo":2609006},
    {"id":1570, "id_estado":17, "cidade":"Machados", "codigo":2609105},
    {"id":1571, "id_estado":17, "cidade":"Manari", "codigo":2609154},
    {"id":1572, "id_estado":17, "cidade":"Maraial", "codigo":2609204},
    {"id":1573, "id_estado":17, "cidade":"Mirandiba", "codigo":2609303},
    {"id":1574, "id_estado":17, "cidade":"Moreno", "codigo":2609402},
    {"id":1575, "id_estado":17, "cidade":"Nazar\u00e9 da Mata", "codigo":2609501},
    {"id":1576, "id_estado":17, "cidade":"Olinda", "codigo":2609600},
    {"id":1577, "id_estado":17, "cidade":"Orob\u00f3", "codigo":2609709},
    {"id":1578, "id_estado":17, "cidade":"Oroc\u00f3", "codigo":2609808},
    {"id":1579, "id_estado":17, "cidade":"Ouricuri", "codigo":2609907},
    {"id":1580, "id_estado":17, "cidade":"Palmares", "codigo":2610004},
    {"id":1581, "id_estado":17, "cidade":"Palmeirina", "codigo":2610103},
    {"id":1582, "id_estado":17, "cidade":"Panelas", "codigo":2610202},
    {"id":1583, "id_estado":17, "cidade":"Paranatama", "codigo":2610301},
    {"id":1584, "id_estado":17, "cidade":"Parnamirim", "codigo":2610400},
    {"id":1585, "id_estado":17, "cidade":"Passira", "codigo":2610509},
    {"id":1586, "id_estado":17, "cidade":"Paudalho", "codigo":2610608},
    {"id":1587, "id_estado":17, "cidade":"Paulista", "codigo":2610707},
    {"id":1588, "id_estado":17, "cidade":"Pedra", "codigo":2610806},
    {"id":1589, "id_estado":17, "cidade":"Pesqueira", "codigo":2610905},
    {"id":1590, "id_estado":17, "cidade":"Petrol\u00e2ndia", "codigo":2611002},
    {"id":1591, "id_estado":17, "cidade":"Petrolina", "codigo":2611101},
    {"id":1592, "id_estado":17, "cidade":"Po\u00e7\u00e3o", "codigo":2611200},
    {"id":1593, "id_estado":17, "cidade":"Pombos", "codigo":2611309},
    {"id":1594, "id_estado":17, "cidade":"Primavera", "codigo":2611408},
    {"id":1595, "id_estado":17, "cidade":"Quipap\u00e1", "codigo":2611507},
    {"id":1596, "id_estado":17, "cidade":"Quixaba", "codigo":2611533},
    {"id":1597, "id_estado":17, "cidade":"Recife", "codigo":2611606},
    {"id":1598, "id_estado":17, "cidade":"Riacho das Almas", "codigo":2611705},
    {"id":1599, "id_estado":17, "cidade":"Ribeir\u00e3o", "codigo":2611804},
    {"id":1600, "id_estado":17, "cidade":"Rio Formoso", "codigo":2611903},
    {"id":1601, "id_estado":17, "cidade":"Sair\u00e9", "codigo":2612000},
    {"id":1602, "id_estado":17, "cidade":"Salgadinho", "codigo":2612109},
    {"id":1603, "id_estado":17, "cidade":"Salgueiro", "codigo":2612208},
    {"id":1604, "id_estado":17, "cidade":"Salo\u00e1", "codigo":2612307},
    {"id":1605, "id_estado":17, "cidade":"Sanhar\u00f3", "codigo":2612406},
    {"id":1606, "id_estado":17, "cidade":"Santa Cruz", "codigo":2612455},
    {"id":1607, "id_estado":17, "cidade":"Santa Cruz da Baixa Verde", "codigo":2612471},
    {"id":1608, "id_estado":17, "cidade":"Santa Cruz do Capibaribe", "codigo":2612505},
    {"id":1609, "id_estado":17, "cidade":"Santa Filomena", "codigo":2612554},
    {"id":1610, "id_estado":17, "cidade":"Santa Maria da Boa Vista", "codigo":2612604},
    {"id":1611, "id_estado":17, "cidade":"Santa Maria do Cambuc\u00e1", "codigo":2612703},
    {"id":1612, "id_estado":17, "cidade":"Santa Terezinha", "codigo":2612802},
    {"id":1613, "id_estado":17, "cidade":"S\u00e3o Benedito do Sul", "codigo":2612901},
    {"id":1614, "id_estado":17, "cidade":"S\u00e3o Bento do Una", "codigo":2613008},
    {"id":1615, "id_estado":17, "cidade":"S\u00e3o Caitano", "codigo":2613107},
    {"id":1616, "id_estado":17, "cidade":"S\u00e3o Jo\u00e3o", "codigo":2613206},
    {"id":1617, "id_estado":17, "cidade":"S\u00e3o Joaquim do Monte", "codigo":2613305},
    {"id":1618, "id_estado":17, "cidade":"S\u00e3o Jos\u00e9 da Coroa Grande", "codigo":2613404},
    {"id":1619, "id_estado":17, "cidade":"S\u00e3o Jos\u00e9 do Belmonte", "codigo":2613503},
    {"id":1620, "id_estado":17, "cidade":"S\u00e3o Jos\u00e9 do Egito", "codigo":2613602},
    {"id":1621, "id_estado":17, "cidade":"S\u00e3o Louren\u00e7o da Mata", "codigo":2613701},
    {"id":1622, "id_estado":17, "cidade":"S\u00e3o Vicente Ferrer", "codigo":2613800},
    {"id":1623, "id_estado":17, "cidade":"Serra Talhada", "codigo":2613909},
    {"id":1624, "id_estado":17, "cidade":"Serrita", "codigo":2614006},
    {"id":1625, "id_estado":17, "cidade":"Sert\u00e2nia", "codigo":2614105},
    {"id":1626, "id_estado":17, "cidade":"Sirinha\u00e9m", "codigo":2614204},
    {"id":1627, "id_estado":17, "cidade":"Moreil\u00e2ndia", "codigo":2614303},
    {"id":1628, "id_estado":17, "cidade":"Solid\u00e3o", "codigo":2614402},
    {"id":1629, "id_estado":17, "cidade":"Surubim", "codigo":2614501},
    {"id":1630, "id_estado":17, "cidade":"Tabira", "codigo":2614600},
    {"id":1631, "id_estado":17, "cidade":"Tacaimb\u00f3", "codigo":2614709},
    {"id":1632, "id_estado":17, "cidade":"Tacaratu", "codigo":2614808},
    {"id":1633, "id_estado":17, "cidade":"Tamandar\u00e9", "codigo":2614857},
    {"id":1634, "id_estado":17, "cidade":"Taquaritinga do Norte", "codigo":2615003},
    {"id":1635, "id_estado":17, "cidade":"Terezinha", "codigo":2615102},
    {"id":1636, "id_estado":17, "cidade":"Terra Nova", "codigo":2615201},
    {"id":1637, "id_estado":17, "cidade":"Timba\u00faba", "codigo":2615300},
    {"id":1638, "id_estado":17, "cidade":"Toritama", "codigo":2615409},
    {"id":1639, "id_estado":17, "cidade":"Tracunha\u00e9m", "codigo":2615508},
    {"id":1640, "id_estado":17, "cidade":"Trindade", "codigo":2615607},
    {"id":1641, "id_estado":17, "cidade":"Triunfo", "codigo":2615706},
    {"id":1642, "id_estado":17, "cidade":"Tupanatinga", "codigo":2615805},
    {"id":1643, "id_estado":17, "cidade":"Tuparetama", "codigo":2615904},
    {"id":1644, "id_estado":17, "cidade":"Venturosa", "codigo":2616001},
    {"id":1645, "id_estado":17, "cidade":"Verdejante", "codigo":2616100},
    {"id":1646, "id_estado":17, "cidade":"Vertente do L\u00e9rio", "codigo":2616183},
    {"id":1647, "id_estado":17, "cidade":"Vertentes", "codigo":2616209},
    {"id":1648, "id_estado":17, "cidade":"Vic\u00eancia", "codigo":2616308},
    {"id":1649, "id_estado":17, "cidade":"Vit\u00f3ria de Santo Ant\u00e3o", "codigo":2616407},
    {"id":1650, "id_estado":17, "cidade":"Xex\u00e9u", "codigo":2616506},
    {"id":1651, "id_estado":2, "cidade":"\u00c1gua Branca", "codigo":2700102},
    {"id":1652, "id_estado":2, "cidade":"Anadia", "codigo":2700201},
    {"id":1653, "id_estado":2, "cidade":"Arapiraca", "codigo":2700300},
    {"id":1654, "id_estado":2, "cidade":"Atalaia", "codigo":2700409},
    {"id":1655, "id_estado":2, "cidade":"Barra de Santo Ant\u00f4nio", "codigo":2700508},
    {"id":1656, "id_estado":2, "cidade":"Barra de S\u00e3o Miguel", "codigo":2700607},
    {"id":1657, "id_estado":2, "cidade":"Batalha", "codigo":2700706},
    {"id":1658, "id_estado":2, "cidade":"Bel\u00e9m", "codigo":2700805},
    {"id":1659, "id_estado":2, "cidade":"Belo Monte", "codigo":2700904},
    {"id":1660, "id_estado":2, "cidade":"Boca da Mata", "codigo":2701001},
    {"id":1661, "id_estado":2, "cidade":"Branquinha", "codigo":2701100},
    {"id":1662, "id_estado":2, "cidade":"Cacimbinhas", "codigo":2701209},
    {"id":1663, "id_estado":2, "cidade":"Cajueiro", "codigo":2701308},
    {"id":1664, "id_estado":2, "cidade":"Campestre", "codigo":2701357},
    {"id":1665, "id_estado":2, "cidade":"Campo Alegre", "codigo":2701407},
    {"id":1666, "id_estado":2, "cidade":"Campo Grande", "codigo":2701506},
    {"id":1667, "id_estado":2, "cidade":"Canapi", "codigo":2701605},
    {"id":1668, "id_estado":2, "cidade":"Capela", "codigo":2701704},
    {"id":1669, "id_estado":2, "cidade":"Carneiros", "codigo":2701803},
    {"id":1670, "id_estado":2, "cidade":"Ch\u00e3 Preta", "codigo":2701902},
    {"id":1671, "id_estado":2, "cidade":"Coit\u00e9 do N\u00f3ia", "codigo":2702009},
    {"id":1672, "id_estado":2, "cidade":"Col\u00f4nia Leopoldina", "codigo":2702108},
    {"id":1673, "id_estado":2, "cidade":"Coqueiro Seco", "codigo":2702207},
    {"id":1674, "id_estado":2, "cidade":"Coruripe", "codigo":2702306},
    {"id":1675, "id_estado":2, "cidade":"Cra\u00edbas", "codigo":2702355},
    {"id":1676, "id_estado":2, "cidade":"Delmiro Gouveia", "codigo":2702405},
    {"id":1677, "id_estado":2, "cidade":"Dois Riachos", "codigo":2702504},
    {"id":1678, "id_estado":2, "cidade":"Estrela de Alagoas", "codigo":2702553},
    {"id":1679, "id_estado":2, "cidade":"Feira Grande", "codigo":2702603},
    {"id":1680, "id_estado":2, "cidade":"Feliz Deserto", "codigo":2702702},
    {"id":1681, "id_estado":2, "cidade":"Flexeiras", "codigo":2702801},
    {"id":1682, "id_estado":2, "cidade":"Girau do Ponciano", "codigo":2702900},
    {"id":1683, "id_estado":2, "cidade":"Ibateguara", "codigo":2703007},
    {"id":1684, "id_estado":2, "cidade":"Igaci", "codigo":2703106},
    {"id":1685, "id_estado":2, "cidade":"Igreja Nova", "codigo":2703205},
    {"id":1686, "id_estado":2, "cidade":"Inhapi", "codigo":2703304},
    {"id":1687, "id_estado":2, "cidade":"Jacar\u00e9 dos Homens", "codigo":2703403},
    {"id":1688, "id_estado":2, "cidade":"Jacu\u00edpe", "codigo":2703502},
    {"id":1689, "id_estado":2, "cidade":"Japaratinga", "codigo":2703601},
    {"id":1690, "id_estado":2, "cidade":"Jaramataia", "codigo":2703700},
    {"id":1691, "id_estado":2, "cidade":"Jequi\u00e1 da Praia", "codigo":2703759},
    {"id":1692, "id_estado":2, "cidade":"Joaquim Gomes", "codigo":2703809},
    {"id":1693, "id_estado":2, "cidade":"Jundi\u00e1", "codigo":2703908},
    {"id":1694, "id_estado":2, "cidade":"Junqueiro", "codigo":2704005},
    {"id":1695, "id_estado":2, "cidade":"Lagoa da Canoa", "codigo":2704104},
    {"id":1696, "id_estado":2, "cidade":"Limoeiro de Anadia", "codigo":2704203},
    {"id":1697, "id_estado":2, "cidade":"Macei\u00f3", "codigo":2704302},
    {"id":1698, "id_estado":2, "cidade":"Major Isidoro", "codigo":2704401},
    {"id":1699, "id_estado":2, "cidade":"Maragogi", "codigo":2704500},
    {"id":1700, "id_estado":2, "cidade":"Maravilha", "codigo":2704609},
    {"id":1701, "id_estado":2, "cidade":"Marechal Deodoro", "codigo":2704708},
    {"id":1702, "id_estado":2, "cidade":"Maribondo", "codigo":2704807},
    {"id":1703, "id_estado":2, "cidade":"Mar Vermelho", "codigo":2704906},
    {"id":1704, "id_estado":2, "cidade":"Mata Grande", "codigo":2705002},
    {"id":1705, "id_estado":2, "cidade":"Matriz de Camaragibe", "codigo":2705101},
    {"id":1706, "id_estado":2, "cidade":"Messias", "codigo":2705200},
    {"id":1707, "id_estado":2, "cidade":"Minador do Negr\u00e3o", "codigo":2705309},
    {"id":1708, "id_estado":2, "cidade":"Monteir\u00f3polis", "codigo":2705408},
    {"id":1709, "id_estado":2, "cidade":"Murici", "codigo":2705507},
    {"id":1710, "id_estado":2, "cidade":"Novo Lino", "codigo":2705606},
    {"id":1711, "id_estado":2, "cidade":"Olho D'\u00c1gua das Flores", "codigo":2705705},
    {"id":1712, "id_estado":2, "cidade":"Olho D'\u00c1gua do Casado", "codigo":2705804},
    {"id":1713, "id_estado":2, "cidade":"Olho D'\u00c1gua Grande", "codigo":2705903},
    {"id":1714, "id_estado":2, "cidade":"Oliven\u00e7a", "codigo":2706000},
    {"id":1715, "id_estado":2, "cidade":"Ouro Branco", "codigo":2706109},
    {"id":1716, "id_estado":2, "cidade":"Palestina", "codigo":2706208},
    {"id":1717, "id_estado":2, "cidade":"Palmeira dos \u00cdndios", "codigo":2706307},
    {"id":1718, "id_estado":2, "cidade":"P\u00e3o de A\u00e7\u00facar", "codigo":2706406},
    {"id":1719, "id_estado":2, "cidade":"Pariconha", "codigo":2706422},
    {"id":1720, "id_estado":2, "cidade":"Paripueira", "codigo":2706448},
    {"id":1721, "id_estado":2, "cidade":"Passo de Camaragibe", "codigo":2706505},
    {"id":1722, "id_estado":2, "cidade":"Paulo Jacinto", "codigo":2706604},
    {"id":1723, "id_estado":2, "cidade":"Penedo", "codigo":2706703},
    {"id":1724, "id_estado":2, "cidade":"Pia\u00e7abu\u00e7u", "codigo":2706802},
    {"id":1725, "id_estado":2, "cidade":"Pilar", "codigo":2706901},
    {"id":1726, "id_estado":2, "cidade":"Pindoba", "codigo":2707008},
    {"id":1727, "id_estado":2, "cidade":"Piranhas", "codigo":2707107},
    {"id":1728, "id_estado":2, "cidade":"Po\u00e7o das Trincheiras", "codigo":2707206},
    {"id":1729, "id_estado":2, "cidade":"Porto Calvo", "codigo":2707305},
    {"id":1730, "id_estado":2, "cidade":"Porto de Pedras", "codigo":2707404},
    {"id":1731, "id_estado":2, "cidade":"Porto Real do Col\u00e9gio", "codigo":2707503},
    {"id":1732, "id_estado":2, "cidade":"Quebrangulo", "codigo":2707602},
    {"id":1733, "id_estado":2, "cidade":"Rio Largo", "codigo":2707701},
    {"id":1734, "id_estado":2, "cidade":"Roteiro", "codigo":2707800},
    {"id":1735, "id_estado":2, "cidade":"Santa Luzia do Norte", "codigo":2707909},
    {"id":1736, "id_estado":2, "cidade":"Santana do Ipanema", "codigo":2708006},
    {"id":1737, "id_estado":2, "cidade":"Santana do Munda\u00fa", "codigo":2708105},
    {"id":1738, "id_estado":2, "cidade":"S\u00e3o Br\u00e1s", "codigo":2708204},
    {"id":1739, "id_estado":2, "cidade":"S\u00e3o Jos\u00e9 da Laje", "codigo":2708303},
    {"id":1740, "id_estado":2, "cidade":"S\u00e3o Jos\u00e9 da Tapera", "codigo":2708402},
    {"id":1741, "id_estado":2, "cidade":"S\u00e3o Lu\u00eds do Quitunde", "codigo":2708501},
    {"id":1742, "id_estado":2, "cidade":"S\u00e3o Miguel dos Campos", "codigo":2708600},
    {"id":1743, "id_estado":2, "cidade":"S\u00e3o Miguel dos Milagres", "codigo":2708709},
    {"id":1744, "id_estado":2, "cidade":"S\u00e3o Sebasti\u00e3o", "codigo":2708808},
    {"id":1745, "id_estado":2, "cidade":"Satuba", "codigo":2708907},
    {"id":1746, "id_estado":2, "cidade":"Senador Rui Palmeira", "codigo":2708956},
    {"id":1747, "id_estado":2, "cidade":"Tanque D'Arca", "codigo":2709004},
    {"id":1748, "id_estado":2, "cidade":"Taquarana", "codigo":2709103},
    {"id":1749, "id_estado":2, "cidade":"Teot\u00f4nio Vilela", "codigo":2709152},
    {"id":1750, "id_estado":2, "cidade":"Traipu", "codigo":2709202},
    {"id":1751, "id_estado":2, "cidade":"Uni\u00e3o dos Palmares", "codigo":2709301},
    {"id":1752, "id_estado":2, "cidade":"Vi\u00e7osa", "codigo":2709400},
    {"id":1753, "id_estado":26, "cidade":"Amparo de S\u00e3o Francisco", "codigo":2800100},
    {"id":1754, "id_estado":26, "cidade":"Aquidab\u00e3", "codigo":2800209},
    {"id":1755, "id_estado":26, "cidade":"Aracaju", "codigo":2800308},
    {"id":1756, "id_estado":26, "cidade":"Arau\u00e1", "codigo":2800407},
    {"id":1757, "id_estado":26, "cidade":"Areia Branca", "codigo":2800506},
    {"id":1758, "id_estado":26, "cidade":"Barra dos Coqueiros", "codigo":2800605},
    {"id":1759, "id_estado":26, "cidade":"Boquim", "codigo":2800670},
    {"id":1760, "id_estado":26, "cidade":"Brejo Grande", "codigo":2800704},
    {"id":1761, "id_estado":26, "cidade":"Campo do Brito", "codigo":2801009},
    {"id":1762, "id_estado":26, "cidade":"Canhoba", "codigo":2801108},
    {"id":1763, "id_estado":26, "cidade":"Canind\u00e9 de S\u00e3o Francisco", "codigo":2801207},
    {"id":1764, "id_estado":26, "cidade":"Capela", "codigo":2801306},
    {"id":1765, "id_estado":26, "cidade":"Carira", "codigo":2801405},
    {"id":1766, "id_estado":26, "cidade":"Carm\u00f3polis", "codigo":2801504},
    {"id":1767, "id_estado":26, "cidade":"Cedro de S\u00e3o Jo\u00e3o", "codigo":2801603},
    {"id":1768, "id_estado":26, "cidade":"Cristin\u00e1polis", "codigo":2801702},
    {"id":1769, "id_estado":26, "cidade":"Cumbe", "codigo":2801900},
    {"id":1770, "id_estado":26, "cidade":"Divina Pastora", "codigo":2802007},
    {"id":1771, "id_estado":26, "cidade":"Est\u00e2ncia", "codigo":2802106},
    {"id":1772, "id_estado":26, "cidade":"Feira Nova", "codigo":2802205},
    {"id":1773, "id_estado":26, "cidade":"Frei Paulo", "codigo":2802304},
    {"id":1774, "id_estado":26, "cidade":"Gararu", "codigo":2802403},
    {"id":1775, "id_estado":26, "cidade":"General Maynard", "codigo":2802502},
    {"id":1776, "id_estado":26, "cidade":"Gracho Cardoso", "codigo":2802601},
    {"id":1777, "id_estado":26, "cidade":"Ilha das Flores", "codigo":2802700},
    {"id":1778, "id_estado":26, "cidade":"Indiaroba", "codigo":2802809},
    {"id":1779, "id_estado":26, "cidade":"Itabaiana", "codigo":2802908},
    {"id":1780, "id_estado":26, "cidade":"Itabaianinha", "codigo":2803005},
    {"id":1781, "id_estado":26, "cidade":"Itabi", "codigo":2803104},
    {"id":1782, "id_estado":26, "cidade":"Itaporanga D'Ajuda", "codigo":2803203},
    {"id":1783, "id_estado":26, "cidade":"Japaratuba", "codigo":2803302},
    {"id":1784, "id_estado":26, "cidade":"Japoat\u00e3", "codigo":2803401},
    {"id":1785, "id_estado":26, "cidade":"Lagarto", "codigo":2803500},
    {"id":1786, "id_estado":26, "cidade":"Laranjeiras", "codigo":2803609},
    {"id":1787, "id_estado":26, "cidade":"Macambira", "codigo":2803708},
    {"id":1788, "id_estado":26, "cidade":"Malhada dos Bois", "codigo":2803807},
    {"id":1789, "id_estado":26, "cidade":"Malhador", "codigo":2803906},
    {"id":1790, "id_estado":26, "cidade":"Maruim", "codigo":2804003},
    {"id":1791, "id_estado":26, "cidade":"Moita Bonita", "codigo":2804102},
    {"id":1792, "id_estado":26, "cidade":"Monte Alegre de Sergipe", "codigo":2804201},
    {"id":1793, "id_estado":26, "cidade":"Muribeca", "codigo":2804300},
    {"id":1794, "id_estado":26, "cidade":"Ne\u00f3polis", "codigo":2804409},
    {"id":1795, "id_estado":26, "cidade":"Nossa Senhora Aparecida", "codigo":2804458},
    {"id":1796, "id_estado":26, "cidade":"Nossa Senhora da Gl\u00f3ria", "codigo":2804508},
    {"id":1797, "id_estado":26, "cidade":"Nossa Senhora das Dores", "codigo":2804607},
    {"id":1798, "id_estado":26, "cidade":"Nossa Senhora de Lourdes", "codigo":2804706},
    {"id":1799, "id_estado":26, "cidade":"Nossa Senhora do Socorro", "codigo":2804805},
    {"id":1800, "id_estado":26, "cidade":"Pacatuba", "codigo":2804904},
    {"id":1801, "id_estado":26, "cidade":"Pedra Mole", "codigo":2805000},
    {"id":1802, "id_estado":26, "cidade":"Pedrinhas", "codigo":2805109},
    {"id":1803, "id_estado":26, "cidade":"Pinh\u00e3o", "codigo":2805208},
    {"id":1804, "id_estado":26, "cidade":"Pirambu", "codigo":2805307},
    {"id":1805, "id_estado":26, "cidade":"Po\u00e7o Redondo", "codigo":2805406},
    {"id":1806, "id_estado":26, "cidade":"Po\u00e7o Verde", "codigo":2805505},
    {"id":1807, "id_estado":26, "cidade":"Porto da Folha", "codigo":2805604},
    {"id":1808, "id_estado":26, "cidade":"Propri\u00e1", "codigo":2805703},
    {"id":1809, "id_estado":26, "cidade":"Riach\u00e3o do Dantas", "codigo":2805802},
    {"id":1810, "id_estado":26, "cidade":"Riachuelo", "codigo":2805901},
    {"id":1811, "id_estado":26, "cidade":"Ribeir\u00f3polis", "codigo":2806008},
    {"id":1812, "id_estado":26, "cidade":"Ros\u00e1rio do Catete", "codigo":2806107},
    {"id":1813, "id_estado":26, "cidade":"Salgado", "codigo":2806206},
    {"id":1814, "id_estado":26, "cidade":"Santa Luzia do Itanhy", "codigo":2806305},
    {"id":1815, "id_estado":26, "cidade":"Santana do S\u00e3o Francisco", "codigo":2806404},
    {"id":1816, "id_estado":26, "cidade":"Santa Rosa de Lima", "codigo":2806503},
    {"id":1817, "id_estado":26, "cidade":"Santo Amaro das Brotas", "codigo":2806602},
    {"id":1818, "id_estado":26, "cidade":"S\u00e3o Crist\u00f3v\u00e3o", "codigo":2806701},
    {"id":1819, "id_estado":26, "cidade":"S\u00e3o Domingos", "codigo":2806800},
    {"id":1820, "id_estado":26, "cidade":"S\u00e3o Francisco", "codigo":2806909},
    {"id":1821, "id_estado":26, "cidade":"S\u00e3o Miguel do Aleixo", "codigo":2807006},
    {"id":1822, "id_estado":26, "cidade":"Sim\u00e3o Dias", "codigo":2807105},
    {"id":1823, "id_estado":26, "cidade":"Siriri", "codigo":2807204},
    {"id":1824, "id_estado":26, "cidade":"Telha", "codigo":2807303},
    {"id":1825, "id_estado":26, "cidade":"Tobias Barreto", "codigo":2807402},
    {"id":1826, "id_estado":26, "cidade":"Tomar do Geru", "codigo":2807501},
    {"id":1827, "id_estado":26, "cidade":"Umba\u00faba", "codigo":2807600},
    {"id":1828, "id_estado":5, "cidade":"Aba\u00edra", "codigo":2900108},
    {"id":1829, "id_estado":5, "cidade":"Abar\u00e9", "codigo":2900207},
    {"id":1830, "id_estado":5, "cidade":"Acajutiba", "codigo":2900306},
    {"id":1831, "id_estado":5, "cidade":"Adustina", "codigo":2900355},
    {"id":1832, "id_estado":5, "cidade":"\u00c1gua Fria", "codigo":2900405},
    {"id":1833, "id_estado":5, "cidade":"\u00c9rico Cardoso", "codigo":2900504},
    {"id":1834, "id_estado":5, "cidade":"Aiquara", "codigo":2900603},
    {"id":1835, "id_estado":5, "cidade":"Alagoinhas", "codigo":2900702},
    {"id":1836, "id_estado":5, "cidade":"Alcoba\u00e7a", "codigo":2900801},
    {"id":1837, "id_estado":5, "cidade":"Almadina", "codigo":2900900},
    {"id":1838, "id_estado":5, "cidade":"Amargosa", "codigo":2901007},
    {"id":1839, "id_estado":5, "cidade":"Am\u00e9lia Rodrigues", "codigo":2901106},
    {"id":1840, "id_estado":5, "cidade":"Am\u00e9rica Dourada", "codigo":2901155},
    {"id":1841, "id_estado":5, "cidade":"Anag\u00e9", "codigo":2901205},
    {"id":1842, "id_estado":5, "cidade":"Andara\u00ed", "codigo":2901304},
    {"id":1843, "id_estado":5, "cidade":"Andorinha", "codigo":2901353},
    {"id":1844, "id_estado":5, "cidade":"Angical", "codigo":2901403},
    {"id":1845, "id_estado":5, "cidade":"Anguera", "codigo":2901502},
    {"id":1846, "id_estado":5, "cidade":"Antas", "codigo":2901601},
    {"id":1847, "id_estado":5, "cidade":"Ant\u00f4nio Cardoso", "codigo":2901700},
    {"id":1848, "id_estado":5, "cidade":"Ant\u00f4nio Gon\u00e7alves", "codigo":2901809},
    {"id":1849, "id_estado":5, "cidade":"Apor\u00e1", "codigo":2901908},
    {"id":1850, "id_estado":5, "cidade":"Apuarema", "codigo":2901957},
    {"id":1851, "id_estado":5, "cidade":"Aracatu", "codigo":2902005},
    {"id":1852, "id_estado":5, "cidade":"Ara\u00e7as", "codigo":2902054},
    {"id":1853, "id_estado":5, "cidade":"Araci", "codigo":2902104},
    {"id":1854, "id_estado":5, "cidade":"Aramari", "codigo":2902203},
    {"id":1855, "id_estado":5, "cidade":"Arataca", "codigo":2902252},
    {"id":1856, "id_estado":5, "cidade":"Aratu\u00edpe", "codigo":2902302},
    {"id":1857, "id_estado":5, "cidade":"Aurelino Leal", "codigo":2902401},
    {"id":1858, "id_estado":5, "cidade":"Baian\u00f3polis", "codigo":2902500},
    {"id":1859, "id_estado":5, "cidade":"Baixa Grande", "codigo":2902609},
    {"id":1860, "id_estado":5, "cidade":"Banza\u00ea", "codigo":2902658},
    {"id":1861, "id_estado":5, "cidade":"Barra", "codigo":2902708},
    {"id":1862, "id_estado":5, "cidade":"Barra da Estiva", "codigo":2902807},
    {"id":1863, "id_estado":5, "cidade":"Barra do Cho\u00e7a", "codigo":2902906},
    {"id":1864, "id_estado":5, "cidade":"Barra do Mendes", "codigo":2903003},
    {"id":1865, "id_estado":5, "cidade":"Barra do Rocha", "codigo":2903102},
    {"id":1866, "id_estado":5, "cidade":"Barreiras", "codigo":2903201},
    {"id":1867, "id_estado":5, "cidade":"Barro Alto", "codigo":2903235},
    {"id":1868, "id_estado":5, "cidade":"Barrocas", "codigo":2903276},
    {"id":1869, "id_estado":5, "cidade":"Barro Preto", "codigo":2903300},
    {"id":1870, "id_estado":5, "cidade":"Belmonte", "codigo":2903409},
    {"id":1871, "id_estado":5, "cidade":"Belo Campo", "codigo":2903508},
    {"id":1872, "id_estado":5, "cidade":"Biritinga", "codigo":2903607},
    {"id":1873, "id_estado":5, "cidade":"Boa Nova", "codigo":2903706},
    {"id":1874, "id_estado":5, "cidade":"Boa Vista do Tupim", "codigo":2903805},
    {"id":1875, "id_estado":5, "cidade":"Bom Jesus da Lapa", "codigo":2903904},
    {"id":1876, "id_estado":5, "cidade":"Bom Jesus da Serra", "codigo":2903953},
    {"id":1877, "id_estado":5, "cidade":"Boninal", "codigo":2904001},
    {"id":1878, "id_estado":5, "cidade":"Bonito", "codigo":2904050},
    {"id":1879, "id_estado":5, "cidade":"Boquira", "codigo":2904100},
    {"id":1880, "id_estado":5, "cidade":"Botupor\u00e3", "codigo":2904209},
    {"id":1881, "id_estado":5, "cidade":"Brej\u00f5es", "codigo":2904308},
    {"id":1882, "id_estado":5, "cidade":"Brejol\u00e2ndia", "codigo":2904407},
    {"id":1883, "id_estado":5, "cidade":"Brotas de Maca\u00fabas", "codigo":2904506},
    {"id":1884, "id_estado":5, "cidade":"Brumado", "codigo":2904605},
    {"id":1885, "id_estado":5, "cidade":"Buerarema", "codigo":2904704},
    {"id":1886, "id_estado":5, "cidade":"Buritirama", "codigo":2904753},
    {"id":1887, "id_estado":5, "cidade":"Caatiba", "codigo":2904803},
    {"id":1888, "id_estado":5, "cidade":"Cabaceiras do Paragua\u00e7u", "codigo":2904852},
    {"id":1889, "id_estado":5, "cidade":"Cachoeira", "codigo":2904902},
    {"id":1890, "id_estado":5, "cidade":"Cacul\u00e9", "codigo":2905008},
    {"id":1891, "id_estado":5, "cidade":"Ca\u00e9m", "codigo":2905107},
    {"id":1892, "id_estado":5, "cidade":"Caetanos", "codigo":2905156},
    {"id":1893, "id_estado":5, "cidade":"Caetit\u00e9", "codigo":2905206},
    {"id":1894, "id_estado":5, "cidade":"Cafarnaum", "codigo":2905305},
    {"id":1895, "id_estado":5, "cidade":"Cairu", "codigo":2905404},
    {"id":1896, "id_estado":5, "cidade":"Caldeir\u00e3o Grande", "codigo":2905503},
    {"id":1897, "id_estado":5, "cidade":"Camacan", "codigo":2905602},
    {"id":1898, "id_estado":5, "cidade":"Cama\u00e7ari", "codigo":2905701},
    {"id":1899, "id_estado":5, "cidade":"Camamu", "codigo":2905800},
    {"id":1900, "id_estado":5, "cidade":"Campo Alegre de Lourdes", "codigo":2905909},
    {"id":1901, "id_estado":5, "cidade":"Campo Formoso", "codigo":2906006},
    {"id":1902, "id_estado":5, "cidade":"Can\u00e1polis", "codigo":2906105},
    {"id":1903, "id_estado":5, "cidade":"Canarana", "codigo":2906204},
    {"id":1904, "id_estado":5, "cidade":"Canavieiras", "codigo":2906303},
    {"id":1905, "id_estado":5, "cidade":"Candeal", "codigo":2906402},
    {"id":1906, "id_estado":5, "cidade":"Candeias", "codigo":2906501},
    {"id":1907, "id_estado":5, "cidade":"Candiba", "codigo":2906600},
    {"id":1908, "id_estado":5, "cidade":"C\u00e2ndido Sales", "codigo":2906709},
    {"id":1909, "id_estado":5, "cidade":"Cansan\u00e7\u00e3o", "codigo":2906808},
    {"id":1910, "id_estado":5, "cidade":"Canudos", "codigo":2906824},
    {"id":1911, "id_estado":5, "cidade":"Capela do Alto Alegre", "codigo":2906857},
    {"id":1912, "id_estado":5, "cidade":"Capim Grosso", "codigo":2906873},
    {"id":1913, "id_estado":5, "cidade":"Cara\u00edbas", "codigo":2906899},
    {"id":1914, "id_estado":5, "cidade":"Caravelas", "codigo":2906907},
    {"id":1915, "id_estado":5, "cidade":"Cardeal da Silva", "codigo":2907004},
    {"id":1916, "id_estado":5, "cidade":"Carinhanha", "codigo":2907103},
    {"id":1917, "id_estado":5, "cidade":"Casa Nova", "codigo":2907202},
    {"id":1918, "id_estado":5, "cidade":"Castro Alves", "codigo":2907301},
    {"id":1919, "id_estado":5, "cidade":"Catol\u00e2ndia", "codigo":2907400},
    {"id":1920, "id_estado":5, "cidade":"Catu", "codigo":2907509},
    {"id":1921, "id_estado":5, "cidade":"Caturama", "codigo":2907558},
    {"id":1922, "id_estado":5, "cidade":"Central", "codigo":2907608},
    {"id":1923, "id_estado":5, "cidade":"Chorroch\u00f3", "codigo":2907707},
    {"id":1924, "id_estado":5, "cidade":"C\u00edcero Dantas", "codigo":2907806},
    {"id":1925, "id_estado":5, "cidade":"Cip\u00f3", "codigo":2907905},
    {"id":1926, "id_estado":5, "cidade":"Coaraci", "codigo":2908002},
    {"id":1927, "id_estado":5, "cidade":"Cocos", "codigo":2908101},
    {"id":1928, "id_estado":5, "cidade":"Concei\u00e7\u00e3o da Feira", "codigo":2908200},
    {"id":1929, "id_estado":5, "cidade":"Concei\u00e7\u00e3o do Almeida", "codigo":2908309},
    {"id":1930, "id_estado":5, "cidade":"Concei\u00e7\u00e3o do Coit\u00e9", "codigo":2908408},
    {"id":1931, "id_estado":5, "cidade":"Concei\u00e7\u00e3o do Jacu\u00edpe", "codigo":2908507},
    {"id":1932, "id_estado":5, "cidade":"Conde", "codigo":2908606},
    {"id":1933, "id_estado":5, "cidade":"Conde\u00faba", "codigo":2908705},
    {"id":1934, "id_estado":5, "cidade":"Contendas do Sincor\u00e1", "codigo":2908804},
    {"id":1935, "id_estado":5, "cidade":"Cora\u00e7\u00e3o de Maria", "codigo":2908903},
    {"id":1936, "id_estado":5, "cidade":"Cordeiros", "codigo":2909000},
    {"id":1937, "id_estado":5, "cidade":"Coribe", "codigo":2909109},
    {"id":1938, "id_estado":5, "cidade":"Coronel Jo\u00e3o S\u00e1", "codigo":2909208},
    {"id":1939, "id_estado":5, "cidade":"Correntina", "codigo":2909307},
    {"id":1940, "id_estado":5, "cidade":"Cotegipe", "codigo":2909406},
    {"id":1941, "id_estado":5, "cidade":"Cravol\u00e2ndia", "codigo":2909505},
    {"id":1942, "id_estado":5, "cidade":"Cris\u00f3polis", "codigo":2909604},
    {"id":1943, "id_estado":5, "cidade":"Crist\u00f3polis", "codigo":2909703},
    {"id":1944, "id_estado":5, "cidade":"Cruz das Almas", "codigo":2909802},
    {"id":1945, "id_estado":5, "cidade":"Cura\u00e7\u00e1", "codigo":2909901},
    {"id":1946, "id_estado":5, "cidade":"D\u00e1rio Meira", "codigo":2910008},
    {"id":1947, "id_estado":5, "cidade":"Dias D'\u00c1vila", "codigo":2910057},
    {"id":1948, "id_estado":5, "cidade":"Dom Bas\u00edlio", "codigo":2910107},
    {"id":1949, "id_estado":5, "cidade":"Dom Macedo Costa", "codigo":2910206},
    {"id":1950, "id_estado":5, "cidade":"El\u00edsio Medrado", "codigo":2910305},
    {"id":1951, "id_estado":5, "cidade":"Encruzilhada", "codigo":2910404},
    {"id":1952, "id_estado":5, "cidade":"Entre Rios", "codigo":2910503},
    {"id":1953, "id_estado":5, "cidade":"Esplanada", "codigo":2910602},
    {"id":1954, "id_estado":5, "cidade":"Euclides da Cunha", "codigo":2910701},
    {"id":1955, "id_estado":5, "cidade":"Eun\u00e1polis", "codigo":2910727},
    {"id":1956, "id_estado":5, "cidade":"F\u00e1tima", "codigo":2910750},
    {"id":1957, "id_estado":5, "cidade":"Feira da Mata", "codigo":2910776},
    {"id":1958, "id_estado":5, "cidade":"Feira de Santana", "codigo":2910800},
    {"id":1959, "id_estado":5, "cidade":"Filad\u00e9lfia", "codigo":2910859},
    {"id":1960, "id_estado":5, "cidade":"Firmino Alves", "codigo":2910909},
    {"id":1961, "id_estado":5, "cidade":"Floresta Azul", "codigo":2911006},
    {"id":1962, "id_estado":5, "cidade":"Formosa do Rio Preto", "codigo":2911105},
    {"id":1963, "id_estado":5, "cidade":"Gandu", "codigo":2911204},
    {"id":1964, "id_estado":5, "cidade":"Gavi\u00e3o", "codigo":2911253},
    {"id":1965, "id_estado":5, "cidade":"Gentio do Ouro", "codigo":2911303},
    {"id":1966, "id_estado":5, "cidade":"Gl\u00f3ria", "codigo":2911402},
    {"id":1967, "id_estado":5, "cidade":"Gongogi", "codigo":2911501},
    {"id":1968, "id_estado":5, "cidade":"Governador Mangabeira", "codigo":2911600},
    {"id":1969, "id_estado":5, "cidade":"Guajeru", "codigo":2911659},
    {"id":1970, "id_estado":5, "cidade":"Guanambi", "codigo":2911709},
    {"id":1971, "id_estado":5, "cidade":"Guaratinga", "codigo":2911808},
    {"id":1972, "id_estado":5, "cidade":"Heli\u00f3polis", "codigo":2911857},
    {"id":1973, "id_estado":5, "cidade":"Ia\u00e7u", "codigo":2911907},
    {"id":1974, "id_estado":5, "cidade":"Ibiassuc\u00ea", "codigo":2912004},
    {"id":1975, "id_estado":5, "cidade":"Ibicara\u00ed", "codigo":2912103},
    {"id":1976, "id_estado":5, "cidade":"Ibicoara", "codigo":2912202},
    {"id":1977, "id_estado":5, "cidade":"Ibicu\u00ed", "codigo":2912301},
    {"id":1978, "id_estado":5, "cidade":"Ibipeba", "codigo":2912400},
    {"id":1979, "id_estado":5, "cidade":"Ibipitanga", "codigo":2912509},
    {"id":1980, "id_estado":5, "cidade":"Ibiquera", "codigo":2912608},
    {"id":1981, "id_estado":5, "cidade":"Ibirapitanga", "codigo":2912707},
    {"id":1982, "id_estado":5, "cidade":"Ibirapu\u00e3", "codigo":2912806},
    {"id":1983, "id_estado":5, "cidade":"Ibirataia", "codigo":2912905},
    {"id":1984, "id_estado":5, "cidade":"Ibitiara", "codigo":2913002},
    {"id":1985, "id_estado":5, "cidade":"Ibitit\u00e1", "codigo":2913101},
    {"id":1986, "id_estado":5, "cidade":"Ibotirama", "codigo":2913200},
    {"id":1987, "id_estado":5, "cidade":"Ichu", "codigo":2913309},
    {"id":1988, "id_estado":5, "cidade":"Igapor\u00e3", "codigo":2913408},
    {"id":1989, "id_estado":5, "cidade":"Igrapi\u00fana", "codigo":2913457},
    {"id":1990, "id_estado":5, "cidade":"Igua\u00ed", "codigo":2913507},
    {"id":1991, "id_estado":5, "cidade":"Ilh\u00e9us", "codigo":2913606},
    {"id":1992, "id_estado":5, "cidade":"Inhambupe", "codigo":2913705},
    {"id":1993, "id_estado":5, "cidade":"Ipecaet\u00e1", "codigo":2913804},
    {"id":1994, "id_estado":5, "cidade":"Ipia\u00fa", "codigo":2913903},
    {"id":1995, "id_estado":5, "cidade":"Ipir\u00e1", "codigo":2914000},
    {"id":1996, "id_estado":5, "cidade":"Ipupiara", "codigo":2914109},
    {"id":1997, "id_estado":5, "cidade":"Irajuba", "codigo":2914208},
    {"id":1998, "id_estado":5, "cidade":"Iramaia", "codigo":2914307},
    {"id":1999, "id_estado":5, "cidade":"Iraquara", "codigo":2914406},
    {"id":2000, "id_estado":5, "cidade":"Irar\u00e1", "codigo":2914505},
    {"id":2001, "id_estado":5, "cidade":"Irec\u00ea", "codigo":2914604},
    {"id":2002, "id_estado":5, "cidade":"Itabela", "codigo":2914653},
    {"id":2003, "id_estado":5, "cidade":"Itaberaba", "codigo":2914703},
    {"id":2004, "id_estado":5, "cidade":"Itabuna", "codigo":2914802},
    {"id":2005, "id_estado":5, "cidade":"Itacar\u00e9", "codigo":2914901},
    {"id":2006, "id_estado":5, "cidade":"Itaet\u00e9", "codigo":2915007},
    {"id":2007, "id_estado":5, "cidade":"Itagi", "codigo":2915106},
    {"id":2008, "id_estado":5, "cidade":"Itagib\u00e1", "codigo":2915205},
    {"id":2009, "id_estado":5, "cidade":"Itagimirim", "codigo":2915304},
    {"id":2010, "id_estado":5, "cidade":"Itagua\u00e7u da Bahia", "codigo":2915353},
    {"id":2011, "id_estado":5, "cidade":"Itaju do Col\u00f4nia", "codigo":2915403},
    {"id":2012, "id_estado":5, "cidade":"Itaju\u00edpe", "codigo":2915502},
    {"id":2013, "id_estado":5, "cidade":"Itamaraju", "codigo":2915601},
    {"id":2014, "id_estado":5, "cidade":"Itamari", "codigo":2915700},
    {"id":2015, "id_estado":5, "cidade":"Itamb\u00e9", "codigo":2915809},
    {"id":2016, "id_estado":5, "cidade":"Itanagra", "codigo":2915908},
    {"id":2017, "id_estado":5, "cidade":"Itanh\u00e9m", "codigo":2916005},
    {"id":2018, "id_estado":5, "cidade":"Itaparica", "codigo":2916104},
    {"id":2019, "id_estado":5, "cidade":"Itap\u00e9", "codigo":2916203},
    {"id":2020, "id_estado":5, "cidade":"Itapebi", "codigo":2916302},
    {"id":2021, "id_estado":5, "cidade":"Itapetinga", "codigo":2916401},
    {"id":2022, "id_estado":5, "cidade":"Itapicuru", "codigo":2916500},
    {"id":2023, "id_estado":5, "cidade":"Itapitanga", "codigo":2916609},
    {"id":2024, "id_estado":5, "cidade":"Itaquara", "codigo":2916708},
    {"id":2025, "id_estado":5, "cidade":"Itarantim", "codigo":2916807},
    {"id":2026, "id_estado":5, "cidade":"Itatim", "codigo":2916856},
    {"id":2027, "id_estado":5, "cidade":"Itiru\u00e7u", "codigo":2916906},
    {"id":2028, "id_estado":5, "cidade":"Iti\u00faba", "codigo":2917003},
    {"id":2029, "id_estado":5, "cidade":"Itoror\u00f3", "codigo":2917102},
    {"id":2030, "id_estado":5, "cidade":"Itua\u00e7u", "codigo":2917201},
    {"id":2031, "id_estado":5, "cidade":"Ituber\u00e1", "codigo":2917300},
    {"id":2032, "id_estado":5, "cidade":"Iui\u00fa", "codigo":2917334},
    {"id":2033, "id_estado":5, "cidade":"Jaborandi", "codigo":2917359},
    {"id":2034, "id_estado":5, "cidade":"Jacaraci", "codigo":2917409},
    {"id":2035, "id_estado":5, "cidade":"Jacobina", "codigo":2917508},
    {"id":2036, "id_estado":5, "cidade":"Jaguaquara", "codigo":2917607},
    {"id":2037, "id_estado":5, "cidade":"Jaguarari", "codigo":2917706},
    {"id":2038, "id_estado":5, "cidade":"Jaguaripe", "codigo":2917805},
    {"id":2039, "id_estado":5, "cidade":"Janda\u00edra", "codigo":2917904},
    {"id":2040, "id_estado":5, "cidade":"Jequi\u00e9", "codigo":2918001},
    {"id":2041, "id_estado":5, "cidade":"Jeremoabo", "codigo":2918100},
    {"id":2042, "id_estado":5, "cidade":"Jiquiri\u00e7\u00e1", "codigo":2918209},
    {"id":2043, "id_estado":5, "cidade":"Jita\u00fana", "codigo":2918308},
    {"id":2044, "id_estado":5, "cidade":"Jo\u00e3o Dourado", "codigo":2918357},
    {"id":2045, "id_estado":5, "cidade":"Juazeiro", "codigo":2918407},
    {"id":2046, "id_estado":5, "cidade":"Jucuru\u00e7u", "codigo":2918456},
    {"id":2047, "id_estado":5, "cidade":"Jussara", "codigo":2918506},
    {"id":2048, "id_estado":5, "cidade":"Jussari", "codigo":2918555},
    {"id":2049, "id_estado":5, "cidade":"Jussiape", "codigo":2918605},
    {"id":2050, "id_estado":5, "cidade":"Lafaiete Coutinho", "codigo":2918704},
    {"id":2051, "id_estado":5, "cidade":"Lagoa Real", "codigo":2918753},
    {"id":2052, "id_estado":5, "cidade":"Laje", "codigo":2918803},
    {"id":2053, "id_estado":5, "cidade":"Lajed\u00e3o", "codigo":2918902},
    {"id":2054, "id_estado":5, "cidade":"Lajedinho", "codigo":2919009},
    {"id":2055, "id_estado":5, "cidade":"Lajedo do Tabocal", "codigo":2919058},
    {"id":2056, "id_estado":5, "cidade":"Lamar\u00e3o", "codigo":2919108},
    {"id":2057, "id_estado":5, "cidade":"Lap\u00e3o", "codigo":2919157},
    {"id":2058, "id_estado":5, "cidade":"Lauro de Freitas", "codigo":2919207},
    {"id":2059, "id_estado":5, "cidade":"Len\u00e7\u00f3is", "codigo":2919306},
    {"id":2060, "id_estado":5, "cidade":"Lic\u00ednio de Almeida", "codigo":2919405},
    {"id":2061, "id_estado":5, "cidade":"Livramento de Nossa Senhora", "codigo":2919504},
    {"id":2062, "id_estado":5, "cidade":"Lu\u00eds Eduardo Magalh\u00e3es", "codigo":2919553},
    {"id":2063, "id_estado":5, "cidade":"Macajuba", "codigo":2919603},
    {"id":2064, "id_estado":5, "cidade":"Macarani", "codigo":2919702},
    {"id":2065, "id_estado":5, "cidade":"Maca\u00fabas", "codigo":2919801},
    {"id":2066, "id_estado":5, "cidade":"Macurur\u00e9", "codigo":2919900},
    {"id":2067, "id_estado":5, "cidade":"Madre de Deus", "codigo":2919926},
    {"id":2068, "id_estado":5, "cidade":"Maetinga", "codigo":2919959},
    {"id":2069, "id_estado":5, "cidade":"Maiquinique", "codigo":2920007},
    {"id":2070, "id_estado":5, "cidade":"Mairi", "codigo":2920106},
    {"id":2071, "id_estado":5, "cidade":"Malhada", "codigo":2920205},
    {"id":2072, "id_estado":5, "cidade":"Malhada de Pedras", "codigo":2920304},
    {"id":2073, "id_estado":5, "cidade":"Manoel Vitorino", "codigo":2920403},
    {"id":2074, "id_estado":5, "cidade":"Mansid\u00e3o", "codigo":2920452},
    {"id":2075, "id_estado":5, "cidade":"Marac\u00e1s", "codigo":2920502},
    {"id":2076, "id_estado":5, "cidade":"Maragogipe", "codigo":2920601},
    {"id":2077, "id_estado":5, "cidade":"Mara\u00fa", "codigo":2920700},
    {"id":2078, "id_estado":5, "cidade":"Marcion\u00edlio Souza", "codigo":2920809},
    {"id":2079, "id_estado":5, "cidade":"Mascote", "codigo":2920908},
    {"id":2080, "id_estado":5, "cidade":"Mata de S\u00e3o Jo\u00e3o", "codigo":2921005},
    {"id":2081, "id_estado":5, "cidade":"Matina", "codigo":2921054},
    {"id":2082, "id_estado":5, "cidade":"Medeiros Neto", "codigo":2921104},
    {"id":2083, "id_estado":5, "cidade":"Miguel Calmon", "codigo":2921203},
    {"id":2084, "id_estado":5, "cidade":"Milagres", "codigo":2921302},
    {"id":2085, "id_estado":5, "cidade":"Mirangaba", "codigo":2921401},
    {"id":2086, "id_estado":5, "cidade":"Mirante", "codigo":2921450},
    {"id":2087, "id_estado":5, "cidade":"Monte Santo", "codigo":2921500},
    {"id":2088, "id_estado":5, "cidade":"Morpar\u00e1", "codigo":2921609},
    {"id":2089, "id_estado":5, "cidade":"Morro do Chap\u00e9u", "codigo":2921708},
    {"id":2090, "id_estado":5, "cidade":"Mortugaba", "codigo":2921807},
    {"id":2091, "id_estado":5, "cidade":"Mucug\u00ea", "codigo":2921906},
    {"id":2092, "id_estado":5, "cidade":"Mucuri", "codigo":2922003},
    {"id":2093, "id_estado":5, "cidade":"Mulungu do Morro", "codigo":2922052},
    {"id":2094, "id_estado":5, "cidade":"Mundo Novo", "codigo":2922102},
    {"id":2095, "id_estado":5, "cidade":"Muniz Ferreira", "codigo":2922201},
    {"id":2096, "id_estado":5, "cidade":"Muqu\u00e9m de S\u00e3o Francisco", "codigo":2922250},
    {"id":2097, "id_estado":5, "cidade":"Muritiba", "codigo":2922300},
    {"id":2098, "id_estado":5, "cidade":"Mutu\u00edpe", "codigo":2922409},
    {"id":2099, "id_estado":5, "cidade":"Nazar\u00e9", "codigo":2922508},
    {"id":2100, "id_estado":5, "cidade":"Nilo Pe\u00e7anha", "codigo":2922607},
    {"id":2101, "id_estado":5, "cidade":"Nordestina", "codigo":2922656},
    {"id":2102, "id_estado":5, "cidade":"Nova Cana\u00e3", "codigo":2922706},
    {"id":2103, "id_estado":5, "cidade":"Nova F\u00e1tima", "codigo":2922730},
    {"id":2104, "id_estado":5, "cidade":"Nova Ibi\u00e1", "codigo":2922755},
    {"id":2105, "id_estado":5, "cidade":"Nova Itarana", "codigo":2922805},
    {"id":2106, "id_estado":5, "cidade":"Nova Reden\u00e7\u00e3o", "codigo":2922854},
    {"id":2107, "id_estado":5, "cidade":"Nova Soure", "codigo":2922904},
    {"id":2108, "id_estado":5, "cidade":"Nova Vi\u00e7osa", "codigo":2923001},
    {"id":2109, "id_estado":5, "cidade":"Novo Horizonte", "codigo":2923035},
    {"id":2110, "id_estado":5, "cidade":"Novo Triunfo", "codigo":2923050},
    {"id":2111, "id_estado":5, "cidade":"Olindina", "codigo":2923100},
    {"id":2112, "id_estado":5, "cidade":"Oliveira dos Brejinhos", "codigo":2923209},
    {"id":2113, "id_estado":5, "cidade":"Ouri\u00e7angas", "codigo":2923308},
    {"id":2114, "id_estado":5, "cidade":"Ourol\u00e2ndia", "codigo":2923357},
    {"id":2115, "id_estado":5, "cidade":"Palmas de Monte Alto", "codigo":2923407},
    {"id":2116, "id_estado":5, "cidade":"Palmeiras", "codigo":2923506},
    {"id":2117, "id_estado":5, "cidade":"Paramirim", "codigo":2923605},
    {"id":2118, "id_estado":5, "cidade":"Paratinga", "codigo":2923704},
    {"id":2119, "id_estado":5, "cidade":"Paripiranga", "codigo":2923803},
    {"id":2120, "id_estado":5, "cidade":"Pau Brasil", "codigo":2923902},
    {"id":2121, "id_estado":5, "cidade":"Paulo Afonso", "codigo":2924009},
    {"id":2122, "id_estado":5, "cidade":"P\u00e9 de Serra", "codigo":2924058},
    {"id":2123, "id_estado":5, "cidade":"Pedr\u00e3o", "codigo":2924108},
    {"id":2124, "id_estado":5, "cidade":"Pedro Alexandre", "codigo":2924207},
    {"id":2125, "id_estado":5, "cidade":"Piat\u00e3", "codigo":2924306},
    {"id":2126, "id_estado":5, "cidade":"Pil\u00e3o Arcado", "codigo":2924405},
    {"id":2127, "id_estado":5, "cidade":"Pinda\u00ed", "codigo":2924504},
    {"id":2128, "id_estado":5, "cidade":"Pindoba\u00e7u", "codigo":2924603},
    {"id":2129, "id_estado":5, "cidade":"Pintadas", "codigo":2924652},
    {"id":2130, "id_estado":5, "cidade":"Pira\u00ed do Norte", "codigo":2924678},
    {"id":2131, "id_estado":5, "cidade":"Pirip\u00e1", "codigo":2924702},
    {"id":2132, "id_estado":5, "cidade":"Piritiba", "codigo":2924801},
    {"id":2133, "id_estado":5, "cidade":"Planaltino", "codigo":2924900},
    {"id":2134, "id_estado":5, "cidade":"Planalto", "codigo":2925006},
    {"id":2135, "id_estado":5, "cidade":"Po\u00e7\u00f5es", "codigo":2925105},
    {"id":2136, "id_estado":5, "cidade":"Pojuca", "codigo":2925204},
    {"id":2137, "id_estado":5, "cidade":"Ponto Novo", "codigo":2925253},
    {"id":2138, "id_estado":5, "cidade":"Porto Seguro", "codigo":2925303},
    {"id":2139, "id_estado":5, "cidade":"Potiragu\u00e1", "codigo":2925402},
    {"id":2140, "id_estado":5, "cidade":"Prado", "codigo":2925501},
    {"id":2141, "id_estado":5, "cidade":"Presidente Dutra", "codigo":2925600},
    {"id":2142, "id_estado":5, "cidade":"Presidente J\u00e2nio Quadros", "codigo":2925709},
    {"id":2143, "id_estado":5, "cidade":"Presidente Tancredo Neves", "codigo":2925758},
    {"id":2144, "id_estado":5, "cidade":"Queimadas", "codigo":2925808},
    {"id":2145, "id_estado":5, "cidade":"Quijingue", "codigo":2925907},
    {"id":2146, "id_estado":5, "cidade":"Quixabeira", "codigo":2925931},
    {"id":2147, "id_estado":5, "cidade":"Rafael Jambeiro", "codigo":2925956},
    {"id":2148, "id_estado":5, "cidade":"Remanso", "codigo":2926004},
    {"id":2149, "id_estado":5, "cidade":"Retirol\u00e2ndia", "codigo":2926103},
    {"id":2150, "id_estado":5, "cidade":"Riach\u00e3o das Neves", "codigo":2926202},
    {"id":2151, "id_estado":5, "cidade":"Riach\u00e3o do Jacu\u00edpe", "codigo":2926301},
    {"id":2152, "id_estado":5, "cidade":"Riacho de Santana", "codigo":2926400},
    {"id":2153, "id_estado":5, "cidade":"Ribeira do Amparo", "codigo":2926509},
    {"id":2154, "id_estado":5, "cidade":"Ribeira do Pombal", "codigo":2926608},
    {"id":2155, "id_estado":5, "cidade":"Ribeir\u00e3o do Largo", "codigo":2926657},
    {"id":2156, "id_estado":5, "cidade":"Rio de Contas", "codigo":2926707},
    {"id":2157, "id_estado":5, "cidade":"Rio do Ant\u00f4nio", "codigo":2926806},
    {"id":2158, "id_estado":5, "cidade":"Rio do Pires", "codigo":2926905},
    {"id":2159, "id_estado":5, "cidade":"Rio Real", "codigo":2927002},
    {"id":2160, "id_estado":5, "cidade":"Rodelas", "codigo":2927101},
    {"id":2161, "id_estado":5, "cidade":"Ruy Barbosa", "codigo":2927200},
    {"id":2162, "id_estado":5, "cidade":"Salinas da Margarida", "codigo":2927309},
    {"id":2163, "id_estado":5, "cidade":"Salvador", "codigo":2927408},
    {"id":2164, "id_estado":5, "cidade":"Santa B\u00e1rbara", "codigo":2927507},
    {"id":2165, "id_estado":5, "cidade":"Santa Br\u00edgida", "codigo":2927606},
    {"id":2166, "id_estado":5, "cidade":"Santa Cruz Cabr\u00e1lia", "codigo":2927705},
    {"id":2167, "id_estado":5, "cidade":"Santa Cruz da Vit\u00f3ria", "codigo":2927804},
    {"id":2168, "id_estado":5, "cidade":"Santa In\u00eas", "codigo":2927903},
    {"id":2169, "id_estado":5, "cidade":"Santaluz", "codigo":2928000},
    {"id":2170, "id_estado":5, "cidade":"Santa Luzia", "codigo":2928059},
    {"id":2171, "id_estado":5, "cidade":"Santa Maria da Vit\u00f3ria", "codigo":2928109},
    {"id":2172, "id_estado":5, "cidade":"Santana", "codigo":2928208},
    {"id":2173, "id_estado":5, "cidade":"Santan\u00f3polis", "codigo":2928307},
    {"id":2174, "id_estado":5, "cidade":"Santa Rita de C\u00e1ssia", "codigo":2928406},
    {"id":2175, "id_estado":5, "cidade":"Santa Teresinha", "codigo":2928505},
    {"id":2176, "id_estado":5, "cidade":"Santo Amaro", "codigo":2928604},
    {"id":2177, "id_estado":5, "cidade":"Santo Ant\u00f4nio de Jesus", "codigo":2928703},
    {"id":2178, "id_estado":5, "cidade":"Santo Est\u00eav\u00e3o", "codigo":2928802},
    {"id":2179, "id_estado":5, "cidade":"S\u00e3o Desid\u00e9rio", "codigo":2928901},
    {"id":2180, "id_estado":5, "cidade":"S\u00e3o Domingos", "codigo":2928950},
    {"id":2181, "id_estado":5, "cidade":"S\u00e3o F\u00e9lix", "codigo":2929008},
    {"id":2182, "id_estado":5, "cidade":"S\u00e3o F\u00e9lix do Coribe", "codigo":2929057},
    {"id":2183, "id_estado":5, "cidade":"S\u00e3o Felipe", "codigo":2929107},
    {"id":2184, "id_estado":5, "cidade":"S\u00e3o Francisco do Conde", "codigo":2929206},
    {"id":2185, "id_estado":5, "cidade":"S\u00e3o Gabriel", "codigo":2929255},
    {"id":2186, "id_estado":5, "cidade":"S\u00e3o Gon\u00e7alo dos Campos", "codigo":2929305},
    {"id":2187, "id_estado":5, "cidade":"S\u00e3o Jos\u00e9 da Vit\u00f3ria", "codigo":2929354},
    {"id":2188, "id_estado":5, "cidade":"S\u00e3o Jos\u00e9 do Jacu\u00edpe", "codigo":2929370},
    {"id":2189, "id_estado":5, "cidade":"S\u00e3o Miguel das Matas", "codigo":2929404},
    {"id":2190, "id_estado":5, "cidade":"S\u00e3o Sebasti\u00e3o do Pass\u00e9", "codigo":2929503},
    {"id":2191, "id_estado":5, "cidade":"Sapea\u00e7u", "codigo":2929602},
    {"id":2192, "id_estado":5, "cidade":"S\u00e1tiro Dias", "codigo":2929701},
    {"id":2193, "id_estado":5, "cidade":"Saubara", "codigo":2929750},
    {"id":2194, "id_estado":5, "cidade":"Sa\u00fade", "codigo":2929800},
    {"id":2195, "id_estado":5, "cidade":"Seabra", "codigo":2929909},
    {"id":2196, "id_estado":5, "cidade":"Sebasti\u00e3o Laranjeiras", "codigo":2930006},
    {"id":2197, "id_estado":5, "cidade":"Senhor do Bonfim", "codigo":2930105},
    {"id":2198, "id_estado":5, "cidade":"Serra do Ramalho", "codigo":2930154},
    {"id":2199, "id_estado":5, "cidade":"Sento S\u00e9", "codigo":2930204},
    {"id":2200, "id_estado":5, "cidade":"Serra Dourada", "codigo":2930303},
    {"id":2201, "id_estado":5, "cidade":"Serra Preta", "codigo":2930402},
    {"id":2202, "id_estado":5, "cidade":"Serrinha", "codigo":2930501},
    {"id":2203, "id_estado":5, "cidade":"Serrol\u00e2ndia", "codigo":2930600},
    {"id":2204, "id_estado":5, "cidade":"Sim\u00f5es Filho", "codigo":2930709},
    {"id":2205, "id_estado":5, "cidade":"S\u00edtio do Mato", "codigo":2930758},
    {"id":2206, "id_estado":5, "cidade":"S\u00edtio do Quinto", "codigo":2930766},
    {"id":2207, "id_estado":5, "cidade":"Sobradinho", "codigo":2930774},
    {"id":2208, "id_estado":5, "cidade":"Souto Soares", "codigo":2930808},
    {"id":2209, "id_estado":5, "cidade":"Tabocas do Brejo Velho", "codigo":2930907},
    {"id":2210, "id_estado":5, "cidade":"Tanha\u00e7u", "codigo":2931004},
    {"id":2211, "id_estado":5, "cidade":"Tanque Novo", "codigo":2931053},
    {"id":2212, "id_estado":5, "cidade":"Tanquinho", "codigo":2931103},
    {"id":2213, "id_estado":5, "cidade":"Tapero\u00e1", "codigo":2931202},
    {"id":2214, "id_estado":5, "cidade":"Tapiramut\u00e1", "codigo":2931301},
    {"id":2215, "id_estado":5, "cidade":"Teixeira de Freitas", "codigo":2931350},
    {"id":2216, "id_estado":5, "cidade":"Teodoro Sampaio", "codigo":2931400},
    {"id":2217, "id_estado":5, "cidade":"Teofil\u00e2ndia", "codigo":2931509},
    {"id":2218, "id_estado":5, "cidade":"Teol\u00e2ndia", "codigo":2931608},
    {"id":2219, "id_estado":5, "cidade":"Terra Nova", "codigo":2931707},
    {"id":2220, "id_estado":5, "cidade":"Tremedal", "codigo":2931806},
    {"id":2221, "id_estado":5, "cidade":"Tucano", "codigo":2931905},
    {"id":2222, "id_estado":5, "cidade":"Uau\u00e1", "codigo":2932002},
    {"id":2223, "id_estado":5, "cidade":"Uba\u00edra", "codigo":2932101},
    {"id":2224, "id_estado":5, "cidade":"Ubaitaba", "codigo":2932200},
    {"id":2225, "id_estado":5, "cidade":"Ubat\u00e3", "codigo":2932309},
    {"id":2226, "id_estado":5, "cidade":"Uiba\u00ed", "codigo":2932408},
    {"id":2227, "id_estado":5, "cidade":"Umburanas", "codigo":2932457},
    {"id":2228, "id_estado":5, "cidade":"Una", "codigo":2932507},
    {"id":2229, "id_estado":5, "cidade":"Urandi", "codigo":2932606},
    {"id":2230, "id_estado":5, "cidade":"Uru\u00e7uca", "codigo":2932705},
    {"id":2231, "id_estado":5, "cidade":"Utinga", "codigo":2932804},
    {"id":2232, "id_estado":5, "cidade":"Valen\u00e7a", "codigo":2932903},
    {"id":2233, "id_estado":5, "cidade":"Valente", "codigo":2933000},
    {"id":2234, "id_estado":5, "cidade":"V\u00e1rzea da Ro\u00e7a", "codigo":2933059},
    {"id":2235, "id_estado":5, "cidade":"V\u00e1rzea do Po\u00e7o", "codigo":2933109},
    {"id":2236, "id_estado":5, "cidade":"V\u00e1rzea Nova", "codigo":2933158},
    {"id":2237, "id_estado":5, "cidade":"Varzedo", "codigo":2933174},
    {"id":2238, "id_estado":5, "cidade":"Vera Cruz", "codigo":2933208},
    {"id":2239, "id_estado":5, "cidade":"Vereda", "codigo":2933257},
    {"id":2240, "id_estado":5, "cidade":"Vit\u00f3ria da Conquista", "codigo":2933307},
    {"id":2241, "id_estado":5, "cidade":"Wagner", "codigo":2933406},
    {"id":2242, "id_estado":5, "cidade":"Wanderley", "codigo":2933455},
    {"id":2243, "id_estado":5, "cidade":"Wenceslau Guimar\u00e3es", "codigo":2933505},
    {"id":2244, "id_estado":5, "cidade":"Xique-Xique", "codigo":2933604},
    {"id":2245, "id_estado":13, "cidade":"Abadia dos Dourados", "codigo":3100104},
    {"id":2246, "id_estado":13, "cidade":"Abaet\u00e9", "codigo":3100203},
    {"id":2247, "id_estado":13, "cidade":"Abre Campo", "codigo":3100302},
    {"id":2248, "id_estado":13, "cidade":"Acaiaca", "codigo":3100401},
    {"id":2249, "id_estado":13, "cidade":"A\u00e7ucena", "codigo":3100500},
    {"id":2250, "id_estado":13, "cidade":"\u00c1gua Boa", "codigo":3100609},
    {"id":2251, "id_estado":13, "cidade":"\u00c1gua Comprida", "codigo":3100708},
    {"id":2252, "id_estado":13, "cidade":"Aguanil", "codigo":3100807},
    {"id":2253, "id_estado":13, "cidade":"\u00c1guas Formosas", "codigo":3100906},
    {"id":2254, "id_estado":13, "cidade":"\u00c1guas Vermelhas", "codigo":3101003},
    {"id":2255, "id_estado":13, "cidade":"Aimor\u00e9s", "codigo":3101102},
    {"id":2256, "id_estado":13, "cidade":"Aiuruoca", "codigo":3101201},
    {"id":2257, "id_estado":13, "cidade":"Alagoa", "codigo":3101300},
    {"id":2258, "id_estado":13, "cidade":"Albertina", "codigo":3101409},
    {"id":2259, "id_estado":13, "cidade":"Al\u00e9m Para\u00edba", "codigo":3101508},
    {"id":2260, "id_estado":13, "cidade":"Alfenas", "codigo":3101607},
    {"id":2261, "id_estado":13, "cidade":"Alfredo Vasconcelos", "codigo":3101631},
    {"id":2262, "id_estado":13, "cidade":"Almenara", "codigo":3101706},
    {"id":2263, "id_estado":13, "cidade":"Alpercata", "codigo":3101805},
    {"id":2264, "id_estado":13, "cidade":"Alpin\u00f3polis", "codigo":3101904},
    {"id":2265, "id_estado":13, "cidade":"Alterosa", "codigo":3102001},
    {"id":2266, "id_estado":13, "cidade":"Alto Capara\u00f3", "codigo":3102050},
    {"id":2267, "id_estado":13, "cidade":"Alto Rio Doce", "codigo":3102100},
    {"id":2268, "id_estado":13, "cidade":"Alvarenga", "codigo":3102209},
    {"id":2269, "id_estado":13, "cidade":"Alvin\u00f3polis", "codigo":3102308},
    {"id":2270, "id_estado":13, "cidade":"Alvorada de Minas", "codigo":3102407},
    {"id":2271, "id_estado":13, "cidade":"Amparo do Serra", "codigo":3102506},
    {"id":2272, "id_estado":13, "cidade":"Andradas", "codigo":3102605},
    {"id":2273, "id_estado":13, "cidade":"Cachoeira de Paje\u00fa", "codigo":3102704},
    {"id":2274, "id_estado":13, "cidade":"Andrel\u00e2ndia", "codigo":3102803},
    {"id":2275, "id_estado":13, "cidade":"Angel\u00e2ndia", "codigo":3102852},
    {"id":2276, "id_estado":13, "cidade":"Ant\u00f4nio Carlos", "codigo":3102902},
    {"id":2277, "id_estado":13, "cidade":"Ant\u00f4nio Dias", "codigo":3103009},
    {"id":2278, "id_estado":13, "cidade":"Ant\u00f4nio Prado de Minas", "codigo":3103108},
    {"id":2279, "id_estado":13, "cidade":"Ara\u00e7a\u00ed", "codigo":3103207},
    {"id":2280, "id_estado":13, "cidade":"Aracitaba", "codigo":3103306},
    {"id":2281, "id_estado":13, "cidade":"Ara\u00e7ua\u00ed", "codigo":3103405},
    {"id":2282, "id_estado":13, "cidade":"Araguari", "codigo":3103504},
    {"id":2283, "id_estado":13, "cidade":"Arantina", "codigo":3103603},
    {"id":2284, "id_estado":13, "cidade":"Araponga", "codigo":3103702},
    {"id":2285, "id_estado":13, "cidade":"Arapor\u00e3", "codigo":3103751},
    {"id":2286, "id_estado":13, "cidade":"Arapu\u00e1", "codigo":3103801},
    {"id":2287, "id_estado":13, "cidade":"Ara\u00fajos", "codigo":3103900},
    {"id":2288, "id_estado":13, "cidade":"Arax\u00e1", "codigo":3104007},
    {"id":2289, "id_estado":13, "cidade":"Arceburgo", "codigo":3104106},
    {"id":2290, "id_estado":13, "cidade":"Arcos", "codigo":3104205},
    {"id":2291, "id_estado":13, "cidade":"Areado", "codigo":3104304},
    {"id":2292, "id_estado":13, "cidade":"Argirita", "codigo":3104403},
    {"id":2293, "id_estado":13, "cidade":"Aricanduva", "codigo":3104452},
    {"id":2294, "id_estado":13, "cidade":"Arinos", "codigo":3104502},
    {"id":2295, "id_estado":13, "cidade":"Astolfo Dutra", "codigo":3104601},
    {"id":2296, "id_estado":13, "cidade":"Atal\u00e9ia", "codigo":3104700},
    {"id":2297, "id_estado":13, "cidade":"Augusto de Lima", "codigo":3104809},
    {"id":2298, "id_estado":13, "cidade":"Baependi", "codigo":3104908},
    {"id":2299, "id_estado":13, "cidade":"Baldim", "codigo":3105004},
    {"id":2300, "id_estado":13, "cidade":"Bambu\u00ed", "codigo":3105103},
    {"id":2301, "id_estado":13, "cidade":"Bandeira", "codigo":3105202},
    {"id":2302, "id_estado":13, "cidade":"Bandeira do Sul", "codigo":3105301},
    {"id":2303, "id_estado":13, "cidade":"Bar\u00e3o de Cocais", "codigo":3105400},
    {"id":2304, "id_estado":13, "cidade":"Bar\u00e3o de Monte Alto", "codigo":3105509},
    {"id":2305, "id_estado":13, "cidade":"Barbacena", "codigo":3105608},
    {"id":2306, "id_estado":13, "cidade":"Barra Longa", "codigo":3105707},
    {"id":2307, "id_estado":13, "cidade":"Barroso", "codigo":3105905},
    {"id":2308, "id_estado":13, "cidade":"Bela Vista de Minas", "codigo":3106002},
    {"id":2309, "id_estado":13, "cidade":"Belmiro Braga", "codigo":3106101},
    {"id":2310, "id_estado":13, "cidade":"Belo Horizonte", "codigo":3106200},
    {"id":2311, "id_estado":13, "cidade":"Belo Oriente", "codigo":3106309},
    {"id":2312, "id_estado":13, "cidade":"Belo Vale", "codigo":3106408},
    {"id":2313, "id_estado":13, "cidade":"Berilo", "codigo":3106507},
    {"id":2314, "id_estado":13, "cidade":"Bert\u00f3polis", "codigo":3106606},
    {"id":2315, "id_estado":13, "cidade":"Berizal", "codigo":3106655},
    {"id":2316, "id_estado":13, "cidade":"Betim", "codigo":3106705},
    {"id":2317, "id_estado":13, "cidade":"Bias Fortes", "codigo":3106804},
    {"id":2318, "id_estado":13, "cidade":"Bicas", "codigo":3106903},
    {"id":2319, "id_estado":13, "cidade":"Biquinhas", "codigo":3107000},
    {"id":2320, "id_estado":13, "cidade":"Boa Esperan\u00e7a", "codigo":3107109},
    {"id":2321, "id_estado":13, "cidade":"Bocaina de Minas", "codigo":3107208},
    {"id":2322, "id_estado":13, "cidade":"Bocai\u00fava", "codigo":3107307},
    {"id":2323, "id_estado":13, "cidade":"Bom Despacho", "codigo":3107406},
    {"id":2324, "id_estado":13, "cidade":"Bom Jardim de Minas", "codigo":3107505},
    {"id":2325, "id_estado":13, "cidade":"Bom Jesus da Penha", "codigo":3107604},
    {"id":2326, "id_estado":13, "cidade":"Bom Jesus do Amparo", "codigo":3107703},
    {"id":2327, "id_estado":13, "cidade":"Bom Jesus do Galho", "codigo":3107802},
    {"id":2328, "id_estado":13, "cidade":"Bom Repouso", "codigo":3107901},
    {"id":2329, "id_estado":13, "cidade":"Bom Sucesso", "codigo":3108008},
    {"id":2330, "id_estado":13, "cidade":"Bonfim", "codigo":3108107},
    {"id":2331, "id_estado":13, "cidade":"Bonfin\u00f3polis de Minas", "codigo":3108206},
    {"id":2332, "id_estado":13, "cidade":"Bonito de Minas", "codigo":3108255},
    {"id":2333, "id_estado":13, "cidade":"Borda da Mata", "codigo":3108305},
    {"id":2334, "id_estado":13, "cidade":"Botelhos", "codigo":3108404},
    {"id":2335, "id_estado":13, "cidade":"Botumirim", "codigo":3108503},
    {"id":2336, "id_estado":13, "cidade":"Brasil\u00e2ndia de Minas", "codigo":3108552},
    {"id":2337, "id_estado":13, "cidade":"Bras\u00edlia de Minas", "codigo":3108602},
    {"id":2338, "id_estado":13, "cidade":"Br\u00e1s Pires", "codigo":3108701},
    {"id":2339, "id_estado":13, "cidade":"Bra\u00fanas", "codigo":3108800},
    {"id":2340, "id_estado":13, "cidade":"Braz\u00f3polis", "codigo":3108909},
    {"id":2341, "id_estado":13, "cidade":"Brumadinho", "codigo":3109006},
    {"id":2342, "id_estado":13, "cidade":"Bueno Brand\u00e3o", "codigo":3109105},
    {"id":2343, "id_estado":13, "cidade":"Buen\u00f3polis", "codigo":3109204},
    {"id":2344, "id_estado":13, "cidade":"Bugre", "codigo":3109253},
    {"id":2345, "id_estado":13, "cidade":"Buritis", "codigo":3109303},
    {"id":2346, "id_estado":13, "cidade":"Buritizeiro", "codigo":3109402},
    {"id":2347, "id_estado":13, "cidade":"Cabeceira Grande", "codigo":3109451},
    {"id":2348, "id_estado":13, "cidade":"Cabo Verde", "codigo":3109501},
    {"id":2349, "id_estado":13, "cidade":"Cachoeira da Prata", "codigo":3109600},
    {"id":2350, "id_estado":13, "cidade":"Cachoeira de Minas", "codigo":3109709},
    {"id":2351, "id_estado":13, "cidade":"Cachoeira Dourada", "codigo":3109808},
    {"id":2352, "id_estado":13, "cidade":"Caetan\u00f3polis", "codigo":3109907},
    {"id":2353, "id_estado":13, "cidade":"Caet\u00e9", "codigo":3110004},
    {"id":2354, "id_estado":13, "cidade":"Caiana", "codigo":3110103},
    {"id":2355, "id_estado":13, "cidade":"Cajuri", "codigo":3110202},
    {"id":2356, "id_estado":13, "cidade":"Caldas", "codigo":3110301},
    {"id":2357, "id_estado":13, "cidade":"Camacho", "codigo":3110400},
    {"id":2358, "id_estado":13, "cidade":"Camanducaia", "codigo":3110509},
    {"id":2359, "id_estado":13, "cidade":"Cambu\u00ed", "codigo":3110608},
    {"id":2360, "id_estado":13, "cidade":"Cambuquira", "codigo":3110707},
    {"id":2361, "id_estado":13, "cidade":"Campan\u00e1rio", "codigo":3110806},
    {"id":2362, "id_estado":13, "cidade":"Campanha", "codigo":3110905},
    {"id":2363, "id_estado":13, "cidade":"Campestre", "codigo":3111002},
    {"id":2364, "id_estado":13, "cidade":"Campina Verde", "codigo":3111101},
    {"id":2365, "id_estado":13, "cidade":"Campo Azul", "codigo":3111150},
    {"id":2366, "id_estado":13, "cidade":"Campo Belo", "codigo":3111200},
    {"id":2367, "id_estado":13, "cidade":"Campo do Meio", "codigo":3111309},
    {"id":2368, "id_estado":13, "cidade":"Campo Florido", "codigo":3111408},
    {"id":2369, "id_estado":13, "cidade":"Campos Altos", "codigo":3111507},
    {"id":2370, "id_estado":13, "cidade":"Campos Gerais", "codigo":3111606},
    {"id":2371, "id_estado":13, "cidade":"Cana\u00e3", "codigo":3111705},
    {"id":2372, "id_estado":13, "cidade":"Can\u00e1polis", "codigo":3111804},
    {"id":2373, "id_estado":13, "cidade":"Cana Verde", "codigo":3111903},
    {"id":2374, "id_estado":13, "cidade":"Candeias", "codigo":3112000},
    {"id":2375, "id_estado":13, "cidade":"Cantagalo", "codigo":3112059},
    {"id":2376, "id_estado":13, "cidade":"Capara\u00f3", "codigo":3112109},
    {"id":2377, "id_estado":13, "cidade":"Capela Nova", "codigo":3112208},
    {"id":2378, "id_estado":13, "cidade":"Capelinha", "codigo":3112307},
    {"id":2379, "id_estado":13, "cidade":"Capetinga", "codigo":3112406},
    {"id":2380, "id_estado":13, "cidade":"Capim Branco", "codigo":3112505},
    {"id":2381, "id_estado":13, "cidade":"Capin\u00f3polis", "codigo":3112604},
    {"id":2382, "id_estado":13, "cidade":"Capit\u00e3o Andrade", "codigo":3112653},
    {"id":2383, "id_estado":13, "cidade":"Capit\u00e3o En\u00e9as", "codigo":3112703},
    {"id":2384, "id_estado":13, "cidade":"Capit\u00f3lio", "codigo":3112802},
    {"id":2385, "id_estado":13, "cidade":"Caputira", "codigo":3112901},
    {"id":2386, "id_estado":13, "cidade":"Cara\u00ed", "codigo":3113008},
    {"id":2387, "id_estado":13, "cidade":"Carana\u00edba", "codigo":3113107},
    {"id":2388, "id_estado":13, "cidade":"Caranda\u00ed", "codigo":3113206},
    {"id":2389, "id_estado":13, "cidade":"Carangola", "codigo":3113305},
    {"id":2390, "id_estado":13, "cidade":"Caratinga", "codigo":3113404},
    {"id":2391, "id_estado":13, "cidade":"Carbonita", "codigo":3113503},
    {"id":2392, "id_estado":13, "cidade":"Carea\u00e7u", "codigo":3113602},
    {"id":2393, "id_estado":13, "cidade":"Carlos Chagas", "codigo":3113701},
    {"id":2394, "id_estado":13, "cidade":"Carm\u00e9sia", "codigo":3113800},
    {"id":2395, "id_estado":13, "cidade":"Carmo da Cachoeira", "codigo":3113909},
    {"id":2396, "id_estado":13, "cidade":"Carmo da Mata", "codigo":3114006},
    {"id":2397, "id_estado":13, "cidade":"Carmo de Minas", "codigo":3114105},
    {"id":2398, "id_estado":13, "cidade":"Carmo do Cajuru", "codigo":3114204},
    {"id":2399, "id_estado":13, "cidade":"Carmo do Parana\u00edba", "codigo":3114303},
    {"id":2400, "id_estado":13, "cidade":"Carmo do Rio Claro", "codigo":3114402},
    {"id":2401, "id_estado":13, "cidade":"Carm\u00f3polis de Minas", "codigo":3114501},
    {"id":2402, "id_estado":13, "cidade":"Carneirinho", "codigo":3114550},
    {"id":2403, "id_estado":13, "cidade":"Carrancas", "codigo":3114600},
    {"id":2404, "id_estado":13, "cidade":"Carvalh\u00f3polis", "codigo":3114709},
    {"id":2405, "id_estado":13, "cidade":"Carvalhos", "codigo":3114808},
    {"id":2406, "id_estado":13, "cidade":"Casa Grande", "codigo":3114907},
    {"id":2407, "id_estado":13, "cidade":"Cascalho Rico", "codigo":3115003},
    {"id":2408, "id_estado":13, "cidade":"C\u00e1ssia", "codigo":3115102},
    {"id":2409, "id_estado":13, "cidade":"Concei\u00e7\u00e3o da Barra de Minas", "codigo":3115201},
    {"id":2410, "id_estado":13, "cidade":"Cataguases", "codigo":3115300},
    {"id":2411, "id_estado":13, "cidade":"Catas Altas", "codigo":3115359},
    {"id":2412, "id_estado":13, "cidade":"Catas Altas da Noruega", "codigo":3115409},
    {"id":2413, "id_estado":13, "cidade":"Catuji", "codigo":3115458},
    {"id":2414, "id_estado":13, "cidade":"Catuti", "codigo":3115474},
    {"id":2415, "id_estado":13, "cidade":"Caxambu", "codigo":3115508},
    {"id":2416, "id_estado":13, "cidade":"Cedro do Abaet\u00e9", "codigo":3115607},
    {"id":2417, "id_estado":13, "cidade":"Central de Minas", "codigo":3115706},
    {"id":2418, "id_estado":13, "cidade":"Centralina", "codigo":3115805},
    {"id":2419, "id_estado":13, "cidade":"Ch\u00e1cara", "codigo":3115904},
    {"id":2420, "id_estado":13, "cidade":"Chal\u00e9", "codigo":3116001},
    {"id":2421, "id_estado":13, "cidade":"Chapada do Norte", "codigo":3116100},
    {"id":2422, "id_estado":13, "cidade":"Chapada Ga\u00facha", "codigo":3116159},
    {"id":2423, "id_estado":13, "cidade":"Chiador", "codigo":3116209},
    {"id":2424, "id_estado":13, "cidade":"Cipot\u00e2nea", "codigo":3116308},
    {"id":2425, "id_estado":13, "cidade":"Claraval", "codigo":3116407},
    {"id":2426, "id_estado":13, "cidade":"Claro dos Po\u00e7\u00f5es", "codigo":3116506},
    {"id":2427, "id_estado":13, "cidade":"Cl\u00e1udio", "codigo":3116605},
    {"id":2428, "id_estado":13, "cidade":"Coimbra", "codigo":3116704},
    {"id":2429, "id_estado":13, "cidade":"Coluna", "codigo":3116803},
    {"id":2430, "id_estado":13, "cidade":"Comendador Gomes", "codigo":3116902},
    {"id":2431, "id_estado":13, "cidade":"Comercinho", "codigo":3117009},
    {"id":2432, "id_estado":13, "cidade":"Concei\u00e7\u00e3o da Aparecida", "codigo":3117108},
    {"id":2433, "id_estado":13, "cidade":"Concei\u00e7\u00e3o das Pedras", "codigo":3117207},
    {"id":2434, "id_estado":13, "cidade":"Concei\u00e7\u00e3o das Alagoas", "codigo":3117306},
    {"id":2435, "id_estado":13, "cidade":"Concei\u00e7\u00e3o de Ipanema", "codigo":3117405},
    {"id":2436, "id_estado":13, "cidade":"Concei\u00e7\u00e3o do Mato Dentro", "codigo":3117504},
    {"id":2437, "id_estado":13, "cidade":"Concei\u00e7\u00e3o do Par\u00e1", "codigo":3117603},
    {"id":2438, "id_estado":13, "cidade":"Concei\u00e7\u00e3o do Rio Verde", "codigo":3117702},
    {"id":2439, "id_estado":13, "cidade":"Concei\u00e7\u00e3o dos Ouros", "codigo":3117801},
    {"id":2440, "id_estado":13, "cidade":"C\u00f4nego Marinho", "codigo":3117836},
    {"id":2441, "id_estado":13, "cidade":"Confins", "codigo":3117876},
    {"id":2442, "id_estado":13, "cidade":"Congonhal", "codigo":3117900},
    {"id":2443, "id_estado":13, "cidade":"Congonhas", "codigo":3118007},
    {"id":2444, "id_estado":13, "cidade":"Congonhas do Norte", "codigo":3118106},
    {"id":2445, "id_estado":13, "cidade":"Conquista", "codigo":3118205},
    {"id":2446, "id_estado":13, "cidade":"Conselheiro Lafaiete", "codigo":3118304},
    {"id":2447, "id_estado":13, "cidade":"Conselheiro Pena", "codigo":3118403},
    {"id":2448, "id_estado":13, "cidade":"Consola\u00e7\u00e3o", "codigo":3118502},
    {"id":2449, "id_estado":13, "cidade":"Contagem", "codigo":3118601},
    {"id":2450, "id_estado":13, "cidade":"Coqueiral", "codigo":3118700},
    {"id":2451, "id_estado":13, "cidade":"Cora\u00e7\u00e3o de Jesus", "codigo":3118809},
    {"id":2452, "id_estado":13, "cidade":"Cordisburgo", "codigo":3118908},
    {"id":2453, "id_estado":13, "cidade":"Cordisl\u00e2ndia", "codigo":3119005},
    {"id":2454, "id_estado":13, "cidade":"Corinto", "codigo":3119104},
    {"id":2455, "id_estado":13, "cidade":"Coroaci", "codigo":3119203},
    {"id":2456, "id_estado":13, "cidade":"Coromandel", "codigo":3119302},
    {"id":2457, "id_estado":13, "cidade":"Coronel Fabriciano", "codigo":3119401},
    {"id":2458, "id_estado":13, "cidade":"Coronel Murta", "codigo":3119500},
    {"id":2459, "id_estado":13, "cidade":"Coronel Pacheco", "codigo":3119609},
    {"id":2460, "id_estado":13, "cidade":"Coronel Xavier Chaves", "codigo":3119708},
    {"id":2461, "id_estado":13, "cidade":"C\u00f3rrego Danta", "codigo":3119807},
    {"id":2462, "id_estado":13, "cidade":"C\u00f3rrego do Bom Jesus", "codigo":3119906},
    {"id":2463, "id_estado":13, "cidade":"C\u00f3rrego Fundo", "codigo":3119955},
    {"id":2464, "id_estado":13, "cidade":"C\u00f3rrego Novo", "codigo":3120003},
    {"id":2465, "id_estado":13, "cidade":"Couto de Magalh\u00e3es de Minas", "codigo":3120102},
    {"id":2466, "id_estado":13, "cidade":"Cris\u00f3lita", "codigo":3120151},
    {"id":2467, "id_estado":13, "cidade":"Cristais", "codigo":3120201},
    {"id":2468, "id_estado":13, "cidade":"Crist\u00e1lia", "codigo":3120300},
    {"id":2469, "id_estado":13, "cidade":"Cristiano Otoni", "codigo":3120409},
    {"id":2470, "id_estado":13, "cidade":"Cristina", "codigo":3120508},
    {"id":2471, "id_estado":13, "cidade":"Crucil\u00e2ndia", "codigo":3120607},
    {"id":2472, "id_estado":13, "cidade":"Cruzeiro da Fortaleza", "codigo":3120706},
    {"id":2473, "id_estado":13, "cidade":"Cruz\u00edlia", "codigo":3120805},
    {"id":2474, "id_estado":13, "cidade":"Cuparaque", "codigo":3120839},
    {"id":2475, "id_estado":13, "cidade":"Curral de Dentro", "codigo":3120870},
    {"id":2476, "id_estado":13, "cidade":"Curvelo", "codigo":3120904},
    {"id":2477, "id_estado":13, "cidade":"Datas", "codigo":3121001},
    {"id":2478, "id_estado":13, "cidade":"Delfim Moreira", "codigo":3121100},
    {"id":2479, "id_estado":13, "cidade":"Delfin\u00f3polis", "codigo":3121209},
    {"id":2480, "id_estado":13, "cidade":"Delta", "codigo":3121258},
    {"id":2481, "id_estado":13, "cidade":"Descoberto", "codigo":3121308},
    {"id":2482, "id_estado":13, "cidade":"Desterro de Entre Rios", "codigo":3121407},
    {"id":2483, "id_estado":13, "cidade":"Desterro do Melo", "codigo":3121506},
    {"id":2484, "id_estado":13, "cidade":"Diamantina", "codigo":3121605},
    {"id":2485, "id_estado":13, "cidade":"Diogo de Vasconcelos", "codigo":3121704},
    {"id":2486, "id_estado":13, "cidade":"Dion\u00edsio", "codigo":3121803},
    {"id":2487, "id_estado":13, "cidade":"Divin\u00e9sia", "codigo":3121902},
    {"id":2488, "id_estado":13, "cidade":"Divino", "codigo":3122009},
    {"id":2489, "id_estado":13, "cidade":"Divino das Laranjeiras", "codigo":3122108},
    {"id":2490, "id_estado":13, "cidade":"Divinol\u00e2ndia de Minas", "codigo":3122207},
    {"id":2491, "id_estado":13, "cidade":"Divin\u00f3polis", "codigo":3122306},
    {"id":2492, "id_estado":13, "cidade":"Divisa Alegre", "codigo":3122355},
    {"id":2493, "id_estado":13, "cidade":"Divisa Nova", "codigo":3122405},
    {"id":2494, "id_estado":13, "cidade":"Divis\u00f3polis", "codigo":3122454},
    {"id":2495, "id_estado":13, "cidade":"Dom Bosco", "codigo":3122470},
    {"id":2496, "id_estado":13, "cidade":"Dom Cavati", "codigo":3122504},
    {"id":2497, "id_estado":13, "cidade":"Dom Joaquim", "codigo":3122603},
    {"id":2498, "id_estado":13, "cidade":"Dom Silv\u00e9rio", "codigo":3122702},
    {"id":2499, "id_estado":13, "cidade":"Dom Vi\u00e7oso", "codigo":3122801},
    {"id":2500, "id_estado":13, "cidade":"Dona Eus\u00e9bia", "codigo":3122900},
    {"id":2501, "id_estado":13, "cidade":"Dores de Campos", "codigo":3123007},
    {"id":2502, "id_estado":13, "cidade":"Dores de Guanh\u00e3es", "codigo":3123106},
    {"id":2503, "id_estado":13, "cidade":"Dores do Indai\u00e1", "codigo":3123205},
    {"id":2504, "id_estado":13, "cidade":"Dores do Turvo", "codigo":3123304},
    {"id":2505, "id_estado":13, "cidade":"Dores\u00f3polis", "codigo":3123403},
    {"id":2506, "id_estado":13, "cidade":"Douradoquara", "codigo":3123502},
    {"id":2507, "id_estado":13, "cidade":"Durand\u00e9", "codigo":3123528},
    {"id":2508, "id_estado":13, "cidade":"El\u00f3i Mendes", "codigo":3123601},
    {"id":2509, "id_estado":13, "cidade":"Engenheiro Caldas", "codigo":3123700},
    {"id":2510, "id_estado":13, "cidade":"Engenheiro Navarro", "codigo":3123809},
    {"id":2511, "id_estado":13, "cidade":"Entre Folhas", "codigo":3123858},
    {"id":2512, "id_estado":13, "cidade":"Entre Rios de Minas", "codigo":3123908},
    {"id":2513, "id_estado":13, "cidade":"Erv\u00e1lia", "codigo":3124005},
    {"id":2514, "id_estado":13, "cidade":"Esmeraldas", "codigo":3124104},
    {"id":2515, "id_estado":13, "cidade":"Espera Feliz", "codigo":3124203},
    {"id":2516, "id_estado":13, "cidade":"Espinosa", "codigo":3124302},
    {"id":2517, "id_estado":13, "cidade":"Esp\u00edrito Santo do Dourado", "codigo":3124401},
    {"id":2518, "id_estado":13, "cidade":"Estiva", "codigo":3124500},
    {"id":2519, "id_estado":13, "cidade":"Estrela Dalva", "codigo":3124609},
    {"id":2520, "id_estado":13, "cidade":"Estrela do Indai\u00e1", "codigo":3124708},
    {"id":2521, "id_estado":13, "cidade":"Estrela do Sul", "codigo":3124807},
    {"id":2522, "id_estado":13, "cidade":"Eugen\u00f3polis", "codigo":3124906},
    {"id":2523, "id_estado":13, "cidade":"Ewbank da C\u00e2mara", "codigo":3125002},
    {"id":2524, "id_estado":13, "cidade":"Extrema", "codigo":3125101},
    {"id":2525, "id_estado":13, "cidade":"Fama", "codigo":3125200},
    {"id":2526, "id_estado":13, "cidade":"Faria Lemos", "codigo":3125309},
    {"id":2527, "id_estado":13, "cidade":"Fel\u00edcio dos Santos", "codigo":3125408},
    {"id":2528, "id_estado":13, "cidade":"S\u00e3o Gon\u00e7alo do Rio Preto", "codigo":3125507},
    {"id":2529, "id_estado":13, "cidade":"Felisburgo", "codigo":3125606},
    {"id":2530, "id_estado":13, "cidade":"Felixl\u00e2ndia", "codigo":3125705},
    {"id":2531, "id_estado":13, "cidade":"Fernandes Tourinho", "codigo":3125804},
    {"id":2532, "id_estado":13, "cidade":"Ferros", "codigo":3125903},
    {"id":2533, "id_estado":13, "cidade":"Fervedouro", "codigo":3125952},
    {"id":2534, "id_estado":13, "cidade":"Florestal", "codigo":3126000},
    {"id":2535, "id_estado":13, "cidade":"Formiga", "codigo":3126109},
    {"id":2536, "id_estado":13, "cidade":"Formoso", "codigo":3126208},
    {"id":2537, "id_estado":13, "cidade":"Fortaleza de Minas", "codigo":3126307},
    {"id":2538, "id_estado":13, "cidade":"Fortuna de Minas", "codigo":3126406},
    {"id":2539, "id_estado":13, "cidade":"Francisco Badar\u00f3", "codigo":3126505},
    {"id":2540, "id_estado":13, "cidade":"Francisco Dumont", "codigo":3126604},
    {"id":2541, "id_estado":13, "cidade":"Francisco S\u00e1", "codigo":3126703},
    {"id":2542, "id_estado":13, "cidade":"Francisc\u00f3polis", "codigo":3126752},
    {"id":2543, "id_estado":13, "cidade":"Frei Gaspar", "codigo":3126802},
    {"id":2544, "id_estado":13, "cidade":"Frei Inoc\u00eancio", "codigo":3126901},
    {"id":2545, "id_estado":13, "cidade":"Frei Lagonegro", "codigo":3126950},
    {"id":2546, "id_estado":13, "cidade":"Fronteira", "codigo":3127008},
    {"id":2547, "id_estado":13, "cidade":"Fronteira dos Vales", "codigo":3127057},
    {"id":2548, "id_estado":13, "cidade":"Fruta de Leite", "codigo":3127073},
    {"id":2549, "id_estado":13, "cidade":"Frutal", "codigo":3127107},
    {"id":2550, "id_estado":13, "cidade":"Funil\u00e2ndia", "codigo":3127206},
    {"id":2551, "id_estado":13, "cidade":"Galil\u00e9ia", "codigo":3127305},
    {"id":2552, "id_estado":13, "cidade":"Gameleiras", "codigo":3127339},
    {"id":2553, "id_estado":13, "cidade":"Glaucil\u00e2ndia", "codigo":3127354},
    {"id":2554, "id_estado":13, "cidade":"Goiabeira", "codigo":3127370},
    {"id":2555, "id_estado":13, "cidade":"Goian\u00e1", "codigo":3127388},
    {"id":2556, "id_estado":13, "cidade":"Gon\u00e7alves", "codigo":3127404},
    {"id":2557, "id_estado":13, "cidade":"Gonzaga", "codigo":3127503},
    {"id":2558, "id_estado":13, "cidade":"Gouveia", "codigo":3127602},
    {"id":2559, "id_estado":13, "cidade":"Governador Valadares", "codigo":3127701},
    {"id":2560, "id_estado":13, "cidade":"Gr\u00e3o Mogol", "codigo":3127800},
    {"id":2561, "id_estado":13, "cidade":"Grupiara", "codigo":3127909},
    {"id":2562, "id_estado":13, "cidade":"Guanh\u00e3es", "codigo":3128006},
    {"id":2563, "id_estado":13, "cidade":"Guap\u00e9", "codigo":3128105},
    {"id":2564, "id_estado":13, "cidade":"Guaraciaba", "codigo":3128204},
    {"id":2565, "id_estado":13, "cidade":"Guaraciama", "codigo":3128253},
    {"id":2566, "id_estado":13, "cidade":"Guaran\u00e9sia", "codigo":3128303},
    {"id":2567, "id_estado":13, "cidade":"Guarani", "codigo":3128402},
    {"id":2568, "id_estado":13, "cidade":"Guarar\u00e1", "codigo":3128501},
    {"id":2569, "id_estado":13, "cidade":"Guarda-Mor", "codigo":3128600},
    {"id":2570, "id_estado":13, "cidade":"Guaxup\u00e9", "codigo":3128709},
    {"id":2571, "id_estado":13, "cidade":"Guidoval", "codigo":3128808},
    {"id":2572, "id_estado":13, "cidade":"Guimar\u00e2nia", "codigo":3128907},
    {"id":2573, "id_estado":13, "cidade":"Guiricema", "codigo":3129004},
    {"id":2574, "id_estado":13, "cidade":"Gurinhat\u00e3", "codigo":3129103},
    {"id":2575, "id_estado":13, "cidade":"Heliodora", "codigo":3129202},
    {"id":2576, "id_estado":13, "cidade":"Iapu", "codigo":3129301},
    {"id":2577, "id_estado":13, "cidade":"Ibertioga", "codigo":3129400},
    {"id":2578, "id_estado":13, "cidade":"Ibi\u00e1", "codigo":3129509},
    {"id":2579, "id_estado":13, "cidade":"Ibia\u00ed", "codigo":3129608},
    {"id":2580, "id_estado":13, "cidade":"Ibiracatu", "codigo":3129657},
    {"id":2581, "id_estado":13, "cidade":"Ibiraci", "codigo":3129707},
    {"id":2582, "id_estado":13, "cidade":"Ibirit\u00e9", "codigo":3129806},
    {"id":2583, "id_estado":13, "cidade":"Ibiti\u00fara de Minas", "codigo":3129905},
    {"id":2584, "id_estado":13, "cidade":"Ibituruna", "codigo":3130002},
    {"id":2585, "id_estado":13, "cidade":"Icara\u00ed de Minas", "codigo":3130051},
    {"id":2586, "id_estado":13, "cidade":"Igarap\u00e9", "codigo":3130101},
    {"id":2587, "id_estado":13, "cidade":"Igaratinga", "codigo":3130200},
    {"id":2588, "id_estado":13, "cidade":"Iguatama", "codigo":3130309},
    {"id":2589, "id_estado":13, "cidade":"Ijaci", "codigo":3130408},
    {"id":2590, "id_estado":13, "cidade":"Ilic\u00ednea", "codigo":3130507},
    {"id":2591, "id_estado":13, "cidade":"Imb\u00e9 de Minas", "codigo":3130556},
    {"id":2592, "id_estado":13, "cidade":"Inconfidentes", "codigo":3130606},
    {"id":2593, "id_estado":13, "cidade":"Indaiabira", "codigo":3130655},
    {"id":2594, "id_estado":13, "cidade":"Indian\u00f3polis", "codigo":3130705},
    {"id":2595, "id_estado":13, "cidade":"Inga\u00ed", "codigo":3130804},
    {"id":2596, "id_estado":13, "cidade":"Inhapim", "codigo":3130903},
    {"id":2597, "id_estado":13, "cidade":"Inha\u00fama", "codigo":3131000},
    {"id":2598, "id_estado":13, "cidade":"Inimutaba", "codigo":3131109},
    {"id":2599, "id_estado":13, "cidade":"Ipaba", "codigo":3131158},
    {"id":2600, "id_estado":13, "cidade":"Ipanema", "codigo":3131208},
    {"id":2601, "id_estado":13, "cidade":"Ipatinga", "codigo":3131307},
    {"id":2602, "id_estado":13, "cidade":"Ipia\u00e7u", "codigo":3131406},
    {"id":2603, "id_estado":13, "cidade":"Ipui\u00fana", "codigo":3131505},
    {"id":2604, "id_estado":13, "cidade":"Ira\u00ed de Minas", "codigo":3131604},
    {"id":2605, "id_estado":13, "cidade":"Itabira", "codigo":3131703},
    {"id":2606, "id_estado":13, "cidade":"Itabirinha", "codigo":3131802},
    {"id":2607, "id_estado":13, "cidade":"Itabirito", "codigo":3131901},
    {"id":2608, "id_estado":13, "cidade":"Itacambira", "codigo":3132008},
    {"id":2609, "id_estado":13, "cidade":"Itacarambi", "codigo":3132107},
    {"id":2610, "id_estado":13, "cidade":"Itaguara", "codigo":3132206},
    {"id":2611, "id_estado":13, "cidade":"Itaip\u00e9", "codigo":3132305},
    {"id":2612, "id_estado":13, "cidade":"Itajub\u00e1", "codigo":3132404},
    {"id":2613, "id_estado":13, "cidade":"Itamarandiba", "codigo":3132503},
    {"id":2614, "id_estado":13, "cidade":"Itamarati de Minas", "codigo":3132602},
    {"id":2615, "id_estado":13, "cidade":"Itambacuri", "codigo":3132701},
    {"id":2616, "id_estado":13, "cidade":"Itamb\u00e9 do Mato Dentro", "codigo":3132800},
    {"id":2617, "id_estado":13, "cidade":"Itamogi", "codigo":3132909},
    {"id":2618, "id_estado":13, "cidade":"Itamonte", "codigo":3133006},
    {"id":2619, "id_estado":13, "cidade":"Itanhandu", "codigo":3133105},
    {"id":2620, "id_estado":13, "cidade":"Itanhomi", "codigo":3133204},
    {"id":2621, "id_estado":13, "cidade":"Itaobim", "codigo":3133303},
    {"id":2622, "id_estado":13, "cidade":"Itapagipe", "codigo":3133402},
    {"id":2623, "id_estado":13, "cidade":"Itapecerica", "codigo":3133501},
    {"id":2624, "id_estado":13, "cidade":"Itapeva", "codigo":3133600},
    {"id":2625, "id_estado":13, "cidade":"Itatiaiu\u00e7u", "codigo":3133709},
    {"id":2626, "id_estado":13, "cidade":"Ita\u00fa de Minas", "codigo":3133758},
    {"id":2627, "id_estado":13, "cidade":"Ita\u00fana", "codigo":3133808},
    {"id":2628, "id_estado":13, "cidade":"Itaverava", "codigo":3133907},
    {"id":2629, "id_estado":13, "cidade":"Itinga", "codigo":3134004},
    {"id":2630, "id_estado":13, "cidade":"Itueta", "codigo":3134103},
    {"id":2631, "id_estado":13, "cidade":"Ituiutaba", "codigo":3134202},
    {"id":2632, "id_estado":13, "cidade":"Itumirim", "codigo":3134301},
    {"id":2633, "id_estado":13, "cidade":"Iturama", "codigo":3134400},
    {"id":2634, "id_estado":13, "cidade":"Itutinga", "codigo":3134509},
    {"id":2635, "id_estado":13, "cidade":"Jaboticatubas", "codigo":3134608},
    {"id":2636, "id_estado":13, "cidade":"Jacinto", "codigo":3134707},
    {"id":2637, "id_estado":13, "cidade":"Jacu\u00ed", "codigo":3134806},
    {"id":2638, "id_estado":13, "cidade":"Jacutinga", "codigo":3134905},
    {"id":2639, "id_estado":13, "cidade":"Jaguara\u00e7u", "codigo":3135001},
    {"id":2640, "id_estado":13, "cidade":"Ja\u00edba", "codigo":3135050},
    {"id":2641, "id_estado":13, "cidade":"Jampruca", "codigo":3135076},
    {"id":2642, "id_estado":13, "cidade":"Jana\u00faba", "codigo":3135100},
    {"id":2643, "id_estado":13, "cidade":"Janu\u00e1ria", "codigo":3135209},
    {"id":2644, "id_estado":13, "cidade":"Japara\u00edba", "codigo":3135308},
    {"id":2645, "id_estado":13, "cidade":"Japonvar", "codigo":3135357},
    {"id":2646, "id_estado":13, "cidade":"Jeceaba", "codigo":3135407},
    {"id":2647, "id_estado":13, "cidade":"Jenipapo de Minas", "codigo":3135456},
    {"id":2648, "id_estado":13, "cidade":"Jequeri", "codigo":3135506},
    {"id":2649, "id_estado":13, "cidade":"Jequita\u00ed", "codigo":3135605},
    {"id":2650, "id_estado":13, "cidade":"Jequitib\u00e1", "codigo":3135704},
    {"id":2651, "id_estado":13, "cidade":"Jequitinhonha", "codigo":3135803},
    {"id":2652, "id_estado":13, "cidade":"Jesu\u00e2nia", "codigo":3135902},
    {"id":2653, "id_estado":13, "cidade":"Joa\u00edma", "codigo":3136009},
    {"id":2654, "id_estado":13, "cidade":"Joan\u00e9sia", "codigo":3136108},
    {"id":2655, "id_estado":13, "cidade":"Jo\u00e3o Monlevade", "codigo":3136207},
    {"id":2656, "id_estado":13, "cidade":"Jo\u00e3o Pinheiro", "codigo":3136306},
    {"id":2657, "id_estado":13, "cidade":"Joaquim Fel\u00edcio", "codigo":3136405},
    {"id":2658, "id_estado":13, "cidade":"Jord\u00e2nia", "codigo":3136504},
    {"id":2659, "id_estado":13, "cidade":"Jos\u00e9 Gon\u00e7alves de Minas", "codigo":3136520},
    {"id":2660, "id_estado":13, "cidade":"Jos\u00e9 Raydan", "codigo":3136553},
    {"id":2661, "id_estado":13, "cidade":"Josen\u00f3polis", "codigo":3136579},
    {"id":2662, "id_estado":13, "cidade":"Nova Uni\u00e3o", "codigo":3136603},
    {"id":2663, "id_estado":13, "cidade":"Juatuba", "codigo":3136652},
    {"id":2664, "id_estado":13, "cidade":"Juiz de Fora", "codigo":3136702},
    {"id":2665, "id_estado":13, "cidade":"Juramento", "codigo":3136801},
    {"id":2666, "id_estado":13, "cidade":"Juruaia", "codigo":3136900},
    {"id":2667, "id_estado":13, "cidade":"Juven\u00edlia", "codigo":3136959},
    {"id":2668, "id_estado":13, "cidade":"Ladainha", "codigo":3137007},
    {"id":2669, "id_estado":13, "cidade":"Lagamar", "codigo":3137106},
    {"id":2670, "id_estado":13, "cidade":"Lagoa da Prata", "codigo":3137205},
    {"id":2671, "id_estado":13, "cidade":"Lagoa dos Patos", "codigo":3137304},
    {"id":2672, "id_estado":13, "cidade":"Lagoa Dourada", "codigo":3137403},
    {"id":2673, "id_estado":13, "cidade":"Lagoa Formosa", "codigo":3137502},
    {"id":2674, "id_estado":13, "cidade":"Lagoa Grande", "codigo":3137536},
    {"id":2675, "id_estado":13, "cidade":"Lagoa Santa", "codigo":3137601},
    {"id":2676, "id_estado":13, "cidade":"Lajinha", "codigo":3137700},
    {"id":2677, "id_estado":13, "cidade":"Lambari", "codigo":3137809},
    {"id":2678, "id_estado":13, "cidade":"Lamim", "codigo":3137908},
    {"id":2679, "id_estado":13, "cidade":"Laranjal", "codigo":3138005},
    {"id":2680, "id_estado":13, "cidade":"Lassance", "codigo":3138104},
    {"id":2681, "id_estado":13, "cidade":"Lavras", "codigo":3138203},
    {"id":2682, "id_estado":13, "cidade":"Leandro Ferreira", "codigo":3138302},
    {"id":2683, "id_estado":13, "cidade":"Leme do Prado", "codigo":3138351},
    {"id":2684, "id_estado":13, "cidade":"Leopoldina", "codigo":3138401},
    {"id":2685, "id_estado":13, "cidade":"Liberdade", "codigo":3138500},
    {"id":2686, "id_estado":13, "cidade":"Lima Duarte", "codigo":3138609},
    {"id":2687, "id_estado":13, "cidade":"Limeira do Oeste", "codigo":3138625},
    {"id":2688, "id_estado":13, "cidade":"Lontra", "codigo":3138658},
    {"id":2689, "id_estado":13, "cidade":"Luisburgo", "codigo":3138674},
    {"id":2690, "id_estado":13, "cidade":"Luisl\u00e2ndia", "codigo":3138682},
    {"id":2691, "id_estado":13, "cidade":"Lumin\u00e1rias", "codigo":3138708},
    {"id":2692, "id_estado":13, "cidade":"Luz", "codigo":3138807},
    {"id":2693, "id_estado":13, "cidade":"Machacalis", "codigo":3138906},
    {"id":2694, "id_estado":13, "cidade":"Machado", "codigo":3139003},
    {"id":2695, "id_estado":13, "cidade":"Madre de Deus de Minas", "codigo":3139102},
    {"id":2696, "id_estado":13, "cidade":"Malacacheta", "codigo":3139201},
    {"id":2697, "id_estado":13, "cidade":"Mamonas", "codigo":3139250},
    {"id":2698, "id_estado":13, "cidade":"Manga", "codigo":3139300},
    {"id":2699, "id_estado":13, "cidade":"Manhua\u00e7u", "codigo":3139409},
    {"id":2700, "id_estado":13, "cidade":"Manhumirim", "codigo":3139508},
    {"id":2701, "id_estado":13, "cidade":"Mantena", "codigo":3139607},
    {"id":2702, "id_estado":13, "cidade":"Maravilhas", "codigo":3139706},
    {"id":2703, "id_estado":13, "cidade":"Mar de Espanha", "codigo":3139805},
    {"id":2704, "id_estado":13, "cidade":"Maria da F\u00e9", "codigo":3139904},
    {"id":2705, "id_estado":13, "cidade":"Mariana", "codigo":3140001},
    {"id":2706, "id_estado":13, "cidade":"Marilac", "codigo":3140100},
    {"id":2707, "id_estado":13, "cidade":"M\u00e1rio Campos", "codigo":3140159},
    {"id":2708, "id_estado":13, "cidade":"Marip\u00e1 de Minas", "codigo":3140209},
    {"id":2709, "id_estado":13, "cidade":"Marli\u00e9ria", "codigo":3140308},
    {"id":2710, "id_estado":13, "cidade":"Marmel\u00f3polis", "codigo":3140407},
    {"id":2711, "id_estado":13, "cidade":"Martinho Campos", "codigo":3140506},
    {"id":2712, "id_estado":13, "cidade":"Martins Soares", "codigo":3140530},
    {"id":2713, "id_estado":13, "cidade":"Mata Verde", "codigo":3140555},
    {"id":2714, "id_estado":13, "cidade":"Materl\u00e2ndia", "codigo":3140605},
    {"id":2715, "id_estado":13, "cidade":"Mateus Leme", "codigo":3140704},
    {"id":2716, "id_estado":13, "cidade":"Matias Barbosa", "codigo":3140803},
    {"id":2717, "id_estado":13, "cidade":"Matias Cardoso", "codigo":3140852},
    {"id":2718, "id_estado":13, "cidade":"Matip\u00f3", "codigo":3140902},
    {"id":2719, "id_estado":13, "cidade":"Mato Verde", "codigo":3141009},
    {"id":2720, "id_estado":13, "cidade":"Matozinhos", "codigo":3141108},
    {"id":2721, "id_estado":13, "cidade":"Matutina", "codigo":3141207},
    {"id":2722, "id_estado":13, "cidade":"Medeiros", "codigo":3141306},
    {"id":2723, "id_estado":13, "cidade":"Medina", "codigo":3141405},
    {"id":2724, "id_estado":13, "cidade":"Mendes Pimentel", "codigo":3141504},
    {"id":2725, "id_estado":13, "cidade":"Merc\u00eas", "codigo":3141603},
    {"id":2726, "id_estado":13, "cidade":"Mesquita", "codigo":3141702},
    {"id":2727, "id_estado":13, "cidade":"Minas Novas", "codigo":3141801},
    {"id":2728, "id_estado":13, "cidade":"Minduri", "codigo":3141900},
    {"id":2729, "id_estado":13, "cidade":"Mirabela", "codigo":3142007},
    {"id":2730, "id_estado":13, "cidade":"Miradouro", "codigo":3142106},
    {"id":2731, "id_estado":13, "cidade":"Mira\u00ed", "codigo":3142205},
    {"id":2732, "id_estado":13, "cidade":"Mirav\u00e2nia", "codigo":3142254},
    {"id":2733, "id_estado":13, "cidade":"Moeda", "codigo":3142304},
    {"id":2734, "id_estado":13, "cidade":"Moema", "codigo":3142403},
    {"id":2735, "id_estado":13, "cidade":"Monjolos", "codigo":3142502},
    {"id":2736, "id_estado":13, "cidade":"Monsenhor Paulo", "codigo":3142601},
    {"id":2737, "id_estado":13, "cidade":"Montalv\u00e2nia", "codigo":3142700},
    {"id":2738, "id_estado":13, "cidade":"Monte Alegre de Minas", "codigo":3142809},
    {"id":2739, "id_estado":13, "cidade":"Monte Azul", "codigo":3142908},
    {"id":2740, "id_estado":13, "cidade":"Monte Belo", "codigo":3143005},
    {"id":2741, "id_estado":13, "cidade":"Monte Carmelo", "codigo":3143104},
    {"id":2742, "id_estado":13, "cidade":"Monte Formoso", "codigo":3143153},
    {"id":2743, "id_estado":13, "cidade":"Monte Santo de Minas", "codigo":3143203},
    {"id":2744, "id_estado":13, "cidade":"Montes Claros", "codigo":3143302},
    {"id":2745, "id_estado":13, "cidade":"Monte Si\u00e3o", "codigo":3143401},
    {"id":2746, "id_estado":13, "cidade":"Montezuma", "codigo":3143450},
    {"id":2747, "id_estado":13, "cidade":"Morada Nova de Minas", "codigo":3143500},
    {"id":2748, "id_estado":13, "cidade":"Morro da Gar\u00e7a", "codigo":3143609},
    {"id":2749, "id_estado":13, "cidade":"Morro do Pilar", "codigo":3143708},
    {"id":2750, "id_estado":13, "cidade":"Munhoz", "codigo":3143807},
    {"id":2751, "id_estado":13, "cidade":"Muria\u00e9", "codigo":3143906},
    {"id":2752, "id_estado":13, "cidade":"Mutum", "codigo":3144003},
    {"id":2753, "id_estado":13, "cidade":"Muzambinho", "codigo":3144102},
    {"id":2754, "id_estado":13, "cidade":"Nacip Raydan", "codigo":3144201},
    {"id":2755, "id_estado":13, "cidade":"Nanuque", "codigo":3144300},
    {"id":2756, "id_estado":13, "cidade":"Naque", "codigo":3144359},
    {"id":2757, "id_estado":13, "cidade":"Natal\u00e2ndia", "codigo":3144375},
    {"id":2758, "id_estado":13, "cidade":"Nat\u00e9rcia", "codigo":3144409},
    {"id":2759, "id_estado":13, "cidade":"Nazareno", "codigo":3144508},
    {"id":2760, "id_estado":13, "cidade":"Nepomuceno", "codigo":3144607},
    {"id":2761, "id_estado":13, "cidade":"Ninheira", "codigo":3144656},
    {"id":2762, "id_estado":13, "cidade":"Nova Bel\u00e9m", "codigo":3144672},
    {"id":2763, "id_estado":13, "cidade":"Nova Era", "codigo":3144706},
    {"id":2764, "id_estado":13, "cidade":"Nova Lima", "codigo":3144805},
    {"id":2765, "id_estado":13, "cidade":"Nova M\u00f3dica", "codigo":3144904},
    {"id":2766, "id_estado":13, "cidade":"Nova Ponte", "codigo":3145000},
    {"id":2767, "id_estado":13, "cidade":"Nova Porteirinha", "codigo":3145059},
    {"id":2768, "id_estado":13, "cidade":"Nova Resende", "codigo":3145109},
    {"id":2769, "id_estado":13, "cidade":"Nova Serrana", "codigo":3145208},
    {"id":2770, "id_estado":13, "cidade":"Novo Cruzeiro", "codigo":3145307},
    {"id":2771, "id_estado":13, "cidade":"Novo Oriente de Minas", "codigo":3145356},
    {"id":2772, "id_estado":13, "cidade":"Novorizonte", "codigo":3145372},
    {"id":2773, "id_estado":13, "cidade":"Olaria", "codigo":3145406},
    {"id":2774, "id_estado":13, "cidade":"Olhos-D'\u00c1gua", "codigo":3145455},
    {"id":2775, "id_estado":13, "cidade":"Ol\u00edmpio Noronha", "codigo":3145505},
    {"id":2776, "id_estado":13, "cidade":"Oliveira", "codigo":3145604},
    {"id":2777, "id_estado":13, "cidade":"Oliveira Fortes", "codigo":3145703},
    {"id":2778, "id_estado":13, "cidade":"On\u00e7a de Pitangui", "codigo":3145802},
    {"id":2779, "id_estado":13, "cidade":"Orat\u00f3rios", "codigo":3145851},
    {"id":2780, "id_estado":13, "cidade":"Oriz\u00e2nia", "codigo":3145877},
    {"id":2781, "id_estado":13, "cidade":"Ouro Branco", "codigo":3145901},
    {"id":2782, "id_estado":13, "cidade":"Ouro Fino", "codigo":3146008},
    {"id":2783, "id_estado":13, "cidade":"Ouro Preto", "codigo":3146107},
    {"id":2784, "id_estado":13, "cidade":"Ouro Verde de Minas", "codigo":3146206},
    {"id":2785, "id_estado":13, "cidade":"Padre Carvalho", "codigo":3146255},
    {"id":2786, "id_estado":13, "cidade":"Padre Para\u00edso", "codigo":3146305},
    {"id":2787, "id_estado":13, "cidade":"Paineiras", "codigo":3146404},
    {"id":2788, "id_estado":13, "cidade":"Pains", "codigo":3146503},
    {"id":2789, "id_estado":13, "cidade":"Pai Pedro", "codigo":3146552},
    {"id":2790, "id_estado":13, "cidade":"Paiva", "codigo":3146602},
    {"id":2791, "id_estado":13, "cidade":"Palma", "codigo":3146701},
    {"id":2792, "id_estado":13, "cidade":"Palm\u00f3polis", "codigo":3146750},
    {"id":2793, "id_estado":13, "cidade":"Papagaios", "codigo":3146909},
    {"id":2794, "id_estado":13, "cidade":"Paracatu", "codigo":3147006},
    {"id":2795, "id_estado":13, "cidade":"Par\u00e1 de Minas", "codigo":3147105},
    {"id":2796, "id_estado":13, "cidade":"Paragua\u00e7u", "codigo":3147204},
    {"id":2797, "id_estado":13, "cidade":"Parais\u00f3polis", "codigo":3147303},
    {"id":2798, "id_estado":13, "cidade":"Paraopeba", "codigo":3147402},
    {"id":2799, "id_estado":13, "cidade":"Passab\u00e9m", "codigo":3147501},
    {"id":2800, "id_estado":13, "cidade":"Passa Quatro", "codigo":3147600},
    {"id":2801, "id_estado":13, "cidade":"Passa Tempo", "codigo":3147709},
    {"id":2802, "id_estado":13, "cidade":"Passa-Vinte", "codigo":3147808},
    {"id":2803, "id_estado":13, "cidade":"Passos", "codigo":3147907},
    {"id":2804, "id_estado":13, "cidade":"Patis", "codigo":3147956},
    {"id":2805, "id_estado":13, "cidade":"Patos de Minas", "codigo":3148004},
    {"id":2806, "id_estado":13, "cidade":"Patroc\u00ednio", "codigo":3148103},
    {"id":2807, "id_estado":13, "cidade":"Patroc\u00ednio do Muria\u00e9", "codigo":3148202},
    {"id":2808, "id_estado":13, "cidade":"Paula C\u00e2ndido", "codigo":3148301},
    {"id":2809, "id_estado":13, "cidade":"Paulistas", "codigo":3148400},
    {"id":2810, "id_estado":13, "cidade":"Pav\u00e3o", "codigo":3148509},
    {"id":2811, "id_estado":13, "cidade":"Pe\u00e7anha", "codigo":3148608},
    {"id":2812, "id_estado":13, "cidade":"Pedra Azul", "codigo":3148707},
    {"id":2813, "id_estado":13, "cidade":"Pedra Bonita", "codigo":3148756},
    {"id":2814, "id_estado":13, "cidade":"Pedra do Anta", "codigo":3148806},
    {"id":2815, "id_estado":13, "cidade":"Pedra do Indai\u00e1", "codigo":3148905},
    {"id":2816, "id_estado":13, "cidade":"Pedra Dourada", "codigo":3149002},
    {"id":2817, "id_estado":13, "cidade":"Pedralva", "codigo":3149101},
    {"id":2818, "id_estado":13, "cidade":"Pedras de Maria da Cruz", "codigo":3149150},
    {"id":2819, "id_estado":13, "cidade":"Pedrin\u00f3polis", "codigo":3149200},
    {"id":2820, "id_estado":13, "cidade":"Pedro Leopoldo", "codigo":3149309},
    {"id":2821, "id_estado":13, "cidade":"Pedro Teixeira", "codigo":3149408},
    {"id":2822, "id_estado":13, "cidade":"Pequeri", "codigo":3149507},
    {"id":2823, "id_estado":13, "cidade":"Pequi", "codigo":3149606},
    {"id":2824, "id_estado":13, "cidade":"Perdig\u00e3o", "codigo":3149705},
    {"id":2825, "id_estado":13, "cidade":"Perdizes", "codigo":3149804},
    {"id":2826, "id_estado":13, "cidade":"Perd\u00f5es", "codigo":3149903},
    {"id":2827, "id_estado":13, "cidade":"Periquito", "codigo":3149952},
    {"id":2828, "id_estado":13, "cidade":"Pescador", "codigo":3150000},
    {"id":2829, "id_estado":13, "cidade":"Piau", "codigo":3150109},
    {"id":2830, "id_estado":13, "cidade":"Piedade de Caratinga", "codigo":3150158},
    {"id":2831, "id_estado":13, "cidade":"Piedade de Ponte Nova", "codigo":3150208},
    {"id":2832, "id_estado":13, "cidade":"Piedade do Rio Grande", "codigo":3150307},
    {"id":2833, "id_estado":13, "cidade":"Piedade dos Gerais", "codigo":3150406},
    {"id":2834, "id_estado":13, "cidade":"Pimenta", "codigo":3150505},
    {"id":2835, "id_estado":13, "cidade":"Pingo-D'\u00c1gua", "codigo":3150539},
    {"id":2836, "id_estado":13, "cidade":"Pint\u00f3polis", "codigo":3150570},
    {"id":2837, "id_estado":13, "cidade":"Piracema", "codigo":3150604},
    {"id":2838, "id_estado":13, "cidade":"Pirajuba", "codigo":3150703},
    {"id":2839, "id_estado":13, "cidade":"Piranga", "codigo":3150802},
    {"id":2840, "id_estado":13, "cidade":"Pirangu\u00e7u", "codigo":3150901},
    {"id":2841, "id_estado":13, "cidade":"Piranguinho", "codigo":3151008},
    {"id":2842, "id_estado":13, "cidade":"Pirapetinga", "codigo":3151107},
    {"id":2843, "id_estado":13, "cidade":"Pirapora", "codigo":3151206},
    {"id":2844, "id_estado":13, "cidade":"Pira\u00faba", "codigo":3151305},
    {"id":2845, "id_estado":13, "cidade":"Pitangui", "codigo":3151404},
    {"id":2846, "id_estado":13, "cidade":"Piumhi", "codigo":3151503},
    {"id":2847, "id_estado":13, "cidade":"Planura", "codigo":3151602},
    {"id":2848, "id_estado":13, "cidade":"Po\u00e7o Fundo", "codigo":3151701},
    {"id":2849, "id_estado":13, "cidade":"Po\u00e7os de Caldas", "codigo":3151800},
    {"id":2850, "id_estado":13, "cidade":"Pocrane", "codigo":3151909},
    {"id":2851, "id_estado":13, "cidade":"Pomp\u00e9u", "codigo":3152006},
    {"id":2852, "id_estado":13, "cidade":"Ponte Nova", "codigo":3152105},
    {"id":2853, "id_estado":13, "cidade":"Ponto Chique", "codigo":3152131},
    {"id":2854, "id_estado":13, "cidade":"Ponto dos Volantes", "codigo":3152170},
    {"id":2855, "id_estado":13, "cidade":"Porteirinha", "codigo":3152204},
    {"id":2856, "id_estado":13, "cidade":"Porto Firme", "codigo":3152303},
    {"id":2857, "id_estado":13, "cidade":"Pot\u00e9", "codigo":3152402},
    {"id":2858, "id_estado":13, "cidade":"Pouso Alegre", "codigo":3152501},
    {"id":2859, "id_estado":13, "cidade":"Pouso Alto", "codigo":3152600},
    {"id":2860, "id_estado":13, "cidade":"Prados", "codigo":3152709},
    {"id":2861, "id_estado":13, "cidade":"Prata", "codigo":3152808},
    {"id":2862, "id_estado":13, "cidade":"Prat\u00e1polis", "codigo":3152907},
    {"id":2863, "id_estado":13, "cidade":"Pratinha", "codigo":3153004},
    {"id":2864, "id_estado":13, "cidade":"Presidente Bernardes", "codigo":3153103},
    {"id":2865, "id_estado":13, "cidade":"Presidente Juscelino", "codigo":3153202},
    {"id":2866, "id_estado":13, "cidade":"Presidente Kubitschek", "codigo":3153301},
    {"id":2867, "id_estado":13, "cidade":"Presidente Oleg\u00e1rio", "codigo":3153400},
    {"id":2868, "id_estado":13, "cidade":"Alto Jequitib\u00e1", "codigo":3153509},
    {"id":2869, "id_estado":13, "cidade":"Prudente de Morais", "codigo":3153608},
    {"id":2870, "id_estado":13, "cidade":"Quartel Geral", "codigo":3153707},
    {"id":2871, "id_estado":13, "cidade":"Queluzito", "codigo":3153806},
    {"id":2872, "id_estado":13, "cidade":"Raposos", "codigo":3153905},
    {"id":2873, "id_estado":13, "cidade":"Raul Soares", "codigo":3154002},
    {"id":2874, "id_estado":13, "cidade":"Recreio", "codigo":3154101},
    {"id":2875, "id_estado":13, "cidade":"Reduto", "codigo":3154150},
    {"id":2876, "id_estado":13, "cidade":"Resende Costa", "codigo":3154200},
    {"id":2877, "id_estado":13, "cidade":"Resplendor", "codigo":3154309},
    {"id":2878, "id_estado":13, "cidade":"Ressaquinha", "codigo":3154408},
    {"id":2879, "id_estado":13, "cidade":"Riachinho", "codigo":3154457},
    {"id":2880, "id_estado":13, "cidade":"Riacho dos Machados", "codigo":3154507},
    {"id":2881, "id_estado":13, "cidade":"Ribeir\u00e3o das Neves", "codigo":3154606},
    {"id":2882, "id_estado":13, "cidade":"Ribeir\u00e3o Vermelho", "codigo":3154705},
    {"id":2883, "id_estado":13, "cidade":"Rio Acima", "codigo":3154804},
    {"id":2884, "id_estado":13, "cidade":"Rio Casca", "codigo":3154903},
    {"id":2885, "id_estado":13, "cidade":"Rio Doce", "codigo":3155009},
    {"id":2886, "id_estado":13, "cidade":"Rio do Prado", "codigo":3155108},
    {"id":2887, "id_estado":13, "cidade":"Rio Espera", "codigo":3155207},
    {"id":2888, "id_estado":13, "cidade":"Rio Manso", "codigo":3155306},
    {"id":2889, "id_estado":13, "cidade":"Rio Novo", "codigo":3155405},
    {"id":2890, "id_estado":13, "cidade":"Rio Parana\u00edba", "codigo":3155504},
    {"id":2891, "id_estado":13, "cidade":"Rio Pardo de Minas", "codigo":3155603},
    {"id":2892, "id_estado":13, "cidade":"Rio Piracicaba", "codigo":3155702},
    {"id":2893, "id_estado":13, "cidade":"Rio Pomba", "codigo":3155801},
    {"id":2894, "id_estado":13, "cidade":"Rio Preto", "codigo":3155900},
    {"id":2895, "id_estado":13, "cidade":"Rio Vermelho", "codigo":3156007},
    {"id":2896, "id_estado":13, "cidade":"Rit\u00e1polis", "codigo":3156106},
    {"id":2897, "id_estado":13, "cidade":"Rochedo de Minas", "codigo":3156205},
    {"id":2898, "id_estado":13, "cidade":"Rodeiro", "codigo":3156304},
    {"id":2899, "id_estado":13, "cidade":"Romaria", "codigo":3156403},
    {"id":2900, "id_estado":13, "cidade":"Ros\u00e1rio da Limeira", "codigo":3156452},
    {"id":2901, "id_estado":13, "cidade":"Rubelita", "codigo":3156502},
    {"id":2902, "id_estado":13, "cidade":"Rubim", "codigo":3156601},
    {"id":2903, "id_estado":13, "cidade":"Sabar\u00e1", "codigo":3156700},
    {"id":2904, "id_estado":13, "cidade":"Sabin\u00f3polis", "codigo":3156809},
    {"id":2905, "id_estado":13, "cidade":"Sacramento", "codigo":3156908},
    {"id":2906, "id_estado":13, "cidade":"Salinas", "codigo":3157005},
    {"id":2907, "id_estado":13, "cidade":"Salto da Divisa", "codigo":3157104},
    {"id":2908, "id_estado":13, "cidade":"Santa B\u00e1rbara", "codigo":3157203},
    {"id":2909, "id_estado":13, "cidade":"Santa B\u00e1rbara do Leste", "codigo":3157252},
    {"id":2910, "id_estado":13, "cidade":"Santa B\u00e1rbara do Monte Verde", "codigo":3157278},
    {"id":2911, "id_estado":13, "cidade":"Santa B\u00e1rbara do Tug\u00fario", "codigo":3157302},
    {"id":2912, "id_estado":13, "cidade":"Santa Cruz de Minas", "codigo":3157336},
    {"id":2913, "id_estado":13, "cidade":"Santa Cruz de Salinas", "codigo":3157377},
    {"id":2914, "id_estado":13, "cidade":"Santa Cruz do Escalvado", "codigo":3157401},
    {"id":2915, "id_estado":13, "cidade":"Santa Efig\u00eania de Minas", "codigo":3157500},
    {"id":2916, "id_estado":13, "cidade":"Santa F\u00e9 de Minas", "codigo":3157609},
    {"id":2917, "id_estado":13, "cidade":"Santa Helena de Minas", "codigo":3157658},
    {"id":2918, "id_estado":13, "cidade":"Santa Juliana", "codigo":3157708},
    {"id":2919, "id_estado":13, "cidade":"Santa Luzia", "codigo":3157807},
    {"id":2920, "id_estado":13, "cidade":"Santa Margarida", "codigo":3157906},
    {"id":2921, "id_estado":13, "cidade":"Santa Maria de Itabira", "codigo":3158003},
    {"id":2922, "id_estado":13, "cidade":"Santa Maria do Salto", "codigo":3158102},
    {"id":2923, "id_estado":13, "cidade":"Santa Maria do Sua\u00e7u\u00ed", "codigo":3158201},
    {"id":2924, "id_estado":13, "cidade":"Santana da Vargem", "codigo":3158300},
    {"id":2925, "id_estado":13, "cidade":"Santana de Cataguases", "codigo":3158409},
    {"id":2926, "id_estado":13, "cidade":"Santana de Pirapama", "codigo":3158508},
    {"id":2927, "id_estado":13, "cidade":"Santana do Deserto", "codigo":3158607},
    {"id":2928, "id_estado":13, "cidade":"Santana do Garamb\u00e9u", "codigo":3158706},
    {"id":2929, "id_estado":13, "cidade":"Santana do Jacar\u00e9", "codigo":3158805},
    {"id":2930, "id_estado":13, "cidade":"Santana do Manhua\u00e7u", "codigo":3158904},
    {"id":2931, "id_estado":13, "cidade":"Santana do Para\u00edso", "codigo":3158953},
    {"id":2932, "id_estado":13, "cidade":"Santana do Riacho", "codigo":3159001},
    {"id":2933, "id_estado":13, "cidade":"Santana dos Montes", "codigo":3159100},
    {"id":2934, "id_estado":13, "cidade":"Santa Rita de Caldas", "codigo":3159209},
    {"id":2935, "id_estado":13, "cidade":"Santa Rita de Jacutinga", "codigo":3159308},
    {"id":2936, "id_estado":13, "cidade":"Santa Rita de Minas", "codigo":3159357},
    {"id":2937, "id_estado":13, "cidade":"Santa Rita de Ibitipoca", "codigo":3159407},
    {"id":2938, "id_estado":13, "cidade":"Santa Rita do Itueto", "codigo":3159506},
    {"id":2939, "id_estado":13, "cidade":"Santa Rita do Sapuca\u00ed", "codigo":3159605},
    {"id":2940, "id_estado":13, "cidade":"Santa Rosa da Serra", "codigo":3159704},
    {"id":2941, "id_estado":13, "cidade":"Santa Vit\u00f3ria", "codigo":3159803},
    {"id":2942, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Amparo", "codigo":3159902},
    {"id":2943, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Aventureiro", "codigo":3160009},
    {"id":2944, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Grama", "codigo":3160108},
    {"id":2945, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Itamb\u00e9", "codigo":3160207},
    {"id":2946, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Jacinto", "codigo":3160306},
    {"id":2947, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Monte", "codigo":3160405},
    {"id":2948, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Retiro", "codigo":3160454},
    {"id":2949, "id_estado":13, "cidade":"Santo Ant\u00f4nio do Rio Abaixo", "codigo":3160504},
    {"id":2950, "id_estado":13, "cidade":"Santo Hip\u00f3lito", "codigo":3160603},
    {"id":2951, "id_estado":13, "cidade":"Santos Dumont", "codigo":3160702},
    {"id":2952, "id_estado":13, "cidade":"S\u00e3o Bento Abade", "codigo":3160801},
    {"id":2953, "id_estado":13, "cidade":"S\u00e3o Br\u00e1s do Sua\u00e7u\u00ed", "codigo":3160900},
    {"id":2954, "id_estado":13, "cidade":"S\u00e3o Domingos das Dores", "codigo":3160959},
    {"id":2955, "id_estado":13, "cidade":"S\u00e3o Domingos do Prata", "codigo":3161007},
    {"id":2956, "id_estado":13, "cidade":"S\u00e3o F\u00e9lix de Minas", "codigo":3161056},
    {"id":2957, "id_estado":13, "cidade":"S\u00e3o Francisco", "codigo":3161106},
    {"id":2958, "id_estado":13, "cidade":"S\u00e3o Francisco de Paula", "codigo":3161205},
    {"id":2959, "id_estado":13, "cidade":"S\u00e3o Francisco de Sales", "codigo":3161304},
    {"id":2960, "id_estado":13, "cidade":"S\u00e3o Francisco do Gl\u00f3ria", "codigo":3161403},
    {"id":2961, "id_estado":13, "cidade":"S\u00e3o Geraldo", "codigo":3161502},
    {"id":2962, "id_estado":13, "cidade":"S\u00e3o Geraldo da Piedade", "codigo":3161601},
    {"id":2963, "id_estado":13, "cidade":"S\u00e3o Geraldo do Baixio", "codigo":3161650},
    {"id":2964, "id_estado":13, "cidade":"S\u00e3o Gon\u00e7alo do Abaet\u00e9", "codigo":3161700},
    {"id":2965, "id_estado":13, "cidade":"S\u00e3o Gon\u00e7alo do Par\u00e1", "codigo":3161809},
    {"id":2966, "id_estado":13, "cidade":"S\u00e3o Gon\u00e7alo do Rio Abaixo", "codigo":3161908},
    {"id":2967, "id_estado":13, "cidade":"S\u00e3o Gon\u00e7alo do Sapuca\u00ed", "codigo":3162005},
    {"id":2968, "id_estado":13, "cidade":"S\u00e3o Gotardo", "codigo":3162104},
    {"id":2969, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o Batista do Gl\u00f3ria", "codigo":3162203},
    {"id":2970, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o da Lagoa", "codigo":3162252},
    {"id":2971, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o da Mata", "codigo":3162302},
    {"id":2972, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o da Ponte", "codigo":3162401},
    {"id":2973, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o das Miss\u00f5es", "codigo":3162450},
    {"id":2974, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o del Rei", "codigo":3162500},
    {"id":2975, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o do Manhua\u00e7u", "codigo":3162559},
    {"id":2976, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o do Manteninha", "codigo":3162575},
    {"id":2977, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o do Oriente", "codigo":3162609},
    {"id":2978, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o do Pacu\u00ed", "codigo":3162658},
    {"id":2979, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o do Para\u00edso", "codigo":3162708},
    {"id":2980, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o Evangelista", "codigo":3162807},
    {"id":2981, "id_estado":13, "cidade":"S\u00e3o Jo\u00e3o Nepomuceno", "codigo":3162906},
    {"id":2982, "id_estado":13, "cidade":"S\u00e3o Joaquim de Bicas", "codigo":3162922},
    {"id":2983, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 da Barra", "codigo":3162948},
    {"id":2984, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 da Lapa", "codigo":3162955},
    {"id":2985, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 da Safira", "codigo":3163003},
    {"id":2986, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 da Varginha", "codigo":3163102},
    {"id":2987, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 do Alegre", "codigo":3163201},
    {"id":2988, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 do Divino", "codigo":3163300},
    {"id":2989, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 do Goiabal", "codigo":3163409},
    {"id":2990, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 do Jacuri", "codigo":3163508},
    {"id":2991, "id_estado":13, "cidade":"S\u00e3o Jos\u00e9 do Mantimento", "codigo":3163607},
    {"id":2992, "id_estado":13, "cidade":"S\u00e3o Louren\u00e7o", "codigo":3163706},
    {"id":2993, "id_estado":13, "cidade":"S\u00e3o Miguel do Anta", "codigo":3163805},
    {"id":2994, "id_estado":13, "cidade":"S\u00e3o Pedro da Uni\u00e3o", "codigo":3163904},
    {"id":2995, "id_estado":13, "cidade":"S\u00e3o Pedro dos Ferros", "codigo":3164001},
    {"id":2996, "id_estado":13, "cidade":"S\u00e3o Pedro do Sua\u00e7u\u00ed", "codigo":3164100},
    {"id":2997, "id_estado":13, "cidade":"S\u00e3o Rom\u00e3o", "codigo":3164209},
    {"id":2998, "id_estado":13, "cidade":"S\u00e3o Roque de Minas", "codigo":3164308},
    {"id":2999, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o da Bela Vista", "codigo":3164407},
    {"id":3000, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o da Vargem Alegre", "codigo":3164431},
    {"id":3001, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o do Anta", "codigo":3164472},
    {"id":3002, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o do Maranh\u00e3o", "codigo":3164506},
    {"id":3003, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o do Oeste", "codigo":3164605},
    {"id":3004, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o do Para\u00edso", "codigo":3164704},
    {"id":3005, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o do Rio Preto", "codigo":3164803},
    {"id":3006, "id_estado":13, "cidade":"S\u00e3o Sebasti\u00e3o do Rio Verde", "codigo":3164902},
    {"id":3007, "id_estado":13, "cidade":"S\u00e3o Tiago", "codigo":3165008},
    {"id":3008, "id_estado":13, "cidade":"S\u00e3o Tom\u00e1s de Aquino", "codigo":3165107},
    {"id":3009, "id_estado":13, "cidade":"S\u00e3o Thom\u00e9 das Letras", "codigo":3165206},
    {"id":3010, "id_estado":13, "cidade":"S\u00e3o Vicente de Minas", "codigo":3165305},
    {"id":3011, "id_estado":13, "cidade":"Sapuca\u00ed-Mirim", "codigo":3165404},
    {"id":3012, "id_estado":13, "cidade":"Sardo\u00e1", "codigo":3165503},
    {"id":3013, "id_estado":13, "cidade":"Sarzedo", "codigo":3165537},
    {"id":3014, "id_estado":13, "cidade":"Setubinha", "codigo":3165552},
    {"id":3015, "id_estado":13, "cidade":"Sem-Peixe", "codigo":3165560},
    {"id":3016, "id_estado":13, "cidade":"Senador Amaral", "codigo":3165578},
    {"id":3017, "id_estado":13, "cidade":"Senador Cortes", "codigo":3165602},
    {"id":3018, "id_estado":13, "cidade":"Senador Firmino", "codigo":3165701},
    {"id":3019, "id_estado":13, "cidade":"Senador Jos\u00e9 Bento", "codigo":3165800},
    {"id":3020, "id_estado":13, "cidade":"Senador Modestino Gon\u00e7alves", "codigo":3165909},
    {"id":3021, "id_estado":13, "cidade":"Senhora de Oliveira", "codigo":3166006},
    {"id":3022, "id_estado":13, "cidade":"Senhora do Porto", "codigo":3166105},
    {"id":3023, "id_estado":13, "cidade":"Senhora dos Rem\u00e9dios", "codigo":3166204},
    {"id":3024, "id_estado":13, "cidade":"Sericita", "codigo":3166303},
    {"id":3025, "id_estado":13, "cidade":"Seritinga", "codigo":3166402},
    {"id":3026, "id_estado":13, "cidade":"Serra Azul de Minas", "codigo":3166501},
    {"id":3027, "id_estado":13, "cidade":"Serra da Saudade", "codigo":3166600},
    {"id":3028, "id_estado":13, "cidade":"Serra dos Aimor\u00e9s", "codigo":3166709},
    {"id":3029, "id_estado":13, "cidade":"Serra do Salitre", "codigo":3166808},
    {"id":3030, "id_estado":13, "cidade":"Serrania", "codigo":3166907},
    {"id":3031, "id_estado":13, "cidade":"Serran\u00f3polis de Minas", "codigo":3166956},
    {"id":3032, "id_estado":13, "cidade":"Serranos", "codigo":3167004},
    {"id":3033, "id_estado":13, "cidade":"Serro", "codigo":3167103},
    {"id":3034, "id_estado":13, "cidade":"Sete Lagoas", "codigo":3167202},
    {"id":3035, "id_estado":13, "cidade":"Silveir\u00e2nia", "codigo":3167301},
    {"id":3036, "id_estado":13, "cidade":"Silvian\u00f3polis", "codigo":3167400},
    {"id":3037, "id_estado":13, "cidade":"Sim\u00e3o Pereira", "codigo":3167509},
    {"id":3038, "id_estado":13, "cidade":"Simon\u00e9sia", "codigo":3167608},
    {"id":3039, "id_estado":13, "cidade":"Sobr\u00e1lia", "codigo":3167707},
    {"id":3040, "id_estado":13, "cidade":"Soledade de Minas", "codigo":3167806},
    {"id":3041, "id_estado":13, "cidade":"Tabuleiro", "codigo":3167905},
    {"id":3042, "id_estado":13, "cidade":"Taiobeiras", "codigo":3168002},
    {"id":3043, "id_estado":13, "cidade":"Taparuba", "codigo":3168051},
    {"id":3044, "id_estado":13, "cidade":"Tapira", "codigo":3168101},
    {"id":3045, "id_estado":13, "cidade":"Tapira\u00ed", "codigo":3168200},
    {"id":3046, "id_estado":13, "cidade":"Taquara\u00e7u de Minas", "codigo":3168309},
    {"id":3047, "id_estado":13, "cidade":"Tarumirim", "codigo":3168408},
    {"id":3048, "id_estado":13, "cidade":"Teixeiras", "codigo":3168507},
    {"id":3049, "id_estado":13, "cidade":"Te\u00f3filo Otoni", "codigo":3168606},
    {"id":3050, "id_estado":13, "cidade":"Tim\u00f3teo", "codigo":3168705},
    {"id":3051, "id_estado":13, "cidade":"Tiradentes", "codigo":3168804},
    {"id":3052, "id_estado":13, "cidade":"Tiros", "codigo":3168903},
    {"id":3053, "id_estado":13, "cidade":"Tocantins", "codigo":3169000},
    {"id":3054, "id_estado":13, "cidade":"Tocos do Moji", "codigo":3169059},
    {"id":3055, "id_estado":13, "cidade":"Toledo", "codigo":3169109},
    {"id":3056, "id_estado":13, "cidade":"Tombos", "codigo":3169208},
    {"id":3057, "id_estado":13, "cidade":"Tr\u00eas Cora\u00e7\u00f5es", "codigo":3169307},
    {"id":3058, "id_estado":13, "cidade":"Tr\u00eas Marias", "codigo":3169356},
    {"id":3059, "id_estado":13, "cidade":"Tr\u00eas Pontas", "codigo":3169406},
    {"id":3060, "id_estado":13, "cidade":"Tumiritinga", "codigo":3169505},
    {"id":3061, "id_estado":13, "cidade":"Tupaciguara", "codigo":3169604},
    {"id":3062, "id_estado":13, "cidade":"Turmalina", "codigo":3169703},
    {"id":3063, "id_estado":13, "cidade":"Turvol\u00e2ndia", "codigo":3169802},
    {"id":3064, "id_estado":13, "cidade":"Ub\u00e1", "codigo":3169901},
    {"id":3065, "id_estado":13, "cidade":"Uba\u00ed", "codigo":3170008},
    {"id":3066, "id_estado":13, "cidade":"Ubaporanga", "codigo":3170057},
    {"id":3067, "id_estado":13, "cidade":"Uberaba", "codigo":3170107},
    {"id":3068, "id_estado":13, "cidade":"Uberl\u00e2ndia", "codigo":3170206},
    {"id":3069, "id_estado":13, "cidade":"Umburatiba", "codigo":3170305},
    {"id":3070, "id_estado":13, "cidade":"Una\u00ed", "codigo":3170404},
    {"id":3071, "id_estado":13, "cidade":"Uni\u00e3o de Minas", "codigo":3170438},
    {"id":3072, "id_estado":13, "cidade":"Uruana de Minas", "codigo":3170479},
    {"id":3073, "id_estado":13, "cidade":"Uruc\u00e2nia", "codigo":3170503},
    {"id":3074, "id_estado":13, "cidade":"Urucuia", "codigo":3170529},
    {"id":3075, "id_estado":13, "cidade":"Vargem Alegre", "codigo":3170578},
    {"id":3076, "id_estado":13, "cidade":"Vargem Bonita", "codigo":3170602},
    {"id":3077, "id_estado":13, "cidade":"Vargem Grande do Rio Pardo", "codigo":3170651},
    {"id":3078, "id_estado":13, "cidade":"Varginha", "codigo":3170701},
    {"id":3079, "id_estado":13, "cidade":"Varj\u00e3o de Minas", "codigo":3170750},
    {"id":3080, "id_estado":13, "cidade":"V\u00e1rzea da Palma", "codigo":3170800},
    {"id":3081, "id_estado":13, "cidade":"Varzel\u00e2ndia", "codigo":3170909},
    {"id":3082, "id_estado":13, "cidade":"Vazante", "codigo":3171006},
    {"id":3083, "id_estado":13, "cidade":"Verdel\u00e2ndia", "codigo":3171030},
    {"id":3084, "id_estado":13, "cidade":"Veredinha", "codigo":3171071},
    {"id":3085, "id_estado":13, "cidade":"Ver\u00edssimo", "codigo":3171105},
    {"id":3086, "id_estado":13, "cidade":"Vermelho Novo", "codigo":3171154},
    {"id":3087, "id_estado":13, "cidade":"Vespasiano", "codigo":3171204},
    {"id":3088, "id_estado":13, "cidade":"Vi\u00e7osa", "codigo":3171303},
    {"id":3089, "id_estado":13, "cidade":"Vieiras", "codigo":3171402},
    {"id":3090, "id_estado":13, "cidade":"Mathias Lobato", "codigo":3171501},
    {"id":3091, "id_estado":13, "cidade":"Virgem da Lapa", "codigo":3171600},
    {"id":3092, "id_estado":13, "cidade":"Virg\u00ednia", "codigo":3171709},
    {"id":3093, "id_estado":13, "cidade":"Virgin\u00f3polis", "codigo":3171808},
    {"id":3094, "id_estado":13, "cidade":"Virgol\u00e2ndia", "codigo":3171907},
    {"id":3095, "id_estado":13, "cidade":"Visconde do Rio Branco", "codigo":3172004},
    {"id":3096, "id_estado":13, "cidade":"Volta Grande", "codigo":3172103},
    {"id":3097, "id_estado":13, "cidade":"Wenceslau Braz", "codigo":3172202},
    {"id":3098, "id_estado":8, "cidade":"Afonso Cl\u00e1udio", "codigo":3200102},
    {"id":3099, "id_estado":8, "cidade":"\u00c1guia Branca", "codigo":3200136},
    {"id":3100, "id_estado":8, "cidade":"\u00c1gua Doce do Norte", "codigo":3200169},
    {"id":3101, "id_estado":8, "cidade":"Alegre", "codigo":3200201},
    {"id":3102, "id_estado":8, "cidade":"Alfredo Chaves", "codigo":3200300},
    {"id":3103, "id_estado":8, "cidade":"Alto Rio Novo", "codigo":3200359},
    {"id":3104, "id_estado":8, "cidade":"Anchieta", "codigo":3200409},
    {"id":3105, "id_estado":8, "cidade":"Apiac\u00e1", "codigo":3200508},
    {"id":3106, "id_estado":8, "cidade":"Aracruz", "codigo":3200607},
    {"id":3107, "id_estado":8, "cidade":"Atilio Vivacqua", "codigo":3200706},
    {"id":3108, "id_estado":8, "cidade":"Baixo Guandu", "codigo":3200805},
    {"id":3109, "id_estado":8, "cidade":"Barra de S\u00e3o Francisco", "codigo":3200904},
    {"id":3110, "id_estado":8, "cidade":"Boa Esperan\u00e7a", "codigo":3201001},
    {"id":3111, "id_estado":8, "cidade":"Bom Jesus do Norte", "codigo":3201100},
    {"id":3112, "id_estado":8, "cidade":"Brejetuba", "codigo":3201159},
    {"id":3113, "id_estado":8, "cidade":"Cachoeiro de Itapemirim", "codigo":3201209},
    {"id":3114, "id_estado":8, "cidade":"Cariacica", "codigo":3201308},
    {"id":3115, "id_estado":8, "cidade":"Castelo", "codigo":3201407},
    {"id":3116, "id_estado":8, "cidade":"Colatina", "codigo":3201506},
    {"id":3117, "id_estado":8, "cidade":"Concei\u00e7\u00e3o da Barra", "codigo":3201605},
    {"id":3118, "id_estado":8, "cidade":"Concei\u00e7\u00e3o do Castelo", "codigo":3201704},
    {"id":3119, "id_estado":8, "cidade":"Divino de S\u00e3o Louren\u00e7o", "codigo":3201803},
    {"id":3120, "id_estado":8, "cidade":"Domingos Martins", "codigo":3201902},
    {"id":3121, "id_estado":8, "cidade":"Dores do Rio Preto", "codigo":3202009},
    {"id":3122, "id_estado":8, "cidade":"Ecoporanga", "codigo":3202108},
    {"id":3123, "id_estado":8, "cidade":"Fund\u00e3o", "codigo":3202207},
    {"id":3124, "id_estado":8, "cidade":"Governador Lindenberg", "codigo":3202256},
    {"id":3125, "id_estado":8, "cidade":"Gua\u00e7u\u00ed", "codigo":3202306},
    {"id":3126, "id_estado":8, "cidade":"Guarapari", "codigo":3202405},
    {"id":3127, "id_estado":8, "cidade":"Ibatiba", "codigo":3202454},
    {"id":3128, "id_estado":8, "cidade":"Ibira\u00e7u", "codigo":3202504},
    {"id":3129, "id_estado":8, "cidade":"Ibitirama", "codigo":3202553},
    {"id":3130, "id_estado":8, "cidade":"Iconha", "codigo":3202603},
    {"id":3131, "id_estado":8, "cidade":"Irupi", "codigo":3202652},
    {"id":3132, "id_estado":8, "cidade":"Itagua\u00e7u", "codigo":3202702},
    {"id":3133, "id_estado":8, "cidade":"Itapemirim", "codigo":3202801},
    {"id":3134, "id_estado":8, "cidade":"Itarana", "codigo":3202900},
    {"id":3135, "id_estado":8, "cidade":"I\u00fana", "codigo":3203007},
    {"id":3136, "id_estado":8, "cidade":"Jaguar\u00e9", "codigo":3203056},
    {"id":3137, "id_estado":8, "cidade":"Jer\u00f4nimo Monteiro", "codigo":3203106},
    {"id":3138, "id_estado":8, "cidade":"Jo\u00e3o Neiva", "codigo":3203130},
    {"id":3139, "id_estado":8, "cidade":"Laranja da Terra", "codigo":3203163},
    {"id":3140, "id_estado":8, "cidade":"Linhares", "codigo":3203205},
    {"id":3141, "id_estado":8, "cidade":"Manten\u00f3polis", "codigo":3203304},
    {"id":3142, "id_estado":8, "cidade":"Marata\u00edzes", "codigo":3203320},
    {"id":3143, "id_estado":8, "cidade":"Marechal Floriano", "codigo":3203346},
    {"id":3144, "id_estado":8, "cidade":"Maril\u00e2ndia", "codigo":3203353},
    {"id":3145, "id_estado":8, "cidade":"Mimoso do Sul", "codigo":3203403},
    {"id":3146, "id_estado":8, "cidade":"Montanha", "codigo":3203502},
    {"id":3147, "id_estado":8, "cidade":"Mucurici", "codigo":3203601},
    {"id":3148, "id_estado":8, "cidade":"Muniz Freire", "codigo":3203700},
    {"id":3149, "id_estado":8, "cidade":"Muqui", "codigo":3203809},
    {"id":3150, "id_estado":8, "cidade":"Nova Ven\u00e9cia", "codigo":3203908},
    {"id":3151, "id_estado":8, "cidade":"Pancas", "codigo":3204005},
    {"id":3152, "id_estado":8, "cidade":"Pedro Can\u00e1rio", "codigo":3204054},
    {"id":3153, "id_estado":8, "cidade":"Pinheiros", "codigo":3204104},
    {"id":3154, "id_estado":8, "cidade":"Pi\u00fama", "codigo":3204203},
    {"id":3155, "id_estado":8, "cidade":"Ponto Belo", "codigo":3204252},
    {"id":3156, "id_estado":8, "cidade":"Presidente Kennedy", "codigo":3204302},
    {"id":3157, "id_estado":8, "cidade":"Rio Bananal", "codigo":3204351},
    {"id":3158, "id_estado":8, "cidade":"Rio Novo do Sul", "codigo":3204401},
    {"id":3159, "id_estado":8, "cidade":"Santa Leopoldina", "codigo":3204500},
    {"id":3160, "id_estado":8, "cidade":"Santa Maria de Jetib\u00e1", "codigo":3204559},
    {"id":3161, "id_estado":8, "cidade":"Santa Teresa", "codigo":3204609},
    {"id":3162, "id_estado":8, "cidade":"S\u00e3o Domingos do Norte", "codigo":3204658},
    {"id":3163, "id_estado":8, "cidade":"S\u00e3o Gabriel da Palha", "codigo":3204708},
    {"id":3164, "id_estado":8, "cidade":"S\u00e3o Jos\u00e9 do Cal\u00e7ado", "codigo":3204807},
    {"id":3165, "id_estado":8, "cidade":"S\u00e3o Mateus", "codigo":3204906},
    {"id":3166, "id_estado":8, "cidade":"S\u00e3o Roque do Cana\u00e3", "codigo":3204955},
    {"id":3167, "id_estado":8, "cidade":"Serra", "codigo":3205002},
    {"id":3168, "id_estado":8, "cidade":"Sooretama", "codigo":3205010},
    {"id":3169, "id_estado":8, "cidade":"Vargem Alta", "codigo":3205036},
    {"id":3170, "id_estado":8, "cidade":"Venda Nova do Imigrante", "codigo":3205069},
    {"id":3171, "id_estado":8, "cidade":"Viana", "codigo":3205101},
    {"id":3172, "id_estado":8, "cidade":"Vila Pav\u00e3o", "codigo":3205150},
    {"id":3173, "id_estado":8, "cidade":"Vila Val\u00e9rio", "codigo":3205176},
    {"id":3174, "id_estado":8, "cidade":"Vila Velha", "codigo":3205200},
    {"id":3175, "id_estado":8, "cidade":"Vit\u00f3ria", "codigo":3205309},
    {"id":3176, "id_estado":19, "cidade":"Angra dos Reis", "codigo":3300100},
    {"id":3177, "id_estado":19, "cidade":"Aperib\u00e9", "codigo":3300159},
    {"id":3178, "id_estado":19, "cidade":"Araruama", "codigo":3300209},
    {"id":3179, "id_estado":19, "cidade":"Areal", "codigo":3300225},
    {"id":3180, "id_estado":19, "cidade":"Arma\u00e7\u00e3o dos B\u00fazios", "codigo":3300233},
    {"id":3181, "id_estado":19, "cidade":"Arraial do Cabo", "codigo":3300258},
    {"id":3182, "id_estado":19, "cidade":"Barra do Pira\u00ed", "codigo":3300308},
    {"id":3183, "id_estado":19, "cidade":"Barra Mansa", "codigo":3300407},
    {"id":3184, "id_estado":19, "cidade":"Belford Roxo", "codigo":3300456},
    {"id":3185, "id_estado":19, "cidade":"Bom Jardim", "codigo":3300506},
    {"id":3186, "id_estado":19, "cidade":"Bom Jesus do Itabapoana", "codigo":3300605},
    {"id":3187, "id_estado":19, "cidade":"Cabo Frio", "codigo":3300704},
    {"id":3188, "id_estado":19, "cidade":"Cachoeiras de Macacu", "codigo":3300803},
    {"id":3189, "id_estado":19, "cidade":"Cambuci", "codigo":3300902},
    {"id":3190, "id_estado":19, "cidade":"Carapebus", "codigo":3300936},
    {"id":3191, "id_estado":19, "cidade":"Comendador Levy Gasparian", "codigo":3300951},
    {"id":3192, "id_estado":19, "cidade":"Campos dos Goytacazes", "codigo":3301009},
    {"id":3193, "id_estado":19, "cidade":"Cantagalo", "codigo":3301108},
    {"id":3194, "id_estado":19, "cidade":"Cardoso Moreira", "codigo":3301157},
    {"id":3195, "id_estado":19, "cidade":"Carmo", "codigo":3301207},
    {"id":3196, "id_estado":19, "cidade":"Casimiro de Abreu", "codigo":3301306},
    {"id":3197, "id_estado":19, "cidade":"Concei\u00e7\u00e3o de Macabu", "codigo":3301405},
    {"id":3198, "id_estado":19, "cidade":"Cordeiro", "codigo":3301504},
    {"id":3199, "id_estado":19, "cidade":"Duas Barras", "codigo":3301603},
    {"id":3200, "id_estado":19, "cidade":"Duque de Caxias", "codigo":3301702},
    {"id":3201, "id_estado":19, "cidade":"Engenheiro Paulo de Frontin", "codigo":3301801},
    {"id":3202, "id_estado":19, "cidade":"Guapimirim", "codigo":3301850},
    {"id":3203, "id_estado":19, "cidade":"Iguaba Grande", "codigo":3301876},
    {"id":3204, "id_estado":19, "cidade":"Itabora\u00ed", "codigo":3301900},
    {"id":3205, "id_estado":19, "cidade":"Itagua\u00ed", "codigo":3302007},
    {"id":3206, "id_estado":19, "cidade":"Italva", "codigo":3302056},
    {"id":3207, "id_estado":19, "cidade":"Itaocara", "codigo":3302106},
    {"id":3208, "id_estado":19, "cidade":"Itaperuna", "codigo":3302205},
    {"id":3209, "id_estado":19, "cidade":"Itatiaia", "codigo":3302254},
    {"id":3210, "id_estado":19, "cidade":"Japeri", "codigo":3302270},
    {"id":3211, "id_estado":19, "cidade":"Laje do Muria\u00e9", "codigo":3302304},
    {"id":3212, "id_estado":19, "cidade":"Maca\u00e9", "codigo":3302403},
    {"id":3213, "id_estado":19, "cidade":"Macuco", "codigo":3302452},
    {"id":3214, "id_estado":19, "cidade":"Mag\u00e9", "codigo":3302502},
    {"id":3215, "id_estado":19, "cidade":"Mangaratiba", "codigo":3302601},
    {"id":3216, "id_estado":19, "cidade":"Maric\u00e1", "codigo":3302700},
    {"id":3217, "id_estado":19, "cidade":"Mendes", "codigo":3302809},
    {"id":3218, "id_estado":19, "cidade":"Mesquita", "codigo":3302858},
    {"id":3219, "id_estado":19, "cidade":"Miguel Pereira", "codigo":3302908},
    {"id":3220, "id_estado":19, "cidade":"Miracema", "codigo":3303005},
    {"id":3221, "id_estado":19, "cidade":"Natividade", "codigo":3303104},
    {"id":3222, "id_estado":19, "cidade":"Nil\u00f3polis", "codigo":3303203},
    {"id":3223, "id_estado":19, "cidade":"Niter\u00f3i", "codigo":3303302},
    {"id":3224, "id_estado":19, "cidade":"Nova Friburgo", "codigo":3303401},
    {"id":3225, "id_estado":19, "cidade":"Nova Igua\u00e7u", "codigo":3303500},
    {"id":3226, "id_estado":19, "cidade":"Paracambi", "codigo":3303609},
    {"id":3227, "id_estado":19, "cidade":"Para\u00edba do Sul", "codigo":3303708},
    {"id":3228, "id_estado":19, "cidade":"Paraty", "codigo":3303807},
    {"id":3229, "id_estado":19, "cidade":"Paty do Alferes", "codigo":3303856},
    {"id":3230, "id_estado":19, "cidade":"Petr\u00f3polis", "codigo":3303906},
    {"id":3231, "id_estado":19, "cidade":"Pinheiral", "codigo":3303955},
    {"id":3232, "id_estado":19, "cidade":"Pira\u00ed", "codigo":3304003},
    {"id":3233, "id_estado":19, "cidade":"Porci\u00fancula", "codigo":3304102},
    {"id":3234, "id_estado":19, "cidade":"Porto Real", "codigo":3304110},
    {"id":3235, "id_estado":19, "cidade":"Quatis", "codigo":3304128},
    {"id":3236, "id_estado":19, "cidade":"Queimados", "codigo":3304144},
    {"id":3237, "id_estado":19, "cidade":"Quissam\u00e3", "codigo":3304151},
    {"id":3238, "id_estado":19, "cidade":"Resende", "codigo":3304201},
    {"id":3239, "id_estado":19, "cidade":"Rio Bonito", "codigo":3304300},
    {"id":3240, "id_estado":19, "cidade":"Rio Claro", "codigo":3304409},
    {"id":3241, "id_estado":19, "cidade":"Rio das Flores", "codigo":3304508},
    {"id":3242, "id_estado":19, "cidade":"Rio das Ostras", "codigo":3304524},
    {"id":3243, "id_estado":19, "cidade":"Rio de Janeiro", "codigo":3304557},
    {"id":3244, "id_estado":19, "cidade":"Santa Maria Madalena", "codigo":3304607},
    {"id":3245, "id_estado":19, "cidade":"Santo Ant\u00f4nio de P\u00e1dua", "codigo":3304706},
    {"id":3246, "id_estado":19, "cidade":"S\u00e3o Francisco de Itabapoana", "codigo":3304755},
    {"id":3247, "id_estado":19, "cidade":"S\u00e3o Fid\u00e9lis", "codigo":3304805},
    {"id":3248, "id_estado":19, "cidade":"S\u00e3o Gon\u00e7alo", "codigo":3304904},
    {"id":3249, "id_estado":19, "cidade":"S\u00e3o Jo\u00e3o da Barra", "codigo":3305000},
    {"id":3250, "id_estado":19, "cidade":"S\u00e3o Jo\u00e3o de Meriti", "codigo":3305109},
    {"id":3251, "id_estado":19, "cidade":"S\u00e3o Jos\u00e9 de Ub\u00e1", "codigo":3305133},
    {"id":3252, "id_estado":19, "cidade":"S\u00e3o Jos\u00e9 do Vale do Rio Preto", "codigo":3305158},
    {"id":3253, "id_estado":19, "cidade":"S\u00e3o Pedro da Aldeia", "codigo":3305208},
    {"id":3254, "id_estado":19, "cidade":"S\u00e3o Sebasti\u00e3o do Alto", "codigo":3305307},
    {"id":3255, "id_estado":19, "cidade":"Sapucaia", "codigo":3305406},
    {"id":3256, "id_estado":19, "cidade":"Saquarema", "codigo":3305505},
    {"id":3257, "id_estado":19, "cidade":"Serop\u00e9dica", "codigo":3305554},
    {"id":3258, "id_estado":19, "cidade":"Silva Jardim", "codigo":3305604},
    {"id":3259, "id_estado":19, "cidade":"Sumidouro", "codigo":3305703},
    {"id":3260, "id_estado":19, "cidade":"Tangu\u00e1", "codigo":3305752},
    {"id":3261, "id_estado":19, "cidade":"Teres\u00f3polis", "codigo":3305802},
    {"id":3262, "id_estado":19, "cidade":"Trajano de Moraes", "codigo":3305901},
    {"id":3263, "id_estado":19, "cidade":"Tr\u00eas Rios", "codigo":3306008},
    {"id":3264, "id_estado":19, "cidade":"Valen\u00e7a", "codigo":3306107},
    {"id":3265, "id_estado":19, "cidade":"Varre-Sai", "codigo":3306156},
    {"id":3266, "id_estado":19, "cidade":"Vassouras", "codigo":3306206},
    {"id":3267, "id_estado":19, "cidade":"Volta Redonda", "codigo":3306305},
    {"id":3268, "id_estado":25, "cidade":"Adamantina", "codigo":3500105},
    {"id":3269, "id_estado":25, "cidade":"Adolfo", "codigo":3500204},
    {"id":3270, "id_estado":25, "cidade":"Agua\u00ed", "codigo":3500303},
    {"id":3271, "id_estado":25, "cidade":"\u00c1guas da Prata", "codigo":3500402},
    {"id":3272, "id_estado":25, "cidade":"\u00c1guas de Lind\u00f3ia", "codigo":3500501},
    {"id":3273, "id_estado":25, "cidade":"\u00c1guas de Santa B\u00e1rbara", "codigo":3500550},
    {"id":3274, "id_estado":25, "cidade":"\u00c1guas de S\u00e3o Pedro", "codigo":3500600},
    {"id":3275, "id_estado":25, "cidade":"Agudos", "codigo":3500709},
    {"id":3276, "id_estado":25, "cidade":"Alambari", "codigo":3500758},
    {"id":3277, "id_estado":25, "cidade":"Alfredo Marcondes", "codigo":3500808},
    {"id":3278, "id_estado":25, "cidade":"Altair", "codigo":3500907},
    {"id":3279, "id_estado":25, "cidade":"Altin\u00f3polis", "codigo":3501004},
    {"id":3280, "id_estado":25, "cidade":"Alto Alegre", "codigo":3501103},
    {"id":3281, "id_estado":25, "cidade":"Alum\u00ednio", "codigo":3501152},
    {"id":3282, "id_estado":25, "cidade":"\u00c1lvares Florence", "codigo":3501202},
    {"id":3283, "id_estado":25, "cidade":"\u00c1lvares Machado", "codigo":3501301},
    {"id":3284, "id_estado":25, "cidade":"\u00c1lvaro de Carvalho", "codigo":3501400},
    {"id":3285, "id_estado":25, "cidade":"Alvinl\u00e2ndia", "codigo":3501509},
    {"id":3286, "id_estado":25, "cidade":"Americana", "codigo":3501608},
    {"id":3287, "id_estado":25, "cidade":"Am\u00e9rico Brasiliense", "codigo":3501707},
    {"id":3288, "id_estado":25, "cidade":"Am\u00e9rico de Campos", "codigo":3501806},
    {"id":3289, "id_estado":25, "cidade":"Amparo", "codigo":3501905},
    {"id":3290, "id_estado":25, "cidade":"Anal\u00e2ndia", "codigo":3502002},
    {"id":3291, "id_estado":25, "cidade":"Andradina", "codigo":3502101},
    {"id":3292, "id_estado":25, "cidade":"Angatuba", "codigo":3502200},
    {"id":3293, "id_estado":25, "cidade":"Anhembi", "codigo":3502309},
    {"id":3294, "id_estado":25, "cidade":"Anhumas", "codigo":3502408},
    {"id":3295, "id_estado":25, "cidade":"Aparecida", "codigo":3502507},
    {"id":3296, "id_estado":25, "cidade":"Aparecida D'Oeste", "codigo":3502606},
    {"id":3297, "id_estado":25, "cidade":"Apia\u00ed", "codigo":3502705},
    {"id":3298, "id_estado":25, "cidade":"Ara\u00e7ariguama", "codigo":3502754},
    {"id":3299, "id_estado":25, "cidade":"Ara\u00e7atuba", "codigo":3502804},
    {"id":3300, "id_estado":25, "cidade":"Ara\u00e7oiaba da Serra", "codigo":3502903},
    {"id":3301, "id_estado":25, "cidade":"Aramina", "codigo":3503000},
    {"id":3302, "id_estado":25, "cidade":"Arandu", "codigo":3503109},
    {"id":3303, "id_estado":25, "cidade":"Arape\u00ed", "codigo":3503158},
    {"id":3304, "id_estado":25, "cidade":"Araraquara", "codigo":3503208},
    {"id":3305, "id_estado":25, "cidade":"Araras", "codigo":3503307},
    {"id":3306, "id_estado":25, "cidade":"Arco-\u00cdris", "codigo":3503356},
    {"id":3307, "id_estado":25, "cidade":"Arealva", "codigo":3503406},
    {"id":3308, "id_estado":25, "cidade":"Areias", "codigo":3503505},
    {"id":3309, "id_estado":25, "cidade":"Arei\u00f3polis", "codigo":3503604},
    {"id":3310, "id_estado":25, "cidade":"Ariranha", "codigo":3503703},
    {"id":3311, "id_estado":25, "cidade":"Artur Nogueira", "codigo":3503802},
    {"id":3312, "id_estado":25, "cidade":"Aruj\u00e1", "codigo":3503901},
    {"id":3313, "id_estado":25, "cidade":"Asp\u00e1sia", "codigo":3503950},
    {"id":3314, "id_estado":25, "cidade":"Assis", "codigo":3504008},
    {"id":3315, "id_estado":25, "cidade":"Atibaia", "codigo":3504107},
    {"id":3316, "id_estado":25, "cidade":"Auriflama", "codigo":3504206},
    {"id":3317, "id_estado":25, "cidade":"Ava\u00ed", "codigo":3504305},
    {"id":3318, "id_estado":25, "cidade":"Avanhandava", "codigo":3504404},
    {"id":3319, "id_estado":25, "cidade":"Avar\u00e9", "codigo":3504503},
    {"id":3320, "id_estado":25, "cidade":"Bady Bassitt", "codigo":3504602},
    {"id":3321, "id_estado":25, "cidade":"Balbinos", "codigo":3504701},
    {"id":3322, "id_estado":25, "cidade":"B\u00e1lsamo", "codigo":3504800},
    {"id":3323, "id_estado":25, "cidade":"Bananal", "codigo":3504909},
    {"id":3324, "id_estado":25, "cidade":"Bar\u00e3o de Antonina", "codigo":3505005},
    {"id":3325, "id_estado":25, "cidade":"Barbosa", "codigo":3505104},
    {"id":3326, "id_estado":25, "cidade":"Bariri", "codigo":3505203},
    {"id":3327, "id_estado":25, "cidade":"Barra Bonita", "codigo":3505302},
    {"id":3328, "id_estado":25, "cidade":"Barra do Chap\u00e9u", "codigo":3505351},
    {"id":3329, "id_estado":25, "cidade":"Barra do Turvo", "codigo":3505401},
    {"id":3330, "id_estado":25, "cidade":"Barretos", "codigo":3505500},
    {"id":3331, "id_estado":25, "cidade":"Barrinha", "codigo":3505609},
    {"id":3332, "id_estado":25, "cidade":"Barueri", "codigo":3505708},
    {"id":3333, "id_estado":25, "cidade":"Bastos", "codigo":3505807},
    {"id":3334, "id_estado":25, "cidade":"Batatais", "codigo":3505906},
    {"id":3335, "id_estado":25, "cidade":"Bauru", "codigo":3506003},
    {"id":3336, "id_estado":25, "cidade":"Bebedouro", "codigo":3506102},
    {"id":3337, "id_estado":25, "cidade":"Bento de Abreu", "codigo":3506201},
    {"id":3338, "id_estado":25, "cidade":"Bernardino de Campos", "codigo":3506300},
    {"id":3339, "id_estado":25, "cidade":"Bertioga", "codigo":3506359},
    {"id":3340, "id_estado":25, "cidade":"Bilac", "codigo":3506409},
    {"id":3341, "id_estado":25, "cidade":"Birigui", "codigo":3506508},
    {"id":3342, "id_estado":25, "cidade":"Biritiba-Mirim", "codigo":3506607},
    {"id":3343, "id_estado":25, "cidade":"Boa Esperan\u00e7a do Sul", "codigo":3506706},
    {"id":3344, "id_estado":25, "cidade":"Bocaina", "codigo":3506805},
    {"id":3345, "id_estado":25, "cidade":"Bofete", "codigo":3506904},
    {"id":3346, "id_estado":25, "cidade":"Boituva", "codigo":3507001},
    {"id":3347, "id_estado":25, "cidade":"Bom Jesus dos Perd\u00f5es", "codigo":3507100},
    {"id":3348, "id_estado":25, "cidade":"Bom Sucesso de Itarar\u00e9", "codigo":3507159},
    {"id":3349, "id_estado":25, "cidade":"Bor\u00e1", "codigo":3507209},
    {"id":3350, "id_estado":25, "cidade":"Borac\u00e9ia", "codigo":3507308},
    {"id":3351, "id_estado":25, "cidade":"Borborema", "codigo":3507407},
    {"id":3352, "id_estado":25, "cidade":"Borebi", "codigo":3507456},
    {"id":3353, "id_estado":25, "cidade":"Botucatu", "codigo":3507506},
    {"id":3354, "id_estado":25, "cidade":"Bragan\u00e7a Paulista", "codigo":3507605},
    {"id":3355, "id_estado":25, "cidade":"Bra\u00fana", "codigo":3507704},
    {"id":3356, "id_estado":25, "cidade":"Brejo Alegre", "codigo":3507753},
    {"id":3357, "id_estado":25, "cidade":"Brodowski", "codigo":3507803},
    {"id":3358, "id_estado":25, "cidade":"Brotas", "codigo":3507902},
    {"id":3359, "id_estado":25, "cidade":"Buri", "codigo":3508009},
    {"id":3360, "id_estado":25, "cidade":"Buritama", "codigo":3508108},
    {"id":3361, "id_estado":25, "cidade":"Buritizal", "codigo":3508207},
    {"id":3362, "id_estado":25, "cidade":"Cabr\u00e1lia Paulista", "codigo":3508306},
    {"id":3363, "id_estado":25, "cidade":"Cabre\u00fava", "codigo":3508405},
    {"id":3364, "id_estado":25, "cidade":"Ca\u00e7apava", "codigo":3508504},
    {"id":3365, "id_estado":25, "cidade":"Cachoeira Paulista", "codigo":3508603},
    {"id":3366, "id_estado":25, "cidade":"Caconde", "codigo":3508702},
    {"id":3367, "id_estado":25, "cidade":"Cafel\u00e2ndia", "codigo":3508801},
    {"id":3368, "id_estado":25, "cidade":"Caiabu", "codigo":3508900},
    {"id":3369, "id_estado":25, "cidade":"Caieiras", "codigo":3509007},
    {"id":3370, "id_estado":25, "cidade":"Caiu\u00e1", "codigo":3509106},
    {"id":3371, "id_estado":25, "cidade":"Cajamar", "codigo":3509205},
    {"id":3372, "id_estado":25, "cidade":"Cajati", "codigo":3509254},
    {"id":3373, "id_estado":25, "cidade":"Cajobi", "codigo":3509304},
    {"id":3374, "id_estado":25, "cidade":"Cajuru", "codigo":3509403},
    {"id":3375, "id_estado":25, "cidade":"Campina do Monte Alegre", "codigo":3509452},
    {"id":3376, "id_estado":25, "cidade":"Campinas", "codigo":3509502},
    {"id":3377, "id_estado":25, "cidade":"Campo Limpo Paulista", "codigo":3509601},
    {"id":3378, "id_estado":25, "cidade":"Campos do Jord\u00e3o", "codigo":3509700},
    {"id":3379, "id_estado":25, "cidade":"Campos Novos Paulista", "codigo":3509809},
    {"id":3380, "id_estado":25, "cidade":"Canan\u00e9ia", "codigo":3509908},
    {"id":3381, "id_estado":25, "cidade":"Canas", "codigo":3509957},
    {"id":3382, "id_estado":25, "cidade":"C\u00e2ndido Mota", "codigo":3510005},
    {"id":3383, "id_estado":25, "cidade":"C\u00e2ndido Rodrigues", "codigo":3510104},
    {"id":3384, "id_estado":25, "cidade":"Canitar", "codigo":3510153},
    {"id":3385, "id_estado":25, "cidade":"Cap\u00e3o Bonito", "codigo":3510203},
    {"id":3386, "id_estado":25, "cidade":"Capela do Alto", "codigo":3510302},
    {"id":3387, "id_estado":25, "cidade":"Capivari", "codigo":3510401},
    {"id":3388, "id_estado":25, "cidade":"Caraguatatuba", "codigo":3510500},
    {"id":3389, "id_estado":25, "cidade":"Carapicu\u00edba", "codigo":3510609},
    {"id":3390, "id_estado":25, "cidade":"Cardoso", "codigo":3510708},
    {"id":3391, "id_estado":25, "cidade":"Casa Branca", "codigo":3510807},
    {"id":3392, "id_estado":25, "cidade":"C\u00e1ssia dos Coqueiros", "codigo":3510906},
    {"id":3393, "id_estado":25, "cidade":"Castilho", "codigo":3511003},
    {"id":3394, "id_estado":25, "cidade":"Catanduva", "codigo":3511102},
    {"id":3395, "id_estado":25, "cidade":"Catigu\u00e1", "codigo":3511201},
    {"id":3396, "id_estado":25, "cidade":"Cedral", "codigo":3511300},
    {"id":3397, "id_estado":25, "cidade":"Cerqueira C\u00e9sar", "codigo":3511409},
    {"id":3398, "id_estado":25, "cidade":"Cerquilho", "codigo":3511508},
    {"id":3399, "id_estado":25, "cidade":"Ces\u00e1rio Lange", "codigo":3511607},
    {"id":3400, "id_estado":25, "cidade":"Charqueada", "codigo":3511706},
    {"id":3401, "id_estado":25, "cidade":"Clementina", "codigo":3511904},
    {"id":3402, "id_estado":25, "cidade":"Colina", "codigo":3512001},
    {"id":3403, "id_estado":25, "cidade":"Col\u00f4mbia", "codigo":3512100},
    {"id":3404, "id_estado":25, "cidade":"Conchal", "codigo":3512209},
    {"id":3405, "id_estado":25, "cidade":"Conchas", "codigo":3512308},
    {"id":3406, "id_estado":25, "cidade":"Cordeir\u00f3polis", "codigo":3512407},
    {"id":3407, "id_estado":25, "cidade":"Coroados", "codigo":3512506},
    {"id":3408, "id_estado":25, "cidade":"Coronel Macedo", "codigo":3512605},
    {"id":3409, "id_estado":25, "cidade":"Corumbata\u00ed", "codigo":3512704},
    {"id":3410, "id_estado":25, "cidade":"Cosm\u00f3polis", "codigo":3512803},
    {"id":3411, "id_estado":25, "cidade":"Cosmorama", "codigo":3512902},
    {"id":3412, "id_estado":25, "cidade":"Cotia", "codigo":3513009},
    {"id":3413, "id_estado":25, "cidade":"Cravinhos", "codigo":3513108},
    {"id":3414, "id_estado":25, "cidade":"Cristais Paulista", "codigo":3513207},
    {"id":3415, "id_estado":25, "cidade":"Cruz\u00e1lia", "codigo":3513306},
    {"id":3416, "id_estado":25, "cidade":"Cruzeiro", "codigo":3513405},
    {"id":3417, "id_estado":25, "cidade":"Cubat\u00e3o", "codigo":3513504},
    {"id":3418, "id_estado":25, "cidade":"Cunha", "codigo":3513603},
    {"id":3419, "id_estado":25, "cidade":"Descalvado", "codigo":3513702},
    {"id":3420, "id_estado":25, "cidade":"Diadema", "codigo":3513801},
    {"id":3421, "id_estado":25, "cidade":"Dirce Reis", "codigo":3513850},
    {"id":3422, "id_estado":25, "cidade":"Divinol\u00e2ndia", "codigo":3513900},
    {"id":3423, "id_estado":25, "cidade":"Dobrada", "codigo":3514007},
    {"id":3424, "id_estado":25, "cidade":"Dois C\u00f3rregos", "codigo":3514106},
    {"id":3425, "id_estado":25, "cidade":"Dolcin\u00f3polis", "codigo":3514205},
    {"id":3426, "id_estado":25, "cidade":"Dourado", "codigo":3514304},
    {"id":3427, "id_estado":25, "cidade":"Dracena", "codigo":3514403},
    {"id":3428, "id_estado":25, "cidade":"Duartina", "codigo":3514502},
    {"id":3429, "id_estado":25, "cidade":"Dumont", "codigo":3514601},
    {"id":3430, "id_estado":25, "cidade":"Echapor\u00e3", "codigo":3514700},
    {"id":3431, "id_estado":25, "cidade":"Eldorado", "codigo":3514809},
    {"id":3432, "id_estado":25, "cidade":"Elias Fausto", "codigo":3514908},
    {"id":3433, "id_estado":25, "cidade":"Elisi\u00e1rio", "codigo":3514924},
    {"id":3434, "id_estado":25, "cidade":"Emba\u00faba", "codigo":3514957},
    {"id":3435, "id_estado":25, "cidade":"Embu das Artes", "codigo":3515004},
    {"id":3436, "id_estado":25, "cidade":"Embu-Gua\u00e7u", "codigo":3515103},
    {"id":3437, "id_estado":25, "cidade":"Emilian\u00f3polis", "codigo":3515129},
    {"id":3438, "id_estado":25, "cidade":"Engenheiro Coelho", "codigo":3515152},
    {"id":3439, "id_estado":25, "cidade":"Esp\u00edrito Santo do Pinhal", "codigo":3515186},
    {"id":3440, "id_estado":25, "cidade":"Esp\u00edrito Santo do Turvo", "codigo":3515194},
    {"id":3441, "id_estado":25, "cidade":"Estrela D'Oeste", "codigo":3515202},
    {"id":3442, "id_estado":25, "cidade":"Estrela do Norte", "codigo":3515301},
    {"id":3443, "id_estado":25, "cidade":"Euclides da Cunha Paulista", "codigo":3515350},
    {"id":3444, "id_estado":25, "cidade":"Fartura", "codigo":3515400},
    {"id":3445, "id_estado":25, "cidade":"Fernand\u00f3polis", "codigo":3515509},
    {"id":3446, "id_estado":25, "cidade":"Fernando Prestes", "codigo":3515608},
    {"id":3447, "id_estado":25, "cidade":"Fern\u00e3o", "codigo":3515657},
    {"id":3448, "id_estado":25, "cidade":"Ferraz de Vasconcelos", "codigo":3515707},
    {"id":3449, "id_estado":25, "cidade":"Flora Rica", "codigo":3515806},
    {"id":3450, "id_estado":25, "cidade":"Floreal", "codigo":3515905},
    {"id":3451, "id_estado":25, "cidade":"Fl\u00f3rida Paulista", "codigo":3516002},
    {"id":3452, "id_estado":25, "cidade":"Flor\u00ednia", "codigo":3516101},
    {"id":3453, "id_estado":25, "cidade":"Franca", "codigo":3516200},
    {"id":3454, "id_estado":25, "cidade":"Francisco Morato", "codigo":3516309},
    {"id":3455, "id_estado":25, "cidade":"Franco da Rocha", "codigo":3516408},
    {"id":3456, "id_estado":25, "cidade":"Gabriel Monteiro", "codigo":3516507},
    {"id":3457, "id_estado":25, "cidade":"G\u00e1lia", "codigo":3516606},
    {"id":3458, "id_estado":25, "cidade":"Gar\u00e7a", "codigo":3516705},
    {"id":3459, "id_estado":25, "cidade":"Gast\u00e3o Vidigal", "codigo":3516804},
    {"id":3460, "id_estado":25, "cidade":"Gavi\u00e3o Peixoto", "codigo":3516853},
    {"id":3461, "id_estado":25, "cidade":"General Salgado", "codigo":3516903},
    {"id":3462, "id_estado":25, "cidade":"Getulina", "codigo":3517000},
    {"id":3463, "id_estado":25, "cidade":"Glic\u00e9rio", "codigo":3517109},
    {"id":3464, "id_estado":25, "cidade":"Guai\u00e7ara", "codigo":3517208},
    {"id":3465, "id_estado":25, "cidade":"Guaimb\u00ea", "codigo":3517307},
    {"id":3466, "id_estado":25, "cidade":"Gua\u00edra", "codigo":3517406},
    {"id":3467, "id_estado":25, "cidade":"Guapia\u00e7u", "codigo":3517505},
    {"id":3468, "id_estado":25, "cidade":"Guapiara", "codigo":3517604},
    {"id":3469, "id_estado":25, "cidade":"Guar\u00e1", "codigo":3517703},
    {"id":3470, "id_estado":25, "cidade":"Guara\u00e7a\u00ed", "codigo":3517802},
    {"id":3471, "id_estado":25, "cidade":"Guaraci", "codigo":3517901},
    {"id":3472, "id_estado":25, "cidade":"Guarani D'Oeste", "codigo":3518008},
    {"id":3473, "id_estado":25, "cidade":"Guarant\u00e3", "codigo":3518107},
    {"id":3474, "id_estado":25, "cidade":"Guararapes", "codigo":3518206},
    {"id":3475, "id_estado":25, "cidade":"Guararema", "codigo":3518305},
    {"id":3476, "id_estado":25, "cidade":"Guaratinguet\u00e1", "codigo":3518404},
    {"id":3477, "id_estado":25, "cidade":"Guare\u00ed", "codigo":3518503},
    {"id":3478, "id_estado":25, "cidade":"Guariba", "codigo":3518602},
    {"id":3479, "id_estado":25, "cidade":"Guaruj\u00e1", "codigo":3518701},
    {"id":3480, "id_estado":25, "cidade":"Guarulhos", "codigo":3518800},
    {"id":3481, "id_estado":25, "cidade":"Guatapar\u00e1", "codigo":3518859},
    {"id":3482, "id_estado":25, "cidade":"Guzol\u00e2ndia", "codigo":3518909},
    {"id":3483, "id_estado":25, "cidade":"Hercul\u00e2ndia", "codigo":3519006},
    {"id":3484, "id_estado":25, "cidade":"Holambra", "codigo":3519055},
    {"id":3485, "id_estado":25, "cidade":"Hortol\u00e2ndia", "codigo":3519071},
    {"id":3486, "id_estado":25, "cidade":"Iacanga", "codigo":3519105},
    {"id":3487, "id_estado":25, "cidade":"Iacri", "codigo":3519204},
    {"id":3488, "id_estado":25, "cidade":"Iaras", "codigo":3519253},
    {"id":3489, "id_estado":25, "cidade":"Ibat\u00e9", "codigo":3519303},
    {"id":3490, "id_estado":25, "cidade":"Ibir\u00e1", "codigo":3519402},
    {"id":3491, "id_estado":25, "cidade":"Ibirarema", "codigo":3519501},
    {"id":3492, "id_estado":25, "cidade":"Ibitinga", "codigo":3519600},
    {"id":3493, "id_estado":25, "cidade":"Ibi\u00fana", "codigo":3519709},
    {"id":3494, "id_estado":25, "cidade":"Ic\u00e9m", "codigo":3519808},
    {"id":3495, "id_estado":25, "cidade":"Iep\u00ea", "codigo":3519907},
    {"id":3496, "id_estado":25, "cidade":"Igara\u00e7u do Tiet\u00ea", "codigo":3520004},
    {"id":3497, "id_estado":25, "cidade":"Igarapava", "codigo":3520103},
    {"id":3498, "id_estado":25, "cidade":"Igarat\u00e1", "codigo":3520202},
    {"id":3499, "id_estado":25, "cidade":"Iguape", "codigo":3520301},
    {"id":3500, "id_estado":25, "cidade":"Ilhabela", "codigo":3520400},
    {"id":3501, "id_estado":25, "cidade":"Ilha Comprida", "codigo":3520426},
    {"id":3502, "id_estado":25, "cidade":"Ilha Solteira", "codigo":3520442},
    {"id":3503, "id_estado":25, "cidade":"Indaiatuba", "codigo":3520509},
    {"id":3504, "id_estado":25, "cidade":"Indiana", "codigo":3520608},
    {"id":3505, "id_estado":25, "cidade":"Indiapor\u00e3", "codigo":3520707},
    {"id":3506, "id_estado":25, "cidade":"In\u00fabia Paulista", "codigo":3520806},
    {"id":3507, "id_estado":25, "cidade":"Ipaussu", "codigo":3520905},
    {"id":3508, "id_estado":25, "cidade":"Iper\u00f3", "codigo":3521002},
    {"id":3509, "id_estado":25, "cidade":"Ipe\u00fana", "codigo":3521101},
    {"id":3510, "id_estado":25, "cidade":"Ipigu\u00e1", "codigo":3521150},
    {"id":3511, "id_estado":25, "cidade":"Iporanga", "codigo":3521200},
    {"id":3512, "id_estado":25, "cidade":"Ipu\u00e3", "codigo":3521309},
    {"id":3513, "id_estado":25, "cidade":"Iracem\u00e1polis", "codigo":3521408},
    {"id":3514, "id_estado":25, "cidade":"Irapu\u00e3", "codigo":3521507},
    {"id":3515, "id_estado":25, "cidade":"Irapuru", "codigo":3521606},
    {"id":3516, "id_estado":25, "cidade":"Itaber\u00e1", "codigo":3521705},
    {"id":3517, "id_estado":25, "cidade":"Ita\u00ed", "codigo":3521804},
    {"id":3518, "id_estado":25, "cidade":"Itajobi", "codigo":3521903},
    {"id":3519, "id_estado":25, "cidade":"Itaju", "codigo":3522000},
    {"id":3520, "id_estado":25, "cidade":"Itanha\u00e9m", "codigo":3522109},
    {"id":3521, "id_estado":25, "cidade":"Ita\u00f3ca", "codigo":3522158},
    {"id":3522, "id_estado":25, "cidade":"Itapecerica da Serra", "codigo":3522208},
    {"id":3523, "id_estado":25, "cidade":"Itapetininga", "codigo":3522307},
    {"id":3524, "id_estado":25, "cidade":"Itapeva", "codigo":3522406},
    {"id":3525, "id_estado":25, "cidade":"Itapevi", "codigo":3522505},
    {"id":3526, "id_estado":25, "cidade":"Itapira", "codigo":3522604},
    {"id":3527, "id_estado":25, "cidade":"Itapirapu\u00e3 Paulista", "codigo":3522653},
    {"id":3528, "id_estado":25, "cidade":"It\u00e1polis", "codigo":3522703},
    {"id":3529, "id_estado":25, "cidade":"Itaporanga", "codigo":3522802},
    {"id":3530, "id_estado":25, "cidade":"Itapu\u00ed", "codigo":3522901},
    {"id":3531, "id_estado":25, "cidade":"Itapura", "codigo":3523008},
    {"id":3532, "id_estado":25, "cidade":"Itaquaquecetuba", "codigo":3523107},
    {"id":3533, "id_estado":25, "cidade":"Itarar\u00e9", "codigo":3523206},
    {"id":3534, "id_estado":25, "cidade":"Itariri", "codigo":3523305},
    {"id":3535, "id_estado":25, "cidade":"Itatiba", "codigo":3523404},
    {"id":3536, "id_estado":25, "cidade":"Itatinga", "codigo":3523503},
    {"id":3537, "id_estado":25, "cidade":"Itirapina", "codigo":3523602},
    {"id":3538, "id_estado":25, "cidade":"Itirapu\u00e3", "codigo":3523701},
    {"id":3539, "id_estado":25, "cidade":"Itobi", "codigo":3523800},
    {"id":3540, "id_estado":25, "cidade":"Itu", "codigo":3523909},
    {"id":3541, "id_estado":25, "cidade":"Itupeva", "codigo":3524006},
    {"id":3542, "id_estado":25, "cidade":"Ituverava", "codigo":3524105},
    {"id":3543, "id_estado":25, "cidade":"Jaborandi", "codigo":3524204},
    {"id":3544, "id_estado":25, "cidade":"Jaboticabal", "codigo":3524303},
    {"id":3545, "id_estado":25, "cidade":"Jacare\u00ed", "codigo":3524402},
    {"id":3546, "id_estado":25, "cidade":"Jaci", "codigo":3524501},
    {"id":3547, "id_estado":25, "cidade":"Jacupiranga", "codigo":3524600},
    {"id":3548, "id_estado":25, "cidade":"Jaguari\u00fana", "codigo":3524709},
    {"id":3549, "id_estado":25, "cidade":"Jales", "codigo":3524808},
    {"id":3550, "id_estado":25, "cidade":"Jambeiro", "codigo":3524907},
    {"id":3551, "id_estado":25, "cidade":"Jandira", "codigo":3525003},
    {"id":3552, "id_estado":25, "cidade":"Jardin\u00f3polis", "codigo":3525102},
    {"id":3553, "id_estado":25, "cidade":"Jarinu", "codigo":3525201},
    {"id":3554, "id_estado":25, "cidade":"Ja\u00fa", "codigo":3525300},
    {"id":3555, "id_estado":25, "cidade":"Jeriquara", "codigo":3525409},
    {"id":3556, "id_estado":25, "cidade":"Joan\u00f3polis", "codigo":3525508},
    {"id":3557, "id_estado":25, "cidade":"Jo\u00e3o Ramalho", "codigo":3525607},
    {"id":3558, "id_estado":25, "cidade":"Jos\u00e9 Bonif\u00e1cio", "codigo":3525706},
    {"id":3559, "id_estado":25, "cidade":"J\u00falio Mesquita", "codigo":3525805},
    {"id":3560, "id_estado":25, "cidade":"Jumirim", "codigo":3525854},
    {"id":3561, "id_estado":25, "cidade":"Jundia\u00ed", "codigo":3525904},
    {"id":3562, "id_estado":25, "cidade":"Junqueir\u00f3polis", "codigo":3526001},
    {"id":3563, "id_estado":25, "cidade":"Juqui\u00e1", "codigo":3526100},
    {"id":3564, "id_estado":25, "cidade":"Juquitiba", "codigo":3526209},
    {"id":3565, "id_estado":25, "cidade":"Lagoinha", "codigo":3526308},
    {"id":3566, "id_estado":25, "cidade":"Laranjal Paulista", "codigo":3526407},
    {"id":3567, "id_estado":25, "cidade":"Lav\u00ednia", "codigo":3526506},
    {"id":3568, "id_estado":25, "cidade":"Lavrinhas", "codigo":3526605},
    {"id":3569, "id_estado":25, "cidade":"Leme", "codigo":3526704},
    {"id":3570, "id_estado":25, "cidade":"Len\u00e7\u00f3is Paulista", "codigo":3526803},
    {"id":3571, "id_estado":25, "cidade":"Limeira", "codigo":3526902},
    {"id":3572, "id_estado":25, "cidade":"Lind\u00f3ia", "codigo":3527009},
    {"id":3573, "id_estado":25, "cidade":"Lins", "codigo":3527108},
    {"id":3574, "id_estado":25, "cidade":"Lorena", "codigo":3527207},
    {"id":3575, "id_estado":25, "cidade":"Lourdes", "codigo":3527256},
    {"id":3576, "id_estado":25, "cidade":"Louveira", "codigo":3527306},
    {"id":3577, "id_estado":25, "cidade":"Luc\u00e9lia", "codigo":3527405},
    {"id":3578, "id_estado":25, "cidade":"Lucian\u00f3polis", "codigo":3527504},
    {"id":3579, "id_estado":25, "cidade":"Lu\u00eds Ant\u00f4nio", "codigo":3527603},
    {"id":3580, "id_estado":25, "cidade":"Luizi\u00e2nia", "codigo":3527702},
    {"id":3581, "id_estado":25, "cidade":"Lup\u00e9rcio", "codigo":3527801},
    {"id":3582, "id_estado":25, "cidade":"Lut\u00e9cia", "codigo":3527900},
    {"id":3583, "id_estado":25, "cidade":"Macatuba", "codigo":3528007},
    {"id":3584, "id_estado":25, "cidade":"Macaubal", "codigo":3528106},
    {"id":3585, "id_estado":25, "cidade":"Maced\u00f4nia", "codigo":3528205},
    {"id":3586, "id_estado":25, "cidade":"Magda", "codigo":3528304},
    {"id":3587, "id_estado":25, "cidade":"Mairinque", "codigo":3528403},
    {"id":3588, "id_estado":25, "cidade":"Mairipor\u00e3", "codigo":3528502},
    {"id":3589, "id_estado":25, "cidade":"Manduri", "codigo":3528601},
    {"id":3590, "id_estado":25, "cidade":"Marab\u00e1 Paulista", "codigo":3528700},
    {"id":3591, "id_estado":25, "cidade":"Maraca\u00ed", "codigo":3528809},
    {"id":3592, "id_estado":25, "cidade":"Marapoama", "codigo":3528858},
    {"id":3593, "id_estado":25, "cidade":"Mari\u00e1polis", "codigo":3528908},
    {"id":3594, "id_estado":25, "cidade":"Mar\u00edlia", "codigo":3529005},
    {"id":3595, "id_estado":25, "cidade":"Marin\u00f3polis", "codigo":3529104},
    {"id":3596, "id_estado":25, "cidade":"Martin\u00f3polis", "codigo":3529203},
    {"id":3597, "id_estado":25, "cidade":"Mat\u00e3o", "codigo":3529302},
    {"id":3598, "id_estado":25, "cidade":"Mau\u00e1", "codigo":3529401},
    {"id":3599, "id_estado":25, "cidade":"Mendon\u00e7a", "codigo":3529500},
    {"id":3600, "id_estado":25, "cidade":"Meridiano", "codigo":3529609},
    {"id":3601, "id_estado":25, "cidade":"Mes\u00f3polis", "codigo":3529658},
    {"id":3602, "id_estado":25, "cidade":"Miguel\u00f3polis", "codigo":3529708},
    {"id":3603, "id_estado":25, "cidade":"Mineiros do Tiet\u00ea", "codigo":3529807},
    {"id":3604, "id_estado":25, "cidade":"Miracatu", "codigo":3529906},
    {"id":3605, "id_estado":25, "cidade":"Mira Estrela", "codigo":3530003},
    {"id":3606, "id_estado":25, "cidade":"Mirand\u00f3polis", "codigo":3530102},
    {"id":3607, "id_estado":25, "cidade":"Mirante do Paranapanema", "codigo":3530201},
    {"id":3608, "id_estado":25, "cidade":"Mirassol", "codigo":3530300},
    {"id":3609, "id_estado":25, "cidade":"Mirassol\u00e2ndia", "codigo":3530409},
    {"id":3610, "id_estado":25, "cidade":"Mococa", "codigo":3530508},
    {"id":3611, "id_estado":25, "cidade":"Mogi das Cruzes", "codigo":3530607},
    {"id":3612, "id_estado":25, "cidade":"Mogi Gua\u00e7u", "codigo":3530706},
    {"id":3613, "id_estado":25, "cidade":"Moji Mirim", "codigo":3530805},
    {"id":3614, "id_estado":25, "cidade":"Mombuca", "codigo":3530904},
    {"id":3615, "id_estado":25, "cidade":"Mon\u00e7\u00f5es", "codigo":3531001},
    {"id":3616, "id_estado":25, "cidade":"Mongagu\u00e1", "codigo":3531100},
    {"id":3617, "id_estado":25, "cidade":"Monte Alegre do Sul", "codigo":3531209},
    {"id":3618, "id_estado":25, "cidade":"Monte Alto", "codigo":3531308},
    {"id":3619, "id_estado":25, "cidade":"Monte Apraz\u00edvel", "codigo":3531407},
    {"id":3620, "id_estado":25, "cidade":"Monte Azul Paulista", "codigo":3531506},
    {"id":3621, "id_estado":25, "cidade":"Monte Castelo", "codigo":3531605},
    {"id":3622, "id_estado":25, "cidade":"Monteiro Lobato", "codigo":3531704},
    {"id":3623, "id_estado":25, "cidade":"Monte Mor", "codigo":3531803},
    {"id":3624, "id_estado":25, "cidade":"Morro Agudo", "codigo":3531902},
    {"id":3625, "id_estado":25, "cidade":"Morungaba", "codigo":3532009},
    {"id":3626, "id_estado":25, "cidade":"Motuca", "codigo":3532058},
    {"id":3627, "id_estado":25, "cidade":"Murutinga do Sul", "codigo":3532108},
    {"id":3628, "id_estado":25, "cidade":"Nantes", "codigo":3532157},
    {"id":3629, "id_estado":25, "cidade":"Narandiba", "codigo":3532207},
    {"id":3630, "id_estado":25, "cidade":"Natividade da Serra", "codigo":3532306},
    {"id":3631, "id_estado":25, "cidade":"Nazar\u00e9 Paulista", "codigo":3532405},
    {"id":3632, "id_estado":25, "cidade":"Neves Paulista", "codigo":3532504},
    {"id":3633, "id_estado":25, "cidade":"Nhandeara", "codigo":3532603},
    {"id":3634, "id_estado":25, "cidade":"Nipo\u00e3", "codigo":3532702},
    {"id":3635, "id_estado":25, "cidade":"Nova Alian\u00e7a", "codigo":3532801},
    {"id":3636, "id_estado":25, "cidade":"Nova Campina", "codigo":3532827},
    {"id":3637, "id_estado":25, "cidade":"Nova Cana\u00e3 Paulista", "codigo":3532843},
    {"id":3638, "id_estado":25, "cidade":"Nova Castilho", "codigo":3532868},
    {"id":3639, "id_estado":25, "cidade":"Nova Europa", "codigo":3532900},
    {"id":3640, "id_estado":25, "cidade":"Nova Granada", "codigo":3533007},
    {"id":3641, "id_estado":25, "cidade":"Nova Guataporanga", "codigo":3533106},
    {"id":3642, "id_estado":25, "cidade":"Nova Independ\u00eancia", "codigo":3533205},
    {"id":3643, "id_estado":25, "cidade":"Novais", "codigo":3533254},
    {"id":3644, "id_estado":25, "cidade":"Nova Luzit\u00e2nia", "codigo":3533304},
    {"id":3645, "id_estado":25, "cidade":"Nova Odessa", "codigo":3533403},
    {"id":3646, "id_estado":25, "cidade":"Novo Horizonte", "codigo":3533502},
    {"id":3647, "id_estado":25, "cidade":"Nuporanga", "codigo":3533601},
    {"id":3648, "id_estado":25, "cidade":"Ocau\u00e7u", "codigo":3533700},
    {"id":3649, "id_estado":25, "cidade":"\u00d3leo", "codigo":3533809},
    {"id":3650, "id_estado":25, "cidade":"Ol\u00edmpia", "codigo":3533908},
    {"id":3651, "id_estado":25, "cidade":"Onda Verde", "codigo":3534005},
    {"id":3652, "id_estado":25, "cidade":"Oriente", "codigo":3534104},
    {"id":3653, "id_estado":25, "cidade":"Orindi\u00fava", "codigo":3534203},
    {"id":3654, "id_estado":25, "cidade":"Orl\u00e2ndia", "codigo":3534302},
    {"id":3655, "id_estado":25, "cidade":"Osasco", "codigo":3534401},
    {"id":3656, "id_estado":25, "cidade":"Oscar Bressane", "codigo":3534500},
    {"id":3657, "id_estado":25, "cidade":"Osvaldo Cruz", "codigo":3534609},
    {"id":3658, "id_estado":25, "cidade":"Ourinhos", "codigo":3534708},
    {"id":3659, "id_estado":25, "cidade":"Ouroeste", "codigo":3534757},
    {"id":3660, "id_estado":25, "cidade":"Ouro Verde", "codigo":3534807},
    {"id":3661, "id_estado":25, "cidade":"Pacaembu", "codigo":3534906},
    {"id":3662, "id_estado":25, "cidade":"Palestina", "codigo":3535002},
    {"id":3663, "id_estado":25, "cidade":"Palmares Paulista", "codigo":3535101},
    {"id":3664, "id_estado":25, "cidade":"Palmeira D'Oeste", "codigo":3535200},
    {"id":3665, "id_estado":25, "cidade":"Palmital", "codigo":3535309},
    {"id":3666, "id_estado":25, "cidade":"Panorama", "codigo":3535408},
    {"id":3667, "id_estado":25, "cidade":"Paragua\u00e7u Paulista", "codigo":3535507},
    {"id":3668, "id_estado":25, "cidade":"Paraibuna", "codigo":3535606},
    {"id":3669, "id_estado":25, "cidade":"Para\u00edso", "codigo":3535705},
    {"id":3670, "id_estado":25, "cidade":"Paranapanema", "codigo":3535804},
    {"id":3671, "id_estado":25, "cidade":"Paranapu\u00e3", "codigo":3535903},
    {"id":3672, "id_estado":25, "cidade":"Parapu\u00e3", "codigo":3536000},
    {"id":3673, "id_estado":25, "cidade":"Pardinho", "codigo":3536109},
    {"id":3674, "id_estado":25, "cidade":"Pariquera-A\u00e7u", "codigo":3536208},
    {"id":3675, "id_estado":25, "cidade":"Parisi", "codigo":3536257},
    {"id":3676, "id_estado":25, "cidade":"Patroc\u00ednio Paulista", "codigo":3536307},
    {"id":3677, "id_estado":25, "cidade":"Paulic\u00e9ia", "codigo":3536406},
    {"id":3678, "id_estado":25, "cidade":"Paul\u00ednia", "codigo":3536505},
    {"id":3679, "id_estado":25, "cidade":"Paulist\u00e2nia", "codigo":3536570},
    {"id":3680, "id_estado":25, "cidade":"Paulo de Faria", "codigo":3536604},
    {"id":3681, "id_estado":25, "cidade":"Pederneiras", "codigo":3536703},
    {"id":3682, "id_estado":25, "cidade":"Pedra Bela", "codigo":3536802},
    {"id":3683, "id_estado":25, "cidade":"Pedran\u00f3polis", "codigo":3536901},
    {"id":3684, "id_estado":25, "cidade":"Pedregulho", "codigo":3537008},
    {"id":3685, "id_estado":25, "cidade":"Pedreira", "codigo":3537107},
    {"id":3686, "id_estado":25, "cidade":"Pedrinhas Paulista", "codigo":3537156},
    {"id":3687, "id_estado":25, "cidade":"Pedro de Toledo", "codigo":3537206},
    {"id":3688, "id_estado":25, "cidade":"Pen\u00e1polis", "codigo":3537305},
    {"id":3689, "id_estado":25, "cidade":"Pereira Barreto", "codigo":3537404},
    {"id":3690, "id_estado":25, "cidade":"Pereiras", "codigo":3537503},
    {"id":3691, "id_estado":25, "cidade":"Peru\u00edbe", "codigo":3537602},
    {"id":3692, "id_estado":25, "cidade":"Piacatu", "codigo":3537701},
    {"id":3693, "id_estado":25, "cidade":"Piedade", "codigo":3537800},
    {"id":3694, "id_estado":25, "cidade":"Pilar do Sul", "codigo":3537909},
    {"id":3695, "id_estado":25, "cidade":"Pindamonhangaba", "codigo":3538006},
    {"id":3696, "id_estado":25, "cidade":"Pindorama", "codigo":3538105},
    {"id":3697, "id_estado":25, "cidade":"Pinhalzinho", "codigo":3538204},
    {"id":3698, "id_estado":25, "cidade":"Piquerobi", "codigo":3538303},
    {"id":3699, "id_estado":25, "cidade":"Piquete", "codigo":3538501},
    {"id":3700, "id_estado":25, "cidade":"Piracaia", "codigo":3538600},
    {"id":3701, "id_estado":25, "cidade":"Piracicaba", "codigo":3538709},
    {"id":3702, "id_estado":25, "cidade":"Piraju", "codigo":3538808},
    {"id":3703, "id_estado":25, "cidade":"Piraju\u00ed", "codigo":3538907},
    {"id":3704, "id_estado":25, "cidade":"Pirangi", "codigo":3539004},
    {"id":3705, "id_estado":25, "cidade":"Pirapora do Bom Jesus", "codigo":3539103},
    {"id":3706, "id_estado":25, "cidade":"Pirapozinho", "codigo":3539202},
    {"id":3707, "id_estado":25, "cidade":"Pirassununga", "codigo":3539301},
    {"id":3708, "id_estado":25, "cidade":"Piratininga", "codigo":3539400},
    {"id":3709, "id_estado":25, "cidade":"Pitangueiras", "codigo":3539509},
    {"id":3710, "id_estado":25, "cidade":"Planalto", "codigo":3539608},
    {"id":3711, "id_estado":25, "cidade":"Platina", "codigo":3539707},
    {"id":3712, "id_estado":25, "cidade":"Po\u00e1", "codigo":3539806},
    {"id":3713, "id_estado":25, "cidade":"Poloni", "codigo":3539905},
    {"id":3714, "id_estado":25, "cidade":"Pomp\u00e9ia", "codigo":3540002},
    {"id":3715, "id_estado":25, "cidade":"Ponga\u00ed", "codigo":3540101},
    {"id":3716, "id_estado":25, "cidade":"Pontal", "codigo":3540200},
    {"id":3717, "id_estado":25, "cidade":"Pontalinda", "codigo":3540259},
    {"id":3718, "id_estado":25, "cidade":"Pontes Gestal", "codigo":3540309},
    {"id":3719, "id_estado":25, "cidade":"Populina", "codigo":3540408},
    {"id":3720, "id_estado":25, "cidade":"Porangaba", "codigo":3540507},
    {"id":3721, "id_estado":25, "cidade":"Porto Feliz", "codigo":3540606},
    {"id":3722, "id_estado":25, "cidade":"Porto Ferreira", "codigo":3540705},
    {"id":3723, "id_estado":25, "cidade":"Potim", "codigo":3540754},
    {"id":3724, "id_estado":25, "cidade":"Potirendaba", "codigo":3540804},
    {"id":3725, "id_estado":25, "cidade":"Pracinha", "codigo":3540853},
    {"id":3726, "id_estado":25, "cidade":"Prad\u00f3polis", "codigo":3540903},
    {"id":3727, "id_estado":25, "cidade":"Praia Grande", "codigo":3541000},
    {"id":3728, "id_estado":25, "cidade":"Prat\u00e2nia", "codigo":3541059},
    {"id":3729, "id_estado":25, "cidade":"Presidente Alves", "codigo":3541109},
    {"id":3730, "id_estado":25, "cidade":"Presidente Bernardes", "codigo":3541208},
    {"id":3731, "id_estado":25, "cidade":"Presidente Epit\u00e1cio", "codigo":3541307},
    {"id":3732, "id_estado":25, "cidade":"Presidente Prudente", "codigo":3541406},
    {"id":3733, "id_estado":25, "cidade":"Presidente Venceslau", "codigo":3541505},
    {"id":3734, "id_estado":25, "cidade":"Promiss\u00e3o", "codigo":3541604},
    {"id":3735, "id_estado":25, "cidade":"Quadra", "codigo":3541653},
    {"id":3736, "id_estado":25, "cidade":"Quat\u00e1", "codigo":3541703},
    {"id":3737, "id_estado":25, "cidade":"Queiroz", "codigo":3541802},
    {"id":3738, "id_estado":25, "cidade":"Queluz", "codigo":3541901},
    {"id":3739, "id_estado":25, "cidade":"Quintana", "codigo":3542008},
    {"id":3740, "id_estado":25, "cidade":"Rafard", "codigo":3542107},
    {"id":3741, "id_estado":25, "cidade":"Rancharia", "codigo":3542206},
    {"id":3742, "id_estado":25, "cidade":"Reden\u00e7\u00e3o da Serra", "codigo":3542305},
    {"id":3743, "id_estado":25, "cidade":"Regente Feij\u00f3", "codigo":3542404},
    {"id":3744, "id_estado":25, "cidade":"Regin\u00f3polis", "codigo":3542503},
    {"id":3745, "id_estado":25, "cidade":"Registro", "codigo":3542602},
    {"id":3746, "id_estado":25, "cidade":"Restinga", "codigo":3542701},
    {"id":3747, "id_estado":25, "cidade":"Ribeira", "codigo":3542800},
    {"id":3748, "id_estado":25, "cidade":"Ribeir\u00e3o Bonito", "codigo":3542909},
    {"id":3749, "id_estado":25, "cidade":"Ribeir\u00e3o Branco", "codigo":3543006},
    {"id":3750, "id_estado":25, "cidade":"Ribeir\u00e3o Corrente", "codigo":3543105},
    {"id":3751, "id_estado":25, "cidade":"Ribeir\u00e3o do Sul", "codigo":3543204},
    {"id":3752, "id_estado":25, "cidade":"Ribeir\u00e3o dos \u00cdndios", "codigo":3543238},
    {"id":3753, "id_estado":25, "cidade":"Ribeir\u00e3o Grande", "codigo":3543253},
    {"id":3754, "id_estado":25, "cidade":"Ribeir\u00e3o Pires", "codigo":3543303},
    {"id":3755, "id_estado":25, "cidade":"Ribeir\u00e3o Preto", "codigo":3543402},
    {"id":3756, "id_estado":25, "cidade":"Riversul", "codigo":3543501},
    {"id":3757, "id_estado":25, "cidade":"Rifaina", "codigo":3543600},
    {"id":3758, "id_estado":25, "cidade":"Rinc\u00e3o", "codigo":3543709},
    {"id":3759, "id_estado":25, "cidade":"Rin\u00f3polis", "codigo":3543808},
    {"id":3760, "id_estado":25, "cidade":"Rio Claro", "codigo":3543907},
    {"id":3761, "id_estado":25, "cidade":"Rio das Pedras", "codigo":3544004},
    {"id":3762, "id_estado":25, "cidade":"Rio Grande da Serra", "codigo":3544103},
    {"id":3763, "id_estado":25, "cidade":"Riol\u00e2ndia", "codigo":3544202},
    {"id":3764, "id_estado":25, "cidade":"Rosana", "codigo":3544251},
    {"id":3765, "id_estado":25, "cidade":"Roseira", "codigo":3544301},
    {"id":3766, "id_estado":25, "cidade":"Rubi\u00e1cea", "codigo":3544400},
    {"id":3767, "id_estado":25, "cidade":"Rubin\u00e9ia", "codigo":3544509},
    {"id":3768, "id_estado":25, "cidade":"Sabino", "codigo":3544608},
    {"id":3769, "id_estado":25, "cidade":"Sagres", "codigo":3544707},
    {"id":3770, "id_estado":25, "cidade":"Sales", "codigo":3544806},
    {"id":3771, "id_estado":25, "cidade":"Sales Oliveira", "codigo":3544905},
    {"id":3772, "id_estado":25, "cidade":"Sales\u00f3polis", "codigo":3545001},
    {"id":3773, "id_estado":25, "cidade":"Salmour\u00e3o", "codigo":3545100},
    {"id":3774, "id_estado":25, "cidade":"Saltinho", "codigo":3545159},
    {"id":3775, "id_estado":25, "cidade":"Salto", "codigo":3545209},
    {"id":3776, "id_estado":25, "cidade":"Salto de Pirapora", "codigo":3545308},
    {"id":3777, "id_estado":25, "cidade":"Salto Grande", "codigo":3545407},
    {"id":3778, "id_estado":25, "cidade":"Sandovalina", "codigo":3545506},
    {"id":3779, "id_estado":25, "cidade":"Santa Ad\u00e9lia", "codigo":3545605},
    {"id":3780, "id_estado":25, "cidade":"Santa Albertina", "codigo":3545704},
    {"id":3781, "id_estado":25, "cidade":"Santa B\u00e1rbara D'Oeste", "codigo":3545803},
    {"id":3782, "id_estado":25, "cidade":"Santa Branca", "codigo":3546009},
    {"id":3783, "id_estado":25, "cidade":"Santa Clara D'Oeste", "codigo":3546108},
    {"id":3784, "id_estado":25, "cidade":"Santa Cruz da Concei\u00e7\u00e3o", "codigo":3546207},
    {"id":3785, "id_estado":25, "cidade":"Santa Cruz da Esperan\u00e7a", "codigo":3546256},
    {"id":3786, "id_estado":25, "cidade":"Santa Cruz das Palmeiras", "codigo":3546306},
    {"id":3787, "id_estado":25, "cidade":"Santa Cruz do Rio Pardo", "codigo":3546405},
    {"id":3788, "id_estado":25, "cidade":"Santa Ernestina", "codigo":3546504},
    {"id":3789, "id_estado":25, "cidade":"Santa F\u00e9 do Sul", "codigo":3546603},
    {"id":3790, "id_estado":25, "cidade":"Santa Gertrudes", "codigo":3546702},
    {"id":3791, "id_estado":25, "cidade":"Santa Isabel", "codigo":3546801},
    {"id":3792, "id_estado":25, "cidade":"Santa L\u00facia", "codigo":3546900},
    {"id":3793, "id_estado":25, "cidade":"Santa Maria da Serra", "codigo":3547007},
    {"id":3794, "id_estado":25, "cidade":"Santa Mercedes", "codigo":3547106},
    {"id":3795, "id_estado":25, "cidade":"Santana da Ponte Pensa", "codigo":3547205},
    {"id":3796, "id_estado":25, "cidade":"Santana de Parna\u00edba", "codigo":3547304},
    {"id":3797, "id_estado":25, "cidade":"Santa Rita D'Oeste", "codigo":3547403},
    {"id":3798, "id_estado":25, "cidade":"Santa Rita do Passa Quatro", "codigo":3547502},
    {"id":3799, "id_estado":25, "cidade":"Santa Rosa de Viterbo", "codigo":3547601},
    {"id":3800, "id_estado":25, "cidade":"Santa Salete", "codigo":3547650},
    {"id":3801, "id_estado":25, "cidade":"Santo Anast\u00e1cio", "codigo":3547700},
    {"id":3802, "id_estado":25, "cidade":"Santo Andr\u00e9", "codigo":3547809},
    {"id":3803, "id_estado":25, "cidade":"Santo Ant\u00f4nio da Alegria", "codigo":3547908},
    {"id":3804, "id_estado":25, "cidade":"Santo Ant\u00f4nio de Posse", "codigo":3548005},
    {"id":3805, "id_estado":25, "cidade":"Santo Ant\u00f4nio do Aracangu\u00e1", "codigo":3548054},
    {"id":3806, "id_estado":25, "cidade":"Santo Ant\u00f4nio do Jardim", "codigo":3548104},
    {"id":3807, "id_estado":25, "cidade":"Santo Ant\u00f4nio do Pinhal", "codigo":3548203},
    {"id":3808, "id_estado":25, "cidade":"Santo Expedito", "codigo":3548302},
    {"id":3809, "id_estado":25, "cidade":"Sant\u00f3polis do Aguape\u00ed", "codigo":3548401},
    {"id":3810, "id_estado":25, "cidade":"Santos", "codigo":3548500},
    {"id":3811, "id_estado":25, "cidade":"S\u00e3o Bento do Sapuca\u00ed", "codigo":3548609},
    {"id":3812, "id_estado":25, "cidade":"S\u00e3o Bernardo do Campo", "codigo":3548708},
    {"id":3813, "id_estado":25, "cidade":"S\u00e3o Caetano do Sul", "codigo":3548807},
    {"id":3814, "id_estado":25, "cidade":"S\u00e3o Carlos", "codigo":3548906},
    {"id":3815, "id_estado":25, "cidade":"S\u00e3o Francisco", "codigo":3549003},
    {"id":3816, "id_estado":25, "cidade":"S\u00e3o Jo\u00e3o da Boa Vista", "codigo":3549102},
    {"id":3817, "id_estado":25, "cidade":"S\u00e3o Jo\u00e3o das Duas Pontes", "codigo":3549201},
    {"id":3818, "id_estado":25, "cidade":"S\u00e3o Jo\u00e3o de Iracema", "codigo":3549250},
    {"id":3819, "id_estado":25, "cidade":"S\u00e3o Jo\u00e3o do Pau D'Alho", "codigo":3549300},
    {"id":3820, "id_estado":25, "cidade":"S\u00e3o Joaquim da Barra", "codigo":3549409},
    {"id":3821, "id_estado":25, "cidade":"S\u00e3o Jos\u00e9 da Bela Vista", "codigo":3549508},
    {"id":3822, "id_estado":25, "cidade":"S\u00e3o Jos\u00e9 do Barreiro", "codigo":3549607},
    {"id":3823, "id_estado":25, "cidade":"S\u00e3o Jos\u00e9 do Rio Pardo", "codigo":3549706},
    {"id":3824, "id_estado":25, "cidade":"S\u00e3o Jos\u00e9 do Rio Preto", "codigo":3549805},
    {"id":3825, "id_estado":25, "cidade":"S\u00e3o Jos\u00e9 dos Campos", "codigo":3549904},
    {"id":3826, "id_estado":25, "cidade":"S\u00e3o Louren\u00e7o da Serra", "codigo":3549953},
    {"id":3827, "id_estado":25, "cidade":"S\u00e3o Lu\u00eds do Paraitinga", "codigo":3550001},
    {"id":3828, "id_estado":25, "cidade":"S\u00e3o Manuel", "codigo":3550100},
    {"id":3829, "id_estado":25, "cidade":"S\u00e3o Miguel Arcanjo", "codigo":3550209},
    {"id":3830, "id_estado":25, "cidade":"S\u00e3o Paulo", "codigo":3550308},
    {"id":3831, "id_estado":25, "cidade":"S\u00e3o Pedro", "codigo":3550407},
    {"id":3832, "id_estado":25, "cidade":"S\u00e3o Pedro do Turvo", "codigo":3550506},
    {"id":3833, "id_estado":25, "cidade":"S\u00e3o Roque", "codigo":3550605},
    {"id":3834, "id_estado":25, "cidade":"S\u00e3o Sebasti\u00e3o", "codigo":3550704},
    {"id":3835, "id_estado":25, "cidade":"S\u00e3o Sebasti\u00e3o da Grama", "codigo":3550803},
    {"id":3836, "id_estado":25, "cidade":"S\u00e3o Sim\u00e3o", "codigo":3550902},
    {"id":3837, "id_estado":25, "cidade":"S\u00e3o Vicente", "codigo":3551009},
    {"id":3838, "id_estado":25, "cidade":"Sarapu\u00ed", "codigo":3551108},
    {"id":3839, "id_estado":25, "cidade":"Sarutai\u00e1", "codigo":3551207},
    {"id":3840, "id_estado":25, "cidade":"Sebastian\u00f3polis do Sul", "codigo":3551306},
    {"id":3841, "id_estado":25, "cidade":"Serra Azul", "codigo":3551405},
    {"id":3842, "id_estado":25, "cidade":"Serrana", "codigo":3551504},
    {"id":3843, "id_estado":25, "cidade":"Serra Negra", "codigo":3551603},
    {"id":3844, "id_estado":25, "cidade":"Sert\u00e3ozinho", "codigo":3551702},
    {"id":3845, "id_estado":25, "cidade":"Sete Barras", "codigo":3551801},
    {"id":3846, "id_estado":25, "cidade":"Sever\u00ednia", "codigo":3551900},
    {"id":3847, "id_estado":25, "cidade":"Silveiras", "codigo":3552007},
    {"id":3848, "id_estado":25, "cidade":"Socorro", "codigo":3552106},
    {"id":3849, "id_estado":25, "cidade":"Sorocaba", "codigo":3552205},
    {"id":3850, "id_estado":25, "cidade":"Sud Mennucci", "codigo":3552304},
    {"id":3851, "id_estado":25, "cidade":"Sumar\u00e9", "codigo":3552403},
    {"id":3852, "id_estado":25, "cidade":"Suzano", "codigo":3552502},
    {"id":3853, "id_estado":25, "cidade":"Suzan\u00e1polis", "codigo":3552551},
    {"id":3854, "id_estado":25, "cidade":"Tabapu\u00e3", "codigo":3552601},
    {"id":3855, "id_estado":25, "cidade":"Tabatinga", "codigo":3552700},
    {"id":3856, "id_estado":25, "cidade":"Tabo\u00e3o da Serra", "codigo":3552809},
    {"id":3857, "id_estado":25, "cidade":"Taciba", "codigo":3552908},
    {"id":3858, "id_estado":25, "cidade":"Tagua\u00ed", "codigo":3553005},
    {"id":3859, "id_estado":25, "cidade":"Taia\u00e7u", "codigo":3553104},
    {"id":3860, "id_estado":25, "cidade":"Tai\u00fava", "codigo":3553203},
    {"id":3861, "id_estado":25, "cidade":"Tamba\u00fa", "codigo":3553302},
    {"id":3862, "id_estado":25, "cidade":"Tanabi", "codigo":3553401},
    {"id":3863, "id_estado":25, "cidade":"Tapira\u00ed", "codigo":3553500},
    {"id":3864, "id_estado":25, "cidade":"Tapiratiba", "codigo":3553609},
    {"id":3865, "id_estado":25, "cidade":"Taquaral", "codigo":3553658},
    {"id":3866, "id_estado":25, "cidade":"Taquaritinga", "codigo":3553708},
    {"id":3867, "id_estado":25, "cidade":"Taquarituba", "codigo":3553807},
    {"id":3868, "id_estado":25, "cidade":"Taquariva\u00ed", "codigo":3553856},
    {"id":3869, "id_estado":25, "cidade":"Tarabai", "codigo":3553906},
    {"id":3870, "id_estado":25, "cidade":"Tarum\u00e3", "codigo":3553955},
    {"id":3871, "id_estado":25, "cidade":"Tatu\u00ed", "codigo":3554003},
    {"id":3872, "id_estado":25, "cidade":"Taubat\u00e9", "codigo":3554102},
    {"id":3873, "id_estado":25, "cidade":"Tejup\u00e1", "codigo":3554201},
    {"id":3874, "id_estado":25, "cidade":"Teodoro Sampaio", "codigo":3554300},
    {"id":3875, "id_estado":25, "cidade":"Terra Roxa", "codigo":3554409},
    {"id":3876, "id_estado":25, "cidade":"Tiet\u00ea", "codigo":3554508},
    {"id":3877, "id_estado":25, "cidade":"Timburi", "codigo":3554607},
    {"id":3878, "id_estado":25, "cidade":"Torre de Pedra", "codigo":3554656},
    {"id":3879, "id_estado":25, "cidade":"Torrinha", "codigo":3554706},
    {"id":3880, "id_estado":25, "cidade":"Trabiju", "codigo":3554755},
    {"id":3881, "id_estado":25, "cidade":"Trememb\u00e9", "codigo":3554805},
    {"id":3882, "id_estado":25, "cidade":"Tr\u00eas Fronteiras", "codigo":3554904},
    {"id":3883, "id_estado":25, "cidade":"Tuiuti", "codigo":3554953},
    {"id":3884, "id_estado":25, "cidade":"Tup\u00e3", "codigo":3555000},
    {"id":3885, "id_estado":25, "cidade":"Tupi Paulista", "codigo":3555109},
    {"id":3886, "id_estado":25, "cidade":"Turi\u00faba", "codigo":3555208},
    {"id":3887, "id_estado":25, "cidade":"Turmalina", "codigo":3555307},
    {"id":3888, "id_estado":25, "cidade":"Ubarana", "codigo":3555356},
    {"id":3889, "id_estado":25, "cidade":"Ubatuba", "codigo":3555406},
    {"id":3890, "id_estado":25, "cidade":"Ubirajara", "codigo":3555505},
    {"id":3891, "id_estado":25, "cidade":"Uchoa", "codigo":3555604},
    {"id":3892, "id_estado":25, "cidade":"Uni\u00e3o Paulista", "codigo":3555703},
    {"id":3893, "id_estado":25, "cidade":"Ur\u00e2nia", "codigo":3555802},
    {"id":3894, "id_estado":25, "cidade":"Uru", "codigo":3555901},
    {"id":3895, "id_estado":25, "cidade":"Urup\u00eas", "codigo":3556008},
    {"id":3896, "id_estado":25, "cidade":"Valentim Gentil", "codigo":3556107},
    {"id":3897, "id_estado":25, "cidade":"Valinhos", "codigo":3556206},
    {"id":3898, "id_estado":25, "cidade":"Valpara\u00edso", "codigo":3556305},
    {"id":3899, "id_estado":25, "cidade":"Vargem", "codigo":3556354},
    {"id":3900, "id_estado":25, "cidade":"Vargem Grande do Sul", "codigo":3556404},
    {"id":3901, "id_estado":25, "cidade":"Vargem Grande Paulista", "codigo":3556453},
    {"id":3902, "id_estado":25, "cidade":"V\u00e1rzea Paulista", "codigo":3556503},
    {"id":3903, "id_estado":25, "cidade":"Vera Cruz", "codigo":3556602},
    {"id":3904, "id_estado":25, "cidade":"Vinhedo", "codigo":3556701},
    {"id":3905, "id_estado":25, "cidade":"Viradouro", "codigo":3556800},
    {"id":3906, "id_estado":25, "cidade":"Vista Alegre do Alto", "codigo":3556909},
    {"id":3907, "id_estado":25, "cidade":"Vit\u00f3ria Brasil", "codigo":3556958},
    {"id":3908, "id_estado":25, "cidade":"Votorantim", "codigo":3557006},
    {"id":3909, "id_estado":25, "cidade":"Votuporanga", "codigo":3557105},
    {"id":3910, "id_estado":25, "cidade":"Zacarias", "codigo":3557154},
    {"id":3911, "id_estado":25, "cidade":"Chavantes", "codigo":3557204},
    {"id":3912, "id_estado":25, "cidade":"Estiva Gerbi", "codigo":3557303},
    {"id":3913, "id_estado":16, "cidade":"Abati\u00e1", "codigo":4100103},
    {"id":3914, "id_estado":16, "cidade":"Adrian\u00f3polis", "codigo":4100202},
    {"id":3915, "id_estado":16, "cidade":"Agudos do Sul", "codigo":4100301},
    {"id":3916, "id_estado":16, "cidade":"Almirante Tamandar\u00e9", "codigo":4100400},
    {"id":3917, "id_estado":16, "cidade":"Altamira do Paran\u00e1", "codigo":4100459},
    {"id":3918, "id_estado":16, "cidade":"Alt\u00f4nia", "codigo":4100509},
    {"id":3919, "id_estado":16, "cidade":"Alto Paran\u00e1", "codigo":4100608},
    {"id":3920, "id_estado":16, "cidade":"Alto Piquiri", "codigo":4100707},
    {"id":3921, "id_estado":16, "cidade":"Alvorada do Sul", "codigo":4100806},
    {"id":3922, "id_estado":16, "cidade":"Amapor\u00e3", "codigo":4100905},
    {"id":3923, "id_estado":16, "cidade":"Amp\u00e9re", "codigo":4101002},
    {"id":3924, "id_estado":16, "cidade":"Anahy", "codigo":4101051},
    {"id":3925, "id_estado":16, "cidade":"Andir\u00e1", "codigo":4101101},
    {"id":3926, "id_estado":16, "cidade":"\u00c2ngulo", "codigo":4101150},
    {"id":3927, "id_estado":16, "cidade":"Antonina", "codigo":4101200},
    {"id":3928, "id_estado":16, "cidade":"Ant\u00f4nio Olinto", "codigo":4101309},
    {"id":3929, "id_estado":16, "cidade":"Apucarana", "codigo":4101408},
    {"id":3930, "id_estado":16, "cidade":"Arapongas", "codigo":4101507},
    {"id":3931, "id_estado":16, "cidade":"Arapoti", "codigo":4101606},
    {"id":3932, "id_estado":16, "cidade":"Arapu\u00e3", "codigo":4101655},
    {"id":3933, "id_estado":16, "cidade":"Araruna", "codigo":4101705},
    {"id":3934, "id_estado":16, "cidade":"Arauc\u00e1ria", "codigo":4101804},
    {"id":3935, "id_estado":16, "cidade":"Ariranha do Iva\u00ed", "codigo":4101853},
    {"id":3936, "id_estado":16, "cidade":"Assa\u00ed", "codigo":4101903},
    {"id":3937, "id_estado":16, "cidade":"Assis Chateaubriand", "codigo":4102000},
    {"id":3938, "id_estado":16, "cidade":"Astorga", "codigo":4102109},
    {"id":3939, "id_estado":16, "cidade":"Atalaia", "codigo":4102208},
    {"id":3940, "id_estado":16, "cidade":"Balsa Nova", "codigo":4102307},
    {"id":3941, "id_estado":16, "cidade":"Bandeirantes", "codigo":4102406},
    {"id":3942, "id_estado":16, "cidade":"Barbosa Ferraz", "codigo":4102505},
    {"id":3943, "id_estado":16, "cidade":"Barrac\u00e3o", "codigo":4102604},
    {"id":3944, "id_estado":16, "cidade":"Barra do Jacar\u00e9", "codigo":4102703},
    {"id":3945, "id_estado":16, "cidade":"Bela Vista da Caroba", "codigo":4102752},
    {"id":3946, "id_estado":16, "cidade":"Bela Vista do Para\u00edso", "codigo":4102802},
    {"id":3947, "id_estado":16, "cidade":"Bituruna", "codigo":4102901},
    {"id":3948, "id_estado":16, "cidade":"Boa Esperan\u00e7a", "codigo":4103008},
    {"id":3949, "id_estado":16, "cidade":"Boa Esperan\u00e7a do Igua\u00e7u", "codigo":4103024},
    {"id":3950, "id_estado":16, "cidade":"Boa Ventura de S\u00e3o Roque", "codigo":4103040},
    {"id":3951, "id_estado":16, "cidade":"Boa Vista da Aparecida", "codigo":4103057},
    {"id":3952, "id_estado":16, "cidade":"Bocai\u00fava do Sul", "codigo":4103107},
    {"id":3953, "id_estado":16, "cidade":"Bom Jesus do Sul", "codigo":4103156},
    {"id":3954, "id_estado":16, "cidade":"Bom Sucesso", "codigo":4103206},
    {"id":3955, "id_estado":16, "cidade":"Bom Sucesso do Sul", "codigo":4103222},
    {"id":3956, "id_estado":16, "cidade":"Borraz\u00f3polis", "codigo":4103305},
    {"id":3957, "id_estado":16, "cidade":"Braganey", "codigo":4103354},
    {"id":3958, "id_estado":16, "cidade":"Brasil\u00e2ndia do Sul", "codigo":4103370},
    {"id":3959, "id_estado":16, "cidade":"Cafeara", "codigo":4103404},
    {"id":3960, "id_estado":16, "cidade":"Cafel\u00e2ndia", "codigo":4103453},
    {"id":3961, "id_estado":16, "cidade":"Cafezal do Sul", "codigo":4103479},
    {"id":3962, "id_estado":16, "cidade":"Calif\u00f3rnia", "codigo":4103503},
    {"id":3963, "id_estado":16, "cidade":"Cambar\u00e1", "codigo":4103602},
    {"id":3964, "id_estado":16, "cidade":"Camb\u00e9", "codigo":4103701},
    {"id":3965, "id_estado":16, "cidade":"Cambira", "codigo":4103800},
    {"id":3966, "id_estado":16, "cidade":"Campina da Lagoa", "codigo":4103909},
    {"id":3967, "id_estado":16, "cidade":"Campina do Sim\u00e3o", "codigo":4103958},
    {"id":3968, "id_estado":16, "cidade":"Campina Grande do Sul", "codigo":4104006},
    {"id":3969, "id_estado":16, "cidade":"Campo Bonito", "codigo":4104055},
    {"id":3970, "id_estado":16, "cidade":"Campo do Tenente", "codigo":4104105},
    {"id":3971, "id_estado":16, "cidade":"Campo Largo", "codigo":4104204},
    {"id":3972, "id_estado":16, "cidade":"Campo Magro", "codigo":4104253},
    {"id":3973, "id_estado":16, "cidade":"Campo Mour\u00e3o", "codigo":4104303},
    {"id":3974, "id_estado":16, "cidade":"C\u00e2ndido de Abreu", "codigo":4104402},
    {"id":3975, "id_estado":16, "cidade":"Cand\u00f3i", "codigo":4104428},
    {"id":3976, "id_estado":16, "cidade":"Cantagalo", "codigo":4104451},
    {"id":3977, "id_estado":16, "cidade":"Capanema", "codigo":4104501},
    {"id":3978, "id_estado":16, "cidade":"Capit\u00e3o Le\u00f4nidas Marques", "codigo":4104600},
    {"id":3979, "id_estado":16, "cidade":"Carambe\u00ed", "codigo":4104659},
    {"id":3980, "id_estado":16, "cidade":"Carl\u00f3polis", "codigo":4104709},
    {"id":3981, "id_estado":16, "cidade":"Cascavel", "codigo":4104808},
    {"id":3982, "id_estado":16, "cidade":"Castro", "codigo":4104907},
    {"id":3983, "id_estado":16, "cidade":"Catanduvas", "codigo":4105003},
    {"id":3984, "id_estado":16, "cidade":"Centen\u00e1rio do Sul", "codigo":4105102},
    {"id":3985, "id_estado":16, "cidade":"Cerro Azul", "codigo":4105201},
    {"id":3986, "id_estado":16, "cidade":"C\u00e9u Azul", "codigo":4105300},
    {"id":3987, "id_estado":16, "cidade":"Chopinzinho", "codigo":4105409},
    {"id":3988, "id_estado":16, "cidade":"Cianorte", "codigo":4105508},
    {"id":3989, "id_estado":16, "cidade":"Cidade Ga\u00facha", "codigo":4105607},
    {"id":3990, "id_estado":16, "cidade":"Clevel\u00e2ndia", "codigo":4105706},
    {"id":3991, "id_estado":16, "cidade":"Colombo", "codigo":4105805},
    {"id":3992, "id_estado":16, "cidade":"Colorado", "codigo":4105904},
    {"id":3993, "id_estado":16, "cidade":"Congonhinhas", "codigo":4106001},
    {"id":3994, "id_estado":16, "cidade":"Conselheiro Mairinck", "codigo":4106100},
    {"id":3995, "id_estado":16, "cidade":"Contenda", "codigo":4106209},
    {"id":3996, "id_estado":16, "cidade":"Corb\u00e9lia", "codigo":4106308},
    {"id":3997, "id_estado":16, "cidade":"Corn\u00e9lio Proc\u00f3pio", "codigo":4106407},
    {"id":3998, "id_estado":16, "cidade":"Coronel Domingos Soares", "codigo":4106456},
    {"id":3999, "id_estado":16, "cidade":"Coronel Vivida", "codigo":4106506},
    {"id":4000, "id_estado":16, "cidade":"Corumbata\u00ed do Sul", "codigo":4106555},
    {"id":4001, "id_estado":16, "cidade":"Cruzeiro do Igua\u00e7u", "codigo":4106571},
    {"id":4002, "id_estado":16, "cidade":"Cruzeiro do Oeste", "codigo":4106605},
    {"id":4003, "id_estado":16, "cidade":"Cruzeiro do Sul", "codigo":4106704},
    {"id":4004, "id_estado":16, "cidade":"Cruz Machado", "codigo":4106803},
    {"id":4005, "id_estado":16, "cidade":"Cruzmaltina", "codigo":4106852},
    {"id":4006, "id_estado":16, "cidade":"Curitiba", "codigo":4106902},
    {"id":4007, "id_estado":16, "cidade":"Curi\u00fava", "codigo":4107009},
    {"id":4008, "id_estado":16, "cidade":"Diamante do Norte", "codigo":4107108},
    {"id":4009, "id_estado":16, "cidade":"Diamante do Sul", "codigo":4107124},
    {"id":4010, "id_estado":16, "cidade":"Diamante D'Oeste", "codigo":4107157},
    {"id":4011, "id_estado":16, "cidade":"Dois Vizinhos", "codigo":4107207},
    {"id":4012, "id_estado":16, "cidade":"Douradina", "codigo":4107256},
    {"id":4013, "id_estado":16, "cidade":"Doutor Camargo", "codigo":4107306},
    {"id":4014, "id_estado":16, "cidade":"En\u00e9as Marques", "codigo":4107405},
    {"id":4015, "id_estado":16, "cidade":"Engenheiro Beltr\u00e3o", "codigo":4107504},
    {"id":4016, "id_estado":16, "cidade":"Esperan\u00e7a Nova", "codigo":4107520},
    {"id":4017, "id_estado":16, "cidade":"Entre Rios do Oeste", "codigo":4107538},
    {"id":4018, "id_estado":16, "cidade":"Espig\u00e3o Alto do Igua\u00e7u", "codigo":4107546},
    {"id":4019, "id_estado":16, "cidade":"Farol", "codigo":4107553},
    {"id":4020, "id_estado":16, "cidade":"Faxinal", "codigo":4107603},
    {"id":4021, "id_estado":16, "cidade":"Fazenda Rio Grande", "codigo":4107652},
    {"id":4022, "id_estado":16, "cidade":"F\u00eanix", "codigo":4107702},
    {"id":4023, "id_estado":16, "cidade":"Fernandes Pinheiro", "codigo":4107736},
    {"id":4024, "id_estado":16, "cidade":"Figueira", "codigo":4107751},
    {"id":4025, "id_estado":16, "cidade":"Flora\u00ed", "codigo":4107801},
    {"id":4026, "id_estado":16, "cidade":"Flor da Serra do Sul", "codigo":4107850},
    {"id":4027, "id_estado":16, "cidade":"Floresta", "codigo":4107900},
    {"id":4028, "id_estado":16, "cidade":"Florest\u00f3polis", "codigo":4108007},
    {"id":4029, "id_estado":16, "cidade":"Fl\u00f3rida", "codigo":4108106},
    {"id":4030, "id_estado":16, "cidade":"Formosa do Oeste", "codigo":4108205},
    {"id":4031, "id_estado":16, "cidade":"Foz do Igua\u00e7u", "codigo":4108304},
    {"id":4032, "id_estado":16, "cidade":"Francisco Alves", "codigo":4108320},
    {"id":4033, "id_estado":16, "cidade":"Francisco Beltr\u00e3o", "codigo":4108403},
    {"id":4034, "id_estado":16, "cidade":"Foz do Jord\u00e3o", "codigo":4108452},
    {"id":4035, "id_estado":16, "cidade":"General Carneiro", "codigo":4108502},
    {"id":4036, "id_estado":16, "cidade":"Godoy Moreira", "codigo":4108551},
    {"id":4037, "id_estado":16, "cidade":"Goioer\u00ea", "codigo":4108601},
    {"id":4038, "id_estado":16, "cidade":"Goioxim", "codigo":4108650},
    {"id":4039, "id_estado":16, "cidade":"Grandes Rios", "codigo":4108700},
    {"id":4040, "id_estado":16, "cidade":"Gua\u00edra", "codigo":4108809},
    {"id":4041, "id_estado":16, "cidade":"Guaira\u00e7\u00e1", "codigo":4108908},
    {"id":4042, "id_estado":16, "cidade":"Guamiranga", "codigo":4108957},
    {"id":4043, "id_estado":16, "cidade":"Guapirama", "codigo":4109005},
    {"id":4044, "id_estado":16, "cidade":"Guaporema", "codigo":4109104},
    {"id":4045, "id_estado":16, "cidade":"Guaraci", "codigo":4109203},
    {"id":4046, "id_estado":16, "cidade":"Guarania\u00e7u", "codigo":4109302},
    {"id":4047, "id_estado":16, "cidade":"Guarapuava", "codigo":4109401},
    {"id":4048, "id_estado":16, "cidade":"Guaraque\u00e7aba", "codigo":4109500},
    {"id":4049, "id_estado":16, "cidade":"Guaratuba", "codigo":4109609},
    {"id":4050, "id_estado":16, "cidade":"Hon\u00f3rio Serpa", "codigo":4109658},
    {"id":4051, "id_estado":16, "cidade":"Ibaiti", "codigo":4109708},
    {"id":4052, "id_estado":16, "cidade":"Ibema", "codigo":4109757},
    {"id":4053, "id_estado":16, "cidade":"Ibipor\u00e3", "codigo":4109807},
    {"id":4054, "id_estado":16, "cidade":"Icara\u00edma", "codigo":4109906},
    {"id":4055, "id_estado":16, "cidade":"Iguara\u00e7u", "codigo":4110003},
    {"id":4056, "id_estado":16, "cidade":"Iguatu", "codigo":4110052},
    {"id":4057, "id_estado":16, "cidade":"Imba\u00fa", "codigo":4110078},
    {"id":4058, "id_estado":16, "cidade":"Imbituva", "codigo":4110102},
    {"id":4059, "id_estado":16, "cidade":"In\u00e1cio Martins", "codigo":4110201},
    {"id":4060, "id_estado":16, "cidade":"Inaj\u00e1", "codigo":4110300},
    {"id":4061, "id_estado":16, "cidade":"Indian\u00f3polis", "codigo":4110409},
    {"id":4062, "id_estado":16, "cidade":"Ipiranga", "codigo":4110508},
    {"id":4063, "id_estado":16, "cidade":"Ipor\u00e3", "codigo":4110607},
    {"id":4064, "id_estado":16, "cidade":"Iracema do Oeste", "codigo":4110656},
    {"id":4065, "id_estado":16, "cidade":"Irati", "codigo":4110706},
    {"id":4066, "id_estado":16, "cidade":"Iretama", "codigo":4110805},
    {"id":4067, "id_estado":16, "cidade":"Itaguaj\u00e9", "codigo":4110904},
    {"id":4068, "id_estado":16, "cidade":"Itaipul\u00e2ndia", "codigo":4110953},
    {"id":4069, "id_estado":16, "cidade":"Itambarac\u00e1", "codigo":4111001},
    {"id":4070, "id_estado":16, "cidade":"Itamb\u00e9", "codigo":4111100},
    {"id":4071, "id_estado":16, "cidade":"Itapejara D'Oeste", "codigo":4111209},
    {"id":4072, "id_estado":16, "cidade":"Itaperu\u00e7u", "codigo":4111258},
    {"id":4073, "id_estado":16, "cidade":"Ita\u00fana do Sul", "codigo":4111308},
    {"id":4074, "id_estado":16, "cidade":"Iva\u00ed", "codigo":4111407},
    {"id":4075, "id_estado":16, "cidade":"Ivaipor\u00e3", "codigo":4111506},
    {"id":4076, "id_estado":16, "cidade":"Ivat\u00e9", "codigo":4111555},
    {"id":4077, "id_estado":16, "cidade":"Ivatuba", "codigo":4111605},
    {"id":4078, "id_estado":16, "cidade":"Jaboti", "codigo":4111704},
    {"id":4079, "id_estado":16, "cidade":"Jacarezinho", "codigo":4111803},
    {"id":4080, "id_estado":16, "cidade":"Jaguapit\u00e3", "codigo":4111902},
    {"id":4081, "id_estado":16, "cidade":"Jaguaria\u00edva", "codigo":4112009},
    {"id":4082, "id_estado":16, "cidade":"Jandaia do Sul", "codigo":4112108},
    {"id":4083, "id_estado":16, "cidade":"Jani\u00f3polis", "codigo":4112207},
    {"id":4084, "id_estado":16, "cidade":"Japira", "codigo":4112306},
    {"id":4085, "id_estado":16, "cidade":"Japur\u00e1", "codigo":4112405},
    {"id":4086, "id_estado":16, "cidade":"Jardim Alegre", "codigo":4112504},
    {"id":4087, "id_estado":16, "cidade":"Jardim Olinda", "codigo":4112603},
    {"id":4088, "id_estado":16, "cidade":"Jataizinho", "codigo":4112702},
    {"id":4089, "id_estado":16, "cidade":"Jesu\u00edtas", "codigo":4112751},
    {"id":4090, "id_estado":16, "cidade":"Joaquim T\u00e1vora", "codigo":4112801},
    {"id":4091, "id_estado":16, "cidade":"Jundia\u00ed do Sul", "codigo":4112900},
    {"id":4092, "id_estado":16, "cidade":"Juranda", "codigo":4112959},
    {"id":4093, "id_estado":16, "cidade":"Jussara", "codigo":4113007},
    {"id":4094, "id_estado":16, "cidade":"Kalor\u00e9", "codigo":4113106},
    {"id":4095, "id_estado":16, "cidade":"Lapa", "codigo":4113205},
    {"id":4096, "id_estado":16, "cidade":"Laranjal", "codigo":4113254},
    {"id":4097, "id_estado":16, "cidade":"Laranjeiras do Sul", "codigo":4113304},
    {"id":4098, "id_estado":16, "cidade":"Le\u00f3polis", "codigo":4113403},
    {"id":4099, "id_estado":16, "cidade":"Lidian\u00f3polis", "codigo":4113429},
    {"id":4100, "id_estado":16, "cidade":"Lindoeste", "codigo":4113452},
    {"id":4101, "id_estado":16, "cidade":"Loanda", "codigo":4113502},
    {"id":4102, "id_estado":16, "cidade":"Lobato", "codigo":4113601},
    {"id":4103, "id_estado":16, "cidade":"Londrina", "codigo":4113700},
    {"id":4104, "id_estado":16, "cidade":"Luiziana", "codigo":4113734},
    {"id":4105, "id_estado":16, "cidade":"Lunardelli", "codigo":4113759},
    {"id":4106, "id_estado":16, "cidade":"Lupion\u00f3polis", "codigo":4113809},
    {"id":4107, "id_estado":16, "cidade":"Mallet", "codigo":4113908},
    {"id":4108, "id_estado":16, "cidade":"Mambor\u00ea", "codigo":4114005},
    {"id":4109, "id_estado":16, "cidade":"Mandagua\u00e7u", "codigo":4114104},
    {"id":4110, "id_estado":16, "cidade":"Mandaguari", "codigo":4114203},
    {"id":4111, "id_estado":16, "cidade":"Mandirituba", "codigo":4114302},
    {"id":4112, "id_estado":16, "cidade":"Manfrin\u00f3polis", "codigo":4114351},
    {"id":4113, "id_estado":16, "cidade":"Mangueirinha", "codigo":4114401},
    {"id":4114, "id_estado":16, "cidade":"Manoel Ribas", "codigo":4114500},
    {"id":4115, "id_estado":16, "cidade":"Marechal C\u00e2ndido Rondon", "codigo":4114609},
    {"id":4116, "id_estado":16, "cidade":"Maria Helena", "codigo":4114708},
    {"id":4117, "id_estado":16, "cidade":"Marialva", "codigo":4114807},
    {"id":4118, "id_estado":16, "cidade":"Maril\u00e2ndia do Sul", "codigo":4114906},
    {"id":4119, "id_estado":16, "cidade":"Marilena", "codigo":4115002},
    {"id":4120, "id_estado":16, "cidade":"Mariluz", "codigo":4115101},
    {"id":4121, "id_estado":16, "cidade":"Maring\u00e1", "codigo":4115200},
    {"id":4122, "id_estado":16, "cidade":"Mari\u00f3polis", "codigo":4115309},
    {"id":4123, "id_estado":16, "cidade":"Marip\u00e1", "codigo":4115358},
    {"id":4124, "id_estado":16, "cidade":"Marmeleiro", "codigo":4115408},
    {"id":4125, "id_estado":16, "cidade":"Marquinho", "codigo":4115457},
    {"id":4126, "id_estado":16, "cidade":"Marumbi", "codigo":4115507},
    {"id":4127, "id_estado":16, "cidade":"Matel\u00e2ndia", "codigo":4115606},
    {"id":4128, "id_estado":16, "cidade":"Matinhos", "codigo":4115705},
    {"id":4129, "id_estado":16, "cidade":"Mato Rico", "codigo":4115739},
    {"id":4130, "id_estado":16, "cidade":"Mau\u00e1 da Serra", "codigo":4115754},
    {"id":4131, "id_estado":16, "cidade":"Medianeira", "codigo":4115804},
    {"id":4132, "id_estado":16, "cidade":"Mercedes", "codigo":4115853},
    {"id":4133, "id_estado":16, "cidade":"Mirador", "codigo":4115903},
    {"id":4134, "id_estado":16, "cidade":"Miraselva", "codigo":4116000},
    {"id":4135, "id_estado":16, "cidade":"Missal", "codigo":4116059},
    {"id":4136, "id_estado":16, "cidade":"Moreira Sales", "codigo":4116109},
    {"id":4137, "id_estado":16, "cidade":"Morretes", "codigo":4116208},
    {"id":4138, "id_estado":16, "cidade":"Munhoz de Melo", "codigo":4116307},
    {"id":4139, "id_estado":16, "cidade":"Nossa Senhora das Gra\u00e7as", "codigo":4116406},
    {"id":4140, "id_estado":16, "cidade":"Nova Alian\u00e7a do Iva\u00ed", "codigo":4116505},
    {"id":4141, "id_estado":16, "cidade":"Nova Am\u00e9rica da Colina", "codigo":4116604},
    {"id":4142, "id_estado":16, "cidade":"Nova Aurora", "codigo":4116703},
    {"id":4143, "id_estado":16, "cidade":"Nova Cantu", "codigo":4116802},
    {"id":4144, "id_estado":16, "cidade":"Nova Esperan\u00e7a", "codigo":4116901},
    {"id":4145, "id_estado":16, "cidade":"Nova Esperan\u00e7a do Sudoeste", "codigo":4116950},
    {"id":4146, "id_estado":16, "cidade":"Nova F\u00e1tima", "codigo":4117008},
    {"id":4147, "id_estado":16, "cidade":"Nova Laranjeiras", "codigo":4117057},
    {"id":4148, "id_estado":16, "cidade":"Nova Londrina", "codigo":4117107},
    {"id":4149, "id_estado":16, "cidade":"Nova Ol\u00edmpia", "codigo":4117206},
    {"id":4150, "id_estado":16, "cidade":"Nova Santa B\u00e1rbara", "codigo":4117214},
    {"id":4151, "id_estado":16, "cidade":"Nova Santa Rosa", "codigo":4117222},
    {"id":4152, "id_estado":16, "cidade":"Nova Prata do Igua\u00e7u", "codigo":4117255},
    {"id":4153, "id_estado":16, "cidade":"Nova Tebas", "codigo":4117271},
    {"id":4154, "id_estado":16, "cidade":"Novo Itacolomi", "codigo":4117297},
    {"id":4155, "id_estado":16, "cidade":"Ortigueira", "codigo":4117305},
    {"id":4156, "id_estado":16, "cidade":"Ourizona", "codigo":4117404},
    {"id":4157, "id_estado":16, "cidade":"Ouro Verde do Oeste", "codigo":4117453},
    {"id":4158, "id_estado":16, "cidade":"Pai\u00e7andu", "codigo":4117503},
    {"id":4159, "id_estado":16, "cidade":"Palmas", "codigo":4117602},
    {"id":4160, "id_estado":16, "cidade":"Palmeira", "codigo":4117701},
    {"id":4161, "id_estado":16, "cidade":"Palmital", "codigo":4117800},
    {"id":4162, "id_estado":16, "cidade":"Palotina", "codigo":4117909},
    {"id":4163, "id_estado":16, "cidade":"Para\u00edso do Norte", "codigo":4118006},
    {"id":4164, "id_estado":16, "cidade":"Paranacity", "codigo":4118105},
    {"id":4165, "id_estado":16, "cidade":"Paranagu\u00e1", "codigo":4118204},
    {"id":4166, "id_estado":16, "cidade":"Paranapoema", "codigo":4118303},
    {"id":4167, "id_estado":16, "cidade":"Paranava\u00ed", "codigo":4118402},
    {"id":4168, "id_estado":16, "cidade":"Pato Bragado", "codigo":4118451},
    {"id":4169, "id_estado":16, "cidade":"Pato Branco", "codigo":4118501},
    {"id":4170, "id_estado":16, "cidade":"Paula Freitas", "codigo":4118600},
    {"id":4171, "id_estado":16, "cidade":"Paulo Frontin", "codigo":4118709},
    {"id":4172, "id_estado":16, "cidade":"Peabiru", "codigo":4118808},
    {"id":4173, "id_estado":16, "cidade":"Perobal", "codigo":4118857},
    {"id":4174, "id_estado":16, "cidade":"P\u00e9rola", "codigo":4118907},
    {"id":4175, "id_estado":16, "cidade":"P\u00e9rola D'Oeste", "codigo":4119004},
    {"id":4176, "id_estado":16, "cidade":"Pi\u00ean", "codigo":4119103},
    {"id":4177, "id_estado":16, "cidade":"Pinhais", "codigo":4119152},
    {"id":4178, "id_estado":16, "cidade":"Pinhal\u00e3o", "codigo":4119202},
    {"id":4179, "id_estado":16, "cidade":"Pinhal de S\u00e3o Bento", "codigo":4119251},
    {"id":4180, "id_estado":16, "cidade":"Pinh\u00e3o", "codigo":4119301},
    {"id":4181, "id_estado":16, "cidade":"Pira\u00ed do Sul", "codigo":4119400},
    {"id":4182, "id_estado":16, "cidade":"Piraquara", "codigo":4119509},
    {"id":4183, "id_estado":16, "cidade":"Pitanga", "codigo":4119608},
    {"id":4184, "id_estado":16, "cidade":"Pitangueiras", "codigo":4119657},
    {"id":4185, "id_estado":16, "cidade":"Planaltina do Paran\u00e1", "codigo":4119707},
    {"id":4186, "id_estado":16, "cidade":"Planalto", "codigo":4119806},
    {"id":4187, "id_estado":16, "cidade":"Ponta Grossa", "codigo":4119905},
    {"id":4188, "id_estado":16, "cidade":"Pontal do Paran\u00e1", "codigo":4119954},
    {"id":4189, "id_estado":16, "cidade":"Porecatu", "codigo":4120002},
    {"id":4190, "id_estado":16, "cidade":"Porto Amazonas", "codigo":4120101},
    {"id":4191, "id_estado":16, "cidade":"Porto Barreiro", "codigo":4120150},
    {"id":4192, "id_estado":16, "cidade":"Porto Rico", "codigo":4120200},
    {"id":4193, "id_estado":16, "cidade":"Porto Vit\u00f3ria", "codigo":4120309},
    {"id":4194, "id_estado":16, "cidade":"Prado Ferreira", "codigo":4120333},
    {"id":4195, "id_estado":16, "cidade":"Pranchita", "codigo":4120358},
    {"id":4196, "id_estado":16, "cidade":"Presidente Castelo Branco", "codigo":4120408},
    {"id":4197, "id_estado":16, "cidade":"Primeiro de Maio", "codigo":4120507},
    {"id":4198, "id_estado":16, "cidade":"Prudent\u00f3polis", "codigo":4120606},
    {"id":4199, "id_estado":16, "cidade":"Quarto Centen\u00e1rio", "codigo":4120655},
    {"id":4200, "id_estado":16, "cidade":"Quatigu\u00e1", "codigo":4120705},
    {"id":4201, "id_estado":16, "cidade":"Quatro Barras", "codigo":4120804},
    {"id":4202, "id_estado":16, "cidade":"Quatro Pontes", "codigo":4120853},
    {"id":4203, "id_estado":16, "cidade":"Quedas do Igua\u00e7u", "codigo":4120903},
    {"id":4204, "id_estado":16, "cidade":"Quer\u00eancia do Norte", "codigo":4121000},
    {"id":4205, "id_estado":16, "cidade":"Quinta do Sol", "codigo":4121109},
    {"id":4206, "id_estado":16, "cidade":"Quitandinha", "codigo":4121208},
    {"id":4207, "id_estado":16, "cidade":"Ramil\u00e2ndia", "codigo":4121257},
    {"id":4208, "id_estado":16, "cidade":"Rancho Alegre", "codigo":4121307},
    {"id":4209, "id_estado":16, "cidade":"Rancho Alegre D'Oeste", "codigo":4121356},
    {"id":4210, "id_estado":16, "cidade":"Realeza", "codigo":4121406},
    {"id":4211, "id_estado":16, "cidade":"Rebou\u00e7as", "codigo":4121505},
    {"id":4212, "id_estado":16, "cidade":"Renascen\u00e7a", "codigo":4121604},
    {"id":4213, "id_estado":16, "cidade":"Reserva", "codigo":4121703},
    {"id":4214, "id_estado":16, "cidade":"Reserva do Igua\u00e7u", "codigo":4121752},
    {"id":4215, "id_estado":16, "cidade":"Ribeir\u00e3o Claro", "codigo":4121802},
    {"id":4216, "id_estado":16, "cidade":"Ribeir\u00e3o do Pinhal", "codigo":4121901},
    {"id":4217, "id_estado":16, "cidade":"Rio Azul", "codigo":4122008},
    {"id":4218, "id_estado":16, "cidade":"Rio Bom", "codigo":4122107},
    {"id":4219, "id_estado":16, "cidade":"Rio Bonito do Igua\u00e7u", "codigo":4122156},
    {"id":4220, "id_estado":16, "cidade":"Rio Branco do Iva\u00ed", "codigo":4122172},
    {"id":4221, "id_estado":16, "cidade":"Rio Branco do Sul", "codigo":4122206},
    {"id":4222, "id_estado":16, "cidade":"Rio Negro", "codigo":4122305},
    {"id":4223, "id_estado":16, "cidade":"Rol\u00e2ndia", "codigo":4122404},
    {"id":4224, "id_estado":16, "cidade":"Roncador", "codigo":4122503},
    {"id":4225, "id_estado":16, "cidade":"Rondon", "codigo":4122602},
    {"id":4226, "id_estado":16, "cidade":"Ros\u00e1rio do Iva\u00ed", "codigo":4122651},
    {"id":4227, "id_estado":16, "cidade":"Sab\u00e1udia", "codigo":4122701},
    {"id":4228, "id_estado":16, "cidade":"Salgado Filho", "codigo":4122800},
    {"id":4229, "id_estado":16, "cidade":"Salto do Itarar\u00e9", "codigo":4122909},
    {"id":4230, "id_estado":16, "cidade":"Salto do Lontra", "codigo":4123006},
    {"id":4231, "id_estado":16, "cidade":"Santa Am\u00e9lia", "codigo":4123105},
    {"id":4232, "id_estado":16, "cidade":"Santa Cec\u00edlia do Pav\u00e3o", "codigo":4123204},
    {"id":4233, "id_estado":16, "cidade":"Santa Cruz de Monte Castelo", "codigo":4123303},
    {"id":4234, "id_estado":16, "cidade":"Santa F\u00e9", "codigo":4123402},
    {"id":4235, "id_estado":16, "cidade":"Santa Helena", "codigo":4123501},
    {"id":4236, "id_estado":16, "cidade":"Santa In\u00eas", "codigo":4123600},
    {"id":4237, "id_estado":16, "cidade":"Santa Isabel do Iva\u00ed", "codigo":4123709},
    {"id":4238, "id_estado":16, "cidade":"Santa Izabel do Oeste", "codigo":4123808},
    {"id":4239, "id_estado":16, "cidade":"Santa L\u00facia", "codigo":4123824},
    {"id":4240, "id_estado":16, "cidade":"Santa Maria do Oeste", "codigo":4123857},
    {"id":4241, "id_estado":16, "cidade":"Santa Mariana", "codigo":4123907},
    {"id":4242, "id_estado":16, "cidade":"Santa M\u00f4nica", "codigo":4123956},
    {"id":4243, "id_estado":16, "cidade":"Santana do Itarar\u00e9", "codigo":4124004},
    {"id":4244, "id_estado":16, "cidade":"Santa Tereza do Oeste", "codigo":4124020},
    {"id":4245, "id_estado":16, "cidade":"Santa Terezinha de Itaipu", "codigo":4124053},
    {"id":4246, "id_estado":16, "cidade":"Santo Ant\u00f4nio da Platina", "codigo":4124103},
    {"id":4247, "id_estado":16, "cidade":"Santo Ant\u00f4nio do Caiu\u00e1", "codigo":4124202},
    {"id":4248, "id_estado":16, "cidade":"Santo Ant\u00f4nio do Para\u00edso", "codigo":4124301},
    {"id":4249, "id_estado":16, "cidade":"Santo Ant\u00f4nio do Sudoeste", "codigo":4124400},
    {"id":4250, "id_estado":16, "cidade":"Santo In\u00e1cio", "codigo":4124509},
    {"id":4251, "id_estado":16, "cidade":"S\u00e3o Carlos do Iva\u00ed", "codigo":4124608},
    {"id":4252, "id_estado":16, "cidade":"S\u00e3o Jer\u00f4nimo da Serra", "codigo":4124707},
    {"id":4253, "id_estado":16, "cidade":"S\u00e3o Jo\u00e3o", "codigo":4124806},
    {"id":4254, "id_estado":16, "cidade":"S\u00e3o Jo\u00e3o do Caiu\u00e1", "codigo":4124905},
    {"id":4255, "id_estado":16, "cidade":"S\u00e3o Jo\u00e3o do Iva\u00ed", "codigo":4125001},
    {"id":4256, "id_estado":16, "cidade":"S\u00e3o Jo\u00e3o do Triunfo", "codigo":4125100},
    {"id":4257, "id_estado":16, "cidade":"S\u00e3o Jorge D'Oeste", "codigo":4125209},
    {"id":4258, "id_estado":16, "cidade":"S\u00e3o Jorge do Iva\u00ed", "codigo":4125308},
    {"id":4259, "id_estado":16, "cidade":"S\u00e3o Jorge do Patroc\u00ednio", "codigo":4125357},
    {"id":4260, "id_estado":16, "cidade":"S\u00e3o Jos\u00e9 da Boa Vista", "codigo":4125407},
    {"id":4261, "id_estado":16, "cidade":"S\u00e3o Jos\u00e9 das Palmeiras", "codigo":4125456},
    {"id":4262, "id_estado":16, "cidade":"S\u00e3o Jos\u00e9 dos Pinhais", "codigo":4125506},
    {"id":4263, "id_estado":16, "cidade":"S\u00e3o Manoel do Paran\u00e1", "codigo":4125555},
    {"id":4264, "id_estado":16, "cidade":"S\u00e3o Mateus do Sul", "codigo":4125605},
    {"id":4265, "id_estado":16, "cidade":"S\u00e3o Miguel do Igua\u00e7u", "codigo":4125704},
    {"id":4266, "id_estado":16, "cidade":"S\u00e3o Pedro do Igua\u00e7u", "codigo":4125753},
    {"id":4267, "id_estado":16, "cidade":"S\u00e3o Pedro do Iva\u00ed", "codigo":4125803},
    {"id":4268, "id_estado":16, "cidade":"S\u00e3o Pedro do Paran\u00e1", "codigo":4125902},
    {"id":4269, "id_estado":16, "cidade":"S\u00e3o Sebasti\u00e3o da Amoreira", "codigo":4126009},
    {"id":4270, "id_estado":16, "cidade":"S\u00e3o Tom\u00e9", "codigo":4126108},
    {"id":4271, "id_estado":16, "cidade":"Sapopema", "codigo":4126207},
    {"id":4272, "id_estado":16, "cidade":"Sarandi", "codigo":4126256},
    {"id":4273, "id_estado":16, "cidade":"Saudade do Igua\u00e7u", "codigo":4126272},
    {"id":4274, "id_estado":16, "cidade":"Seng\u00e9s", "codigo":4126306},
    {"id":4275, "id_estado":16, "cidade":"Serran\u00f3polis do Igua\u00e7u", "codigo":4126355},
    {"id":4276, "id_estado":16, "cidade":"Sertaneja", "codigo":4126405},
    {"id":4277, "id_estado":16, "cidade":"Sertan\u00f3polis", "codigo":4126504},
    {"id":4278, "id_estado":16, "cidade":"Siqueira Campos", "codigo":4126603},
    {"id":4279, "id_estado":16, "cidade":"Sulina", "codigo":4126652},
    {"id":4280, "id_estado":16, "cidade":"Tamarana", "codigo":4126678},
    {"id":4281, "id_estado":16, "cidade":"Tamboara", "codigo":4126702},
    {"id":4282, "id_estado":16, "cidade":"Tapejara", "codigo":4126801},
    {"id":4283, "id_estado":16, "cidade":"Tapira", "codigo":4126900},
    {"id":4284, "id_estado":16, "cidade":"Teixeira Soares", "codigo":4127007},
    {"id":4285, "id_estado":16, "cidade":"Tel\u00eamaco Borba", "codigo":4127106},
    {"id":4286, "id_estado":16, "cidade":"Terra Boa", "codigo":4127205},
    {"id":4287, "id_estado":16, "cidade":"Terra Rica", "codigo":4127304},
    {"id":4288, "id_estado":16, "cidade":"Terra Roxa", "codigo":4127403},
    {"id":4289, "id_estado":16, "cidade":"Tibagi", "codigo":4127502},
    {"id":4290, "id_estado":16, "cidade":"Tijucas do Sul", "codigo":4127601},
    {"id":4291, "id_estado":16, "cidade":"Toledo", "codigo":4127700},
    {"id":4292, "id_estado":16, "cidade":"Tomazina", "codigo":4127809},
    {"id":4293, "id_estado":16, "cidade":"Tr\u00eas Barras do Paran\u00e1", "codigo":4127858},
    {"id":4294, "id_estado":16, "cidade":"Tunas do Paran\u00e1", "codigo":4127882},
    {"id":4295, "id_estado":16, "cidade":"Tuneiras do Oeste", "codigo":4127908},
    {"id":4296, "id_estado":16, "cidade":"Tup\u00e3ssi", "codigo":4127957},
    {"id":4297, "id_estado":16, "cidade":"Turvo", "codigo":4127965},
    {"id":4298, "id_estado":16, "cidade":"Ubirat\u00e3", "codigo":4128005},
    {"id":4299, "id_estado":16, "cidade":"Umuarama", "codigo":4128104},
    {"id":4300, "id_estado":16, "cidade":"Uni\u00e3o da Vit\u00f3ria", "codigo":4128203},
    {"id":4301, "id_estado":16, "cidade":"Uniflor", "codigo":4128302},
    {"id":4302, "id_estado":16, "cidade":"Ura\u00ed", "codigo":4128401},
    {"id":4303, "id_estado":16, "cidade":"Wenceslau Braz", "codigo":4128500},
    {"id":4304, "id_estado":16, "cidade":"Ventania", "codigo":4128534},
    {"id":4305, "id_estado":16, "cidade":"Vera Cruz do Oeste", "codigo":4128559},
    {"id":4306, "id_estado":16, "cidade":"Ver\u00ea", "codigo":4128609},
    {"id":4307, "id_estado":16, "cidade":"Alto Para\u00edso", "codigo":4128625},
    {"id":4308, "id_estado":16, "cidade":"Doutor Ulysses", "codigo":4128633},
    {"id":4309, "id_estado":16, "cidade":"Virmond", "codigo":4128658},
    {"id":4310, "id_estado":16, "cidade":"Vitorino", "codigo":4128708},
    {"id":4311, "id_estado":16, "cidade":"Xambr\u00ea", "codigo":4128807},
    {"id":4312, "id_estado":24, "cidade":"Abdon Batista", "codigo":4200051},
    {"id":4313, "id_estado":24, "cidade":"Abelardo Luz", "codigo":4200101},
    {"id":4314, "id_estado":24, "cidade":"Agrol\u00e2ndia", "codigo":4200200},
    {"id":4315, "id_estado":24, "cidade":"Agron\u00f4mica", "codigo":4200309},
    {"id":4316, "id_estado":24, "cidade":"\u00c1gua Doce", "codigo":4200408},
    {"id":4317, "id_estado":24, "cidade":"\u00c1guas de Chapec\u00f3", "codigo":4200507},
    {"id":4318, "id_estado":24, "cidade":"\u00c1guas Frias", "codigo":4200556},
    {"id":4319, "id_estado":24, "cidade":"\u00c1guas Mornas", "codigo":4200606},
    {"id":4320, "id_estado":24, "cidade":"Alfredo Wagner", "codigo":4200705},
    {"id":4321, "id_estado":24, "cidade":"Alto Bela Vista", "codigo":4200754},
    {"id":4322, "id_estado":24, "cidade":"Anchieta", "codigo":4200804},
    {"id":4323, "id_estado":24, "cidade":"Angelina", "codigo":4200903},
    {"id":4324, "id_estado":24, "cidade":"Anita Garibaldi", "codigo":4201000},
    {"id":4325, "id_estado":24, "cidade":"Anit\u00e1polis", "codigo":4201109},
    {"id":4326, "id_estado":24, "cidade":"Ant\u00f4nio Carlos", "codigo":4201208},
    {"id":4327, "id_estado":24, "cidade":"Api\u00fana", "codigo":4201257},
    {"id":4328, "id_estado":24, "cidade":"Arabut\u00e3", "codigo":4201273},
    {"id":4329, "id_estado":24, "cidade":"Araquari", "codigo":4201307},
    {"id":4330, "id_estado":24, "cidade":"Ararangu\u00e1", "codigo":4201406},
    {"id":4331, "id_estado":24, "cidade":"Armaz\u00e9m", "codigo":4201505},
    {"id":4332, "id_estado":24, "cidade":"Arroio Trinta", "codigo":4201604},
    {"id":4333, "id_estado":24, "cidade":"Arvoredo", "codigo":4201653},
    {"id":4334, "id_estado":24, "cidade":"Ascurra", "codigo":4201703},
    {"id":4335, "id_estado":24, "cidade":"Atalanta", "codigo":4201802},
    {"id":4336, "id_estado":24, "cidade":"Aurora", "codigo":4201901},
    {"id":4337, "id_estado":24, "cidade":"Balne\u00e1rio Arroio do Silva", "codigo":4201950},
    {"id":4338, "id_estado":24, "cidade":"Balne\u00e1rio Cambori\u00fa", "codigo":4202008},
    {"id":4339, "id_estado":24, "cidade":"Balne\u00e1rio Barra do Sul", "codigo":4202057},
    {"id":4340, "id_estado":24, "cidade":"Balne\u00e1rio Gaivota", "codigo":4202073},
    {"id":4341, "id_estado":24, "cidade":"Bandeirante", "codigo":4202081},
    {"id":4342, "id_estado":24, "cidade":"Barra Bonita", "codigo":4202099},
    {"id":4343, "id_estado":24, "cidade":"Barra Velha", "codigo":4202107},
    {"id":4344, "id_estado":24, "cidade":"Bela Vista do Toldo", "codigo":4202131},
    {"id":4345, "id_estado":24, "cidade":"Belmonte", "codigo":4202156},
    {"id":4346, "id_estado":24, "cidade":"Benedito Novo", "codigo":4202206},
    {"id":4347, "id_estado":24, "cidade":"Bigua\u00e7u", "codigo":4202305},
    {"id":4348, "id_estado":24, "cidade":"Blumenau", "codigo":4202404},
    {"id":4349, "id_estado":24, "cidade":"Bocaina do Sul", "codigo":4202438},
    {"id":4350, "id_estado":24, "cidade":"Bombinhas", "codigo":4202453},
    {"id":4351, "id_estado":24, "cidade":"Bom Jardim da Serra", "codigo":4202503},
    {"id":4352, "id_estado":24, "cidade":"Bom Jesus", "codigo":4202537},
    {"id":4353, "id_estado":24, "cidade":"Bom Jesus do Oeste", "codigo":4202578},
    {"id":4354, "id_estado":24, "cidade":"Bom Retiro", "codigo":4202602},
    {"id":4355, "id_estado":24, "cidade":"Botuver\u00e1", "codigo":4202701},
    {"id":4356, "id_estado":24, "cidade":"Bra\u00e7o do Norte", "codigo":4202800},
    {"id":4357, "id_estado":24, "cidade":"Bra\u00e7o do Trombudo", "codigo":4202859},
    {"id":4358, "id_estado":24, "cidade":"Brun\u00f3polis", "codigo":4202875},
    {"id":4359, "id_estado":24, "cidade":"Brusque", "codigo":4202909},
    {"id":4360, "id_estado":24, "cidade":"Ca\u00e7ador", "codigo":4203006},
    {"id":4361, "id_estado":24, "cidade":"Caibi", "codigo":4203105},
    {"id":4362, "id_estado":24, "cidade":"Calmon", "codigo":4203154},
    {"id":4363, "id_estado":24, "cidade":"Cambori\u00fa", "codigo":4203204},
    {"id":4364, "id_estado":24, "cidade":"Cap\u00e3o Alto", "codigo":4203253},
    {"id":4365, "id_estado":24, "cidade":"Campo Alegre", "codigo":4203303},
    {"id":4366, "id_estado":24, "cidade":"Campo Belo do Sul", "codigo":4203402},
    {"id":4367, "id_estado":24, "cidade":"Campo Er\u00ea", "codigo":4203501},
    {"id":4368, "id_estado":24, "cidade":"Campos Novos", "codigo":4203600},
    {"id":4369, "id_estado":24, "cidade":"Canelinha", "codigo":4203709},
    {"id":4370, "id_estado":24, "cidade":"Canoinhas", "codigo":4203808},
    {"id":4371, "id_estado":24, "cidade":"Capinzal", "codigo":4203907},
    {"id":4372, "id_estado":24, "cidade":"Capivari de Baixo", "codigo":4203956},
    {"id":4373, "id_estado":24, "cidade":"Catanduvas", "codigo":4204004},
    {"id":4374, "id_estado":24, "cidade":"Caxambu do Sul", "codigo":4204103},
    {"id":4375, "id_estado":24, "cidade":"Celso Ramos", "codigo":4204152},
    {"id":4376, "id_estado":24, "cidade":"Cerro Negro", "codigo":4204178},
    {"id":4377, "id_estado":24, "cidade":"Chapad\u00e3o do Lageado", "codigo":4204194},
    {"id":4378, "id_estado":24, "cidade":"Chapec\u00f3", "codigo":4204202},
    {"id":4379, "id_estado":24, "cidade":"Cocal do Sul", "codigo":4204251},
    {"id":4380, "id_estado":24, "cidade":"Conc\u00f3rdia", "codigo":4204301},
    {"id":4381, "id_estado":24, "cidade":"Cordilheira Alta", "codigo":4204350},
    {"id":4382, "id_estado":24, "cidade":"Coronel Freitas", "codigo":4204400},
    {"id":4383, "id_estado":24, "cidade":"Coronel Martins", "codigo":4204459},
    {"id":4384, "id_estado":24, "cidade":"Corup\u00e1", "codigo":4204509},
    {"id":4385, "id_estado":24, "cidade":"Correia Pinto", "codigo":4204558},
    {"id":4386, "id_estado":24, "cidade":"Crici\u00fama", "codigo":4204608},
    {"id":4387, "id_estado":24, "cidade":"Cunha Por\u00e3", "codigo":4204707},
    {"id":4388, "id_estado":24, "cidade":"Cunhata\u00ed", "codigo":4204756},
    {"id":4389, "id_estado":24, "cidade":"Curitibanos", "codigo":4204806},
    {"id":4390, "id_estado":24, "cidade":"Descanso", "codigo":4204905},
    {"id":4391, "id_estado":24, "cidade":"Dion\u00edsio Cerqueira", "codigo":4205001},
    {"id":4392, "id_estado":24, "cidade":"Dona Emma", "codigo":4205100},
    {"id":4393, "id_estado":24, "cidade":"Doutor Pedrinho", "codigo":4205159},
    {"id":4394, "id_estado":24, "cidade":"Entre Rios", "codigo":4205175},
    {"id":4395, "id_estado":24, "cidade":"Ermo", "codigo":4205191},
    {"id":4396, "id_estado":24, "cidade":"Erval Velho", "codigo":4205209},
    {"id":4397, "id_estado":24, "cidade":"Faxinal dos Guedes", "codigo":4205308},
    {"id":4398, "id_estado":24, "cidade":"Flor do Sert\u00e3o", "codigo":4205357},
    {"id":4399, "id_estado":24, "cidade":"Florian\u00f3polis", "codigo":4205407},
    {"id":4400, "id_estado":24, "cidade":"Formosa do Sul", "codigo":4205431},
    {"id":4401, "id_estado":24, "cidade":"Forquilhinha", "codigo":4205456},
    {"id":4402, "id_estado":24, "cidade":"Fraiburgo", "codigo":4205506},
    {"id":4403, "id_estado":24, "cidade":"Frei Rog\u00e9rio", "codigo":4205555},
    {"id":4404, "id_estado":24, "cidade":"Galv\u00e3o", "codigo":4205605},
    {"id":4405, "id_estado":24, "cidade":"Garopaba", "codigo":4205704},
    {"id":4406, "id_estado":24, "cidade":"Garuva", "codigo":4205803},
    {"id":4407, "id_estado":24, "cidade":"Gaspar", "codigo":4205902},
    {"id":4408, "id_estado":24, "cidade":"Governador Celso Ramos", "codigo":4206009},
    {"id":4409, "id_estado":24, "cidade":"Gr\u00e3o Par\u00e1", "codigo":4206108},
    {"id":4410, "id_estado":24, "cidade":"Gravatal", "codigo":4206207},
    {"id":4411, "id_estado":24, "cidade":"Guabiruba", "codigo":4206306},
    {"id":4412, "id_estado":24, "cidade":"Guaraciaba", "codigo":4206405},
    {"id":4413, "id_estado":24, "cidade":"Guaramirim", "codigo":4206504},
    {"id":4414, "id_estado":24, "cidade":"Guaruj\u00e1 do Sul", "codigo":4206603},
    {"id":4415, "id_estado":24, "cidade":"Guatamb\u00fa", "codigo":4206652},
    {"id":4416, "id_estado":24, "cidade":"Herval D'Oeste", "codigo":4206702},
    {"id":4417, "id_estado":24, "cidade":"Ibiam", "codigo":4206751},
    {"id":4418, "id_estado":24, "cidade":"Ibicar\u00e9", "codigo":4206801},
    {"id":4419, "id_estado":24, "cidade":"Ibirama", "codigo":4206900},
    {"id":4420, "id_estado":24, "cidade":"I\u00e7ara", "codigo":4207007},
    {"id":4421, "id_estado":24, "cidade":"Ilhota", "codigo":4207106},
    {"id":4422, "id_estado":24, "cidade":"Imaru\u00ed", "codigo":4207205},
    {"id":4423, "id_estado":24, "cidade":"Imbituba", "codigo":4207304},
    {"id":4424, "id_estado":24, "cidade":"Imbuia", "codigo":4207403},
    {"id":4425, "id_estado":24, "cidade":"Indaial", "codigo":4207502},
    {"id":4426, "id_estado":24, "cidade":"Iomer\u00ea", "codigo":4207577},
    {"id":4427, "id_estado":24, "cidade":"Ipira", "codigo":4207601},
    {"id":4428, "id_estado":24, "cidade":"Ipor\u00e3 do Oeste", "codigo":4207650},
    {"id":4429, "id_estado":24, "cidade":"Ipua\u00e7u", "codigo":4207684},
    {"id":4430, "id_estado":24, "cidade":"Ipumirim", "codigo":4207700},
    {"id":4431, "id_estado":24, "cidade":"Iraceminha", "codigo":4207759},
    {"id":4432, "id_estado":24, "cidade":"Irani", "codigo":4207809},
    {"id":4433, "id_estado":24, "cidade":"Irati", "codigo":4207858},
    {"id":4434, "id_estado":24, "cidade":"Irine\u00f3polis", "codigo":4207908},
    {"id":4435, "id_estado":24, "cidade":"It\u00e1", "codigo":4208005},
    {"id":4436, "id_estado":24, "cidade":"Itai\u00f3polis", "codigo":4208104},
    {"id":4437, "id_estado":24, "cidade":"Itaja\u00ed", "codigo":4208203},
    {"id":4438, "id_estado":24, "cidade":"Itapema", "codigo":4208302},
    {"id":4439, "id_estado":24, "cidade":"Itapiranga", "codigo":4208401},
    {"id":4440, "id_estado":24, "cidade":"Itapo\u00e1", "codigo":4208450},
    {"id":4441, "id_estado":24, "cidade":"Ituporanga", "codigo":4208500},
    {"id":4442, "id_estado":24, "cidade":"Jabor\u00e1", "codigo":4208609},
    {"id":4443, "id_estado":24, "cidade":"Jacinto Machado", "codigo":4208708},
    {"id":4444, "id_estado":24, "cidade":"Jaguaruna", "codigo":4208807},
    {"id":4445, "id_estado":24, "cidade":"Jaragu\u00e1 do Sul", "codigo":4208906},
    {"id":4446, "id_estado":24, "cidade":"Jardin\u00f3polis", "codigo":4208955},
    {"id":4447, "id_estado":24, "cidade":"Joa\u00e7aba", "codigo":4209003},
    {"id":4448, "id_estado":24, "cidade":"Joinville", "codigo":4209102},
    {"id":4449, "id_estado":24, "cidade":"Jos\u00e9 Boiteux", "codigo":4209151},
    {"id":4450, "id_estado":24, "cidade":"Jupi\u00e1", "codigo":4209177},
    {"id":4451, "id_estado":24, "cidade":"Lacerd\u00f3polis", "codigo":4209201},
    {"id":4452, "id_estado":24, "cidade":"Lages", "codigo":4209300},
    {"id":4453, "id_estado":24, "cidade":"Laguna", "codigo":4209409},
    {"id":4454, "id_estado":24, "cidade":"Lajeado Grande", "codigo":4209458},
    {"id":4455, "id_estado":24, "cidade":"Laurentino", "codigo":4209508},
    {"id":4456, "id_estado":24, "cidade":"Lauro Muller", "codigo":4209607},
    {"id":4457, "id_estado":24, "cidade":"Lebon R\u00e9gis", "codigo":4209706},
    {"id":4458, "id_estado":24, "cidade":"Leoberto Leal", "codigo":4209805},
    {"id":4459, "id_estado":24, "cidade":"Lind\u00f3ia do Sul", "codigo":4209854},
    {"id":4460, "id_estado":24, "cidade":"Lontras", "codigo":4209904},
    {"id":4461, "id_estado":24, "cidade":"Luiz Alves", "codigo":4210001},
    {"id":4462, "id_estado":24, "cidade":"Luzerna", "codigo":4210035},
    {"id":4463, "id_estado":24, "cidade":"Macieira", "codigo":4210050},
    {"id":4464, "id_estado":24, "cidade":"Mafra", "codigo":4210100},
    {"id":4465, "id_estado":24, "cidade":"Major Gercino", "codigo":4210209},
    {"id":4466, "id_estado":24, "cidade":"Major Vieira", "codigo":4210308},
    {"id":4467, "id_estado":24, "cidade":"Maracaj\u00e1", "codigo":4210407},
    {"id":4468, "id_estado":24, "cidade":"Maravilha", "codigo":4210506},
    {"id":4469, "id_estado":24, "cidade":"Marema", "codigo":4210555},
    {"id":4470, "id_estado":24, "cidade":"Massaranduba", "codigo":4210605},
    {"id":4471, "id_estado":24, "cidade":"Matos Costa", "codigo":4210704},
    {"id":4472, "id_estado":24, "cidade":"Meleiro", "codigo":4210803},
    {"id":4473, "id_estado":24, "cidade":"Mirim Doce", "codigo":4210852},
    {"id":4474, "id_estado":24, "cidade":"Modelo", "codigo":4210902},
    {"id":4475, "id_estado":24, "cidade":"Monda\u00ed", "codigo":4211009},
    {"id":4476, "id_estado":24, "cidade":"Monte Carlo", "codigo":4211058},
    {"id":4477, "id_estado":24, "cidade":"Monte Castelo", "codigo":4211108},
    {"id":4478, "id_estado":24, "cidade":"Morro da Fuma\u00e7a", "codigo":4211207},
    {"id":4479, "id_estado":24, "cidade":"Morro Grande", "codigo":4211256},
    {"id":4480, "id_estado":24, "cidade":"Navegantes", "codigo":4211306},
    {"id":4481, "id_estado":24, "cidade":"Nova Erechim", "codigo":4211405},
    {"id":4482, "id_estado":24, "cidade":"Nova Itaberaba", "codigo":4211454},
    {"id":4483, "id_estado":24, "cidade":"Nova Trento", "codigo":4211504},
    {"id":4484, "id_estado":24, "cidade":"Nova Veneza", "codigo":4211603},
    {"id":4485, "id_estado":24, "cidade":"Novo Horizonte", "codigo":4211652},
    {"id":4486, "id_estado":24, "cidade":"Orleans", "codigo":4211702},
    {"id":4487, "id_estado":24, "cidade":"Otac\u00edlio Costa", "codigo":4211751},
    {"id":4488, "id_estado":24, "cidade":"Ouro", "codigo":4211801},
    {"id":4489, "id_estado":24, "cidade":"Ouro Verde", "codigo":4211850},
    {"id":4490, "id_estado":24, "cidade":"Paial", "codigo":4211876},
    {"id":4491, "id_estado":24, "cidade":"Painel", "codigo":4211892},
    {"id":4492, "id_estado":24, "cidade":"Palho\u00e7a", "codigo":4211900},
    {"id":4493, "id_estado":24, "cidade":"Palma Sola", "codigo":4212007},
    {"id":4494, "id_estado":24, "cidade":"Palmeira", "codigo":4212056},
    {"id":4495, "id_estado":24, "cidade":"Palmitos", "codigo":4212106},
    {"id":4496, "id_estado":24, "cidade":"Papanduva", "codigo":4212205},
    {"id":4497, "id_estado":24, "cidade":"Para\u00edso", "codigo":4212239},
    {"id":4498, "id_estado":24, "cidade":"Passo de Torres", "codigo":4212254},
    {"id":4499, "id_estado":24, "cidade":"Passos Maia", "codigo":4212270},
    {"id":4500, "id_estado":24, "cidade":"Paulo Lopes", "codigo":4212304},
    {"id":4501, "id_estado":24, "cidade":"Pedras Grandes", "codigo":4212403},
    {"id":4502, "id_estado":24, "cidade":"Penha", "codigo":4212502},
    {"id":4503, "id_estado":24, "cidade":"Peritiba", "codigo":4212601},
    {"id":4504, "id_estado":24, "cidade":"Pescaria Brava", "codigo":4212650},
    {"id":4505, "id_estado":24, "cidade":"Petrol\u00e2ndia", "codigo":4212700},
    {"id":4506, "id_estado":24, "cidade":"Balne\u00e1rio Pi\u00e7arras", "codigo":4212809},
    {"id":4507, "id_estado":24, "cidade":"Pinhalzinho", "codigo":4212908},
    {"id":4508, "id_estado":24, "cidade":"Pinheiro Preto", "codigo":4213005},
    {"id":4509, "id_estado":24, "cidade":"Piratuba", "codigo":4213104},
    {"id":4510, "id_estado":24, "cidade":"Planalto Alegre", "codigo":4213153},
    {"id":4511, "id_estado":24, "cidade":"Pomerode", "codigo":4213203},
    {"id":4512, "id_estado":24, "cidade":"Ponte Alta", "codigo":4213302},
    {"id":4513, "id_estado":24, "cidade":"Ponte Alta do Norte", "codigo":4213351},
    {"id":4514, "id_estado":24, "cidade":"Ponte Serrada", "codigo":4213401},
    {"id":4515, "id_estado":24, "cidade":"Porto Belo", "codigo":4213500},
    {"id":4516, "id_estado":24, "cidade":"Porto Uni\u00e3o", "codigo":4213609},
    {"id":4517, "id_estado":24, "cidade":"Pouso Redondo", "codigo":4213708},
    {"id":4518, "id_estado":24, "cidade":"Praia Grande", "codigo":4213807},
    {"id":4519, "id_estado":24, "cidade":"Presidente Castello Branco", "codigo":4213906},
    {"id":4520, "id_estado":24, "cidade":"Presidente Get\u00falio", "codigo":4214003},
    {"id":4521, "id_estado":24, "cidade":"Presidente Nereu", "codigo":4214102},
    {"id":4522, "id_estado":24, "cidade":"Princesa", "codigo":4214151},
    {"id":4523, "id_estado":24, "cidade":"Quilombo", "codigo":4214201},
    {"id":4524, "id_estado":24, "cidade":"Rancho Queimado", "codigo":4214300},
    {"id":4525, "id_estado":24, "cidade":"Rio das Antas", "codigo":4214409},
    {"id":4526, "id_estado":24, "cidade":"Rio do Campo", "codigo":4214508},
    {"id":4527, "id_estado":24, "cidade":"Rio do Oeste", "codigo":4214607},
    {"id":4528, "id_estado":24, "cidade":"Rio dos Cedros", "codigo":4214706},
    {"id":4529, "id_estado":24, "cidade":"Rio do Sul", "codigo":4214805},
    {"id":4530, "id_estado":24, "cidade":"Rio Fortuna", "codigo":4214904},
    {"id":4531, "id_estado":24, "cidade":"Rio Negrinho", "codigo":4215000},
    {"id":4532, "id_estado":24, "cidade":"Rio Rufino", "codigo":4215059},
    {"id":4533, "id_estado":24, "cidade":"Riqueza", "codigo":4215075},
    {"id":4534, "id_estado":24, "cidade":"Rodeio", "codigo":4215109},
    {"id":4535, "id_estado":24, "cidade":"Romel\u00e2ndia", "codigo":4215208},
    {"id":4536, "id_estado":24, "cidade":"Salete", "codigo":4215307},
    {"id":4537, "id_estado":24, "cidade":"Saltinho", "codigo":4215356},
    {"id":4538, "id_estado":24, "cidade":"Salto Veloso", "codigo":4215406},
    {"id":4539, "id_estado":24, "cidade":"Sang\u00e3o", "codigo":4215455},
    {"id":4540, "id_estado":24, "cidade":"Santa Cec\u00edlia", "codigo":4215505},
    {"id":4541, "id_estado":24, "cidade":"Santa Helena", "codigo":4215554},
    {"id":4542, "id_estado":24, "cidade":"Santa Rosa de Lima", "codigo":4215604},
    {"id":4543, "id_estado":24, "cidade":"Santa Rosa do Sul", "codigo":4215653},
    {"id":4544, "id_estado":24, "cidade":"Santa Terezinha", "codigo":4215679},
    {"id":4545, "id_estado":24, "cidade":"Santa Terezinha do Progresso", "codigo":4215687},
    {"id":4546, "id_estado":24, "cidade":"Santiago do Sul", "codigo":4215695},
    {"id":4547, "id_estado":24, "cidade":"Santo Amaro da Imperatriz", "codigo":4215703},
    {"id":4548, "id_estado":24, "cidade":"S\u00e3o Bernardino", "codigo":4215752},
    {"id":4549, "id_estado":24, "cidade":"S\u00e3o Bento do Sul", "codigo":4215802},
    {"id":4550, "id_estado":24, "cidade":"S\u00e3o Bonif\u00e1cio", "codigo":4215901},
    {"id":4551, "id_estado":24, "cidade":"S\u00e3o Carlos", "codigo":4216008},
    {"id":4552, "id_estado":24, "cidade":"S\u00e3o Cristov\u00e3o do Sul", "codigo":4216057},
    {"id":4553, "id_estado":24, "cidade":"S\u00e3o Domingos", "codigo":4216107},
    {"id":4554, "id_estado":24, "cidade":"S\u00e3o Francisco do Sul", "codigo":4216206},
    {"id":4555, "id_estado":24, "cidade":"S\u00e3o Jo\u00e3o do Oeste", "codigo":4216255},
    {"id":4556, "id_estado":24, "cidade":"S\u00e3o Jo\u00e3o Batista", "codigo":4216305},
    {"id":4557, "id_estado":24, "cidade":"S\u00e3o Jo\u00e3o do Itaperi\u00fa", "codigo":4216354},
    {"id":4558, "id_estado":24, "cidade":"S\u00e3o Jo\u00e3o do Sul", "codigo":4216404},
    {"id":4559, "id_estado":24, "cidade":"S\u00e3o Joaquim", "codigo":4216503},
    {"id":4560, "id_estado":24, "cidade":"S\u00e3o Jos\u00e9", "codigo":4216602},
    {"id":4561, "id_estado":24, "cidade":"S\u00e3o Jos\u00e9 do Cedro", "codigo":4216701},
    {"id":4562, "id_estado":24, "cidade":"S\u00e3o Jos\u00e9 do Cerrito", "codigo":4216800},
    {"id":4563, "id_estado":24, "cidade":"S\u00e3o Louren\u00e7o do Oeste", "codigo":4216909},
    {"id":4564, "id_estado":24, "cidade":"S\u00e3o Ludgero", "codigo":4217006},
    {"id":4565, "id_estado":24, "cidade":"S\u00e3o Martinho", "codigo":4217105},
    {"id":4566, "id_estado":24, "cidade":"S\u00e3o Miguel da Boa Vista", "codigo":4217154},
    {"id":4567, "id_estado":24, "cidade":"S\u00e3o Miguel do Oeste", "codigo":4217204},
    {"id":4568, "id_estado":24, "cidade":"S\u00e3o Pedro de Alc\u00e2ntara", "codigo":4217253},
    {"id":4569, "id_estado":24, "cidade":"Saudades", "codigo":4217303},
    {"id":4570, "id_estado":24, "cidade":"Schroeder", "codigo":4217402},
    {"id":4571, "id_estado":24, "cidade":"Seara", "codigo":4217501},
    {"id":4572, "id_estado":24, "cidade":"Serra Alta", "codigo":4217550},
    {"id":4573, "id_estado":24, "cidade":"Sider\u00f3polis", "codigo":4217600},
    {"id":4574, "id_estado":24, "cidade":"Sombrio", "codigo":4217709},
    {"id":4575, "id_estado":24, "cidade":"Sul Brasil", "codigo":4217758},
    {"id":4576, "id_estado":24, "cidade":"Tai\u00f3", "codigo":4217808},
    {"id":4577, "id_estado":24, "cidade":"Tangar\u00e1", "codigo":4217907},
    {"id":4578, "id_estado":24, "cidade":"Tigrinhos", "codigo":4217956},
    {"id":4579, "id_estado":24, "cidade":"Tijucas", "codigo":4218004},
    {"id":4580, "id_estado":24, "cidade":"Timb\u00e9 do Sul", "codigo":4218103},
    {"id":4581, "id_estado":24, "cidade":"Timb\u00f3", "codigo":4218202},
    {"id":4582, "id_estado":24, "cidade":"Timb\u00f3 Grande", "codigo":4218251},
    {"id":4583, "id_estado":24, "cidade":"Tr\u00eas Barras", "codigo":4218301},
    {"id":4584, "id_estado":24, "cidade":"Treviso", "codigo":4218350},
    {"id":4585, "id_estado":24, "cidade":"Treze de Maio", "codigo":4218400},
    {"id":4586, "id_estado":24, "cidade":"Treze T\u00edlias", "codigo":4218509},
    {"id":4587, "id_estado":24, "cidade":"Trombudo Central", "codigo":4218608},
    {"id":4588, "id_estado":24, "cidade":"Tubar\u00e3o", "codigo":4218707},
    {"id":4589, "id_estado":24, "cidade":"Tun\u00e1polis", "codigo":4218756},
    {"id":4590, "id_estado":24, "cidade":"Turvo", "codigo":4218806},
    {"id":4591, "id_estado":24, "cidade":"Uni\u00e3o do Oeste", "codigo":4218855},
    {"id":4592, "id_estado":24, "cidade":"Urubici", "codigo":4218905},
    {"id":4593, "id_estado":24, "cidade":"Urupema", "codigo":4218954},
    {"id":4594, "id_estado":24, "cidade":"Urussanga", "codigo":4219002},
    {"id":4595, "id_estado":24, "cidade":"Varge\u00e3o", "codigo":4219101},
    {"id":4596, "id_estado":24, "cidade":"Vargem", "codigo":4219150},
    {"id":4597, "id_estado":24, "cidade":"Vargem Bonita", "codigo":4219176},
    {"id":4598, "id_estado":24, "cidade":"Vidal Ramos", "codigo":4219200},
    {"id":4599, "id_estado":24, "cidade":"Videira", "codigo":4219309},
    {"id":4600, "id_estado":24, "cidade":"Vitor Meireles", "codigo":4219358},
    {"id":4601, "id_estado":24, "cidade":"Witmarsum", "codigo":4219408},
    {"id":4602, "id_estado":24, "cidade":"Xanxer\u00ea", "codigo":4219507},
    {"id":4603, "id_estado":24, "cidade":"Xavantina", "codigo":4219606},
    {"id":4604, "id_estado":24, "cidade":"Xaxim", "codigo":4219705},
    {"id":4605, "id_estado":24, "cidade":"Zort\u00e9a", "codigo":4219853},
    {"id":4606, "id_estado":24, "cidade":"Balne\u00e1rio Rinc\u00e3o", "codigo":4220000},
    {"id":4607, "id_estado":21, "cidade":"Acegu\u00e1", "codigo":4300034},
    {"id":4608, "id_estado":21, "cidade":"\u00c1gua Santa", "codigo":4300059},
    {"id":4609, "id_estado":21, "cidade":"Agudo", "codigo":4300109},
    {"id":4610, "id_estado":21, "cidade":"Ajuricaba", "codigo":4300208},
    {"id":4611, "id_estado":21, "cidade":"Alecrim", "codigo":4300307},
    {"id":4612, "id_estado":21, "cidade":"Alegrete", "codigo":4300406},
    {"id":4613, "id_estado":21, "cidade":"Alegria", "codigo":4300455},
    {"id":4614, "id_estado":21, "cidade":"Almirante Tamandar\u00e9 do Sul", "codigo":4300471},
    {"id":4615, "id_estado":21, "cidade":"Alpestre", "codigo":4300505},
    {"id":4616, "id_estado":21, "cidade":"Alto Alegre", "codigo":4300554},
    {"id":4617, "id_estado":21, "cidade":"Alto Feliz", "codigo":4300570},
    {"id":4618, "id_estado":21, "cidade":"Alvorada", "codigo":4300604},
    {"id":4619, "id_estado":21, "cidade":"Amaral Ferrador", "codigo":4300638},
    {"id":4620, "id_estado":21, "cidade":"Ametista do Sul", "codigo":4300646},
    {"id":4621, "id_estado":21, "cidade":"Andr\u00e9 da Rocha", "codigo":4300661},
    {"id":4622, "id_estado":21, "cidade":"Anta Gorda", "codigo":4300703},
    {"id":4623, "id_estado":21, "cidade":"Ant\u00f4nio Prado", "codigo":4300802},
    {"id":4624, "id_estado":21, "cidade":"Arambar\u00e9", "codigo":4300851},
    {"id":4625, "id_estado":21, "cidade":"Araric\u00e1", "codigo":4300877},
    {"id":4626, "id_estado":21, "cidade":"Aratiba", "codigo":4300901},
    {"id":4627, "id_estado":21, "cidade":"Arroio do Meio", "codigo":4301008},
    {"id":4628, "id_estado":21, "cidade":"Arroio do Sal", "codigo":4301057},
    {"id":4629, "id_estado":21, "cidade":"Arroio do Padre", "codigo":4301073},
    {"id":4630, "id_estado":21, "cidade":"Arroio dos Ratos", "codigo":4301107},
    {"id":4631, "id_estado":21, "cidade":"Arroio do Tigre", "codigo":4301206},
    {"id":4632, "id_estado":21, "cidade":"Arroio Grande", "codigo":4301305},
    {"id":4633, "id_estado":21, "cidade":"Arvorezinha", "codigo":4301404},
    {"id":4634, "id_estado":21, "cidade":"Augusto Pestana", "codigo":4301503},
    {"id":4635, "id_estado":21, "cidade":"\u00c1urea", "codigo":4301552},
    {"id":4636, "id_estado":21, "cidade":"Bag\u00e9", "codigo":4301602},
    {"id":4637, "id_estado":21, "cidade":"Balne\u00e1rio Pinhal", "codigo":4301636},
    {"id":4638, "id_estado":21, "cidade":"Bar\u00e3o", "codigo":4301651},
    {"id":4639, "id_estado":21, "cidade":"Bar\u00e3o de Cotegipe", "codigo":4301701},
    {"id":4640, "id_estado":21, "cidade":"Bar\u00e3o do Triunfo", "codigo":4301750},
    {"id":4641, "id_estado":21, "cidade":"Barrac\u00e3o", "codigo":4301800},
    {"id":4642, "id_estado":21, "cidade":"Barra do Guarita", "codigo":4301859},
    {"id":4643, "id_estado":21, "cidade":"Barra do Quara\u00ed", "codigo":4301875},
    {"id":4644, "id_estado":21, "cidade":"Barra do Ribeiro", "codigo":4301909},
    {"id":4645, "id_estado":21, "cidade":"Barra do Rio Azul", "codigo":4301925},
    {"id":4646, "id_estado":21, "cidade":"Barra Funda", "codigo":4301958},
    {"id":4647, "id_estado":21, "cidade":"Barros Cassal", "codigo":4302006},
    {"id":4648, "id_estado":21, "cidade":"Benjamin Constant do Sul", "codigo":4302055},
    {"id":4649, "id_estado":21, "cidade":"Bento Gon\u00e7alves", "codigo":4302105},
    {"id":4650, "id_estado":21, "cidade":"Boa Vista das Miss\u00f5es", "codigo":4302154},
    {"id":4651, "id_estado":21, "cidade":"Boa Vista do Buric\u00e1", "codigo":4302204},
    {"id":4652, "id_estado":21, "cidade":"Boa Vista do Cadeado", "codigo":4302220},
    {"id":4653, "id_estado":21, "cidade":"Boa Vista do Incra", "codigo":4302238},
    {"id":4654, "id_estado":21, "cidade":"Boa Vista do Sul", "codigo":4302253},
    {"id":4655, "id_estado":21, "cidade":"Bom Jesus", "codigo":4302303},
    {"id":4656, "id_estado":21, "cidade":"Bom Princ\u00edpio", "codigo":4302352},
    {"id":4657, "id_estado":21, "cidade":"Bom Progresso", "codigo":4302378},
    {"id":4658, "id_estado":21, "cidade":"Bom Retiro do Sul", "codigo":4302402},
    {"id":4659, "id_estado":21, "cidade":"Boqueir\u00e3o do Le\u00e3o", "codigo":4302451},
    {"id":4660, "id_estado":21, "cidade":"Bossoroca", "codigo":4302501},
    {"id":4661, "id_estado":21, "cidade":"Bozano", "codigo":4302584},
    {"id":4662, "id_estado":21, "cidade":"Braga", "codigo":4302600},
    {"id":4663, "id_estado":21, "cidade":"Brochier", "codigo":4302659},
    {"id":4664, "id_estado":21, "cidade":"Buti\u00e1", "codigo":4302709},
    {"id":4665, "id_estado":21, "cidade":"Ca\u00e7apava do Sul", "codigo":4302808},
    {"id":4666, "id_estado":21, "cidade":"Cacequi", "codigo":4302907},
    {"id":4667, "id_estado":21, "cidade":"Cachoeira do Sul", "codigo":4303004},
    {"id":4668, "id_estado":21, "cidade":"Cachoeirinha", "codigo":4303103},
    {"id":4669, "id_estado":21, "cidade":"Cacique Doble", "codigo":4303202},
    {"id":4670, "id_estado":21, "cidade":"Caibat\u00e9", "codigo":4303301},
    {"id":4671, "id_estado":21, "cidade":"Cai\u00e7ara", "codigo":4303400},
    {"id":4672, "id_estado":21, "cidade":"Camaqu\u00e3", "codigo":4303509},
    {"id":4673, "id_estado":21, "cidade":"Camargo", "codigo":4303558},
    {"id":4674, "id_estado":21, "cidade":"Cambar\u00e1 do Sul", "codigo":4303608},
    {"id":4675, "id_estado":21, "cidade":"Campestre da Serra", "codigo":4303673},
    {"id":4676, "id_estado":21, "cidade":"Campina das Miss\u00f5es", "codigo":4303707},
    {"id":4677, "id_estado":21, "cidade":"Campinas do Sul", "codigo":4303806},
    {"id":4678, "id_estado":21, "cidade":"Campo Bom", "codigo":4303905},
    {"id":4679, "id_estado":21, "cidade":"Campo Novo", "codigo":4304002},
    {"id":4680, "id_estado":21, "cidade":"Campos Borges", "codigo":4304101},
    {"id":4681, "id_estado":21, "cidade":"Candel\u00e1ria", "codigo":4304200},
    {"id":4682, "id_estado":21, "cidade":"C\u00e2ndido God\u00f3i", "codigo":4304309},
    {"id":4683, "id_estado":21, "cidade":"Candiota", "codigo":4304358},
    {"id":4684, "id_estado":21, "cidade":"Canela", "codigo":4304408},
    {"id":4685, "id_estado":21, "cidade":"Cangu\u00e7u", "codigo":4304507},
    {"id":4686, "id_estado":21, "cidade":"Canoas", "codigo":4304606},
    {"id":4687, "id_estado":21, "cidade":"Canudos do Vale", "codigo":4304614},
    {"id":4688, "id_estado":21, "cidade":"Cap\u00e3o Bonito do Sul", "codigo":4304622},
    {"id":4689, "id_estado":21, "cidade":"Cap\u00e3o da Canoa", "codigo":4304630},
    {"id":4690, "id_estado":21, "cidade":"Cap\u00e3o do Cip\u00f3", "codigo":4304655},
    {"id":4691, "id_estado":21, "cidade":"Cap\u00e3o do Le\u00e3o", "codigo":4304663},
    {"id":4692, "id_estado":21, "cidade":"Capivari do Sul", "codigo":4304671},
    {"id":4693, "id_estado":21, "cidade":"Capela de Santana", "codigo":4304689},
    {"id":4694, "id_estado":21, "cidade":"Capit\u00e3o", "codigo":4304697},
    {"id":4695, "id_estado":21, "cidade":"Carazinho", "codigo":4304705},
    {"id":4696, "id_estado":21, "cidade":"Cara\u00e1", "codigo":4304713},
    {"id":4697, "id_estado":21, "cidade":"Carlos Barbosa", "codigo":4304804},
    {"id":4698, "id_estado":21, "cidade":"Carlos Gomes", "codigo":4304853},
    {"id":4699, "id_estado":21, "cidade":"Casca", "codigo":4304903},
    {"id":4700, "id_estado":21, "cidade":"Caseiros", "codigo":4304952},
    {"id":4701, "id_estado":21, "cidade":"Catu\u00edpe", "codigo":4305009},
    {"id":4702, "id_estado":21, "cidade":"Caxias do Sul", "codigo":4305108},
    {"id":4703, "id_estado":21, "cidade":"Centen\u00e1rio", "codigo":4305116},
    {"id":4704, "id_estado":21, "cidade":"Cerrito", "codigo":4305124},
    {"id":4705, "id_estado":21, "cidade":"Cerro Branco", "codigo":4305132},
    {"id":4706, "id_estado":21, "cidade":"Cerro Grande", "codigo":4305157},
    {"id":4707, "id_estado":21, "cidade":"Cerro Grande do Sul", "codigo":4305173},
    {"id":4708, "id_estado":21, "cidade":"Cerro Largo", "codigo":4305207},
    {"id":4709, "id_estado":21, "cidade":"Chapada", "codigo":4305306},
    {"id":4710, "id_estado":21, "cidade":"Charqueadas", "codigo":4305355},
    {"id":4711, "id_estado":21, "cidade":"Charrua", "codigo":4305371},
    {"id":4712, "id_estado":21, "cidade":"Chiapetta", "codigo":4305405},
    {"id":4713, "id_estado":21, "cidade":"Chu\u00ed", "codigo":4305439},
    {"id":4714, "id_estado":21, "cidade":"Chuvisca", "codigo":4305447},
    {"id":4715, "id_estado":21, "cidade":"Cidreira", "codigo":4305454},
    {"id":4716, "id_estado":21, "cidade":"Cir\u00edaco", "codigo":4305504},
    {"id":4717, "id_estado":21, "cidade":"Colinas", "codigo":4305587},
    {"id":4718, "id_estado":21, "cidade":"Colorado", "codigo":4305603},
    {"id":4719, "id_estado":21, "cidade":"Condor", "codigo":4305702},
    {"id":4720, "id_estado":21, "cidade":"Constantina", "codigo":4305801},
    {"id":4721, "id_estado":21, "cidade":"Coqueiro Baixo", "codigo":4305835},
    {"id":4722, "id_estado":21, "cidade":"Coqueiros do Sul", "codigo":4305850},
    {"id":4723, "id_estado":21, "cidade":"Coronel Barros", "codigo":4305871},
    {"id":4724, "id_estado":21, "cidade":"Coronel Bicaco", "codigo":4305900},
    {"id":4725, "id_estado":21, "cidade":"Coronel Pilar", "codigo":4305934},
    {"id":4726, "id_estado":21, "cidade":"Cotipor\u00e3", "codigo":4305959},
    {"id":4727, "id_estado":21, "cidade":"Coxilha", "codigo":4305975},
    {"id":4728, "id_estado":21, "cidade":"Crissiumal", "codigo":4306007},
    {"id":4729, "id_estado":21, "cidade":"Cristal", "codigo":4306056},
    {"id":4730, "id_estado":21, "cidade":"Cristal do Sul", "codigo":4306072},
    {"id":4731, "id_estado":21, "cidade":"Cruz Alta", "codigo":4306106},
    {"id":4732, "id_estado":21, "cidade":"Cruzaltense", "codigo":4306130},
    {"id":4733, "id_estado":21, "cidade":"Cruzeiro do Sul", "codigo":4306205},
    {"id":4734, "id_estado":21, "cidade":"David Canabarro", "codigo":4306304},
    {"id":4735, "id_estado":21, "cidade":"Derrubadas", "codigo":4306320},
    {"id":4736, "id_estado":21, "cidade":"Dezesseis de Novembro", "codigo":4306353},
    {"id":4737, "id_estado":21, "cidade":"Dilermando de Aguiar", "codigo":4306379},
    {"id":4738, "id_estado":21, "cidade":"Dois Irm\u00e3os", "codigo":4306403},
    {"id":4739, "id_estado":21, "cidade":"Dois Irm\u00e3os das Miss\u00f5es", "codigo":4306429},
    {"id":4740, "id_estado":21, "cidade":"Dois Lajeados", "codigo":4306452},
    {"id":4741, "id_estado":21, "cidade":"Dom Feliciano", "codigo":4306502},
    {"id":4742, "id_estado":21, "cidade":"Dom Pedro de Alc\u00e2ntara", "codigo":4306551},
    {"id":4743, "id_estado":21, "cidade":"Dom Pedrito", "codigo":4306601},
    {"id":4744, "id_estado":21, "cidade":"Dona Francisca", "codigo":4306700},
    {"id":4745, "id_estado":21, "cidade":"Doutor Maur\u00edcio Cardoso", "codigo":4306734},
    {"id":4746, "id_estado":21, "cidade":"Doutor Ricardo", "codigo":4306759},
    {"id":4747, "id_estado":21, "cidade":"Eldorado do Sul", "codigo":4306767},
    {"id":4748, "id_estado":21, "cidade":"Encantado", "codigo":4306809},
    {"id":4749, "id_estado":21, "cidade":"Encruzilhada do Sul", "codigo":4306908},
    {"id":4750, "id_estado":21, "cidade":"Engenho Velho", "codigo":4306924},
    {"id":4751, "id_estado":21, "cidade":"Entre-Iju\u00eds", "codigo":4306932},
    {"id":4752, "id_estado":21, "cidade":"Entre Rios do Sul", "codigo":4306957},
    {"id":4753, "id_estado":21, "cidade":"Erebango", "codigo":4306973},
    {"id":4754, "id_estado":21, "cidade":"Erechim", "codigo":4307005},
    {"id":4755, "id_estado":21, "cidade":"Ernestina", "codigo":4307054},
    {"id":4756, "id_estado":21, "cidade":"Herval", "codigo":4307104},
    {"id":4757, "id_estado":21, "cidade":"Erval Grande", "codigo":4307203},
    {"id":4758, "id_estado":21, "cidade":"Erval Seco", "codigo":4307302},
    {"id":4759, "id_estado":21, "cidade":"Esmeralda", "codigo":4307401},
    {"id":4760, "id_estado":21, "cidade":"Esperan\u00e7a do Sul", "codigo":4307450},
    {"id":4761, "id_estado":21, "cidade":"Espumoso", "codigo":4307500},
    {"id":4762, "id_estado":21, "cidade":"Esta\u00e7\u00e3o", "codigo":4307559},
    {"id":4763, "id_estado":21, "cidade":"Est\u00e2ncia Velha", "codigo":4307609},
    {"id":4764, "id_estado":21, "cidade":"Esteio", "codigo":4307708},
    {"id":4765, "id_estado":21, "cidade":"Estrela", "codigo":4307807},
    {"id":4766, "id_estado":21, "cidade":"Estrela Velha", "codigo":4307815},
    {"id":4767, "id_estado":21, "cidade":"Eug\u00eanio de Castro", "codigo":4307831},
    {"id":4768, "id_estado":21, "cidade":"Fagundes Varela", "codigo":4307864},
    {"id":4769, "id_estado":21, "cidade":"Farroupilha", "codigo":4307906},
    {"id":4770, "id_estado":21, "cidade":"Faxinal do Soturno", "codigo":4308003},
    {"id":4771, "id_estado":21, "cidade":"Faxinalzinho", "codigo":4308052},
    {"id":4772, "id_estado":21, "cidade":"Fazenda Vilanova", "codigo":4308078},
    {"id":4773, "id_estado":21, "cidade":"Feliz", "codigo":4308102},
    {"id":4774, "id_estado":21, "cidade":"Flores da Cunha", "codigo":4308201},
    {"id":4775, "id_estado":21, "cidade":"Floriano Peixoto", "codigo":4308250},
    {"id":4776, "id_estado":21, "cidade":"Fontoura Xavier", "codigo":4308300},
    {"id":4777, "id_estado":21, "cidade":"Formigueiro", "codigo":4308409},
    {"id":4778, "id_estado":21, "cidade":"Forquetinha", "codigo":4308433},
    {"id":4779, "id_estado":21, "cidade":"Fortaleza dos Valos", "codigo":4308458},
    {"id":4780, "id_estado":21, "cidade":"Frederico Westphalen", "codigo":4308508},
    {"id":4781, "id_estado":21, "cidade":"Garibaldi", "codigo":4308607},
    {"id":4782, "id_estado":21, "cidade":"Garruchos", "codigo":4308656},
    {"id":4783, "id_estado":21, "cidade":"Gaurama", "codigo":4308706},
    {"id":4784, "id_estado":21, "cidade":"General C\u00e2mara", "codigo":4308805},
    {"id":4785, "id_estado":21, "cidade":"Gentil", "codigo":4308854},
    {"id":4786, "id_estado":21, "cidade":"Get\u00falio Vargas", "codigo":4308904},
    {"id":4787, "id_estado":21, "cidade":"Giru\u00e1", "codigo":4309001},
    {"id":4788, "id_estado":21, "cidade":"Glorinha", "codigo":4309050},
    {"id":4789, "id_estado":21, "cidade":"Gramado", "codigo":4309100},
    {"id":4790, "id_estado":21, "cidade":"Gramado dos Loureiros", "codigo":4309126},
    {"id":4791, "id_estado":21, "cidade":"Gramado Xavier", "codigo":4309159},
    {"id":4792, "id_estado":21, "cidade":"Gravata\u00ed", "codigo":4309209},
    {"id":4793, "id_estado":21, "cidade":"Guabiju", "codigo":4309258},
    {"id":4794, "id_estado":21, "cidade":"Gua\u00edba", "codigo":4309308},
    {"id":4795, "id_estado":21, "cidade":"Guapor\u00e9", "codigo":4309407},
    {"id":4796, "id_estado":21, "cidade":"Guarani das Miss\u00f5es", "codigo":4309506},
    {"id":4797, "id_estado":21, "cidade":"Harmonia", "codigo":4309555},
    {"id":4798, "id_estado":21, "cidade":"Herveiras", "codigo":4309571},
    {"id":4799, "id_estado":21, "cidade":"Horizontina", "codigo":4309605},
    {"id":4800, "id_estado":21, "cidade":"Hulha Negra", "codigo":4309654},
    {"id":4801, "id_estado":21, "cidade":"Humait\u00e1", "codigo":4309704},
    {"id":4802, "id_estado":21, "cidade":"Ibarama", "codigo":4309753},
    {"id":4803, "id_estado":21, "cidade":"Ibia\u00e7\u00e1", "codigo":4309803},
    {"id":4804, "id_estado":21, "cidade":"Ibiraiaras", "codigo":4309902},
    {"id":4805, "id_estado":21, "cidade":"Ibirapuit\u00e3", "codigo":4309951},
    {"id":4806, "id_estado":21, "cidade":"Ibirub\u00e1", "codigo":4310009},
    {"id":4807, "id_estado":21, "cidade":"Igrejinha", "codigo":4310108},
    {"id":4808, "id_estado":21, "cidade":"Iju\u00ed", "codigo":4310207},
    {"id":4809, "id_estado":21, "cidade":"Il\u00f3polis", "codigo":4310306},
    {"id":4810, "id_estado":21, "cidade":"Imb\u00e9", "codigo":4310330},
    {"id":4811, "id_estado":21, "cidade":"Imigrante", "codigo":4310363},
    {"id":4812, "id_estado":21, "cidade":"Independ\u00eancia", "codigo":4310405},
    {"id":4813, "id_estado":21, "cidade":"Inhacor\u00e1", "codigo":4310413},
    {"id":4814, "id_estado":21, "cidade":"Ip\u00ea", "codigo":4310439},
    {"id":4815, "id_estado":21, "cidade":"Ipiranga do Sul", "codigo":4310462},
    {"id":4816, "id_estado":21, "cidade":"Ira\u00ed", "codigo":4310504},
    {"id":4817, "id_estado":21, "cidade":"Itaara", "codigo":4310538},
    {"id":4818, "id_estado":21, "cidade":"Itacurubi", "codigo":4310553},
    {"id":4819, "id_estado":21, "cidade":"Itapuca", "codigo":4310579},
    {"id":4820, "id_estado":21, "cidade":"Itaqui", "codigo":4310603},
    {"id":4821, "id_estado":21, "cidade":"Itati", "codigo":4310652},
    {"id":4822, "id_estado":21, "cidade":"Itatiba do Sul", "codigo":4310702},
    {"id":4823, "id_estado":21, "cidade":"Ivor\u00e1", "codigo":4310751},
    {"id":4824, "id_estado":21, "cidade":"Ivoti", "codigo":4310801},
    {"id":4825, "id_estado":21, "cidade":"Jaboticaba", "codigo":4310850},
    {"id":4826, "id_estado":21, "cidade":"Jacuizinho", "codigo":4310876},
    {"id":4827, "id_estado":21, "cidade":"Jacutinga", "codigo":4310900},
    {"id":4828, "id_estado":21, "cidade":"Jaguar\u00e3o", "codigo":4311007},
    {"id":4829, "id_estado":21, "cidade":"Jaguari", "codigo":4311106},
    {"id":4830, "id_estado":21, "cidade":"Jaquirana", "codigo":4311122},
    {"id":4831, "id_estado":21, "cidade":"Jari", "codigo":4311130},
    {"id":4832, "id_estado":21, "cidade":"J\u00f3ia", "codigo":4311155},
    {"id":4833, "id_estado":21, "cidade":"J\u00falio de Castilhos", "codigo":4311205},
    {"id":4834, "id_estado":21, "cidade":"Lagoa Bonita do Sul", "codigo":4311239},
    {"id":4835, "id_estado":21, "cidade":"Lago\u00e3o", "codigo":4311254},
    {"id":4836, "id_estado":21, "cidade":"Lagoa dos Tr\u00eas Cantos", "codigo":4311270},
    {"id":4837, "id_estado":21, "cidade":"Lagoa Vermelha", "codigo":4311304},
    {"id":4838, "id_estado":21, "cidade":"Lajeado", "codigo":4311403},
    {"id":4839, "id_estado":21, "cidade":"Lajeado do Bugre", "codigo":4311429},
    {"id":4840, "id_estado":21, "cidade":"Lavras do Sul", "codigo":4311502},
    {"id":4841, "id_estado":21, "cidade":"Liberato Salzano", "codigo":4311601},
    {"id":4842, "id_estado":21, "cidade":"Lindolfo Collor", "codigo":4311627},
    {"id":4843, "id_estado":21, "cidade":"Linha Nova", "codigo":4311643},
    {"id":4844, "id_estado":21, "cidade":"Machadinho", "codigo":4311700},
    {"id":4845, "id_estado":21, "cidade":"Ma\u00e7ambar\u00e1", "codigo":4311718},
    {"id":4846, "id_estado":21, "cidade":"Mampituba", "codigo":4311734},
    {"id":4847, "id_estado":21, "cidade":"Manoel Viana", "codigo":4311759},
    {"id":4848, "id_estado":21, "cidade":"Maquin\u00e9", "codigo":4311775},
    {"id":4849, "id_estado":21, "cidade":"Marat\u00e1", "codigo":4311791},
    {"id":4850, "id_estado":21, "cidade":"Marau", "codigo":4311809},
    {"id":4851, "id_estado":21, "cidade":"Marcelino Ramos", "codigo":4311908},
    {"id":4852, "id_estado":21, "cidade":"Mariana Pimentel", "codigo":4311981},
    {"id":4853, "id_estado":21, "cidade":"Mariano Moro", "codigo":4312005},
    {"id":4854, "id_estado":21, "cidade":"Marques de Souza", "codigo":4312054},
    {"id":4855, "id_estado":21, "cidade":"Mata", "codigo":4312104},
    {"id":4856, "id_estado":21, "cidade":"Mato Castelhano", "codigo":4312138},
    {"id":4857, "id_estado":21, "cidade":"Mato Leit\u00e3o", "codigo":4312153},
    {"id":4858, "id_estado":21, "cidade":"Mato Queimado", "codigo":4312179},
    {"id":4859, "id_estado":21, "cidade":"Maximiliano de Almeida", "codigo":4312203},
    {"id":4860, "id_estado":21, "cidade":"Minas do Le\u00e3o", "codigo":4312252},
    {"id":4861, "id_estado":21, "cidade":"Miragua\u00ed", "codigo":4312302},
    {"id":4862, "id_estado":21, "cidade":"Montauri", "codigo":4312351},
    {"id":4863, "id_estado":21, "cidade":"Monte Alegre dos Campos", "codigo":4312377},
    {"id":4864, "id_estado":21, "cidade":"Monte Belo do Sul", "codigo":4312385},
    {"id":4865, "id_estado":21, "cidade":"Montenegro", "codigo":4312401},
    {"id":4866, "id_estado":21, "cidade":"Morma\u00e7o", "codigo":4312427},
    {"id":4867, "id_estado":21, "cidade":"Morrinhos do Sul", "codigo":4312443},
    {"id":4868, "id_estado":21, "cidade":"Morro Redondo", "codigo":4312450},
    {"id":4869, "id_estado":21, "cidade":"Morro Reuter", "codigo":4312476},
    {"id":4870, "id_estado":21, "cidade":"Mostardas", "codigo":4312500},
    {"id":4871, "id_estado":21, "cidade":"Mu\u00e7um", "codigo":4312609},
    {"id":4872, "id_estado":21, "cidade":"Muitos Cap\u00f5es", "codigo":4312617},
    {"id":4873, "id_estado":21, "cidade":"Muliterno", "codigo":4312625},
    {"id":4874, "id_estado":21, "cidade":"N\u00e3o-Me-Toque", "codigo":4312658},
    {"id":4875, "id_estado":21, "cidade":"Nicolau Vergueiro", "codigo":4312674},
    {"id":4876, "id_estado":21, "cidade":"Nonoai", "codigo":4312708},
    {"id":4877, "id_estado":21, "cidade":"Nova Alvorada", "codigo":4312757},
    {"id":4878, "id_estado":21, "cidade":"Nova Ara\u00e7\u00e1", "codigo":4312807},
    {"id":4879, "id_estado":21, "cidade":"Nova Bassano", "codigo":4312906},
    {"id":4880, "id_estado":21, "cidade":"Nova Boa Vista", "codigo":4312955},
    {"id":4881, "id_estado":21, "cidade":"Nova Br\u00e9scia", "codigo":4313003},
    {"id":4882, "id_estado":21, "cidade":"Nova Candel\u00e1ria", "codigo":4313011},
    {"id":4883, "id_estado":21, "cidade":"Nova Esperan\u00e7a do Sul", "codigo":4313037},
    {"id":4884, "id_estado":21, "cidade":"Nova Hartz", "codigo":4313060},
    {"id":4885, "id_estado":21, "cidade":"Nova P\u00e1dua", "codigo":4313086},
    {"id":4886, "id_estado":21, "cidade":"Nova Palma", "codigo":4313102},
    {"id":4887, "id_estado":21, "cidade":"Nova Petr\u00f3polis", "codigo":4313201},
    {"id":4888, "id_estado":21, "cidade":"Nova Prata", "codigo":4313300},
    {"id":4889, "id_estado":21, "cidade":"Nova Ramada", "codigo":4313334},
    {"id":4890, "id_estado":21, "cidade":"Nova Roma do Sul", "codigo":4313359},
    {"id":4891, "id_estado":21, "cidade":"Nova Santa Rita", "codigo":4313375},
    {"id":4892, "id_estado":21, "cidade":"Novo Cabrais", "codigo":4313391},
    {"id":4893, "id_estado":21, "cidade":"Novo Hamburgo", "codigo":4313409},
    {"id":4894, "id_estado":21, "cidade":"Novo Machado", "codigo":4313425},
    {"id":4895, "id_estado":21, "cidade":"Novo Tiradentes", "codigo":4313441},
    {"id":4896, "id_estado":21, "cidade":"Novo Xingu", "codigo":4313466},
    {"id":4897, "id_estado":21, "cidade":"Novo Barreiro", "codigo":4313490},
    {"id":4898, "id_estado":21, "cidade":"Os\u00f3rio", "codigo":4313508},
    {"id":4899, "id_estado":21, "cidade":"Paim Filho", "codigo":4313607},
    {"id":4900, "id_estado":21, "cidade":"Palmares do Sul", "codigo":4313656},
    {"id":4901, "id_estado":21, "cidade":"Palmeira das Miss\u00f5es", "codigo":4313706},
    {"id":4902, "id_estado":21, "cidade":"Palmitinho", "codigo":4313805},
    {"id":4903, "id_estado":21, "cidade":"Panambi", "codigo":4313904},
    {"id":4904, "id_estado":21, "cidade":"Pantano Grande", "codigo":4313953},
    {"id":4905, "id_estado":21, "cidade":"Para\u00ed", "codigo":4314001},
    {"id":4906, "id_estado":21, "cidade":"Para\u00edso do Sul", "codigo":4314027},
    {"id":4907, "id_estado":21, "cidade":"Pareci Novo", "codigo":4314035},
    {"id":4908, "id_estado":21, "cidade":"Parob\u00e9", "codigo":4314050},
    {"id":4909, "id_estado":21, "cidade":"Passa Sete", "codigo":4314068},
    {"id":4910, "id_estado":21, "cidade":"Passo do Sobrado", "codigo":4314076},
    {"id":4911, "id_estado":21, "cidade":"Passo Fundo", "codigo":4314100},
    {"id":4912, "id_estado":21, "cidade":"Paulo Bento", "codigo":4314134},
    {"id":4913, "id_estado":21, "cidade":"Paverama", "codigo":4314159},
    {"id":4914, "id_estado":21, "cidade":"Pedras Altas", "codigo":4314175},
    {"id":4915, "id_estado":21, "cidade":"Pedro Os\u00f3rio", "codigo":4314209},
    {"id":4916, "id_estado":21, "cidade":"Peju\u00e7ara", "codigo":4314308},
    {"id":4917, "id_estado":21, "cidade":"Pelotas", "codigo":4314407},
    {"id":4918, "id_estado":21, "cidade":"Picada Caf\u00e9", "codigo":4314423},
    {"id":4919, "id_estado":21, "cidade":"Pinhal", "codigo":4314456},
    {"id":4920, "id_estado":21, "cidade":"Pinhal da Serra", "codigo":4314464},
    {"id":4921, "id_estado":21, "cidade":"Pinhal Grande", "codigo":4314472},
    {"id":4922, "id_estado":21, "cidade":"Pinheirinho do Vale", "codigo":4314498},
    {"id":4923, "id_estado":21, "cidade":"Pinheiro Machado", "codigo":4314506},
    {"id":4924, "id_estado":21, "cidade":"Pinto Bandeira", "codigo":4314548},
    {"id":4925, "id_estado":21, "cidade":"Pirap\u00f3", "codigo":4314555},
    {"id":4926, "id_estado":21, "cidade":"Piratini", "codigo":4314605},
    {"id":4927, "id_estado":21, "cidade":"Planalto", "codigo":4314704},
    {"id":4928, "id_estado":21, "cidade":"Po\u00e7o das Antas", "codigo":4314753},
    {"id":4929, "id_estado":21, "cidade":"Pont\u00e3o", "codigo":4314779},
    {"id":4930, "id_estado":21, "cidade":"Ponte Preta", "codigo":4314787},
    {"id":4931, "id_estado":21, "cidade":"Port\u00e3o", "codigo":4314803},
    {"id":4932, "id_estado":21, "cidade":"Porto Alegre", "codigo":4314902},
    {"id":4933, "id_estado":21, "cidade":"Porto Lucena", "codigo":4315008},
    {"id":4934, "id_estado":21, "cidade":"Porto Mau\u00e1", "codigo":4315057},
    {"id":4935, "id_estado":21, "cidade":"Porto Vera Cruz", "codigo":4315073},
    {"id":4936, "id_estado":21, "cidade":"Porto Xavier", "codigo":4315107},
    {"id":4937, "id_estado":21, "cidade":"Pouso Novo", "codigo":4315131},
    {"id":4938, "id_estado":21, "cidade":"Presidente Lucena", "codigo":4315149},
    {"id":4939, "id_estado":21, "cidade":"Progresso", "codigo":4315156},
    {"id":4940, "id_estado":21, "cidade":"Prot\u00e1sio Alves", "codigo":4315172},
    {"id":4941, "id_estado":21, "cidade":"Putinga", "codigo":4315206},
    {"id":4942, "id_estado":21, "cidade":"Quara\u00ed", "codigo":4315305},
    {"id":4943, "id_estado":21, "cidade":"Quatro Irm\u00e3os", "codigo":4315313},
    {"id":4944, "id_estado":21, "cidade":"Quevedos", "codigo":4315321},
    {"id":4945, "id_estado":21, "cidade":"Quinze de Novembro", "codigo":4315354},
    {"id":4946, "id_estado":21, "cidade":"Redentora", "codigo":4315404},
    {"id":4947, "id_estado":21, "cidade":"Relvado", "codigo":4315453},
    {"id":4948, "id_estado":21, "cidade":"Restinga Seca", "codigo":4315503},
    {"id":4949, "id_estado":21, "cidade":"Rio dos \u00cdndios", "codigo":4315552},
    {"id":4950, "id_estado":21, "cidade":"Rio Grande", "codigo":4315602},
    {"id":4951, "id_estado":21, "cidade":"Rio Pardo", "codigo":4315701},
    {"id":4952, "id_estado":21, "cidade":"Riozinho", "codigo":4315750},
    {"id":4953, "id_estado":21, "cidade":"Roca Sales", "codigo":4315800},
    {"id":4954, "id_estado":21, "cidade":"Rodeio Bonito", "codigo":4315909},
    {"id":4955, "id_estado":21, "cidade":"Rolador", "codigo":4315958},
    {"id":4956, "id_estado":21, "cidade":"Rolante", "codigo":4316006},
    {"id":4957, "id_estado":21, "cidade":"Ronda Alta", "codigo":4316105},
    {"id":4958, "id_estado":21, "cidade":"Rondinha", "codigo":4316204},
    {"id":4959, "id_estado":21, "cidade":"Roque Gonzales", "codigo":4316303},
    {"id":4960, "id_estado":21, "cidade":"Ros\u00e1rio do Sul", "codigo":4316402},
    {"id":4961, "id_estado":21, "cidade":"Sagrada Fam\u00edlia", "codigo":4316428},
    {"id":4962, "id_estado":21, "cidade":"Saldanha Marinho", "codigo":4316436},
    {"id":4963, "id_estado":21, "cidade":"Salto do Jacu\u00ed", "codigo":4316451},
    {"id":4964, "id_estado":21, "cidade":"Salvador das Miss\u00f5es", "codigo":4316477},
    {"id":4965, "id_estado":21, "cidade":"Salvador do Sul", "codigo":4316501},
    {"id":4966, "id_estado":21, "cidade":"Sananduva", "codigo":4316600},
    {"id":4967, "id_estado":21, "cidade":"Santa B\u00e1rbara do Sul", "codigo":4316709},
    {"id":4968, "id_estado":21, "cidade":"Santa Cec\u00edlia do Sul", "codigo":4316733},
    {"id":4969, "id_estado":21, "cidade":"Santa Clara do Sul", "codigo":4316758},
    {"id":4970, "id_estado":21, "cidade":"Santa Cruz do Sul", "codigo":4316808},
    {"id":4971, "id_estado":21, "cidade":"Santa Maria", "codigo":4316907},
    {"id":4972, "id_estado":21, "cidade":"Santa Maria do Herval", "codigo":4316956},
    {"id":4973, "id_estado":21, "cidade":"Santa Margarida do Sul", "codigo":4316972},
    {"id":4974, "id_estado":21, "cidade":"Santana da Boa Vista", "codigo":4317004},
    {"id":4975, "id_estado":21, "cidade":"Sant'Ana do Livramento", "codigo":4317103},
    {"id":4976, "id_estado":21, "cidade":"Santa Rosa", "codigo":4317202},
    {"id":4977, "id_estado":21, "cidade":"Santa Tereza", "codigo":4317251},
    {"id":4978, "id_estado":21, "cidade":"Santa Vit\u00f3ria do Palmar", "codigo":4317301},
    {"id":4979, "id_estado":21, "cidade":"Santiago", "codigo":4317400},
    {"id":4980, "id_estado":21, "cidade":"Santo \u00c2ngelo", "codigo":4317509},
    {"id":4981, "id_estado":21, "cidade":"Santo Ant\u00f4nio do Palma", "codigo":4317558},
    {"id":4982, "id_estado":21, "cidade":"Santo Ant\u00f4nio da Patrulha", "codigo":4317608},
    {"id":4983, "id_estado":21, "cidade":"Santo Ant\u00f4nio das Miss\u00f5es", "codigo":4317707},
    {"id":4984, "id_estado":21, "cidade":"Santo Ant\u00f4nio do Planalto", "codigo":4317756},
    {"id":4985, "id_estado":21, "cidade":"Santo Augusto", "codigo":4317806},
    {"id":4986, "id_estado":21, "cidade":"Santo Cristo", "codigo":4317905},
    {"id":4987, "id_estado":21, "cidade":"Santo Expedito do Sul", "codigo":4317954},
    {"id":4988, "id_estado":21, "cidade":"S\u00e3o Borja", "codigo":4318002},
    {"id":4989, "id_estado":21, "cidade":"S\u00e3o Domingos do Sul", "codigo":4318051},
    {"id":4990, "id_estado":21, "cidade":"S\u00e3o Francisco de Assis", "codigo":4318101},
    {"id":4991, "id_estado":21, "cidade":"S\u00e3o Francisco de Paula", "codigo":4318200},
    {"id":4992, "id_estado":21, "cidade":"S\u00e3o Gabriel", "codigo":4318309},
    {"id":4993, "id_estado":21, "cidade":"S\u00e3o Jer\u00f4nimo", "codigo":4318408},
    {"id":4994, "id_estado":21, "cidade":"S\u00e3o Jo\u00e3o da Urtiga", "codigo":4318424},
    {"id":4995, "id_estado":21, "cidade":"S\u00e3o Jo\u00e3o do Pol\u00easine", "codigo":4318432},
    {"id":4996, "id_estado":21, "cidade":"S\u00e3o Jorge", "codigo":4318440},
    {"id":4997, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 das Miss\u00f5es", "codigo":4318457},
    {"id":4998, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 do Herval", "codigo":4318465},
    {"id":4999, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 do Hort\u00eancio", "codigo":4318481},
    {"id":5000, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 do Inhacor\u00e1", "codigo":4318499},
    {"id":5001, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 do Norte", "codigo":4318507},
    {"id":5002, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 do Ouro", "codigo":4318606},
    {"id":5003, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 do Sul", "codigo":4318614},
    {"id":5004, "id_estado":21, "cidade":"S\u00e3o Jos\u00e9 dos Ausentes", "codigo":4318622},
    {"id":5005, "id_estado":21, "cidade":"S\u00e3o Leopoldo", "codigo":4318705},
    {"id":5006, "id_estado":21, "cidade":"S\u00e3o Louren\u00e7o do Sul", "codigo":4318804},
    {"id":5007, "id_estado":21, "cidade":"S\u00e3o Luiz Gonzaga", "codigo":4318903},
    {"id":5008, "id_estado":21, "cidade":"S\u00e3o Marcos", "codigo":4319000},
    {"id":5009, "id_estado":21, "cidade":"S\u00e3o Martinho", "codigo":4319109},
    {"id":5010, "id_estado":21, "cidade":"S\u00e3o Martinho da Serra", "codigo":4319125},
    {"id":5011, "id_estado":21, "cidade":"S\u00e3o Miguel das Miss\u00f5es", "codigo":4319158},
    {"id":5012, "id_estado":21, "cidade":"S\u00e3o Nicolau", "codigo":4319208},
    {"id":5013, "id_estado":21, "cidade":"S\u00e3o Paulo das Miss\u00f5es", "codigo":4319307},
    {"id":5014, "id_estado":21, "cidade":"S\u00e3o Pedro da Serra", "codigo":4319356},
    {"id":5015, "id_estado":21, "cidade":"S\u00e3o Pedro das Miss\u00f5es", "codigo":4319364},
    {"id":5016, "id_estado":21, "cidade":"S\u00e3o Pedro do Buti\u00e1", "codigo":4319372},
    {"id":5017, "id_estado":21, "cidade":"S\u00e3o Pedro do Sul", "codigo":4319406},
    {"id":5018, "id_estado":21, "cidade":"S\u00e3o Sebasti\u00e3o do Ca\u00ed", "codigo":4319505},
    {"id":5019, "id_estado":21, "cidade":"S\u00e3o Sep\u00e9", "codigo":4319604},
    {"id":5020, "id_estado":21, "cidade":"S\u00e3o Valentim", "codigo":4319703},
    {"id":5021, "id_estado":21, "cidade":"S\u00e3o Valentim do Sul", "codigo":4319711},
    {"id":5022, "id_estado":21, "cidade":"S\u00e3o Val\u00e9rio do Sul", "codigo":4319737},
    {"id":5023, "id_estado":21, "cidade":"S\u00e3o Vendelino", "codigo":4319752},
    {"id":5024, "id_estado":21, "cidade":"S\u00e3o Vicente do Sul", "codigo":4319802},
    {"id":5025, "id_estado":21, "cidade":"Sapiranga", "codigo":4319901},
    {"id":5026, "id_estado":21, "cidade":"Sapucaia do Sul", "codigo":4320008},
    {"id":5027, "id_estado":21, "cidade":"Sarandi", "codigo":4320107},
    {"id":5028, "id_estado":21, "cidade":"Seberi", "codigo":4320206},
    {"id":5029, "id_estado":21, "cidade":"Sede Nova", "codigo":4320230},
    {"id":5030, "id_estado":21, "cidade":"Segredo", "codigo":4320263},
    {"id":5031, "id_estado":21, "cidade":"Selbach", "codigo":4320305},
    {"id":5032, "id_estado":21, "cidade":"Senador Salgado Filho", "codigo":4320321},
    {"id":5033, "id_estado":21, "cidade":"Sentinela do Sul", "codigo":4320354},
    {"id":5034, "id_estado":21, "cidade":"Serafina Corr\u00eaa", "codigo":4320404},
    {"id":5035, "id_estado":21, "cidade":"S\u00e9rio", "codigo":4320453},
    {"id":5036, "id_estado":21, "cidade":"Sert\u00e3o", "codigo":4320503},
    {"id":5037, "id_estado":21, "cidade":"Sert\u00e3o Santana", "codigo":4320552},
    {"id":5038, "id_estado":21, "cidade":"Sete de Setembro", "codigo":4320578},
    {"id":5039, "id_estado":21, "cidade":"Severiano de Almeida", "codigo":4320602},
    {"id":5040, "id_estado":21, "cidade":"Silveira Martins", "codigo":4320651},
    {"id":5041, "id_estado":21, "cidade":"Sinimbu", "codigo":4320677},
    {"id":5042, "id_estado":21, "cidade":"Sobradinho", "codigo":4320701},
    {"id":5043, "id_estado":21, "cidade":"Soledade", "codigo":4320800},
    {"id":5044, "id_estado":21, "cidade":"Taba\u00ed", "codigo":4320859},
    {"id":5045, "id_estado":21, "cidade":"Tapejara", "codigo":4320909},
    {"id":5046, "id_estado":21, "cidade":"Tapera", "codigo":4321006},
    {"id":5047, "id_estado":21, "cidade":"Tapes", "codigo":4321105},
    {"id":5048, "id_estado":21, "cidade":"Taquara", "codigo":4321204},
    {"id":5049, "id_estado":21, "cidade":"Taquari", "codigo":4321303},
    {"id":5050, "id_estado":21, "cidade":"Taquaru\u00e7u do Sul", "codigo":4321329},
    {"id":5051, "id_estado":21, "cidade":"Tavares", "codigo":4321352},
    {"id":5052, "id_estado":21, "cidade":"Tenente Portela", "codigo":4321402},
    {"id":5053, "id_estado":21, "cidade":"Terra de Areia", "codigo":4321436},
    {"id":5054, "id_estado":21, "cidade":"Teut\u00f4nia", "codigo":4321451},
    {"id":5055, "id_estado":21, "cidade":"Tio Hugo", "codigo":4321469},
    {"id":5056, "id_estado":21, "cidade":"Tiradentes do Sul", "codigo":4321477},
    {"id":5057, "id_estado":21, "cidade":"Toropi", "codigo":4321493},
    {"id":5058, "id_estado":21, "cidade":"Torres", "codigo":4321501},
    {"id":5059, "id_estado":21, "cidade":"Tramanda\u00ed", "codigo":4321600},
    {"id":5060, "id_estado":21, "cidade":"Travesseiro", "codigo":4321626},
    {"id":5061, "id_estado":21, "cidade":"Tr\u00eas Arroios", "codigo":4321634},
    {"id":5062, "id_estado":21, "cidade":"Tr\u00eas Cachoeiras", "codigo":4321667},
    {"id":5063, "id_estado":21, "cidade":"Tr\u00eas Coroas", "codigo":4321709},
    {"id":5064, "id_estado":21, "cidade":"Tr\u00eas de Maio", "codigo":4321808},
    {"id":5065, "id_estado":21, "cidade":"Tr\u00eas Forquilhas", "codigo":4321832},
    {"id":5066, "id_estado":21, "cidade":"Tr\u00eas Palmeiras", "codigo":4321857},
    {"id":5067, "id_estado":21, "cidade":"Tr\u00eas Passos", "codigo":4321907},
    {"id":5068, "id_estado":21, "cidade":"Trindade do Sul", "codigo":4321956},
    {"id":5069, "id_estado":21, "cidade":"Triunfo", "codigo":4322004},
    {"id":5070, "id_estado":21, "cidade":"Tucunduva", "codigo":4322103},
    {"id":5071, "id_estado":21, "cidade":"Tunas", "codigo":4322152},
    {"id":5072, "id_estado":21, "cidade":"Tupanci do Sul", "codigo":4322186},
    {"id":5073, "id_estado":21, "cidade":"Tupanciret\u00e3", "codigo":4322202},
    {"id":5074, "id_estado":21, "cidade":"Tupandi", "codigo":4322251},
    {"id":5075, "id_estado":21, "cidade":"Tuparendi", "codigo":4322301},
    {"id":5076, "id_estado":21, "cidade":"Turu\u00e7u", "codigo":4322327},
    {"id":5077, "id_estado":21, "cidade":"Ubiretama", "codigo":4322343},
    {"id":5078, "id_estado":21, "cidade":"Uni\u00e3o da Serra", "codigo":4322350},
    {"id":5079, "id_estado":21, "cidade":"Unistalda", "codigo":4322376},
    {"id":5080, "id_estado":21, "cidade":"Uruguaiana", "codigo":4322400},
    {"id":5081, "id_estado":21, "cidade":"Vacaria", "codigo":4322509},
    {"id":5082, "id_estado":21, "cidade":"Vale Verde", "codigo":4322525},
    {"id":5083, "id_estado":21, "cidade":"Vale do Sol", "codigo":4322533},
    {"id":5084, "id_estado":21, "cidade":"Vale Real", "codigo":4322541},
    {"id":5085, "id_estado":21, "cidade":"Vanini", "codigo":4322558},
    {"id":5086, "id_estado":21, "cidade":"Ven\u00e2ncio Aires", "codigo":4322608},
    {"id":5087, "id_estado":21, "cidade":"Vera Cruz", "codigo":4322707},
    {"id":5088, "id_estado":21, "cidade":"Veran\u00f3polis", "codigo":4322806},
    {"id":5089, "id_estado":21, "cidade":"Vespasiano Correa", "codigo":4322855},
    {"id":5090, "id_estado":21, "cidade":"Viadutos", "codigo":4322905},
    {"id":5091, "id_estado":21, "cidade":"Viam\u00e3o", "codigo":4323002},
    {"id":5092, "id_estado":21, "cidade":"Vicente Dutra", "codigo":4323101},
    {"id":5093, "id_estado":21, "cidade":"Victor Graeff", "codigo":4323200},
    {"id":5094, "id_estado":21, "cidade":"Vila Flores", "codigo":4323309},
    {"id":5095, "id_estado":21, "cidade":"Vila L\u00e2ngaro", "codigo":4323358},
    {"id":5096, "id_estado":21, "cidade":"Vila Maria", "codigo":4323408},
    {"id":5097, "id_estado":21, "cidade":"Vila Nova do Sul", "codigo":4323457},
    {"id":5098, "id_estado":21, "cidade":"Vista Alegre", "codigo":4323507},
    {"id":5099, "id_estado":21, "cidade":"Vista Alegre do Prata", "codigo":4323606},
    {"id":5100, "id_estado":21, "cidade":"Vista Ga\u00facha", "codigo":4323705},
    {"id":5101, "id_estado":21, "cidade":"Vit\u00f3ria das Miss\u00f5es", "codigo":4323754},
    {"id":5102, "id_estado":21, "cidade":"Westfalia", "codigo":4323770},
    {"id":5103, "id_estado":21, "cidade":"Xangri-l\u00e1", "codigo":4323804},
    {"id":5104, "id_estado":12, "cidade":"\u00c1gua Clara", "codigo":5000203},
    {"id":5105, "id_estado":12, "cidade":"Alcin\u00f3polis", "codigo":5000252},
    {"id":5106, "id_estado":12, "cidade":"Amambai", "codigo":5000609},
    {"id":5107, "id_estado":12, "cidade":"Anast\u00e1cio", "codigo":5000708},
    {"id":5108, "id_estado":12, "cidade":"Anauril\u00e2ndia", "codigo":5000807},
    {"id":5109, "id_estado":12, "cidade":"Ang\u00e9lica", "codigo":5000856},
    {"id":5110, "id_estado":12, "cidade":"Ant\u00f4nio Jo\u00e3o", "codigo":5000906},
    {"id":5111, "id_estado":12, "cidade":"Aparecida do Taboado", "codigo":5001003},
    {"id":5112, "id_estado":12, "cidade":"Aquidauana", "codigo":5001102},
    {"id":5113, "id_estado":12, "cidade":"Aral Moreira", "codigo":5001243},
    {"id":5114, "id_estado":12, "cidade":"Bandeirantes", "codigo":5001508},
    {"id":5115, "id_estado":12, "cidade":"Bataguassu", "codigo":5001904},
    {"id":5116, "id_estado":12, "cidade":"Bataypor\u00e3", "codigo":5002001},
    {"id":5117, "id_estado":12, "cidade":"Bela Vista", "codigo":5002100},
    {"id":5118, "id_estado":12, "cidade":"Bodoquena", "codigo":5002159},
    {"id":5119, "id_estado":12, "cidade":"Bonito", "codigo":5002209},
    {"id":5120, "id_estado":12, "cidade":"Brasil\u00e2ndia", "codigo":5002308},
    {"id":5121, "id_estado":12, "cidade":"Caarap\u00f3", "codigo":5002407},
    {"id":5122, "id_estado":12, "cidade":"Camapu\u00e3", "codigo":5002605},
    {"id":5123, "id_estado":12, "cidade":"Campo Grande", "codigo":5002704},
    {"id":5124, "id_estado":12, "cidade":"Caracol", "codigo":5002803},
    {"id":5125, "id_estado":12, "cidade":"Cassil\u00e2ndia", "codigo":5002902},
    {"id":5126, "id_estado":12, "cidade":"Chapad\u00e3o do Sul", "codigo":5002951},
    {"id":5127, "id_estado":12, "cidade":"Corguinho", "codigo":5003108},
    {"id":5128, "id_estado":12, "cidade":"Coronel Sapucaia", "codigo":5003157},
    {"id":5129, "id_estado":12, "cidade":"Corumb\u00e1", "codigo":5003207},
    {"id":5130, "id_estado":12, "cidade":"Costa Rica", "codigo":5003256},
    {"id":5131, "id_estado":12, "cidade":"Coxim", "codigo":5003306},
    {"id":5132, "id_estado":12, "cidade":"Deod\u00e1polis", "codigo":5003454},
    {"id":5133, "id_estado":12, "cidade":"Dois Irm\u00e3os do Buriti", "codigo":5003488},
    {"id":5134, "id_estado":12, "cidade":"Douradina", "codigo":5003504},
    {"id":5135, "id_estado":12, "cidade":"Dourados", "codigo":5003702},
    {"id":5136, "id_estado":12, "cidade":"Eldorado", "codigo":5003751},
    {"id":5137, "id_estado":12, "cidade":"F\u00e1tima do Sul", "codigo":5003801},
    {"id":5138, "id_estado":12, "cidade":"Figueir\u00e3o", "codigo":5003900},
    {"id":5139, "id_estado":12, "cidade":"Gl\u00f3ria de Dourados", "codigo":5004007},
    {"id":5140, "id_estado":12, "cidade":"Guia Lopes da Laguna", "codigo":5004106},
    {"id":5141, "id_estado":12, "cidade":"Iguatemi", "codigo":5004304},
    {"id":5142, "id_estado":12, "cidade":"Inoc\u00eancia", "codigo":5004403},
    {"id":5143, "id_estado":12, "cidade":"Itapor\u00e3", "codigo":5004502},
    {"id":5144, "id_estado":12, "cidade":"Itaquira\u00ed", "codigo":5004601},
    {"id":5145, "id_estado":12, "cidade":"Ivinhema", "codigo":5004700},
    {"id":5146, "id_estado":12, "cidade":"Japor\u00e3", "codigo":5004809},
    {"id":5147, "id_estado":12, "cidade":"Jaraguari", "codigo":5004908},
    {"id":5148, "id_estado":12, "cidade":"Jardim", "codigo":5005004},
    {"id":5149, "id_estado":12, "cidade":"Jate\u00ed", "codigo":5005103},
    {"id":5150, "id_estado":12, "cidade":"Juti", "codigo":5005152},
    {"id":5151, "id_estado":12, "cidade":"Lad\u00e1rio", "codigo":5005202},
    {"id":5152, "id_estado":12, "cidade":"Laguna Carap\u00e3", "codigo":5005251},
    {"id":5153, "id_estado":12, "cidade":"Maracaju", "codigo":5005400},
    {"id":5154, "id_estado":12, "cidade":"Miranda", "codigo":5005608},
    {"id":5155, "id_estado":12, "cidade":"Mundo Novo", "codigo":5005681},
    {"id":5156, "id_estado":12, "cidade":"Navira\u00ed", "codigo":5005707},
    {"id":5157, "id_estado":12, "cidade":"Nioaque", "codigo":5005806},
    {"id":5158, "id_estado":12, "cidade":"Nova Alvorada do Sul", "codigo":5006002},
    {"id":5159, "id_estado":12, "cidade":"Nova Andradina", "codigo":5006200},
    {"id":5160, "id_estado":12, "cidade":"Novo Horizonte do Sul", "codigo":5006259},
    {"id":5161, "id_estado":12, "cidade":"Para\u00edso das \u00c1guas", "codigo":5006275},
    {"id":5162, "id_estado":12, "cidade":"Parana\u00edba", "codigo":5006309},
    {"id":5163, "id_estado":12, "cidade":"Paranhos", "codigo":5006358},
    {"id":5164, "id_estado":12, "cidade":"Pedro Gomes", "codigo":5006408},
    {"id":5165, "id_estado":12, "cidade":"Ponta Por\u00e3", "codigo":5006606},
    {"id":5166, "id_estado":12, "cidade":"Porto Murtinho", "codigo":5006903},
    {"id":5167, "id_estado":12, "cidade":"Ribas do Rio Pardo", "codigo":5007109},
    {"id":5168, "id_estado":12, "cidade":"Rio Brilhante", "codigo":5007208},
    {"id":5169, "id_estado":12, "cidade":"Rio Negro", "codigo":5007307},
    {"id":5170, "id_estado":12, "cidade":"Rio Verde de Mato Grosso", "codigo":5007406},
    {"id":5171, "id_estado":12, "cidade":"Rochedo", "codigo":5007505},
    {"id":5172, "id_estado":12, "cidade":"Santa Rita do Pardo", "codigo":5007554},
    {"id":5173, "id_estado":12, "cidade":"S\u00e3o Gabriel do Oeste", "codigo":5007695},
    {"id":5174, "id_estado":12, "cidade":"Sete Quedas", "codigo":5007703},
    {"id":5175, "id_estado":12, "cidade":"Selv\u00edria", "codigo":5007802},
    {"id":5176, "id_estado":12, "cidade":"Sidrol\u00e2ndia", "codigo":5007901},
    {"id":5177, "id_estado":12, "cidade":"Sonora", "codigo":5007935},
    {"id":5178, "id_estado":12, "cidade":"Tacuru", "codigo":5007950},
    {"id":5179, "id_estado":12, "cidade":"Taquarussu", "codigo":5007976},
    {"id":5180, "id_estado":12, "cidade":"Terenos", "codigo":5008008},
    {"id":5181, "id_estado":12, "cidade":"Tr\u00eas Lagoas", "codigo":5008305},
    {"id":5182, "id_estado":12, "cidade":"Vicentina", "codigo":5008404},
    {"id":5183, "id_estado":11, "cidade":"Acorizal", "codigo":5100102},
    {"id":5184, "id_estado":11, "cidade":"\u00c1gua Boa", "codigo":5100201},
    {"id":5185, "id_estado":11, "cidade":"Alta Floresta", "codigo":5100250},
    {"id":5186, "id_estado":11, "cidade":"Alto Araguaia", "codigo":5100300},
    {"id":5187, "id_estado":11, "cidade":"Alto Boa Vista", "codigo":5100359},
    {"id":5188, "id_estado":11, "cidade":"Alto Gar\u00e7as", "codigo":5100409},
    {"id":5189, "id_estado":11, "cidade":"Alto Paraguai", "codigo":5100508},
    {"id":5190, "id_estado":11, "cidade":"Alto Taquari", "codigo":5100607},
    {"id":5191, "id_estado":11, "cidade":"Apiac\u00e1s", "codigo":5100805},
    {"id":5192, "id_estado":11, "cidade":"Araguaiana", "codigo":5101001},
    {"id":5193, "id_estado":11, "cidade":"Araguainha", "codigo":5101209},
    {"id":5194, "id_estado":11, "cidade":"Araputanga", "codigo":5101258},
    {"id":5195, "id_estado":11, "cidade":"Aren\u00e1polis", "codigo":5101308},
    {"id":5196, "id_estado":11, "cidade":"Aripuan\u00e3", "codigo":5101407},
    {"id":5197, "id_estado":11, "cidade":"Bar\u00e3o de Melga\u00e7o", "codigo":5101605},
    {"id":5198, "id_estado":11, "cidade":"Barra do Bugres", "codigo":5101704},
    {"id":5199, "id_estado":11, "cidade":"Barra do Gar\u00e7as", "codigo":5101803},
    {"id":5200, "id_estado":11, "cidade":"Bom Jesus do Araguaia", "codigo":5101852},
    {"id":5201, "id_estado":11, "cidade":"Brasnorte", "codigo":5101902},
    {"id":5202, "id_estado":11, "cidade":"C\u00e1ceres", "codigo":5102504},
    {"id":5203, "id_estado":11, "cidade":"Campin\u00e1polis", "codigo":5102603},
    {"id":5204, "id_estado":11, "cidade":"Campo Novo do Parecis", "codigo":5102637},
    {"id":5205, "id_estado":11, "cidade":"Campo Verde", "codigo":5102678},
    {"id":5206, "id_estado":11, "cidade":"Campos de J\u00falio", "codigo":5102686},
    {"id":5207, "id_estado":11, "cidade":"Canabrava do Norte", "codigo":5102694},
    {"id":5208, "id_estado":11, "cidade":"Canarana", "codigo":5102702},
    {"id":5209, "id_estado":11, "cidade":"Carlinda", "codigo":5102793},
    {"id":5210, "id_estado":11, "cidade":"Castanheira", "codigo":5102850},
    {"id":5211, "id_estado":11, "cidade":"Chapada dos Guimar\u00e3es", "codigo":5103007},
    {"id":5212, "id_estado":11, "cidade":"Cl\u00e1udia", "codigo":5103056},
    {"id":5213, "id_estado":11, "cidade":"Cocalinho", "codigo":5103106},
    {"id":5214, "id_estado":11, "cidade":"Col\u00edder", "codigo":5103205},
    {"id":5215, "id_estado":11, "cidade":"Colniza", "codigo":5103254},
    {"id":5216, "id_estado":11, "cidade":"Comodoro", "codigo":5103304},
    {"id":5217, "id_estado":11, "cidade":"Confresa", "codigo":5103353},
    {"id":5218, "id_estado":11, "cidade":"Conquista D'Oeste", "codigo":5103361},
    {"id":5219, "id_estado":11, "cidade":"Cotrigua\u00e7u", "codigo":5103379},
    {"id":5220, "id_estado":11, "cidade":"Cuiab\u00e1", "codigo":5103403},
    {"id":5221, "id_estado":11, "cidade":"Curvel\u00e2ndia", "codigo":5103437},
    {"id":5222, "id_estado":11, "cidade":"Denise", "codigo":5103452},
    {"id":5223, "id_estado":11, "cidade":"Diamantino", "codigo":5103502},
    {"id":5224, "id_estado":11, "cidade":"Dom Aquino", "codigo":5103601},
    {"id":5225, "id_estado":11, "cidade":"Feliz Natal", "codigo":5103700},
    {"id":5226, "id_estado":11, "cidade":"Figueir\u00f3polis D'Oeste", "codigo":5103809},
    {"id":5227, "id_estado":11, "cidade":"Ga\u00facha do Norte", "codigo":5103858},
    {"id":5228, "id_estado":11, "cidade":"General Carneiro", "codigo":5103908},
    {"id":5229, "id_estado":11, "cidade":"Gl\u00f3ria D'Oeste", "codigo":5103957},
    {"id":5230, "id_estado":11, "cidade":"Guarant\u00e3 do Norte", "codigo":5104104},
    {"id":5231, "id_estado":11, "cidade":"Guiratinga", "codigo":5104203},
    {"id":5232, "id_estado":11, "cidade":"Indiava\u00ed", "codigo":5104500},
    {"id":5233, "id_estado":11, "cidade":"Ipiranga do Norte", "codigo":5104526},
    {"id":5234, "id_estado":11, "cidade":"Itanhang\u00e1", "codigo":5104542},
    {"id":5235, "id_estado":11, "cidade":"Ita\u00faba", "codigo":5104559},
    {"id":5236, "id_estado":11, "cidade":"Itiquira", "codigo":5104609},
    {"id":5237, "id_estado":11, "cidade":"Jaciara", "codigo":5104807},
    {"id":5238, "id_estado":11, "cidade":"Jangada", "codigo":5104906},
    {"id":5239, "id_estado":11, "cidade":"Jauru", "codigo":5105002},
    {"id":5240, "id_estado":11, "cidade":"Juara", "codigo":5105101},
    {"id":5241, "id_estado":11, "cidade":"Ju\u00edna", "codigo":5105150},
    {"id":5242, "id_estado":11, "cidade":"Juruena", "codigo":5105176},
    {"id":5243, "id_estado":11, "cidade":"Juscimeira", "codigo":5105200},
    {"id":5244, "id_estado":11, "cidade":"Lambari D'Oeste", "codigo":5105234},
    {"id":5245, "id_estado":11, "cidade":"Lucas do Rio Verde", "codigo":5105259},
    {"id":5246, "id_estado":11, "cidade":"Luciara", "codigo":5105309},
    {"id":5247, "id_estado":11, "cidade":"Vila Bela da Sant\u00edssima Trindade", "codigo":5105507},
    {"id":5248, "id_estado":11, "cidade":"Marcel\u00e2ndia", "codigo":5105580},
    {"id":5249, "id_estado":11, "cidade":"Matup\u00e1", "codigo":5105606},
    {"id":5250, "id_estado":11, "cidade":"Mirassol D'Oeste", "codigo":5105622},
    {"id":5251, "id_estado":11, "cidade":"Nobres", "codigo":5105903},
    {"id":5252, "id_estado":11, "cidade":"Nortel\u00e2ndia", "codigo":5106000},
    {"id":5253, "id_estado":11, "cidade":"Nossa Senhora do Livramento", "codigo":5106109},
    {"id":5254, "id_estado":11, "cidade":"Nova Bandeirantes", "codigo":5106158},
    {"id":5255, "id_estado":11, "cidade":"Nova Nazar\u00e9", "codigo":5106174},
    {"id":5256, "id_estado":11, "cidade":"Nova Lacerda", "codigo":5106182},
    {"id":5257, "id_estado":11, "cidade":"Nova Santa Helena", "codigo":5106190},
    {"id":5258, "id_estado":11, "cidade":"Nova Brasil\u00e2ndia", "codigo":5106208},
    {"id":5259, "id_estado":11, "cidade":"Nova Cana\u00e3 do Norte", "codigo":5106216},
    {"id":5260, "id_estado":11, "cidade":"Nova Mutum", "codigo":5106224},
    {"id":5261, "id_estado":11, "cidade":"Nova Ol\u00edmpia", "codigo":5106232},
    {"id":5262, "id_estado":11, "cidade":"Nova Ubirat\u00e3", "codigo":5106240},
    {"id":5263, "id_estado":11, "cidade":"Nova Xavantina", "codigo":5106257},
    {"id":5264, "id_estado":11, "cidade":"Novo Mundo", "codigo":5106265},
    {"id":5265, "id_estado":11, "cidade":"Novo Horizonte do Norte", "codigo":5106273},
    {"id":5266, "id_estado":11, "cidade":"Novo S\u00e3o Joaquim", "codigo":5106281},
    {"id":5267, "id_estado":11, "cidade":"Parana\u00edta", "codigo":5106299},
    {"id":5268, "id_estado":11, "cidade":"Paranatinga", "codigo":5106307},
    {"id":5269, "id_estado":11, "cidade":"Novo Santo Ant\u00f4nio", "codigo":5106315},
    {"id":5270, "id_estado":11, "cidade":"Pedra Preta", "codigo":5106372},
    {"id":5271, "id_estado":11, "cidade":"Peixoto de Azevedo", "codigo":5106422},
    {"id":5272, "id_estado":11, "cidade":"Planalto da Serra", "codigo":5106455},
    {"id":5273, "id_estado":11, "cidade":"Pocon\u00e9", "codigo":5106505},
    {"id":5274, "id_estado":11, "cidade":"Pontal do Araguaia", "codigo":5106653},
    {"id":5275, "id_estado":11, "cidade":"Ponte Branca", "codigo":5106703},
    {"id":5276, "id_estado":11, "cidade":"Pontes e Lacerda", "codigo":5106752},
    {"id":5277, "id_estado":11, "cidade":"Porto Alegre do Norte", "codigo":5106778},
    {"id":5278, "id_estado":11, "cidade":"Porto dos Ga\u00fachos", "codigo":5106802},
    {"id":5279, "id_estado":11, "cidade":"Porto Esperidi\u00e3o", "codigo":5106828},
    {"id":5280, "id_estado":11, "cidade":"Porto Estrela", "codigo":5106851},
    {"id":5281, "id_estado":11, "cidade":"Poxor\u00e9o", "codigo":5107008},
    {"id":5282, "id_estado":11, "cidade":"Primavera do Leste", "codigo":5107040},
    {"id":5283, "id_estado":11, "cidade":"Quer\u00eancia", "codigo":5107065},
    {"id":5284, "id_estado":11, "cidade":"S\u00e3o Jos\u00e9 dos Quatro Marcos", "codigo":5107107},
    {"id":5285, "id_estado":11, "cidade":"Reserva do Caba\u00e7al", "codigo":5107156},
    {"id":5286, "id_estado":11, "cidade":"Ribeir\u00e3o Cascalheira", "codigo":5107180},
    {"id":5287, "id_estado":11, "cidade":"Ribeir\u00e3ozinho", "codigo":5107198},
    {"id":5288, "id_estado":11, "cidade":"Rio Branco", "codigo":5107206},
    {"id":5289, "id_estado":11, "cidade":"Santa Carmem", "codigo":5107248},
    {"id":5290, "id_estado":11, "cidade":"Santo Afonso", "codigo":5107263},
    {"id":5291, "id_estado":11, "cidade":"S\u00e3o Jos\u00e9 do Povo", "codigo":5107297},
    {"id":5292, "id_estado":11, "cidade":"S\u00e3o Jos\u00e9 do Rio Claro", "codigo":5107305},
    {"id":5293, "id_estado":11, "cidade":"S\u00e3o Jos\u00e9 do Xingu", "codigo":5107354},
    {"id":5294, "id_estado":11, "cidade":"S\u00e3o Pedro da Cipa", "codigo":5107404},
    {"id":5295, "id_estado":11, "cidade":"Rondol\u00e2ndia", "codigo":5107578},
    {"id":5296, "id_estado":11, "cidade":"Rondon\u00f3polis", "codigo":5107602},
    {"id":5297, "id_estado":11, "cidade":"Ros\u00e1rio Oeste", "codigo":5107701},
    {"id":5298, "id_estado":11, "cidade":"Santa Cruz do Xingu", "codigo":5107743},
    {"id":5299, "id_estado":11, "cidade":"Salto do C\u00e9u", "codigo":5107750},
    {"id":5300, "id_estado":11, "cidade":"Santa Rita do Trivelato", "codigo":5107768},
    {"id":5301, "id_estado":11, "cidade":"Santa Terezinha", "codigo":5107776},
    {"id":5302, "id_estado":11, "cidade":"Santo Ant\u00f4nio do Leste", "codigo":5107792},
    {"id":5303, "id_estado":11, "cidade":"Santo Ant\u00f4nio do Leverger", "codigo":5107800},
    {"id":5304, "id_estado":11, "cidade":"S\u00e3o F\u00e9lix do Araguaia", "codigo":5107859},
    {"id":5305, "id_estado":11, "cidade":"Sapezal", "codigo":5107875},
    {"id":5306, "id_estado":11, "cidade":"Serra Nova Dourada", "codigo":5107883},
    {"id":5307, "id_estado":11, "cidade":"Sinop", "codigo":5107909},
    {"id":5308, "id_estado":11, "cidade":"Sorriso", "codigo":5107925},
    {"id":5309, "id_estado":11, "cidade":"Tabapor\u00e3", "codigo":5107941},
    {"id":5310, "id_estado":11, "cidade":"Tangar\u00e1 da Serra", "codigo":5107958},
    {"id":5311, "id_estado":11, "cidade":"Tapurah", "codigo":5108006},
    {"id":5312, "id_estado":11, "cidade":"Terra Nova do Norte", "codigo":5108055},
    {"id":5313, "id_estado":11, "cidade":"Tesouro", "codigo":5108105},
    {"id":5314, "id_estado":11, "cidade":"Torixor\u00e9u", "codigo":5108204},
    {"id":5315, "id_estado":11, "cidade":"Uni\u00e3o do Sul", "codigo":5108303},
    {"id":5316, "id_estado":11, "cidade":"Vale de S\u00e3o Domingos", "codigo":5108352},
    {"id":5317, "id_estado":11, "cidade":"V\u00e1rzea Grande", "codigo":5108402},
    {"id":5318, "id_estado":11, "cidade":"Vera", "codigo":5108501},
    {"id":5319, "id_estado":11, "cidade":"Vila Rica", "codigo":5108600},
    {"id":5320, "id_estado":11, "cidade":"Nova Guarita", "codigo":5108808},
    {"id":5321, "id_estado":11, "cidade":"Nova Maril\u00e2ndia", "codigo":5108857},
    {"id":5322, "id_estado":11, "cidade":"Nova Maring\u00e1", "codigo":5108907},
    {"id":5323, "id_estado":11, "cidade":"Nova Monte Verde", "codigo":5108956},
    {"id":5324, "id_estado":9, "cidade":"Abadia de Goi\u00e1s", "codigo":5200050},
    {"id":5325, "id_estado":9, "cidade":"Abadi\u00e2nia", "codigo":5200100},
    {"id":5326, "id_estado":9, "cidade":"Acre\u00fana", "codigo":5200134},
    {"id":5327, "id_estado":9, "cidade":"Adel\u00e2ndia", "codigo":5200159},
    {"id":5328, "id_estado":9, "cidade":"\u00c1gua Fria de Goi\u00e1s", "codigo":5200175},
    {"id":5329, "id_estado":9, "cidade":"\u00c1gua Limpa", "codigo":5200209},
    {"id":5330, "id_estado":9, "cidade":"\u00c1guas Lindas de Goi\u00e1s", "codigo":5200258},
    {"id":5331, "id_estado":9, "cidade":"Alex\u00e2nia", "codigo":5200308},
    {"id":5332, "id_estado":9, "cidade":"Alo\u00e2ndia", "codigo":5200506},
    {"id":5333, "id_estado":9, "cidade":"Alto Horizonte", "codigo":5200555},
    {"id":5334, "id_estado":9, "cidade":"Alto Para\u00edso de Goi\u00e1s", "codigo":5200605},
    {"id":5335, "id_estado":9, "cidade":"Alvorada do Norte", "codigo":5200803},
    {"id":5336, "id_estado":9, "cidade":"Amaralina", "codigo":5200829},
    {"id":5337, "id_estado":9, "cidade":"Americano do Brasil", "codigo":5200852},
    {"id":5338, "id_estado":9, "cidade":"Amorin\u00f3polis", "codigo":5200902},
    {"id":5339, "id_estado":9, "cidade":"An\u00e1polis", "codigo":5201108},
    {"id":5340, "id_estado":9, "cidade":"Anhanguera", "codigo":5201207},
    {"id":5341, "id_estado":9, "cidade":"Anicuns", "codigo":5201306},
    {"id":5342, "id_estado":9, "cidade":"Aparecida de Goi\u00e2nia", "codigo":5201405},
    {"id":5343, "id_estado":9, "cidade":"Aparecida do Rio Doce", "codigo":5201454},
    {"id":5344, "id_estado":9, "cidade":"Apor\u00e9", "codigo":5201504},
    {"id":5345, "id_estado":9, "cidade":"Ara\u00e7u", "codigo":5201603},
    {"id":5346, "id_estado":9, "cidade":"Aragar\u00e7as", "codigo":5201702},
    {"id":5347, "id_estado":9, "cidade":"Aragoi\u00e2nia", "codigo":5201801},
    {"id":5348, "id_estado":9, "cidade":"Araguapaz", "codigo":5202155},
    {"id":5349, "id_estado":9, "cidade":"Aren\u00f3polis", "codigo":5202353},
    {"id":5350, "id_estado":9, "cidade":"Aruan\u00e3", "codigo":5202502},
    {"id":5351, "id_estado":9, "cidade":"Auril\u00e2ndia", "codigo":5202601},
    {"id":5352, "id_estado":9, "cidade":"Avelin\u00f3polis", "codigo":5202809},
    {"id":5353, "id_estado":9, "cidade":"Baliza", "codigo":5203104},
    {"id":5354, "id_estado":9, "cidade":"Barro Alto", "codigo":5203203},
    {"id":5355, "id_estado":9, "cidade":"Bela Vista de Goi\u00e1s", "codigo":5203302},
    {"id":5356, "id_estado":9, "cidade":"Bom Jardim de Goi\u00e1s", "codigo":5203401},
    {"id":5357, "id_estado":9, "cidade":"Bom Jesus de Goi\u00e1s", "codigo":5203500},
    {"id":5358, "id_estado":9, "cidade":"Bonfin\u00f3polis", "codigo":5203559},
    {"id":5359, "id_estado":9, "cidade":"Bon\u00f3polis", "codigo":5203575},
    {"id":5360, "id_estado":9, "cidade":"Brazabrantes", "codigo":5203609},
    {"id":5361, "id_estado":9, "cidade":"Brit\u00e2nia", "codigo":5203807},
    {"id":5362, "id_estado":9, "cidade":"Buriti Alegre", "codigo":5203906},
    {"id":5363, "id_estado":9, "cidade":"Buriti de Goi\u00e1s", "codigo":5203939},
    {"id":5364, "id_estado":9, "cidade":"Buritin\u00f3polis", "codigo":5203962},
    {"id":5365, "id_estado":9, "cidade":"Cabeceiras", "codigo":5204003},
    {"id":5366, "id_estado":9, "cidade":"Cachoeira Alta", "codigo":5204102},
    {"id":5367, "id_estado":9, "cidade":"Cachoeira de Goi\u00e1s", "codigo":5204201},
    {"id":5368, "id_estado":9, "cidade":"Cachoeira Dourada", "codigo":5204250},
    {"id":5369, "id_estado":9, "cidade":"Ca\u00e7u", "codigo":5204300},
    {"id":5370, "id_estado":9, "cidade":"Caiap\u00f4nia", "codigo":5204409},
    {"id":5371, "id_estado":9, "cidade":"Caldas Novas", "codigo":5204508},
    {"id":5372, "id_estado":9, "cidade":"Caldazinha", "codigo":5204557},
    {"id":5373, "id_estado":9, "cidade":"Campestre de Goi\u00e1s", "codigo":5204607},
    {"id":5374, "id_estado":9, "cidade":"Campina\u00e7u", "codigo":5204656},
    {"id":5375, "id_estado":9, "cidade":"Campinorte", "codigo":5204706},
    {"id":5376, "id_estado":9, "cidade":"Campo Alegre de Goi\u00e1s", "codigo":5204805},
    {"id":5377, "id_estado":9, "cidade":"Campo Limpo de Goi\u00e1s", "codigo":5204854},
    {"id":5378, "id_estado":9, "cidade":"Campos Belos", "codigo":5204904},
    {"id":5379, "id_estado":9, "cidade":"Campos Verdes", "codigo":5204953},
    {"id":5380, "id_estado":9, "cidade":"Carmo do Rio Verde", "codigo":5205000},
    {"id":5381, "id_estado":9, "cidade":"Castel\u00e2ndia", "codigo":5205059},
    {"id":5382, "id_estado":9, "cidade":"Catal\u00e3o", "codigo":5205109},
    {"id":5383, "id_estado":9, "cidade":"Catura\u00ed", "codigo":5205208},
    {"id":5384, "id_estado":9, "cidade":"Cavalcante", "codigo":5205307},
    {"id":5385, "id_estado":9, "cidade":"Ceres", "codigo":5205406},
    {"id":5386, "id_estado":9, "cidade":"Cezarina", "codigo":5205455},
    {"id":5387, "id_estado":9, "cidade":"Chapad\u00e3o do C\u00e9u", "codigo":5205471},
    {"id":5388, "id_estado":9, "cidade":"Cidade Ocidental", "codigo":5205497},
    {"id":5389, "id_estado":9, "cidade":"Cocalzinho de Goi\u00e1s", "codigo":5205513},
    {"id":5390, "id_estado":9, "cidade":"Colinas do Sul", "codigo":5205521},
    {"id":5391, "id_estado":9, "cidade":"C\u00f3rrego do Ouro", "codigo":5205703},
    {"id":5392, "id_estado":9, "cidade":"Corumb\u00e1 de Goi\u00e1s", "codigo":5205802},
    {"id":5393, "id_estado":9, "cidade":"Corumba\u00edba", "codigo":5205901},
    {"id":5394, "id_estado":9, "cidade":"Cristalina", "codigo":5206206},
    {"id":5395, "id_estado":9, "cidade":"Cristian\u00f3polis", "codigo":5206305},
    {"id":5396, "id_estado":9, "cidade":"Crix\u00e1s", "codigo":5206404},
    {"id":5397, "id_estado":9, "cidade":"Crom\u00ednia", "codigo":5206503},
    {"id":5398, "id_estado":9, "cidade":"Cumari", "codigo":5206602},
    {"id":5399, "id_estado":9, "cidade":"Damian\u00f3polis", "codigo":5206701},
    {"id":5400, "id_estado":9, "cidade":"Damol\u00e2ndia", "codigo":5206800},
    {"id":5401, "id_estado":9, "cidade":"Davin\u00f3polis", "codigo":5206909},
    {"id":5402, "id_estado":9, "cidade":"Diorama", "codigo":5207105},
    {"id":5403, "id_estado":9, "cidade":"Doverl\u00e2ndia", "codigo":5207253},
    {"id":5404, "id_estado":9, "cidade":"Edealina", "codigo":5207352},
    {"id":5405, "id_estado":9, "cidade":"Ed\u00e9ia", "codigo":5207402},
    {"id":5406, "id_estado":9, "cidade":"Estrela do Norte", "codigo":5207501},
    {"id":5407, "id_estado":9, "cidade":"Faina", "codigo":5207535},
    {"id":5408, "id_estado":9, "cidade":"Fazenda Nova", "codigo":5207600},
    {"id":5409, "id_estado":9, "cidade":"Firmin\u00f3polis", "codigo":5207808},
    {"id":5410, "id_estado":9, "cidade":"Flores de Goi\u00e1s", "codigo":5207907},
    {"id":5411, "id_estado":9, "cidade":"Formosa", "codigo":5208004},
    {"id":5412, "id_estado":9, "cidade":"Formoso", "codigo":5208103},
    {"id":5413, "id_estado":9, "cidade":"Gameleira de Goi\u00e1s", "codigo":5208152},
    {"id":5414, "id_estado":9, "cidade":"Divin\u00f3polis de Goi\u00e1s", "codigo":5208301},
    {"id":5415, "id_estado":9, "cidade":"Goian\u00e1polis", "codigo":5208400},
    {"id":5416, "id_estado":9, "cidade":"Goiandira", "codigo":5208509},
    {"id":5417, "id_estado":9, "cidade":"Goian\u00e9sia", "codigo":5208608},
    {"id":5418, "id_estado":9, "cidade":"Goi\u00e2nia", "codigo":5208707},
    {"id":5419, "id_estado":9, "cidade":"Goianira", "codigo":5208806},
    {"id":5420, "id_estado":9, "cidade":"Goi\u00e1s", "codigo":5208905},
    {"id":5421, "id_estado":9, "cidade":"Goiatuba", "codigo":5209101},
    {"id":5422, "id_estado":9, "cidade":"Gouvel\u00e2ndia", "codigo":5209150},
    {"id":5423, "id_estado":9, "cidade":"Guap\u00f3", "codigo":5209200},
    {"id":5424, "id_estado":9, "cidade":"Guara\u00edta", "codigo":5209291},
    {"id":5425, "id_estado":9, "cidade":"Guarani de Goi\u00e1s", "codigo":5209408},
    {"id":5426, "id_estado":9, "cidade":"Guarinos", "codigo":5209457},
    {"id":5427, "id_estado":9, "cidade":"Heitora\u00ed", "codigo":5209606},
    {"id":5428, "id_estado":9, "cidade":"Hidrol\u00e2ndia", "codigo":5209705},
    {"id":5429, "id_estado":9, "cidade":"Hidrolina", "codigo":5209804},
    {"id":5430, "id_estado":9, "cidade":"Iaciara", "codigo":5209903},
    {"id":5431, "id_estado":9, "cidade":"Inaciol\u00e2ndia", "codigo":5209937},
    {"id":5432, "id_estado":9, "cidade":"Indiara", "codigo":5209952},
    {"id":5433, "id_estado":9, "cidade":"Inhumas", "codigo":5210000},
    {"id":5434, "id_estado":9, "cidade":"Ipameri", "codigo":5210109},
    {"id":5435, "id_estado":9, "cidade":"Ipiranga de Goi\u00e1s", "codigo":5210158},
    {"id":5436, "id_estado":9, "cidade":"Ipor\u00e1", "codigo":5210208},
    {"id":5437, "id_estado":9, "cidade":"Israel\u00e2ndia", "codigo":5210307},
    {"id":5438, "id_estado":9, "cidade":"Itabera\u00ed", "codigo":5210406},
    {"id":5439, "id_estado":9, "cidade":"Itaguari", "codigo":5210562},
    {"id":5440, "id_estado":9, "cidade":"Itaguaru", "codigo":5210604},
    {"id":5441, "id_estado":9, "cidade":"Itaj\u00e1", "codigo":5210802},
    {"id":5442, "id_estado":9, "cidade":"Itapaci", "codigo":5210901},
    {"id":5443, "id_estado":9, "cidade":"Itapirapu\u00e3", "codigo":5211008},
    {"id":5444, "id_estado":9, "cidade":"Itapuranga", "codigo":5211206},
    {"id":5445, "id_estado":9, "cidade":"Itarum\u00e3", "codigo":5211305},
    {"id":5446, "id_estado":9, "cidade":"Itau\u00e7u", "codigo":5211404},
    {"id":5447, "id_estado":9, "cidade":"Itumbiara", "codigo":5211503},
    {"id":5448, "id_estado":9, "cidade":"Ivol\u00e2ndia", "codigo":5211602},
    {"id":5449, "id_estado":9, "cidade":"Jandaia", "codigo":5211701},
    {"id":5450, "id_estado":9, "cidade":"Jaragu\u00e1", "codigo":5211800},
    {"id":5451, "id_estado":9, "cidade":"Jata\u00ed", "codigo":5211909},
    {"id":5452, "id_estado":9, "cidade":"Jaupaci", "codigo":5212006},
    {"id":5453, "id_estado":9, "cidade":"Jes\u00fapolis", "codigo":5212055},
    {"id":5454, "id_estado":9, "cidade":"Jovi\u00e2nia", "codigo":5212105},
    {"id":5455, "id_estado":9, "cidade":"Jussara", "codigo":5212204},
    {"id":5456, "id_estado":9, "cidade":"Lagoa Santa", "codigo":5212253},
    {"id":5457, "id_estado":9, "cidade":"Leopoldo de Bulh\u00f5es", "codigo":5212303},
    {"id":5458, "id_estado":9, "cidade":"Luzi\u00e2nia", "codigo":5212501},
    {"id":5459, "id_estado":9, "cidade":"Mairipotaba", "codigo":5212600},
    {"id":5460, "id_estado":9, "cidade":"Mamba\u00ed", "codigo":5212709},
    {"id":5461, "id_estado":9, "cidade":"Mara Rosa", "codigo":5212808},
    {"id":5462, "id_estado":9, "cidade":"Marzag\u00e3o", "codigo":5212907},
    {"id":5463, "id_estado":9, "cidade":"Matrinch\u00e3", "codigo":5212956},
    {"id":5464, "id_estado":9, "cidade":"Mauril\u00e2ndia", "codigo":5213004},
    {"id":5465, "id_estado":9, "cidade":"Mimoso de Goi\u00e1s", "codigo":5213053},
    {"id":5466, "id_estado":9, "cidade":"Mina\u00e7u", "codigo":5213087},
    {"id":5467, "id_estado":9, "cidade":"Mineiros", "codigo":5213103},
    {"id":5468, "id_estado":9, "cidade":"Moipor\u00e1", "codigo":5213400},
    {"id":5469, "id_estado":9, "cidade":"Monte Alegre de Goi\u00e1s", "codigo":5213509},
    {"id":5470, "id_estado":9, "cidade":"Montes Claros de Goi\u00e1s", "codigo":5213707},
    {"id":5471, "id_estado":9, "cidade":"Montividiu", "codigo":5213756},
    {"id":5472, "id_estado":9, "cidade":"Montividiu do Norte", "codigo":5213772},
    {"id":5473, "id_estado":9, "cidade":"Morrinhos", "codigo":5213806},
    {"id":5474, "id_estado":9, "cidade":"Morro Agudo de Goi\u00e1s", "codigo":5213855},
    {"id":5475, "id_estado":9, "cidade":"Moss\u00e2medes", "codigo":5213905},
    {"id":5476, "id_estado":9, "cidade":"Mozarl\u00e2ndia", "codigo":5214002},
    {"id":5477, "id_estado":9, "cidade":"Mundo Novo", "codigo":5214051},
    {"id":5478, "id_estado":9, "cidade":"Mutun\u00f3polis", "codigo":5214101},
    {"id":5479, "id_estado":9, "cidade":"Naz\u00e1rio", "codigo":5214408},
    {"id":5480, "id_estado":9, "cidade":"Ner\u00f3polis", "codigo":5214507},
    {"id":5481, "id_estado":9, "cidade":"Niquel\u00e2ndia", "codigo":5214606},
    {"id":5482, "id_estado":9, "cidade":"Nova Am\u00e9rica", "codigo":5214705},
    {"id":5483, "id_estado":9, "cidade":"Nova Aurora", "codigo":5214804},
    {"id":5484, "id_estado":9, "cidade":"Nova Crix\u00e1s", "codigo":5214838},
    {"id":5485, "id_estado":9, "cidade":"Nova Gl\u00f3ria", "codigo":5214861},
    {"id":5486, "id_estado":9, "cidade":"Nova Igua\u00e7u de Goi\u00e1s", "codigo":5214879},
    {"id":5487, "id_estado":9, "cidade":"Nova Roma", "codigo":5214903},
    {"id":5488, "id_estado":9, "cidade":"Nova Veneza", "codigo":5215009},
    {"id":5489, "id_estado":9, "cidade":"Novo Brasil", "codigo":5215207},
    {"id":5490, "id_estado":9, "cidade":"Novo Gama", "codigo":5215231},
    {"id":5491, "id_estado":9, "cidade":"Novo Planalto", "codigo":5215256},
    {"id":5492, "id_estado":9, "cidade":"Orizona", "codigo":5215306},
    {"id":5493, "id_estado":9, "cidade":"Ouro Verde de Goi\u00e1s", "codigo":5215405},
    {"id":5494, "id_estado":9, "cidade":"Ouvidor", "codigo":5215504},
    {"id":5495, "id_estado":9, "cidade":"Padre Bernardo", "codigo":5215603},
    {"id":5496, "id_estado":9, "cidade":"Palestina de Goi\u00e1s", "codigo":5215652},
    {"id":5497, "id_estado":9, "cidade":"Palmeiras de Goi\u00e1s", "codigo":5215702},
    {"id":5498, "id_estado":9, "cidade":"Palmelo", "codigo":5215801},
    {"id":5499, "id_estado":9, "cidade":"Palmin\u00f3polis", "codigo":5215900},
    {"id":5500, "id_estado":9, "cidade":"Panam\u00e1", "codigo":5216007},
    {"id":5501, "id_estado":9, "cidade":"Paranaiguara", "codigo":5216304},
    {"id":5502, "id_estado":9, "cidade":"Para\u00fana", "codigo":5216403},
    {"id":5503, "id_estado":9, "cidade":"Perol\u00e2ndia", "codigo":5216452},
    {"id":5504, "id_estado":9, "cidade":"Petrolina de Goi\u00e1s", "codigo":5216809},
    {"id":5505, "id_estado":9, "cidade":"Pilar de Goi\u00e1s", "codigo":5216908},
    {"id":5506, "id_estado":9, "cidade":"Piracanjuba", "codigo":5217104},
    {"id":5507, "id_estado":9, "cidade":"Piranhas", "codigo":5217203},
    {"id":5508, "id_estado":9, "cidade":"Piren\u00f3polis", "codigo":5217302},
    {"id":5509, "id_estado":9, "cidade":"Pires do Rio", "codigo":5217401},
    {"id":5510, "id_estado":9, "cidade":"Planaltina", "codigo":5217609},
    {"id":5511, "id_estado":9, "cidade":"Pontalina", "codigo":5217708},
    {"id":5512, "id_estado":9, "cidade":"Porangatu", "codigo":5218003},
    {"id":5513, "id_estado":9, "cidade":"Porteir\u00e3o", "codigo":5218052},
    {"id":5514, "id_estado":9, "cidade":"Portel\u00e2ndia", "codigo":5218102},
    {"id":5515, "id_estado":9, "cidade":"Posse", "codigo":5218300},
    {"id":5516, "id_estado":9, "cidade":"Professor Jamil", "codigo":5218391},
    {"id":5517, "id_estado":9, "cidade":"Quirin\u00f3polis", "codigo":5218508},
    {"id":5518, "id_estado":9, "cidade":"Rialma", "codigo":5218607},
    {"id":5519, "id_estado":9, "cidade":"Rian\u00e1polis", "codigo":5218706},
    {"id":5520, "id_estado":9, "cidade":"Rio Quente", "codigo":5218789},
    {"id":5521, "id_estado":9, "cidade":"Rio Verde", "codigo":5218805},
    {"id":5522, "id_estado":9, "cidade":"Rubiataba", "codigo":5218904},
    {"id":5523, "id_estado":9, "cidade":"Sanclerl\u00e2ndia", "codigo":5219001},
    {"id":5524, "id_estado":9, "cidade":"Santa B\u00e1rbara de Goi\u00e1s", "codigo":5219100},
    {"id":5525, "id_estado":9, "cidade":"Santa Cruz de Goi\u00e1s", "codigo":5219209},
    {"id":5526, "id_estado":9, "cidade":"Santa F\u00e9 de Goi\u00e1s", "codigo":5219258},
    {"id":5527, "id_estado":9, "cidade":"Santa Helena de Goi\u00e1s", "codigo":5219308},
    {"id":5528, "id_estado":9, "cidade":"Santa Isabel", "codigo":5219357},
    {"id":5529, "id_estado":9, "cidade":"Santa Rita do Araguaia", "codigo":5219407},
    {"id":5530, "id_estado":9, "cidade":"Santa Rita do Novo Destino", "codigo":5219456},
    {"id":5531, "id_estado":9, "cidade":"Santa Rosa de Goi\u00e1s", "codigo":5219506},
    {"id":5532, "id_estado":9, "cidade":"Santa Tereza de Goi\u00e1s", "codigo":5219605},
    {"id":5533, "id_estado":9, "cidade":"Santa Terezinha de Goi\u00e1s", "codigo":5219704},
    {"id":5534, "id_estado":9, "cidade":"Santo Ant\u00f4nio da Barra", "codigo":5219712},
    {"id":5535, "id_estado":9, "cidade":"Santo Ant\u00f4nio de Goi\u00e1s", "codigo":5219738},
    {"id":5536, "id_estado":9, "cidade":"Santo Ant\u00f4nio do Descoberto", "codigo":5219753},
    {"id":5537, "id_estado":9, "cidade":"S\u00e3o Domingos", "codigo":5219803},
    {"id":5538, "id_estado":9, "cidade":"S\u00e3o Francisco de Goi\u00e1s", "codigo":5219902},
    {"id":5539, "id_estado":9, "cidade":"S\u00e3o Jo\u00e3o D'Alian\u00e7a", "codigo":5220009},
    {"id":5540, "id_estado":9, "cidade":"S\u00e3o Jo\u00e3o da Para\u00fana", "codigo":5220058},
    {"id":5541, "id_estado":9, "cidade":"S\u00e3o Lu\u00eds de Montes Belos", "codigo":5220108},
    {"id":5542, "id_estado":9, "cidade":"S\u00e3o Lu\u00edz do Norte", "codigo":5220157},
    {"id":5543, "id_estado":9, "cidade":"S\u00e3o Miguel do Araguaia", "codigo":5220207},
    {"id":5544, "id_estado":9, "cidade":"S\u00e3o Miguel do Passa Quatro", "codigo":5220264},
    {"id":5545, "id_estado":9, "cidade":"S\u00e3o Patr\u00edcio", "codigo":5220280},
    {"id":5546, "id_estado":9, "cidade":"S\u00e3o Sim\u00e3o", "codigo":5220405},
    {"id":5547, "id_estado":9, "cidade":"Senador Canedo", "codigo":5220454},
    {"id":5548, "id_estado":9, "cidade":"Serran\u00f3polis", "codigo":5220504},
    {"id":5549, "id_estado":9, "cidade":"Silv\u00e2nia", "codigo":5220603},
    {"id":5550, "id_estado":9, "cidade":"Simol\u00e2ndia", "codigo":5220686},
    {"id":5551, "id_estado":9, "cidade":"S\u00edtio D'Abadia", "codigo":5220702},
    {"id":5552, "id_estado":9, "cidade":"Taquaral de Goi\u00e1s", "codigo":5221007},
    {"id":5553, "id_estado":9, "cidade":"Teresina de Goi\u00e1s", "codigo":5221080},
    {"id":5554, "id_estado":9, "cidade":"Terez\u00f3polis de Goi\u00e1s", "codigo":5221197},
    {"id":5555, "id_estado":9, "cidade":"Tr\u00eas Ranchos", "codigo":5221304},
    {"id":5556, "id_estado":9, "cidade":"Trindade", "codigo":5221403},
    {"id":5557, "id_estado":9, "cidade":"Trombas", "codigo":5221452},
    {"id":5558, "id_estado":9, "cidade":"Turv\u00e2nia", "codigo":5221502},
    {"id":5559, "id_estado":9, "cidade":"Turvel\u00e2ndia", "codigo":5221551},
    {"id":5560, "id_estado":9, "cidade":"Uirapuru", "codigo":5221577},
    {"id":5561, "id_estado":9, "cidade":"Urua\u00e7u", "codigo":5221601},
    {"id":5562, "id_estado":9, "cidade":"Uruana", "codigo":5221700},
    {"id":5563, "id_estado":9, "cidade":"Uruta\u00ed", "codigo":5221809},
    {"id":5564, "id_estado":9, "cidade":"Valpara\u00edso de Goi\u00e1s", "codigo":5221858},
    {"id":5565, "id_estado":9, "cidade":"Varj\u00e3o", "codigo":5221908},
    {"id":5566, "id_estado":9, "cidade":"Vian\u00f3polis", "codigo":5222005},
    {"id":5567, "id_estado":9, "cidade":"Vicentin\u00f3polis", "codigo":5222054},
    {"id":5568, "id_estado":9, "cidade":"Vila Boa", "codigo":5222203},
    {"id":5569, "id_estado":9, "cidade":"Vila Prop\u00edcio", "codigo":5222302},
    {"id":5570, "id_estado":7, "cidade":"Bras\u00edlia", "codigo":5300108}]
}