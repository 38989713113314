import styled from 'styled-components';

export const TitleFirst = styled.h1`
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #858585;

  font-size: 20px;
  margin-bottom: 30px;
`;

export const TitleSecond = styled.h1`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #858585;
  margin-top: 35px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .input-group {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    div {
      width: 100%;
    }

    span {
      font-size: 14px;
      background: #ebebeb;
    }
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  form {
    display: flex;
    margin-top: 30px;
    width: 90%;
    max-width: 500px;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .btn.disabled,
    .btn:disabled,
    fieldset:disabled .btn {
      background: #f1f1f1;
      border-color: #858585;
      color: #858585;
    }

    .form-label {
      font-size: 13px;
      color: #858585;
    }

    input {
      width: 100%;
      height: 34px;
    }

    small {
      justify-content: center;
      display: flex;
      font-family: Inter;
      font-size: 14.4px;
      font-weight: 700;
      line-height: 21.61px;
      text-align: center;
      color: #858585;
      width: 100%;
      height: 22px;
      gap: 0px;
      opacity: 0px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f2f5;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 55px;
  position: sticky;

  .register-button {
    background: #03933f;
    border-color: #03933f;
    width: 90vw;
    max-width: 500px;
  }
`;
