import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Pagination from 'react-bootstrap/Pagination';
import PetsModal from '../../components/PetsModal';
import { Service } from '../../services/index';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Search } from '../../assets/icons/Search'
import Spinner from 'react-bootstrap/Spinner';

import {
  Container,
  FilterContainer, FlipCard, FlipCardBack, FlipCardFront, FlipCardInner, PaginationBox, PetsCardsContainer, TableContainer, TitleTop
} from './styles';
import { Button } from 'react-bootstrap';


const ListPets = () => {
  const service = useMemo(() => new Service(), []);
  const [items, setItems] = useState([]);
  const [actualPage, setActualPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [filters, setFilters] = useState({
    location_id: null,
    species: null,
    color: null,
    sex: null,
    age: null,
  });

  const [exitList, setExitList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updatedPet = (values) => {
    loadData(filters, actualPage, exitList ? 1 : 0);
    setSelectedItem(values);
  }


  const getLocations = useCallback(async () => {
    const data = await service.getPetsLocations();
    setLocationOptions(data);
  }, [service]);

  const loadData = useCallback((params, page, exits) => {
    setItems([]);
    setIsLoading(true);
    service.getPets(params, page, exits).then((response) => {
      setItems(response.data.pets);
      setPagesCount(Math.ceil(response.data.total_count / 48));
      setIsLoading(false);
    });
  }, [service]);

  const changePage = (type, page) => {
    let auxPage = actualPage;
    if (type === 'goBack') {
      auxPage = auxPage - 1 === 0 ? 1 : auxPage - 1;
    } else if (type === 'goNext') {
      auxPage = auxPage + 1 > pagesCount ? auxPage : auxPage + 1;
    } else if (type === 'goTo') {
      auxPage = page;
    }
    setActualPage(auxPage);
    loadData(filters, auxPage, exitList ? 1 : 0);
  };

  const returnPages = () => {
    const elements = [];
    for (let index = 1; index <= pagesCount; index++) {
      elements.push(
        <Pagination.Item
          onClick={() => changePage('goTo', index)}
          active={index === actualPage}
        >
          {index}
        </Pagination.Item>,
      );
    }
    return elements;
  };

  const renderPetsCards = () => {
    return items.map((item) => {
      return (
        <FlipCard>
          <FlipCardInner className="flip-card-inner">
            <FlipCardFront>
              <img src={item.photo_link} alt="Avatar" />
            </FlipCardFront>
            <FlipCardBack>
              <div className='infos'>
                <span title={item.location.name_city}><strong>Abrigo: </strong>{item.location.name_city}</span>
                <span><strong>Número do chip: </strong>{item?.chip_number || '-'}</span>
                <span><strong>Coloração: </strong>{item?.color || '-'}</span>
                <span><strong>Sexo: </strong>{item?.sex === "unknown" ? "Não identificado" : item?.sex || '-'}</span>
                <span><strong>Idade: </strong>{item?.age === "unknown" ? "Não identificado" : item?.age || '-'}</span>
              </div>
              <button onClick={() => {
                setSelectedItem(item);
                setShowMoreInfo(true);
              }} className='moreInfos'>Mais informações</button>
            </FlipCardBack>
          </FlipCardInner>
        </FlipCard>
      );
    })
  }

  const changeListType = (value) => {
    setExitList(value);
    setActualPage(1);
    loadData(filters, actualPage, value ? 1 : 0);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value || null,
    });
  };

  useEffect(() => {
    loadData(null, 1);
  }, [loadData]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  return (
    <React.Fragment>
      <Container>
        <TitleTop>
          Lista de animais resgatados
        </TitleTop>
        <FilterContainer>
          <InputGroup>
            <InputGroup.Text id="basic-addon1" >
              <Search />
            </InputGroup.Text>
            <Typeahead
              className="searchInput"
              id="select-location"
              labelKey="name_city"
              multiple={false}
              name="id"
              value={filters?.location_id}
              options={locationOptions}
              placeholder={'Pesquise por abrigo'}
              onChange={(value) => {
                setFilters({ ...filters, location_id: value[0]?.id })
              }}
            />
          </InputGroup>
          <div className="selectFilters">
            <Form.Select value={filters?.color} onChange={handleChange} name="color" aria-label="pet_type">
              <option value="">Cor</option>
              <option value="Bege">Bege</option>
              <option value="Branco">Branco</option>
              <option value="Branco/Marrom">Branco/Marrom</option>
              <option value="Caramelo">Caramelo</option>
              <option value="Cinza">Cinza</option>
              <option value="Cinza/Tigrado">Cinza/Tigrado</option>
              <option value="Frajola">Frajola</option>
              <option value="Laranja">Laranja</option>
              <option value="Laranja/Tigrado">Laranja/Tigrado</option>
              <option value="Marrom">Marrom</option>
              <option value="Mesclado">Mesclado</option>
              <option value="Preto">Preto</option>
              <option value="Preto/Branco">Preto/Branco</option>
              <option value="Preto/Marrom">Preto/Marrom</option>
              <option value="Siamês">Siamês</option>
            </Form.Select>
            <Form.Select value={filters?.species} onChange={handleChange} name="species" aria-label="pet_type">
              <option value="">Espécie</option>
              <option value="Cachorro">Cachorro</option>
              <option value="Gato">Gato</option>
              <option value="Outros">Outros</option>
            </Form.Select>
            <Form.Select value={filters?.sex} onChange={handleChange} name="sex" aria-label="pet_gender">
              <option value="">Sexo</option>
              <option value="Macho">Macho</option>
              <option value="Fêmea">Fêmea</option>
            </Form.Select>
            <Form.Select value={filters?.age} onChange={handleChange} name="age" aria-label="pet_age">
              <option value="">Idade</option>
              <option value="Filhote">Filhote</option>
              <option value="Adulto">Adulto</option>
              <option value="Sênior">Sênior</option>
            </Form.Select>
            <Button variant="success" onClick={() => loadData(filters, actualPage, exitList ? 1 : 0)}>Buscar</Button>
            <div className='checkbox'>
              <input type="checkbox" id="exitList" name="exitList" onChange={(e) => changeListType(e.target.checked)} checked={exitList} />
              <label for="exitList">Listar saídas</label>
            </div>

          </div>
        </FilterContainer>
        <TableContainer>
          {isLoading && (
            <div className='spinner'>
              <Spinner animation="border" variant="success" className='mt-15 spinnerItem' />
            </div>
          )}
          <div className='content'>
            <PetsCardsContainer>
              {items && items.length > 0 && renderPetsCards()}
            </PetsCardsContainer>
          </div>
          <PaginationBox>
            <Pagination className="pagination">
              <Pagination.Prev onClick={() => changePage('goBack')} />
              {returnPages()}
              <Pagination.Next onClick={() => changePage('goNext')} />
            </Pagination>
          </PaginationBox>
        </TableContainer>
      </Container>
      <PetsModal
        open={showMoreInfo}
        selectedData={selectedItem}
        updatedPet={updatedPet}
        changeListType={changeListType}
        onClose={() => {
          setSelectedItem(null);
          setShowMoreInfo(false);
        }}
      />
    </React.Fragment>
  );
};

export default ListPets;
