
import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .login-form {
    margin-top: 50px;
    margin-bottom: auto;
    width: 85%;
    max-width: 300px;
  }

  button {
    width: 100%;
    margin-top: 15px;
  }
`;


export const Title = styled.h1`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #858585;
`;