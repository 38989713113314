import { useEffect, useState, useMemo } from 'react';
import { Container, Item, Panel, Title } from './styles';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Search } from '../../assets/icons/Search'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


import { Service } from '../../services';
import { useMessages } from '../../context/Messages';

const Shelters = () => {
    
    const [ shelterItems, setShelterItems ] = useState([]); 
    const [ search, setSearch ] = useState('');
    const [ searchItems, setSearchItems ] = useState('');
    const [ controlSearch, setControlSearch ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const { setNeed, setNotNeed } = useMessages('');

    
    const handleGetShelter = () => {
        setIsLoading(true);
        new Service().getFullLocations().then((data) => {
            setShelterItems(data.locations);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
            console.log('error');
        })
    }

    const sheltersFiltered = useMemo(() => {
        if(searchItems.length > 0) {

            const typeOne = []  
            
            shelterItems.forEach((item)=> {

                const hasMessageTypeOne = item.messages.filter(item2 =>
                    item2 && item2.type_message === 1
                )

                if(hasMessageTypeOne.length > 0){

                    let lastTwoMessage = []

                    if (hasMessageTypeOne.length > 2){
                        lastTwoMessage.push( hasMessageTypeOne[hasMessageTypeOne.length - 2])
                        lastTwoMessage.push( hasMessageTypeOne[hasMessageTypeOne.length - 1])
                    }else {
                        lastTwoMessage = hasMessageTypeOne;
                    }

                    lastTwoMessage = lastTwoMessage.filter(message => 
                        message.message.toLowerCase().includes(searchItems.toLowerCase())        
                    )

                    if(lastTwoMessage.length > 0){
                        item.lastTwoMessage = lastTwoMessage
                        typeOne.push(item)
                    }

                }
                
            })
            
            return typeOne;
        }


        return shelterItems.filter(item => 
            item.name.toLowerCase().includes(search.toLowerCase())
        )

    },[shelterItems, search, searchItems])



    useEffect(() => {
        handleGetShelter();
    },[controlSearch])




    const handleShowNecessities = (locations) => {
        if(!locations.messages) return

        const { messages } = locations;

        if(messages[0] && messages[messages.length - 1].type_message === 1){
            return <>{ messages[messages.length - 1].message }</>
        }

        if( messages[messages.length - 2] && messages[messages.length - 2].type_message === 1){
            return <>{ messages[messages.length - 2].message }</>
        }

    }


    const handleShowNotNecessities = (locations) => {

        if(!locations.messages) return

        const { messages } = locations 
        
        if( messages[0] && (messages[messages.length - 1].type_message === 2)){
            return <>{messages[[messages.length - 1]].message}</>
        }

        if( messages[messages.length - 2] && (messages[messages.length - 2].type_message === 2)){
            return <>{messages[[messages.length - 2]].message}</>
        }

    }

    const saveItems = (item) => {
        setNeed(handleShowNecessities(item));
        setNotNeed(handleShowNotNecessities(item));
    }

    const showPer = (item) => {
        if(item.occupation && item.capacity) {
            return (item.occupation.people * 100 / item.capacity)+'%'
        }

        return <>---</>
    }

    return ( 
        <>
            <Container> 
                <Title>
                    Locais de Abrigos
                </Title>
                <InputGroup style={{marginTop: '28px', margin: '0 auto'}}>
                <InputGroup.Text id="basic-addon1" >
                    <Search />
                </InputGroup.Text>
                <Form.Control
                    placeholder= {controlSearch ? "Digite para filtrar o nome do abrigo" : "Digite para filtrar os suprimentos necessários"}
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                        if(controlSearch){
                            setSearch(e.target.value);
                            setSearchItems('');
                        }else {
                            setSearch('');
                            setSearchItems(e.target.value);
                        }
                    } }
                />
                </InputGroup>
                <InputGroup style={{marginTop: '28px', margin: '0 auto'}}>

                <Form>
                    <div key={`inline-radio`} className="mb-3 mt-3">
                    <Form.Check
                        inline
                        label="Buscar por abrigo"
                        name="group1"
                        type="radio"
                        id={`inline-radio-1`}
                        onClick={() => setControlSearch(!controlSearch)}
                        checked={controlSearch}
                
                    />
                    <Form.Check
                        inline
                        label="Buscar por necessidades"
                        name="group1"
                        type="radio"
                        id={`inline-radio-2`}
                        onClick={() => setControlSearch(!controlSearch)}
                    />
                    </div>
                </Form>
                </InputGroup>
                { isLoading && ( 
                    <div className='spinner'> 
                        <Spinner animation="border" variant="success" className='mt-15 spinnerItem' /> 
                    </div>
                )}
                { sheltersFiltered && sheltersFiltered.map(item => ( 
                <>
                    <Item key={item.id}> 
                        <Panel className="mt-5 panel card panel-default card-item">
                            <div className="panel-heading card-header card-item-title">
                                <strong>Abrigo: </strong>{item.name}
                            </div>
                            <div className="panel-heading card-header card-item-title">
                                <strong>Capacidade: </strong>{item.capacity || '---'}
                            </div>
                            <div className="panel-heading card-header card-item-title">
                                <strong>Porcentagem ocupada: </strong>
                                { showPer(item) }
                            </div>
                            <div className="panel-body card-body card-item-content" style={{minHeight:"170px"}}>
                                <strong>Endereço: </strong>{`${item.address}, ${item.number || '---'}, ${item.neighborhood || '---'}`}
                                <p></p>
                                <strong>Complemento: </strong>{item.complement || '---'}
                                <p></p>
                                <strong>Cidade: </strong>{item.city} 
                                <br/>
                                <br/>
                                <strong>Necessidades: </strong> 
                                    { handleShowNecessities(item) }
                                <br/>
                                <br/>
                                <strong>Não Necessários: </strong> 
                                    { handleShowNotNecessities(item) }
                                <br/>
                                <br/>

                                <Link to={`/abrigos/${item.id}`} onClick={() => saveItems(item)} >
                                    <Button variant="success btn btn-primary w-100"> 
                                        CADASTRAR NECESSIDADES
                                    </Button>
                                </Link>
                            </div>
                        </Panel>
                    </Item>
                </>
                ))}
            </Container>
        </>
    )
}


export default Shelters;




