import * as S from './styles';
import { importantURLs } from './data';
import RsPixImage from '../../assets/images/RsPixImage.png';

const Home = () => {
  return (
    <S.Container>
      <S.PixBox>
        <S.PixTitle>Ajude com uma doação</S.PixTitle>
        <div className='pixInfo'>
          <div className='QrImage'>
            <S.PixImage src={RsPixImage} alt='' />
            <S.PixKey>CNPJ: 92.958.800/0001-38</S.PixKey>
          </div>
          <div className='pixSubtitle'>
            <p className='title'>
              Dados para doação:
            </p>

            <p className='leg desktop'>A imagem de código QR e o CNPJ ao lado representam a chave pix de doação para a conta SOS Rio Grande do Sul.</p>
            <p className='leg mobile'>A imagem de código QR e o CNPJ acima representam a chave pix de doação para a conta SOS Rio Grande do Sul.</p>
            <p className='title last'>
              Atenção:
            </p>
            <p className='identification desktop'>Ao usar uma das chaves disponibilizadas ao lado a identificação do recebedor deve ser a Associação dos Bancos No Estado do Rio Grande do Sul ou Banco do Estado do Rio Grande do Sul (as duas opções podem aparecer).</p>
            <p className='identification mobile'>Ao usar uma das chaves disponibilizadas acima a identificação do recebedor deve ser a Associação dos Bancos No Estado do Rio Grande do Sul ou Banco do Estado do Rio Grande do Sul (as duas opções podem aparecer).</p>
          </div>
        </div>
      </S.PixBox>
      <S.Informations>
        <S.InformationsList>
          {Object.keys(importantURLs).map((key) => (
            <S.InformationItem key={`info_card_${key}`}>
              <p className='title'>{importantURLs[key]?.title}</p>
              <p className='description'>{importantURLs[key]?.description}</p>
              <S.InformationButton onClick={() => window.open(importantURLs[key]?.url, '_blank').focus()}>Saiba mais</S.InformationButton>
            </S.InformationItem>
          ))}
        </S.InformationsList>
      </S.Informations>
    </S.Container>
  );
}

export default Home;