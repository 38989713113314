import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

export const Title = styled.h1`
  font-size: 48px;
  color: #3a3a3a;
`;

export const CardContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
`;

export const Card = styled.div`
  background: ${({ total }) => (total ? '#FF872C' : '#fff')};
  padding: 22px 24px;
  border-radius: 5px;
  color: ${({ total }) => (total ? '#fff' : '#363F5F')};
  z-index: 999;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  min-width: 310px;
  
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #858585;
    margin: 0;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    margin: 0;
    color: #4A4A4A;
  }
`;

export const FilterContainer = styled.section`
  .searchInput {
    width: calc(100% - 44px) !important;
  }

  .form-control, .searchInput {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  .selectFilters {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 10px;

    .form-select {
      width: calc(50% - 5px);
      max-width: 182px;
    }

    .checkbox {
      display: flex;
      gap: 5px;
      align-items: center;
      min-width: 110px;
    }
  }

  button {
    min-width: 100px;
  }
`;

export const TableContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .spinner {
    width: 100%;
    position: relative;

    .spinnerItem {
      position: absolute;
      left: 50%;
    }
  }
`;

export const PetsCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 60px;

  @media screen and (max-width: 775px) {
    justify-content: center;
  }

  @media screen and (min-width: 775px) {
    width: 735px;
  }

  /* @media screen and (min-width: 1080px) {
    width: 1020px;
  }  */
`;

export const PaginationBox = styled.div`
  display: flex;
  justify-content: flex-end;

  .pagination {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination li a{
    font-size: 12px !important;
    color: #6C757D;
  }

  .active>.page-link, .page-link.active {
    background: #6B6B6B;
    border-color: #6B6B6B;
    font-size: 12px !important;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 200px;
  gap: 10px;
  p {
    margin: 0;
    text-align: center;
    color: #4A4A4A;
    font-weight: 700;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const CustomButton = styled(Button)`
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    padding: 13px 19px;

    &.close {
      background: #E9ECEF !important;
      color: #343A40;
      border: none;
    }
    &.confirm {
      background: #03933F !important;
      color: #FFF;
      border: none;
    }
`;


export const TitleTop = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #858585;
`;


export const FlipCard = styled.div`
  background-color: transparent;
  width: 100%;
  height: 450px;
  max-width: 337.5px;
  min-width: 337.5px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  border-radius: 5px;
  overflow: hidden;

  &:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
`;

export const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 5px;
`;

export const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: #2e2e2e;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    
    /* max-width: 100%;
    height: auto; */
  }
`;

export const FlipCardBack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: #2e2e2e;
  color: white;
  transform: rotateY(180deg);

  div.infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 18px;
    gap: 10px;
  }

  button.moreInfos {
    padding: 10px;
    border: none;
    border-radius: 5px;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
`;