import styled from "styled-components";

export const Container = styled.main`
    width: 100%;
    max-width: 1120px;
    padding: 30px 20px;
`;

export const PixTitle = styled.p`
  font-size: 28px;
  font-weight: 600;
`;

export const PixBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .pixInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .QrImage {
    max-width: fit-content;
    color: #F0F2F5;
    background: black;
    border-radius: 6px;
    padding: 10px;
  }

  .pixSubtitle {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    @media screen and (max-width: 729px) {
      justify-content: center;
      .desktop {
        display: none;
      }
    }

    @media screen and (min-width: 730px) {
      justify-content: center;
      .mobile {
        display: none;
      }
    }
    p {
      margin: 0;
      font-size: 16px;
      &.title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &.last {
        margin-top: 30px;
      }
    }
  }
`;

export const PixImage = styled.img`
  max-width: 250px;
  height: auto;
`;

export const PixKey = styled.p`
  font-size: 18px;
  margin: 10px 0 0 0;
  font-weight: 700;
  text-align: center;
`;

export const Informations = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const InformationsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  @media screen and (min-width: 520px) {
    width: 520px;
  }

  @media screen and (min-width: 790px) {
    width: 790px;
  }

  @media screen and (min-width: 1060px) {
    width: 1060px;
  }
`;

export const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 250px;
  height: 230px;
  border-radius: 6px;
  padding: 10px;
  background: #fff;

  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  p {
    margin: 0;
    display: flex;
  }
  
  p.title {
    font-size: 20px;
    font-weight: 600;
    height: 70px;
    align-items: center;
  }

  p.description {
    font-size: 16px;
    height: 100px;
    align-items: center;
  }

  div.buttonBox {
    height: 50px;
  }

  button {
    padding: 10px;
    background: transparent;
    border: solid #6C757D 1px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const InformationButton = styled.button``