import axios from "axios";
import { HelperAuthentication } from '../helper-authentication';

class BaseService {
  constructor(baseURL) {
    this._instance = axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${HelperAuthentication.getCookieByKey("access_token")}`,
      },
    })
  }
}

export class Service extends BaseService {
  constructor() {
    super('https://fastapi.sosrs.info');
    //  super('http://localhost:8000');
  }

  async getLogin(username, password) {
    const response = await this._instance.post(`/administrators/login`, { username, password });
    return response.data;
  }



  async createPeople(data) {
    for (var propName in data) {
      if (data[propName] === null || data[propName] === "" || data[propName] === undefined) {
        delete data[propName];
      }
    }
    delete data.location
    const response = await this._instance.post('/people', data);

    return response.data;
  }

  async createShelterMessage(data) {
    const response = await this._instance.post('/message/create', data);

    return response.data;
  }

  async getPeopleById(id) {
    const response = await this._instance.get(`/people/${id}`);
    return response.data;
  }

  async getLocations() {
    const storedData = JSON.parse(sessionStorage.getItem('locations'));

    if (storedData)
      return storedData

    const response = await this._instance.post('/locations/v2/search', {
      name: "",
      zip_code: "",
      address: "",
      number: "",
      complement: "",
      neighborhood: "",
      uf: "",
      city: ""
    });
    return response.data;
  }

  async getPeople(page = 1, search_name = "") {
    const response = await this._instance.get(`/people/page/${page}?search_name=${search_name}`);
    return response;
  }

  async setPersonCheckout(id, date) {
    const response = await this._instance.patch(`/people`, { id, checked_out_at: date })
    return response;
  }

  async getPanelData() {
    const response = await this._instance.get(`/people/counters`);
    return response;
  }

  async getAccessToken() {
    return await this._instance.post('/maps/routing/here/oauth2/access-token', {});
  }

  async getBlockedAreas() {
    return await this._instance.get('/maps/routing/blocked-areas');
  }

  async getFullLocations() {
    const response = await this._instance.get('/locations/full_search');
    return response.data;
  }

  async createAnimal(data) {
    const response = await this._instance.post('/pets', data);
    return response.data;
  }

  async getPetsLocations() {
    const response = await this._instance.get('/locations/pets');
    return response.data;
  }

  async getPets(filters, page = 1, active = 0) {
    return await this._instance.get(`/pets/search`, {
      params: {
        ...filters,
        page,
        status: active,
      }
    });
  }

  async checkEditApiToken(apiToken) {
    try {
      const response = await this._instance.get(`/pets/check-api-token?api_token=${apiToken}`);
      return response.status;
    } catch (error) {
      return false;
    }
  }

  async updatePet(data, apiToken) {
    const response = await this._instance.patch(`/pets?api_token=${apiToken}`, data);
    return response.data;
  }
}