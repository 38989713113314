import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const ToastForm = ({ message, setShow, toastPosition }) => {

  return (
    <ToastContainer position={toastPosition || "bottom-center"}>
      <Toast onClose={() => setShow(false)} delay={3000} autohide>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">{message}</strong>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};

export default ToastForm;
