import styled from 'styled-components';


export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  justify-content: space-around;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 500px;


  label {
    
    margin-top: 4px;
    cursor: pointer;
  }


  .form-check-input{
    margin-left: 2px;
    margin-right: 8px;
    cursor: pointer;
  }

  .spinner{
    width: 100%;
    position: relative;

    .spinnerItem{
      position: absolute;
      left: 50%;
    }
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #858585;
  margin-bottom: 20px;
`;



export const Item = styled.div`
  display: flex;
  width: 340px;
`


export const Panel = styled.div`
  width: 100%;
`