import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Pagination from 'react-bootstrap/Pagination';
import { Search } from '../../assets/icons/Search';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Service } from '../../services/index';
import { format } from 'date-fns';
import {
  Container,
  CardContainer,
  Card,
  TableContainer,
  PaginationBox,
  ModalContainer,
  ModalContent,
  ModalActions,
  CustomButton,
  TitleTop
} from './styles';
import { PictureIcon } from '../../assets/icons/PictureIcon';
import { Person } from '../../assets/icons/Person';
import PersonModal from '../../components/PersonModal';

const ListPeople = () => {
  const [items, setItems] = useState([]);
  const [personId, setPersonId] = useState();
  const [imageOpenId, setImageOpenId] = useState();
  const [actionButtonModal, setActionButtonModal] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [showPersonInfo, setShowPersonInfo] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const [panelCounts, setPanelCounts] = useState({
    people_total_count: 0,
    children_count: 0,
    adults_count: 0,
  });

  const searchName = useRef('');
  const service = useMemo(() => new Service(), []);

  const getPeople = useCallback(async () => {
    const { data } = await service.getPeople(
      actualPage,
      searchName.current.value,
    );
    setPagesCount(Math.ceil(data.total_count / 50));
    setItems(data.people);
  }, [actualPage, service]);

  const getPersonById = id => {
    service
      .getPeopleById(id)
      .then(data => {
        setSelectedPerson(data);
      })
      .catch(() => {
        console.log('error');
      });
  };

  const openSelectedPerson = id => {
    getPersonById(id);
    setShowPersonInfo(true);
  };

  const cleanPersonData = () => {
    setSelectedPerson(null);
  };

  const getPanelData = useCallback(async () => {
    const { data } = await service.getPanelData();
    setPanelCounts(data);
  }, [service]);

  useEffect(() => {
    getPeople();
  }, [actualPage, getPeople]);

  useEffect(() => {
    window.addEventListener('keypress', e => {
      if (e.key === 'Enter') {
        getPeople();
      }
    });
    return window.removeEventListener('keypress', () => null);
  }, [getPeople]);

  useEffect(() => {
    getPanelData();
  }, [getPanelData]);

  const changePage = (type, page) => {
    if (type === 'goBack') {
      setActualPage(actualPage - 1 === 0 ? 1 : actualPage - 1);
    } else if (type === 'goNext') {
      setActualPage(actualPage + 1 > pagesCount ? actualPage : actualPage + 1);
    } else if (type === 'goTo') {
      setActualPage(page);
    }
  };

  const returnPages = () => {
    const elements = [];
    for (let index = 1; index <= pagesCount; index++) {
      elements.push(
        <Pagination.Item
          onClick={() => changePage('goTo', index)}
          active={index === actualPage}
        >
          {index}
        </Pagination.Item>,
      );
    }
    return elements;
  };

  const setActiveImage = id => {
    setImageOpenId(imageOpenId === id ? null : id);
  };

  const openActionModal = id => {
    setPersonId(id);
    setActionButtonModal(true);
  };

  const closenActionModal = id => {
    setPersonId(null);
    setActionButtonModal(false);
  };

  const checkoutPerson = () => {
    service.setPersonCheckout(personId, new Date()).then(() => {
      closenActionModal();
      getPeople();
    });
  };

  return (
    <React.Fragment>
      <Container>
        <TitleTop>
          Lista de Desabrigados
        </TitleTop>
        <CardContainer>
          <Card>
            <p>Total de cadastros</p>
            <h1>{panelCounts.people_total_count}</h1>
          </Card>
          <Card>
            <p>Adultos</p>
            <h1>{panelCounts.adults_count}</h1>
          </Card>
          <Card>
            <p>Menores de idade</p>
            <h1>{panelCounts.children_count}</h1>
          </Card>
        </CardContainer>
        <InputGroup>
          <InputGroup.Text id="basic-addon1">
            <Search />
          </InputGroup.Text>
          <Form.Control
            ref={searchName}
            placeholder="Buscar cadastro"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onBlur={getPeople}
          />
          <a href="/cadastro">
            <Button variant="success" style={{ marginLeft: '5px' }}>
              Cadastrar nova pessoa
            </Button>
          </a>
        </InputGroup>
        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th className="hideOnMobile">Foto</th>
                <th>CPF</th>
                <th className="hideOnMobile">Data de Nascimento</th>
                <th className="hideOnMobile">Idade</th>
                <th className="hideOnMobile">Cidade origem</th>
                <th>Acolhido em</th>
                <th className="hideOnMobile">UF</th>
                <th className="hideOnMobile"></th>
              </tr>
            </thead>
            <tbody>
              {items?.map(item => (
                <>
                  {item?.photo_link && (
                    <div
                      id={`${item.id}`}
                      className={`imageCard ${item.id === imageOpenId ? 'active' : ''
                        }`}
                    >
                      <img
                        height={165}
                        width={183}
                        src={item.photo_link}
                        alt=""
                      />
                    </div>
                  )}
                  <tr id={item.id} onClick={() => openSelectedPerson(item.id)}>
                    <td className="title">{item.full_name}</td>
                    <td className="hideOnMobile">
                      <button
                        className={`pictureButton ${item?.photo_link ? '' : 'disabled'
                          }`}
                        onMouseEnter={() => setActiveImage(item.id)}
                        onMouseLeave={() => setActiveImage(null)}
                      >
                        <PictureIcon
                          color={item?.photo_link ? '#03933F' : 'gray'}
                        />
                      </button>
                    </td>
                    <td>{item.identification}</td>
                    <td className="hideOnMobile">
                      {item.date_of_birth
                        ? format(new Date(item.date_of_birth), 'dd/MM/yyyy')
                        : null}
                    </td>
                    <td className="hideOnMobile">{item.age}</td>
                    <td className="hideOnMobile">{item.city}</td>
                    <td>
                      {item.checked_in_at
                        ? format(new Date(item.checked_in_at), 'dd/MM/yyyy')
                        : null}
                    </td>
                    <td className="hideOnMobile">{item.uf}</td>
                    <td className="hideOnMobile">
                      <Button
                        className="actionButton"
                        onClick={(e) => {
                          e.stopPropagation();
                          openActionModal(item.id);
                        }}
                      >
                        <Person />
                        <p>Marcar saída</p>
                      </Button>
                    </td>
                  </tr>
                  <br />
                </>
              ))}
            </tbody>
          </table>
          <br />
          <PaginationBox>
            <Pagination className="pagination">
              <Pagination.Prev onClick={() => changePage('goBack')} />
              {returnPages()}
              <Pagination.Next onClick={() => changePage('goNext')} />
            </Pagination>
          </PaginationBox>
        </TableContainer>
      </Container>
      <Modal
        centered
        show={actionButtonModal}
        onHide={() => closenActionModal()}
      >
        <ModalContainer>
          <ModalContent>
            <Person height="74px" width="auto" />
            <p>
              Tem certeza que deseja marcar a saída do refúgio para esta pessoa?
            </p>
          </ModalContent>
          <ModalActions>
            <CustomButton className="close" onClick={() => closenActionModal()}>
              Cancelar
            </CustomButton>
            <CustomButton className="confirm" onClick={() => checkoutPerson()}>
              Sim, marcar saída desta pessoa
            </CustomButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
      <PersonModal
        show={showPersonInfo}
        setShow={setShowPersonInfo}
        personData={selectedPerson}
        cleanPersonData={cleanPersonData}
        openActionModal={() => {
          setShowPersonInfo(false);
          openActionModal();
        }}
      />
    </React.Fragment>
  );
};

export default ListPeople;
