import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

export const Title = styled.h1`
  font-size: 48px;
  color: #3a3a3a;
`;

export const CardContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
`;

export const Card = styled.div`
  background: ${({ total }) => (total ? '#FF872C' : '#fff')};
  padding: 22px 24px;
  border-radius: 5px;
  color: ${({ total }) => (total ? '#fff' : '#363F5F')};
  z-index: 999;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  min-width: 310px;
  
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #858585;
    margin: 0;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    margin: 0;
    color: #4A4A4A;
  }
`;

export const TableContainer = styled.section`

  table {
    width: 100%;
    border-spacing: 0 8px;
    
    @media screen and (max-width: 815px){
      .hideOnMobile {
        display: none;
      }
    }

    th {
      color: #969cb3;
      font-weight: normal;
      padding: 20px 32px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }

    td {
      padding: 20px 20px;
      border: 0;
      background: #fff;
      font-size: 13px;
      line-height: 24px;
      font-weight: normal;
      color: #969cb3;

      &.title {
        color: #363f5f;
      }

      &.income {
        color: #12a454;
      }

      &.outcome {
        color: #e83f5b;
      }

      button.pictureButton {
        position: relative;
        border-radius: 50px;
        width: 31px;
        height: 31px;
        border: none;
        background-color: #EBEBEB;
        svg {
          zoom: 1.1;
        }      
      }
    }


    button.disabled {
      opacity: 0.5 !important;
      cursor: not-allowed;
    }

    button.actionButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      height: 29px;
      width: 113px;
      background: transparent;
      border: solid #6C757D 1px;
      border-radius: 6px;

      p {
        font-size: 12px; 
        font-weight: 600;
        margin: 0;
        color: #6C757D;
        line-height: 18px;
      }

    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
  .imageCard {
    position: absolute;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-left: 70px;
    margin-top: -150px;
    padding: 5px;
    border-radius: 6px;
    display: none;
    &.active {
      display: unset;
    }
    img {
      object-fit: cover;
    }
  }
`;

export const PaginationBox = styled.div`
  display: flex;
  justify-content: flex-end;

  .pagination {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination li a{
    font-size: 12px !important;
    color: #6C757D;
  }

  .active>.page-link, .page-link.active {
    background: #6B6B6B;
    border-color: #6B6B6B;
    font-size: 12px !important;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 200px;
  gap: 10px;
  p {
    margin: 0;
    text-align: center;
    color: #4A4A4A;
    font-weight: 700;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const CustomButton = styled(Button)`
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    padding: 13px 19px;

    &.close {
      background: #E9ECEF !important;
      color: #343A40;
      border: none;
    }
    &.confirm {
      background: #03933F !important;
      color: #FFF;
      border: none;
    }
`;


export const TitleTop = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #858585;
`;