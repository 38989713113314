import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';
import { Container, MenuItems, List } from './styles';
import { useState } from 'react';

const Menu = () => {
  const [open, setOpen] = useState(false);
  
  return (
    <Container>
      <button className='menu' onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
        <Icon path={mdiMenu} size={1} />
      </button>
      <MenuItems in={open}>
        <List>
          <List.Item action onClick={() => window.location.href = '/login'}>Login</List.Item>
          <List.Item action onClick={() => window.location.href = '/'}>Início</List.Item>
          <List.Item>
            <p className='subMenu'>Desabrigados</p>
          </List.Item>
          <List.Item className='subItem' action onClick={() => window.location.href = '/desabrigados/cadastro'}>Cadastro de Desabrigados</List.Item>
          <List.Item className='subItem' action onClick={() => window.location.href = '/desabrigados/lista'}>Lista de Desabrigados</List.Item>
          <List.Item>
            <p className='subMenu'>Abrigos</p>
          </List.Item>
          <List.Item action className='subItem' onClick={() => window.location.href = '/abrigos/lista'}>Locais de Abrigos</List.Item>
          <List.Item>
            <p className='subMenu'>Rotas</p>
          </List.Item>
          <List.Item className='subItem' action onClick={() => window.location.href = '/rotas'}>Rotas Alternativas (CRBM)</List.Item>
          <List.Item>
            <p className='subMenu'>Animais Resgatados</p>
          </List.Item>
          <List.Item className='subItem' action onClick={() => window.location.href = '/animais-resgatados/lista'}>Lista de animais resgatados</List.Item>
        </List>
      </MenuItems>
    </Container>
  )
}

export default Menu;