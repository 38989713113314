import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button, InputGroup } from 'react-bootstrap';
import {
  SwitchCamera,
  TakePictureButton,
  Video,
  Container,
  ButtonInformation,
} from './style';
import { SwitchCameraIcon } from '../../assets/icons/SwitchCameraIcon';
import { Photo } from '../../assets/icons/Photo';
import { Trash } from '../../assets/icons/Trash';

const TakePictureInput = ({
  savePhoto,
  savedPhoto,
  setSavedPhoto,
  takenPicture,
  setTakenPicture,
  enableCam,
  setEnableCam,
  removeMessage,
}) => {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState('environment');

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setTakenPicture(imageSrc);
  };

  const usePhoto = () => {
    setEnableCam(false);
    savePhoto(takenPicture);
    setSavedPhoto(true);
    setTakenPicture(null);
  };

  const handleTakePicture = () => {
    setEnableCam(!enableCam);
  };

  const switchCamera = () => {
    setFacingMode(prevMode => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const handleRetake = () => {
    setEnableCam(true);
    setTakenPicture(null);
    setSavedPhoto(false);
  };

  return (
    <Container>
      <InputGroup id="form-select" onClick={handleTakePicture}>
        {!enableCam && !savedPhoto && (
          <Button
            active
            variant="outline-secondary"
            style={{
              width: '100%',
              backgroundColor: 'transparent',
              border: '1px solid #6C757D',
              color: '#6C757D',
            }}
          >
            <span
              style={{
                marginRight: '5px',
                alignItems: 'start',
                backgroundColor: 'transparent',
              }}
            >
              <Photo width="15px" height="15px" fill={'#6C757D'} /> Tirar foto
            </span>
          </Button>
        )}

        {!enableCam && savedPhoto && (
          <>
            <Button
              active
              variant="outline-secondary"
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                border: '1px solid #6C757D',
                color: '#6C757D',
              }}
            >
              <span style={{ marginRight: '5px', alignItems: 'start' }}>
                <Photo width="15px" height="15px" fill={'#43B25C'} />
              </span>
              Foto Salva
            </Button>
            <Button
              className="btn-trash"
              onClick={handleRetake}
              variant="outline-secondary"
              style={{ borderLeft: 'none' }}
            >
              <Trash width="15px" height="15px" fill={'#DA271E'} />{' '}
            </Button>
          </>
        )}
      </InputGroup>
      {!enableCam && !removeMessage && (
        <ButtonInformation>
          Foto obtida únicamente para fins de{' '}
          <b>identificação e localização de familiares.</b>
        </ButtonInformation>
      )}
      {enableCam && !takenPicture && (
        <Video>
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={{ facingMode }}
            mirrored={facingMode === 'user'}
            screenshotFormat="image/webp"
            style={{ width: '100%', height: 'auto' }}
          />
          <TakePictureButton onClick={takenPicture ? handleRetake : capture} />

          <SwitchCamera type="button" onClick={switchCamera}>
            <SwitchCameraIcon width={32} height={32} fill={'#F0F2F5'} />
          </SwitchCamera>
        </Video>
      )}
      {enableCam && takenPicture && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Video>
            <img
              src={takenPicture}
              alt="Captured"
              style={{ maxWidth: '100%' }}
            />
            <TakePictureButton
              onClick={takenPicture ? handleRetake : capture}
            />
          </Video>
        </div>
      )}
      {enableCam && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            padding: '20px 0',
          }}
        >
          {
            <Button
              active
              variant="dark"
              onClick={usePhoto}
              style={{ width: '100%', margin: '0' }}
              disabled={!takenPicture}
            >
              USAR ESTA FOTO
            </Button>
          }
          <Button
            variant="outline-dark"
            onClick={handleRetake}
            disabled={!takenPicture}
            style={{
              backgroundColor: 'transparent',
              border: '1px solid #6C757D',
              color: '#6C757D',
              margin: '0',
              width: '100%',
            }}
          >
            TIRAR NOVA FOTO
          </Button>
        </div>
      )}
    </Container>
  );
};

export default TakePictureInput;
