import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { ButtonContainer, Container, TitleFirst, TitleSecond } from './styles';
import { Typeahead } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getBrazilianCities } from '../../utils/getBrazilianCitiesHelper';
import TakePictureInput from '../../components/TakePictureInput';
import { Service } from '../../services';
import ToastForm from '../../components/Toast';

const Register = () => {
  const service = useMemo(() => new Service(), []);
  const [message, setMessage] = useState();
  const [showToast, setShowToast] = useState(false);
  const ref = useRef();
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [brazilianCities] = useState(getBrazilianCities());
  const [savedPhoto, setSavedPhoto] = useState(false);
  const [takenPicture, setTakenPicture] = useState(null);
  const [enableCam, setEnableCam] = useState(false);
  const [formData, setFormData] = useState({
    full_name: '',
    date_of_birth: '',
    nickname: '',
    phone_number: '',
    status: '',
    mothers_name: '',
    fathers_name: '',
    identification: '',
    photo: '',
    zip_code: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    uf: '',
    city: '',
    location_id: '',
    age: '',
  });

  const blankState = async () => {
    setFormData({
      full_name: '',
      date_of_birth: '',
      nickname: '',
      phone_number: '',
      status: '',
      mothers_name: '',
      fathers_name: '',
      identification: '',
      photo: '',
      zip_code: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      uf: '',
      city: '',
      location_id: '',
      age: '',
    });
    ref.current?.clear();
    setSavedPhoto(false);
    clearPhotoInputs();
  };

  const getLocations = useCallback(async () => {
    const data = await service.getLocations();
    setLocationsOptions(data);
  }, [service]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const handleSubmit = e => {
    e.preventDefault();
    service
      .createPeople(formData)
      .then(() => {
        blankState();
        window.scrollTo(0, 0);
        setMessage('Cadastrado com sucesso!');
        setShowToast(true);
      })
      .catch(e => {
        console.log(e);
        setMessage('Erro ao cadastrar!');
      });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const savePhoto = newPhoto => {
    setFormData({
      ...formData,
      photo: newPhoto,
    });
  };

  const clearPhotoInputs = () => {
    setEnableCam(false);
    setTakenPicture(null);
    setSavedPhoto(false);
  };

  return (
    <React.Fragment>
      <Container>
        <Form onSubmit={handleSubmit}>
          <TitleFirst>Cadastro de Desabrigados</TitleFirst>
          <Form.Group className="mb-3 formGroup" controlId="mandatoryData">
            <div>
              <Form.Label>
                Lugar de acolhimento
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Typeahead
                id="select-location"
                labelKey="name_city"
                multiple={false}
                name="id"
                value={formData?.location_id}
                options={locationsOptions}
                placeholder={'Escolha o abrigo'}
                onChange={location =>
                  setFormData({ ...formData, location_id: location[0]?.id })
                }
              />
            </div>
            <TakePictureInput
              savePhoto={savePhoto}
              savedPhoto={savedPhoto}
              setSavedPhoto={setSavedPhoto}
              takenPicture={takenPicture}
              setTakenPicture={setTakenPicture}
              enableCam={enableCam}
              setEnableCam={setEnableCam}
            />
            <div>
              <Form.Label style={{ display: 'flex' }}>
                Nome completo
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="full_name"
                value={formData?.full_name}
                placeholder="Nome Completo"
                onChange={handleChange}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Form.Label>
                Cidade de residência
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Typeahead
                id="select-city"
                labelKey="cidade"
                value={formData?.city}
                multiple={false}
                options={brazilianCities}
                ref={ref}
                placeholder="Cidade de residência"
                onChange={city => {
                  setFormData({ ...formData, city: city[0]?.cidade });
                }}
              />
            </div>
          </Form.Group>

          <Form.Group
            className="mb-3 formGroup"
            controlId="mandatoryData"
            onChange={handleChange}
          >
            <TitleSecond>Dados Recomendados</TitleSecond>
            <div>
              <Form.Label>Apelido</Form.Label>
              <Form.Control
                type="text"
                name="nickname"
                placeholder="Apelido"
                value={formData?.nickname}
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>Idade </Form.Label>
              <Form.Control
                type="number"
                name="age"
                value={formData?.age}
                placeholder="Idade"
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>Algum medicamento crítico? </Form.Label>
              <Form.Control
                type="text"
                name="complement"
                value={formData?.complement}
                placeholder="Medicamentos"
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>Info. extra ou contato de emergência: </Form.Label>
              <Form.Control
                type="text"
                name="complement"
                placeholder="Complementos"
                onChange={handleChange}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3 formGroup" controlId="optionalData">
            <TitleSecond>Dados Opcionais</TitleSecond>

            <div>
              <Form.Label>CPF</Form.Label>
              <Form.Control
                type="number"
                name="identification"
                placeholder="CPF"
                value={formData?.identification}
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>Nome da mãe</Form.Label>
              <Form.Control
                type="text"
                name="mothers_name"
                placeholder="Nome da mãe"
                value={formData?.mothers_name}
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>Nome do pai</Form.Label>
              <Form.Control
                type="text"
                name="fathers_name"
                placeholder="Nome do pai"
                value={formData?.fathers_name}
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>CEP cidade de residência</Form.Label>
              <Form.Control
                type="number"
                name="zip_code"
                placeholder="CEP"
                value={formData?.zip_code}
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>Bairro cidade de residência</Form.Label>
              <Form.Control
                type="text"
                name="neighborhood"
                placeholder="Bairro"
                value={formData?.neighborhood}
                onChange={handleChange}
              />
            </div>
          </Form.Group>
          <ButtonContainer>
            <Button
              className="register-button"
              variant="primary"
              type="submit"
              disabled={
                !formData?.full_name ||
                !formData?.location_id ||
                !formData?.city
              }
            >
              Cadastrar
            </Button>
            <div>
              {showToast && (
                <ToastForm message={message} setShow={setShowToast} />
              )}
            </div>
          </ButtonContainer>
        </Form>
      </Container>
    </React.Fragment>
  );
};
export default Register;
