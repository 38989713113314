import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';


export const StyledModal = styled(Modal)`
  
  @media screen and (min-width: 815px) {
    padding-right: 0 !important;
    
    :first-child {
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .modal-content {
      height: 100vh;
    }
  
    &.modal.modal-right .modal-dialog {
      right: 0;
      margin-right: 0;
      transition: transform 0.3s ease-out;
    }
  
    &.modal.modal-right.show .modal-dialog {
      transform: translateX(0);
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Header = styled.div`
  padding: 25px 25px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .imageBox {
    max-width: 150px;
    width: auto;
    height: 170px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button.actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    height: 29px;
    width: 113px;
    background: transparent;
    border: solid #6C757D 1px;
    border-radius: 6px;

    p {
      font-size: 12px; 
      font-weight: 600;
      margin: 0;
      color: #6C757D;
      line-height: 18px;
    }
  }
`;

export const Body = styled.div`
  padding: 0 25px 25px 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .name-person {
    color: #858585;
  }
  p {
    margin: 0;
  }
  section {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;


export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin: 0px;
  color: #5e5e5e;
`;

export const Label = styled.h6`
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: #858585;
`;

export const Text = styled.p`
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  color: #858585;
`;
