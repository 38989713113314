import React from 'react';
import { Container } from './styles';
import HeaderRsColorLine from '../../assets/images/HeaderRsColorLine.svg';
import Banner from '../../assets/images/Banner.svg';
import DogsBanner from '../../assets/images/DogsBanner.jpeg';
import Help from '../../assets/images/Help.svg';
import CanoasLogo from '../../assets/images/CanoasLogo.png';
import Menu from '../Menu/index'

const Header = ({ position = 'bottom' }) => {
  const pathname = window?.location?.pathname;
  return (
    <Container position={position}>
      <img className="flag" src={HeaderRsColorLine} alt='' />
      {pathname.includes("/canoas/") ? (
        <React.Fragment>
          <img className="canoarBanner" src={DogsBanner} alt='' />
          <div className="canoasLogo">
            <img src={CanoasLogo} alt='' />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img className="banner" src={Banner} alt='' />
          <img className="help" src={Help} alt='' />
        </React.Fragment>
      )}
      <Menu />
    </Container>
  )
};

export default Header;