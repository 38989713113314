import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { ButtonContainer, Container, TitleFirst, TitleSecond, ContentTabs } from './styles';
import { Typeahead } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import TakePictureInput from '../../components/TakePictureInput';
import { Service } from '../../services';
import ToastForm from '../../components/Toast';

const PetsRegister = () => {
  const service = useMemo(() => new Service(), []);
  const [message, setMessage] = useState();
  const [showToast, setShowToast] = useState(false);
  const refCity = useRef();
  const refLocation = useRef();

  const [locationsOptions, setLocationsOptions] = useState([]);

  const [savedPhoto, setSavedPhoto] = useState(false);
  const [takenPicture, setTakenPicture] = useState(false);
  const [enableCam, setEnableCam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [photoTab, setPhotoTab] = useState("takePicture");
  const [photoTakenValue, setPhotoTakenValue] = useState('');
  const [photoPickValue, setPhotoPickValue] = useState('');


  const [formData, setFormData] = useState({
    location_id: '',
    photo: '',
    species: 'Cachorro',
    color: 'Bege',
    sex: 'unknown',
    age: 'unknown',
    chip_number: '',
    extra_info: '',
  });

  const blankState = async () => {
    setFormData({
      location_id: '',
      photo: '',
      species: 'Cachorro',
      color: 'Bege',
      sex: 'Não informado',
      age: 'Não informado',
      chip_number: '',
      extra_info: '',
    });
    document.getElementsByName("galleryFile")[0].value = '';
    refCity.current?.clear();
    refLocation.current?.clear();
    setSavedPhoto(false);
    clearPhotoInputs();
  };

  const getLocations = useCallback(async () => {
    const data = await service.getPetsLocations();
    setLocationsOptions(data);
  }, [service]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    service
      .createAnimal(formData)
      .then(() => {
        blankState();
        window.scrollTo(0, 0);
        setMessage('Cadastrado com sucesso!');
        setShowToast(true);
        setIsLoading(false);
      })
      .catch(e => {
        console.log(e);
        setMessage('Erro ao cadastrar!');
        setIsLoading(false);
      });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const savePhoto = newPhoto => {
    if (photoTab === "takePicture") {
      setPhotoTakenValue(newPhoto);
    } else if (photoTab === "pickGallery") {
      setPhotoPickValue(newPhoto)
    }

    setFormData({
      ...formData,
      photo: newPhoto,
    });
  };

  const clearPhotoInputs = () => {
    setEnableCam(false);
    setTakenPicture(null);
    setSavedPhoto(false);
  };

  const onChangePhotoTab = (name) => {
    setFormData({
      ...formData,
      photo: name === "takePicture" ? photoTakenValue : photoPickValue,
    });
    setPhotoTab(name);
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    return reader.result;
  });

  const selectImage = async (e) => {
    const file = document.getElementsByName("galleryFile")[0].files[0];
    const base64File = await toBase64(file);
    savePhoto(base64File);
  };

  return (
    <React.Fragment>
      <Container>
        <Form onSubmit={handleSubmit}>
          <TitleFirst>Cadastro de Animais Resgatados</TitleFirst>
          <Form.Group className="mb-3 formGroup" controlId="mandatoryData">
            <div>
              <Form.Label>
                Abrigo
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Typeahead
                id="select-location"
                labelKey="name_city"
                multiple={false}
                name="id"
                ref={refLocation}
                value={formData?.location_id}
                options={locationsOptions}
                placeholder={'Escolha o abrigo'}
                onChange={location =>
                  setFormData({ ...formData, location_id: location[0]?.id })
                }
              />
            </div>
            <div>
              <ContentTabs variant="tabs" defaultActiveKey={photoTab}>
                <ContentTabs.Item onClick={() => onChangePhotoTab("takePicture")}>
                  <ContentTabs.Link eventKey="takePicture">
                    <p>Tirar Foto</p>
                  </ContentTabs.Link>
                </ContentTabs.Item>
                <ContentTabs.Item onClick={() => onChangePhotoTab("pickGallery")}>
                  <ContentTabs.Link eventKey="pickGallery">
                    <p>Escolher da Galeria</p>
                  </ContentTabs.Link>
                </ContentTabs.Item>
              </ContentTabs>
              <Form.Label>
                Foto
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <div style={photoTab !== "takePicture" ? { display: 'none' } : {}}>
                <TakePictureInput
                  savePhoto={savePhoto}
                  savedPhoto={savedPhoto}
                  setSavedPhoto={setSavedPhoto}
                  takenPicture={takenPicture}
                  setTakenPicture={setTakenPicture}
                  enableCam={enableCam}
                  setEnableCam={setEnableCam}
                  removeMessage
                />
              </div>
              <div style={photoTab !== "pickGallery" ? { display: 'none' } : {}}>
                <Form.Control name="galleryFile" type="file" accept="image/*" onChange={(e) => selectImage(e)} />
              </div>
            </div>
            <div>
              <Form.Label>
                Espécie
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Form.Select value={formData?.species} onChange={handleChange} name="species" placeholder="Tipo" aria-label="pet_type">
                <option value="Cachorro">Cachorro</option>
                <option value="Gato">Gato</option>
                <option value="Outros">Outros</option>
              </Form.Select>
            </div>
            <div>
              <Form.Label>
                Coloração Predominante
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Form.Select value={formData?.color} onChange={handleChange} name="color" placeholder="Coloração" aria-label="pet_type">
                <option value="Bege">Bege</option>
                <option value="Branco">Branco</option>
                <option value="Branco/Marrom">Branco/Marrom</option>
                <option value="Caramelo">Caramelo</option>
                <option value="Cinza">Cinza</option>
                <option value="Cinza/Tigrado">Cinza/Tigrado</option>
                <option value="Frajola">Frajola</option>
                <option value="Laranja">Laranja</option>
                <option value="Laranja/Tigrado">Laranja/Tigrado</option>
                <option value="Marrom">Marrom</option>
                <option value="Mesclado">Mesclado</option>
                <option value="Preto">Preto</option>
                <option value="Preto/Branco">Preto/Branco</option>
                <option value="Preto/Marrom">Preto/Marrom</option>
                <option value="Siamês">Siamês</option>
              </Form.Select>
            </div>
            <div>
              <Form.Label>
                Sexo
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Form.Select value={formData?.sex} onChange={handleChange} name="sex" placeholder="Gênero" aria-label="pet_gender">
                <option value="Não informado">Não Identificado</option>
                <option value="Macho">Macho</option>
                <option value="Fêmea">Fêmea</option>
              </Form.Select>
            </div>
            <div>
              <Form.Label>
                Idade
                <span style={{ fontSize: '10px', color: '#DA271E' }}>*</span>
              </Form.Label>
              <Form.Select value={formData?.age} onChange={handleChange} name="age" placeholder="idade" aria-label="pet_age">
                <option value="Não informado">Não Identificado</option>
                <option value="Filhote">Filhote</option>
                <option value="Adulto">Adulto</option>
                <option value="Sênior">Sênior</option>
              </Form.Select>
            </div>
          </Form.Group>
          <Form.Group className="mb-3 formGroup" controlId="optionalData">
            <TitleSecond>Dados Opcionais</TitleSecond>
            <div>
              <Form.Label>Número do Chip</Form.Label>
              <Form.Control
                type="number"
                name="chip_number"
                value={formData?.chip_number}
                placeholder="Número do chip de identificação"
                onChange={handleChange}
              />
            </div>
            <div>
              <Form.Label>Observações</Form.Label>
              <Form.Control value={formData?.extra_info} onChange={handleChange} name="extra_info" as="textarea" placeholder="Insira uma observação" />
            </div>
          </Form.Group>
          <ButtonContainer>
            <Button
              className="register-button"
              variant="primary"
              type="submit"
              disabled={
                !formData?.location_id ||
                !formData?.photo ||
                !savePhoto ||
                !formData?.species ||
                !formData?.color ||
                !formData?.sex ||
                !formData?.age ||
                isLoading
              }
            >
              Cadastrar
            </Button>
            <div>
              {showToast && (
                <ToastForm message={message} setShow={setShowToast} />
              )}
            </div>
          </ButtonContainer>
        </Form>
      </Container>
    </React.Fragment>
  );
};
export default PetsRegister;
