import * as jose from 'jose';

export class HelperAuthentication {
  static getCookieByKey(key) {
    const allcookies = document.cookie;
    const cookieArray = allcookies.split('; ');
    const cookieJson = {};
    for (let i = 0; i < cookieArray.length; i++) {
      const name = cookieArray[i].split('=')[0];
      const value = cookieArray[i].split('=')[1];
      cookieJson[name] = value;
    }

    return cookieJson[key];
  }

  static decodeJwt(jwt_token) {
    if (typeof jwt_token) {
      const decode = jose.decodeJwt(jwt_token);
      if (decode) {
        return decode;
      }
      return false;
    }
    return false;
  }
}
