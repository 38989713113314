import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Icon from '@mdi/react';
import { mdiPencil, mdiPaw } from '@mdi/js';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Service } from '../../services';
import { ModalContainer, Header, Body, Label, StyledModal, Text, Title, Line, CustomButtom } from './styles';
import { useQuery } from '../../hooks/useQuery';
import ToastForm from '../Toast';

const PetsModal = ({ selectedData, open, updatedPet, onClose,  changeListType }) => {
  const service = useMemo(() => new Service(), []);
  const [values, setValues] = useState(selectedData);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [editApiToken, setEditApiToken] = useState();
  const [editModes, setEditModes] = useState({
    general: false,
    location: false,
    additional: false
  });

  const [message, setMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  const query = useQuery();

  const ref = useRef();

  const validateToken = useCallback((token) => {
    service.checkEditApiToken(token).then((status) => {
      if (status === 200) {
        setEditApiToken(token);
      }
    });
  }, [service]);

  const getLocations = useCallback(async () => {
    const data = await service.getPetsLocations();
    setLocationsOptions(data);
  }, [service]);

  const handleSave = () => {
    if (editApiToken) {
      service.updatePet(values, editApiToken).then(() => {
        updatedPet(values);
      });
    }
  };

  const handleChangeStatus = async () => {
    if (editApiToken && selectedData?.status === 0) {
      await service.updatePet(
        { id: selectedData?.id, status: 1 },
        editApiToken,
      );
      updatedPet({ ...selectedData, status: 1 });
      changeListType(false);
      setShowToast(true);
      setMessage('Registrada a saída com sucesso!');
    }
  }

  const handleCancel = () => {
    setValues(selectedData);
    clearEdits();
    ref?.current?.clear();
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const allowEdit = (section) => {
    setEditModes((prev) => ({ ...prev, [section]: true }));
  }

  const clearEdits = () => {
    setEditModes({
      general: false,
      location: false,
      additional: false
    });
  }

  useEffect(() => {
    setValues(selectedData);
    clearEdits();
  }, [selectedData]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  useEffect(() => {
    if (!editApiToken) {
      const token = query.get("api_token");
      if (token) {
        validateToken(token);
      }
    }
  }, [query, validateToken, editApiToken]);

  return (
    <StyledModal show={open} onHide={onClose} dialogClassName="modal-right">
      <ModalContainer>
        <Header>
          <div className='imageBox'>
            <img src={selectedData?.photo_link} alt="" />
          </div>
          {editApiToken && (
            <div className="buttonsContainer">
              {
                <CustomButtom
                  disabled={selectedData?.status === 1}
                  className="actionButton"
                  onClick={e => {
                    e.stopPropagation();
                    handleChangeStatus();
                  }}
                >
                  <Icon path={mdiPaw} size={0.75} />
                  <p>Marcar saída</p>
                </CustomButtom>
              }
              <div className='actionButtons'>
                <CustomButtom
                  type='buttom'
                  variant='success'
                  onClick={() => handleSave()}
                  disabled={
                    editModes && !Object.values(editModes).includes(true)
                  }
                >
                  Salvar
                </CustomButtom>
                <CustomButtom
                  disabled={
                    editModes && !Object.values(editModes).includes(true)
                  }
                  type='button'
                  onClick={() => handleCancel()}
                >
                  Cancelar
                </CustomButtom>
              </div>
            </div>
          )}
        </Header>
        <Body>
          <section>
            <Line>
              <Title>Informações Gerais</Title>
              {editApiToken && (
                <button type="button" onClick={() => allowEdit("general")}>
                  <Icon path={mdiPencil} size={0.75} />
                </button>
              )}
            </Line>
            {editModes?.general ? (
              <div className='fields'>
                <div>
                  <Label>Número do chip:</Label>
                  <Form.Control
                    type="number"
                    name="chip_number"
                    value={values?.chip_number}
                    placeholder="Número do chip de identificação"
                    onChange={handleChange}
                    size="sm"
                  />
                </div>
                <div>
                  <Label>Coloração:</Label>
                  <Form.Select size="sm" value={values?.color} onChange={handleChange} name="color" placeholder="Coloração" aria-label="pet_type">
                    <option value="Bege">Bege</option>
                    <option value="Branco">Branco</option>
                    <option value="Branco/Marrom">Branco/Marrom</option>
                    <option value="Caramelo">Caramelo</option>
                    <option value="Cinza">Cinza</option>
                    <option value="Cinza/Tigrado">Cinza/Tigrado</option>
                    <option value="Frajola">Frajola</option>
                    <option value="Laranja">Laranja</option>
                    <option value="Laranja/Tigrado">Laranja/Tigrado</option>
                    <option value="Marrom">Marrom</option>
                    <option value="Mesclado">Mesclado</option>
                    <option value="Preto">Preto</option>
                    <option value="Preto/Branco">Preto/Branco</option>
                    <option value="Preto/Marrom">Preto/Marrom</option>
                    <option value="Siamês">Siamês</option>
                  </Form.Select>
                </div>
                <div>
                  <Label>Sexo:</Label>
                  <Form.Select size="sm" value={values?.sex} onChange={handleChange} name="sex" placeholder="Gênero" aria-label="pet_gender">
                    <option value="Não informado">Não Identificado</option>
                    <option value="Macho">Macho</option>
                    <option value="Fêmea">Fêmea</option>
                  </Form.Select>
                </div>
                <div>
                  <Label>Idade:</Label>
                  <Form.Select size="sm" value={values?.age} onChange={handleChange} name="age" placeholder="idade" aria-label="pet_age">
                    <option value="Não informado">Não Identificado</option>
                    <option value="Filhote">Filhote</option>
                    <option value="Adulto">Adulto</option>
                    <option value="Sênior">Sênior</option>
                  </Form.Select>
                </div>
              </div>
            ) : (
              <div className='fields'>
                <div>
                  <Label>Número do chip:</Label>
                  <Text>
                    {selectedData?.chip_number || '-'}
                  </Text>
                </div>
                <div>
                  <Label>Coloração:</Label>
                  <Text>
                    {selectedData?.color || '-'}
                  </Text>
                </div>
                <div>
                  <Label>Sexo:</Label>
                  <Text>
                    {selectedData?.sex === "unknown" ? "Não identificado" : selectedData?.sex || '-'}
                  </Text>
                </div>
                <div>
                  <Label>Idade:</Label>
                  <Text>
                    {selectedData?.age === "unknown" ? "Não identificado" : selectedData?.age || '-'}
                  </Text>
                </div>
              </div>
            )}
          </section>
          <section>
            <Line>
              <Title>Acolhimento</Title>
              {editApiToken && (
                <button type="button" onClick={() => allowEdit("location")}>
                  <Icon path={mdiPencil} size={0.75} />
                </button>
              )}
            </Line>
            {editModes?.location ? (
              <div>
                <div>
                  <Label>Abrigo:</Label>
                  <Typeahead
                    id="select-location"
                    labelKey="name_city"
                    multiple={false}
                    name="id"
                    ref={ref}
                    value={values?.location_id}
                    options={locationsOptions}
                    placeholder={'Escolha o abrigo'}
                    onChange={location =>
                      setValues({ ...values, location_id: location[0]?.id, location: location[0] })
                    }
                  />
                </div>
              </div>
            ) : (
              <div className='fields'>
                <div>
                  <Label>Abrigo:</Label>
                  <Text>
                    {selectedData?.location?.name_city || '-'}
                  </Text>
                </div>
                <div>
                  <Label>Endereço:</Label>
                  <Text>
                    {`${selectedData?.location?.address}, Nº ${selectedData?.location?.number || '-'}, Bairro ${selectedData?.location?.neighborhood}`}
                    <br />
                    {`${selectedData?.location?.city} - ${selectedData?.location?.uf}`}
                  </Text>
                </div>
              </div>
            )}
          </section>
          <section>
            <Line>
              <Title>Informações complementares</Title>
              {editApiToken && (
                <button type="button" onClick={() => allowEdit("additional")}>
                  <Icon path={mdiPencil} size={0.75} />
                </button>
              )}
            </Line>
            <div>
              <Label>Observações: </Label>
              {editModes?.additional ? (
                <Form.Control size="sm" value={values?.extra_info} onChange={handleChange} name="extra_info" as="textarea" placeholder="Insira uma observação" />
              ) : (
                <Text>
                  {selectedData?.extra_info || '-'}
                </Text>
              )}
            </div>
          </section>
        </Body>
      </ModalContainer>
      <div>
        {showToast && (
          <ToastForm
            style={{ width: '100%' }}
            message={message}
            setShow={setShowToast}
            toastPosition={'top-end'}
          />
        )}
      </div>
    </StyledModal>
  );
};

export default PetsModal;
