
import styled from 'styled-components';

import { Container } from 'react-bootstrap';


export const ContainerStyled = styled(Container)`
  margin-top: 20px;
  width: 100%;
  max-width: 720px;

  textarea {
    margin-top: 10px;
  }

  label{
    margin-top: 6px;
  }

  .form-check-input {
    height: 20px;
  }


  .custom-switch {
    margin-left: 4px;
  }

`;
  export const Title = styled.h1`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #858585;

`;